import React from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'
import AppLoader from './AppLoader'
import { AppConfig, CatalogRenderProps, ContextConfig, ElementRenderProps } from './types'
import store from './redux'
import { Provider } from 'react-redux'
import {
  connectCustomer,
  storeSettings,
  toggleAdmin,
  toggleIsSnapshotRender,
  updateContext as _updateContext,
  debug as _debug
} from './redux/slices/app'
import { getWishListSettings } from './redux/slices/wishlist'
import FilterApp from './components/FilterApp'
import SearchApp from './components/SearchApp'
import { hooks } from './lib/hooks'
import NoginSession from './lib/nogin-session'
import { Element } from './elements/index'
import { analytics } from './lib/analytics-plugin-nogin'
import './styles.scss'
import domToImage from 'dom-to-image-more'
import { CartProvider, ShopifyProvider } from '@shopify/hydrogen-react'

declare global {
  interface Window {
    ElementComponents: any
  }
}

const DEFAULT_SERVER = 'https://app.nogin.com'
const ElementComponents = import.meta.glob(
  `./elements/**/!(*.test.tsx|_*|*.ts)`,
  { eager: true }
)
window.ElementComponents = ElementComponents

const init = (config: AppConfig) => {
  // setup default axios configuration
  axios.defaults.baseURL = `${config.server || DEFAULT_SERVER}/api/v1`
  axios.defaults.params = {}
  axios.defaults.params['publicToken'] = config.publicToken
  axios.defaults.params['sid'] = session.sessionId
  axios.defaults.params['isNewSession'] = session.isNewSession
  axios.defaults.headers['x-session-id'] = session.sessionId

  store.dispatch(storeSettings({ config }))
  store.dispatch(connectCustomer({ config }))

  ReactDOM.render(
    <Provider store={store}>
      <AppLoader />
    </Provider>,
    document.getElementById('nogin-toolbox') as HTMLElement
  )
}

const session: NoginSession = new NoginSession()

const updateContext = (context: ContextConfig) => {
  store.dispatch(_updateContext(context))
}

const debug = (enabled: boolean) => {
  store.dispatch(_debug(enabled))
}

const wishlist = {
  reload: () => {
    store.dispatch(getWishListSettings())
  }
}

const catalog = {
  render: ({ type, elementId, config }: CatalogRenderProps) => {
    let Component
    if (type === 'filter') {
      Component = FilterApp
    } else if (type === 'search') {
      Component = SearchApp
    } else {
      console.log(`${type} unknown`)
      return
    }

    ReactDOM.render(
      <Provider store={store}>
        <Component config={config} />
      </Provider>,
      document.getElementById(elementId)
    )
  }
}

const elements = {
  render: ({
    domElementId,
    name,
    data,
    slug,
    isCardLayout,
    redirectOn404 = false
  }: ElementRenderProps) => {
    const domElement = document.getElementById(domElementId)
    if (!domElement) {
      console.log(`DOM element id ${domElementId} not found.`)
      return
    }
    
    try {
      //don't want to kill render function with analytics
      analytics.track('elementView', {
        location: window?.location?.toString(),
        key: name,
        slug
      })
    } catch (e) {
      console.log('analytics call error')
    }

    const { app } = store.getState()

    const RenderedElement = (
      <CartProvider>
        <Provider store={store}>
          <Element
            data={data}
            name={name}
            slug={slug}
            isCardLayout={isCardLayout}
            onError={(e) => {
              if (axios.isAxiosError(e)) {
                const httpErrorCode = e.response?.status
                if (httpErrorCode === 404) {
                  if (redirectOn404 && import.meta.env.MODE !== 'development') {
                    window.location.href = '/404'
                  }
                }
              }
            }}
          />
        </Provider>
      </CartProvider>
    )

    if (app.site.enableHeadlessCommerce && app.storefront?.accessToken && app.storefront?.url) {
      ReactDOM.render(
        <ShopifyProvider
          storeDomain={`https://${app.storefront.url}`}
          storefrontToken={app.storefront.accessToken}
          storefrontApiVersion='2023-10'
          countryIsoCode='US'
          languageIsoCode='EN'>
          {RenderedElement}
        </ShopifyProvider>,
        domElement
      )
    } else {
      ReactDOM.render(RenderedElement, domElement)
    }
  },

  getComponent(name: string, data: any) {
    return (
      <Provider store={store}>
        <Element data={data} name={name} />
      </Provider>
    )
  },

  toggleAdmin: () => {
    store.dispatch(toggleAdmin(true))
  },

  toggleIsSnapshotRender: (isSnapshotRender = false) => {
    store.dispatch(toggleIsSnapshotRender(isSnapshotRender))
  },

  takeScreenshot: async (params: {
    domElementId: string
    allowDownload: boolean
  }): Promise<any | null> => {
    const { domElementId, allowDownload = false } = params

    const domNode = document.getElementById(domElementId)

    if (!domNode) {
      console.error(`Unable to take screenshot of DOM Node Id #${domElementId}`)
      return null
    }

    // set the background to white if it's transparent
    const oldColor = domNode.style.backgroundColor
    domNode.style.backgroundColor = '#fff'

    let oldDisplay = domNode.style.display ?? ''
    let oldFlexDirection = domNode.style.flexDirection ?? ''
    let oldAlignItems = domNode.style.alignItems ?? ''

    let oldWidth = domNode.style.width ?? ''
    let oldHeight = domNode.style.height ?? ''
    if (domNode?.offsetWidth < 150) domNode.style.width = '150px'
    if (domNode?.offsetHeight < 250) {
      // for Elements w/ limited content
      // Extend the height to a minimum
      // & center vertically to get a more aesthetically pleasing Snapshot
      domNode.style.display = 'flex'
      domNode.style.flexDirection = 'row'
      domNode.style.alignItems = 'center'
      domNode.style.height = '250px'
    }

    let data

    try {
      data = await domToImage.toBlob(domNode)

      // We can optionally allow the image to be downloaded
      // Either for sharing, or... just development, to make sure our Previews are generated correctly
      if (allowDownload) {
        domToImage.toPng(domNode).then((dataUrl: string) => {
          var link = document.createElement('a')
          link.download = 'test.png'
          link.href = dataUrl
          link.click()
        })
      }
    } catch (error: any) {
      console.error('oops, something went wrong!', error)
    } finally {
      domNode.style.backgroundColor = oldColor

      domNode.style.display = oldDisplay
      domNode.style.flexDirection = oldFlexDirection
      domNode.style.alignItems = oldAlignItems

      domNode.style.width = oldWidth
      domNode.style.height = oldHeight
    }

    return data
  }
}

const on = (eventName: string, handler: (result: any) => void) => {
  store.subscribe(() => {
    const lastAction = store.getState().lastAction as any
    // this sends data out to 3rd party subscribers
    if (lastAction.type === 'app/sendEvent') {
      if (eventName == lastAction.payload.source) {
        handler(lastAction.payload.data)
      }
    }
  })
}

// backwards compat with lum
const discount = {
  render: () => {},
  reload: () => {}
}

export {
  init,
  updateContext,
  wishlist,
  catalog,
  elements,
  hooks,
  on,
  debug,
  analytics,
  session,
  discount
}
