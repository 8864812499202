import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { SubPickerProps } from './Picker'
import { CollectionFilterReviewsCountRangesNestedModel } from '../models'
import { ListTitle } from './ListPicker'
import { Container } from './PricePicker'

export default function ReviewsCountPicker({
  filter,
  defaultSelected,
  facetedData,
  onPicked,
  setPickerVisibility
}: SubPickerProps) {
  const [selected, setSelected] = useState(defaultSelected)
  const [validRanges, setValidRanges] = useState<string[]>([])

  useEffect(() => {
    if (defaultSelected) {
      setSelected(filter.selectable == 'single' ? [defaultSelected[0]] : defaultSelected)
    } else {
      setSelected([])
    }
  }, [defaultSelected])

  useEffect(() => {
    //find which reviews counts ranges have products in them
    if (filter.hideEmpty && facetedData?.reviewsCounts?.length > 0) {
      let tempRanges: string[] = []
      //count products in each reviews counts range
      for (const item of facetedData.reviewsCounts) {
        if (item.count > 0) {
          for (const range of filter.reviewsCountRanges) {
            if (
              item.reviews_count >= range.minReviewsCounts &&
              item.reviews_count <= range.maxReviewsCounts
            ) {
              tempRanges.push(range.title)
            }
          }
        }
      }
      setValidRanges(tempRanges)
    }
  }, [facetedData])

  function isActive(item: any) {
    return selected?.indexOf(item) !== -1
  }

  function onClickListTitle(
    itemValue: any,
    e?: React.MouseEvent<HTMLAnchorElement, MouseEvent> | React.KeyboardEvent<HTMLAnchorElement>
  ) {
    e?.preventDefault()

    let newSelected
    if (!isActive(itemValue)) {
      newSelected = filter.selectable == 'single' ? [itemValue] : [...selected, itemValue]
    } else {
      newSelected = [...selected.filter((o: any) => o !== itemValue)]
    }

    if (onPicked) {
      onPicked(newSelected)
    }

    setSelected(newSelected)
  }

  return (
    <Container data={filter} className='picker-item-container'>
      {filter.reviewsCountRanges
        .filter((reviewCount: CollectionFilterReviewsCountRangesNestedModel) => {
          const hideEmpty: boolean = filter.hideEmpty && !validRanges.includes(reviewCount.title)
          return !hideEmpty
        })
        .map((reviewCount: CollectionFilterReviewsCountRangesNestedModel, i: number) => {
          const itemValue = reviewCount.minReviewsCounts + ':' + reviewCount.maxReviewsCounts
          return (
            <a
              key={i}
              href='#'
              className={classNames(filter.displayType, {
                active: isActive(itemValue)
              })}
              onClick={(e) => onClickListTitle(itemValue, e)}
              onKeyUp={(e) => (e.code === 'Enter' ? onClickListTitle(itemValue, e) : null)}>
              <ListTitle
                showIcon={filter.textShowIcon}
                name={reviewCount.title}
                isActive={isActive(itemValue)}
                itemCount={0}
              />
            </a>
          )
        })}
    </Container>
  )
}
