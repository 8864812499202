import React from 'react'
import { CloseCircleOutlined } from '@ant-design/icons'
import { NotificationAppData } from '@nogin/api/src/appdata/appearance'
import Handlebars from 'handlebars'

interface NotificationItemProps {
  settings: NotificationAppData
  title?: string
  subtitle?: string
  linkTitle?: string
  linkUrl?: string
  onClose?: () => void
}

function NotificationItem({
  settings,
  title = '',
  subtitle = '',
  linkTitle = '',
  linkUrl = '',
  onClose
}: NotificationItemProps) {
  const originalTitle = title
  const originalSubtitle = subtitle

  try {
    let template = Handlebars.compile(title)
    title = template({ link: new Handlebars.SafeString(`<a href="${linkUrl}">${linkTitle}</a>`) })

    template = Handlebars.compile(subtitle)
    subtitle = template({
      link: new Handlebars.SafeString(`<a href="${linkUrl}">${linkTitle}</a>`)
    })
  } catch (e) {
    // A parse error occurs if you type in an incomplete expression like "{{"
    // Uncomment this to see the error
    // For now, we can catch the error and just set the text to the original
    // console.error(e)

    title = originalTitle
    subtitle = originalSubtitle
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: settings.backgroundColor,
        color: settings.foregroundColor,
        borderRadius: settings.borderRadius,
        borderColor: settings.borderColor,
        borderStyle: 'solid',
        borderWidth: settings.borderWidth || 0,
        paddingLeft: settings.paddingHorizontal || 0,
        paddingRight: settings.paddingHorizontal || 0,
        paddingTop: settings.paddingVertical || 0,
        paddingBottom: settings.paddingVertical || 0,
        boxShadow: (settings.shadow && '0px 0px 10px #D0D0D0') || '',
        margin: 5
      }}>
      <div>
        <div style={{ fontSize: 15 }} dangerouslySetInnerHTML={{ __html: title }} />
        <div style={{ fontSize: 12 }} dangerouslySetInnerHTML={{ __html: subtitle }} />
      </div>
      <CloseCircleOutlined
        style={{
          fontSize: 20,
          alignSelf: 'center',
          marginLeft: '10px'
        }}
        onClick={() => onClose?.()}
        onKeyUp={(e) => (e.code === 'Enter' ? onClose?.() : null)}
      />
    </div>
  )
}

export default NotificationItem
