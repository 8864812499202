import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import styled from '@emotion/styled'
import { SubPickerProps } from './Picker'
import CollectionFilterModel, { CollectionFilterPriceRangesNestedModel } from '../models'
import { ListTitle } from './ListPicker'

export const Container = styled.div<{ data: CollectionFilterModel }>`
  flex-grow: 1;
  overflow: auto;
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(${(props) => props.data.textGridColumns}, minmax(0, 1fr));
  flex-wrap: wrap;
  font-size: ${(props) => props.data.textFontSize};
  color: ${(props) => props.data.textFontColor};
  gap: ${(props) => props.data.textSpacing};

  .text-picker {
    color: ${(props) => props.data.textFontColor};
    text-decoration: none;
    border: ${(props) => props.data.textBorderThickness} solid
      ${(props) => props.data.textBorderColor};
    border-radius: ${(props) => props.data.textBorderRadius};
    padding: ${(props) => props.data.textPadding};
    background-color: ${(props) => props.data.textBackgroundColor};
    display: flex;
    align-items: center;
    justify-content: ${(props) => props.data.textContentAlignment};
    text-align: ${(props) => {
      switch (props.data.textContentAlignment) {
        case 'flex-start':
          return 'left'
        case 'center':
          return 'center'
        case 'flex-end':
          return 'right'
      }
    }};
  }

  .active {
    color: ${(props) => props.data.textFontSelectedColor};
    border: ${(props) => props.data.textBorderThickness} solid
      ${(props) => props.data.textBorderSelectedColor};
    background-color: ${(props) => props.data.textBackgroundSelectedColor};
  }

  .disabled {
    opacity: ${(props) => props.data.textDisabledOpacity};
  }

  .picker-item-row {
    display: flex;
    gap: 5px;
    align-items: center;
  }
`

export default function PricePicker({
  filter,
  defaultSelected,
  facetedData,
  onPicked,
  setPickerVisibility
}: SubPickerProps) {
  const [selected, setSelected] = useState(defaultSelected)
  const [validRanges, setValidRanges] = useState<string[]>([])

  useEffect(() => {
    if (defaultSelected) {
      setSelected(filter.selectable == 'single' ? [defaultSelected[0]] : defaultSelected)
    } else {
      setSelected([])
    }
  }, [defaultSelected])

  useEffect(() => {
    //find which price ranges have products in them
    if (filter.hideEmpty && facetedData?.prices?.length > 0) {
      let tempRanges: string[] = []
      //count products in each price range
      for (const item of facetedData.prices) {
        if (item.count > 0) {
          const price = item.price / 100
          for (const priceRange of filter.priceRanges) {
            if (price >= priceRange.minPrice && price <= priceRange.maxPrice) {
              tempRanges.push(priceRange.title)
            }
          }
        }
      }
      setValidRanges(tempRanges)
    }
  }, [facetedData])

  function isActive(item: any) {
    return selected?.indexOf(item) !== -1
  }

  function onClickListTitle(
    itemValue: any,
    e?: React.MouseEvent<HTMLAnchorElement, MouseEvent> | React.KeyboardEvent<HTMLAnchorElement>
  ) {
    e?.preventDefault()

    let newSelected
    if (!isActive(itemValue)) {
      newSelected = filter.selectable == 'single' ? [itemValue] : [...selected, itemValue]
    } else {
      newSelected = [...selected.filter((o: any) => o !== itemValue)]
    }

    if (onPicked) {
      onPicked(newSelected)
    }

    setSelected(newSelected)
  }

  return (
    <Container data={filter} className='picker-item-container'>
      {filter.priceRanges
        .filter((price: CollectionFilterPriceRangesNestedModel, i: number) => {
          const hideEmpty: boolean = filter.hideEmpty && !validRanges.includes(price.title)
          return !hideEmpty
        })
        .map((price: CollectionFilterPriceRangesNestedModel, i: number) => {
          const itemValue = price.minPrice * 100 + ':' + price.maxPrice * 100
          return (
            <a
              key={i}
              href='#'
              className={classNames(filter.displayType, {
                active: isActive(itemValue)
              })}
              onClick={(e) => onClickListTitle(itemValue, e)}
              onKeyUp={(e) => (e.code === 'Enter' ? onClickListTitle(itemValue, e) : null)}>
              <ListTitle
                showIcon={filter.textShowIcon}
                name={price.title}
                isActive={isActive(itemValue)}
                itemCount={0}
              />
            </a>
          )
        })}
    </Container>
  )
}
