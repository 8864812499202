import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import CountdownModel from './models'
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds
} from 'date-fns'

interface Props {
  data: CountdownModel
  containerProps: any
}

const Container = styled.div<{ data: CountdownModel }>`
  display: flex;
  gap: ${(props) => props.data.gap};
  align-items: center;

  > div {
    display: flex;
    flex-direction: column;
    text-transform: uppercase;

    span {
      display: ${(props) => (props.data.showLabel ? 'inline' : 'none')};
      font-size: ${(props) => props.data.labelFontSize};
      text-align: center;
      color: ${(props) => props.data.labelTextColor};
    }
  }

  .value {
    display: inline-block;
    padding: ${(props) => props.data.padding};
    background-color: ${(props) => props.data.backgroundColor};
    color: ${(props) => props.data.textColor};
    border-color: ${(props) => props.data.borderColor};
    border-width: ${(props) => props.data.borderThickness};
    border-radius: ${(props) => props.data.borderRadius};
    width: ${(props) => props.data.width};
    text-align: center;
    font-size: ${(props) => props.data.fontSize};
  }

  > span {
    display: ${(props) => (props.data.showSeparator ? 'inline-block' : 'none')};
    color: ${(props) => props.data.textColor};
  }
`

export default function Countdown({ data, containerProps }: Props) {
  const [now, setNow] = useState(new Date())

  useEffect(() => {
    function tick() {
      setNow(new Date())

      setTimeout(() => {
        tick()
      }, 1000)
    }

    tick()
  }, [])

  const endDateTime = data?.countdownDate ? new Date(data.countdownDate) : new Date()
  const days = differenceInDays(endDateTime, now)
  const hours = differenceInHours(endDateTime, now) - days * 24
  const mins = differenceInMinutes(endDateTime, now) - days * 24 * 60 - hours * 60
  const secs =
    differenceInSeconds(endDateTime, now) - days * 24 * 60 * 60 - hours * 60 * 60 - mins * 60

  return (
    <Container data={data} {...containerProps}>
      {data.showDays && (
        <>
          <div>
            <span>Days</span>
            <div className='value'>{days}</div>
          </div>
          <span>:</span>
        </>
      )}
      {data.showHours && (
        <>
          <div>
            <span>Hrs</span>
            <div className='value'>{hours}</div>
          </div>
          <span>:</span>
        </>
      )}
      {data.showMins && (
        <>
          <div>
            <span>Mins</span>
            <div className='value'>{mins}</div>
          </div>
          <span>:</span>
        </>
      )}
      {data.showSecs && (
        <div>
          <span>Secs</span>
          <div className='value'>{secs}</div>
        </div>
      )}
    </Container>
  )
}
