import React from 'react'
import DynamicPlacementModel from './models'

interface Props {
  data: DynamicPlacementModel
  containerProps: any
}

export default function DynamicPlacement({ data, containerProps }: Props) {
    return <div {...containerProps}></div>
}
