import React, { useCallback, useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import querystring from 'querystring'
import {
  getCustomerWishLists,
  getCustomerWishListItemsForList,
  getWishListUsingCode
} from '../../redux/slices/wishlist'
import { useAppDispatch, useAppSelector } from '../../redux'
import Button from '../Button'
import WishListManagerHeader from './WishListManagerHeader'
import WishListItem from './WishListItem'
import { WishListCustomerListPayload } from '../../types'
import IntlPricesLoader from '../../components/IntlPricesLoader/'

export default function WishListManager() {
  const isMobile = useMediaQuery({ query: '(max-width: 800px)' })
  const lists = useAppSelector((state) => state.wishlist.customer.lists)
  const sharedList = useAppSelector((state) => state.wishlist.customer.sharedList)
  const currentListId = useAppSelector((state) => state.wishlist.customer.currentListId)
  const currentItemTotal = useAppSelector((state) => state.wishlist.customer.currentListItemTotal)
  const currentItems = useAppSelector((state) => state.wishlist.customer.currentListItems)
  const pageIndex = useAppSelector((state) => state.wishlist.customer.currentListPageIndex)
  const sortBy = useAppSelector((state) => state.wishlist.customer.listSortBy)
  const managerSettings = useAppSelector((state) => state.wishlist.settings.manager)
  const [editable, setEditable] = useState(true)
  const [shareCode, setShareCode] = useState('')
  const [initialLoad, setInitialLoad] = useState(true)
  const [displayLists, setDisplayLists] = useState<WishListCustomerListPayload[]>([])
  const loading = useAppSelector((state) => state.app.loading)
  const customer = useAppSelector((state) => state.app.customer)
  const dispatch = useAppDispatch()

  // load initial wish list
  useEffect(() => {
    // check if share code exists in querystring
    const query = querystring.parse(window.location.search.replace('?', ''))
    if (Object.keys(query).includes('code')) {
      setEditable(false)

      const code = query.code as string
      setShareCode(code)

      // load wishlist using code
      dispatch(getWishListUsingCode({ code }))
    } else {
      setShareCode('')
      dispatch(getCustomerWishLists())
    }

    if (initialLoad) {
      setInitialLoad(false)
    }
  }, [])

  useEffect(() => {
    if (!editable && sharedList) {
      setDisplayLists([sharedList])
    } else {
      setDisplayLists(lists)
    }
    // If customer has no wishlists, just an empty fake wishlist returned, do not allow editing since nothing exists
    if (lists.length === 1 && !lists[0]?.id) {
      setEditable(false)
    }
  }, [editable, sharedList, shareCode, lists])

  useEffect(() => {
    if (currentListId) {
      if (!editable && sharedList) {
        dispatch(getCustomerWishListItemsForList({ shareCode, pageIndex: 1 }))
      } else {
        dispatch(getCustomerWishListItemsForList({ listId: currentListId as string, pageIndex: 1 }))
      }
    }
  }, [sortBy])

  const handleLoadMoreClick = useCallback(() => {
    if (!editable && sharedList) {
      dispatch(getCustomerWishListItemsForList({ shareCode, pageIndex: pageIndex + 1 }))
    } else {
      dispatch(
        getCustomerWishListItemsForList({
          listId: currentListId as string,
          pageIndex: pageIndex + 1
        })
      )
    }
  }, [lists, displayLists, currentItems, pageIndex, sortBy])

  if (initialLoad) return <></>

  if (!managerSettings.allowAnonymousUser && !!!customer?.isRegistered) {
    // @ts-ignore check if running on shopify site
    if (typeof Shopify !== 'undefined') {
      window.location.href = '/account/login'
    } else {
      console.log(
        'DEBUG: currently not running on live site. Please login to use the app or enable anonymous usage.'
      )
    }
    return <></>
  }

  return (
    <div style={{ minHeight: 400 }}>
      <WishListManagerHeader
        lists={displayLists}
        editable={editable}
        onWishListChanged={() => {
          dispatch(getCustomerWishLists())
        }}
      />
      {!loading && (
        <>
          <IntlPricesLoader items={currentItems?.map((item: any) => item?.product )} />
          <section
            style={{
              display: 'grid',
              gridTemplateColumns: isMobile
                ? `repeat(${managerSettings.gridColumnsMobile}, 1fr)`
                : `repeat(${managerSettings.gridColumns}, 1fr)`,
              gap: 10
            }}>
            {currentItems.map((item: any) => (
              <WishListItem item={item} key={item.id} editable={editable} />
            ))}
            {currentItems.length === 0 && managerSettings.emptyMessage}
          </section>
          {currentItemTotal > currentItems.length && (
            <div className='pagination load-more' style={{ margin: 30, textAlign: 'center' }}>
              <div className='more-text' style={{ marginBottom: 20, fontSize: '.8em' }}>
                There are {currentItemTotal - currentItems.length} more items
              </div>
              <Button type='secondary' onClick={handleLoadMoreClick}>
                Load More
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  )
}
