export const loadAllMessagesForReplay = async (channels: []) => {
  const promises = channels.map(async (channel) => {
    return await getAllChannelMessages(channel)
  })
  await Promise.all(promises)
}

const getAllChannelMessages = async (channel: any) => {
  const numMessagesToRequest = 300
  let lastMessageId: string
  let messages: any[] = []
  let m
  do {
    lastMessageId = messages.length ? messages[0].id : null
    m = (await _getMoreMessages(channel, numMessagesToRequest, lastMessageId)) || []
    /**
     * getMoreMessages returns an array of messages including
     * the message with id equal to lastMessageId
     * id_lte explained here: https://getstream.io/chat/docs/channel_pagination/?language=js
     * if we concat we'll end up with duplicates in the messages list
     * to prevent lets just filter out this message
     */
    messages = m.filter((mi: any) => mi.id !== lastMessageId).concat(messages)
  } while (m.length === numMessagesToRequest)
  return messages
}

const _getMoreMessages = async (channel: any, limit: any, messageIdLte: any) => {
  try {
    const currentChannel = await channel.query({
      messages: {
        limit: limit,
        id_lte: messageIdLte
      }
    })
    /**
     * If we got more messages, return them
     * If not, then return an empty array
     */
    if (currentChannel.messages.length) {
      return currentChannel.messages
    } else {
      return []
    }
  } catch (e) {
    console.error('There was an error getting more messages from Stream chat', e)
    return false
  }
}
