/**
* THIS FILE WAS AUTOGENERATED USING NOGIN CLI
**/




export default class ContainerStyleModel {
  __id: string = ''
  __type: 'ContainerStyle' = 'ContainerStyle'
  __elementId: string = ''
  visibility: string = 'visible'
  widthType: string = 'auto'
  widthValue: string  = '0px'
  heightType: string = 'auto'
  heightValue: string  = '0px'
  topType: string = 'auto'
  topValue: string  = '0px'
  leftType: string = 'auto'
  leftValue: string  = '0px'
  paddingTop: string  = '0px'
  paddingBottom: string  = '0px'
  paddingLeft: string  = '0px'
  paddingRight: string  = '0px'
  marginTop: string  = '0px'
  marginBottom: string  = '0px'
  marginLeft: string  = '0px'
  marginRight: string  = '0px'
  borderTop: string  = '0px'
  borderBottom: string  = '0px'
  borderLeft: string  = '0px'
  borderRight: string  = '0px'
  borderThickness: string  = '0px'
  borderColor: string = ''
  borderRadius: string  = '0px'
  backgroundColor: string = ''
}

