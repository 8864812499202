import React, { ReactNode } from 'react'
import styled from '@emotion/styled'

interface SelectContainerProps {
  ghost?: boolean
}

const SelectContainer = styled.select<SelectContainerProps>`
  cursor: pointer;
  padding: 10px;
  padding-right: 40px;
  border: ${(props) => (props.ghost ? 0 : '1px solid #939393')};
  border-radius: 3px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAhElEQVQ4T+3TMQqDQBCF4U9IlSKQOpDDpMkh7LxG6lwjnYdI42EEayGFVQoZ2IDIogFbt5udef8Mj5nCxlds1NsBsh5c0GOYGXzEGd30P2fiEzfc8UnFJ7zR4LEGOKDGNUGiPsQtSnzXAJGfQiLOiiOxtAcBeaVu1bzzb4p9kZZN/OtQRwRBEBGAmtluAAAAAElFTkSuQmCC)
    no-repeat right transparent;
  -webkit-appearance: none;
  background-position-x: calc(100% - 5px);
  outline: none;
`

interface SelectProps
  extends React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  > {
  children: ReactNode[]
  ghost?: boolean
}

export default function Select({
  children,
  autoComplete,
  autoFocus,
  value,
  form,
  multiple,
  name,
  required,
  size,
  onChange,
  ghost
}: SelectProps) {
  return (
    <SelectContainer
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      form={form}
      multiple={multiple}
      name={name}
      required={required}
      size={size}
      value={value}
      ghost={ghost}
      onChange={onChange}>
      {children}
    </SelectContainer>
  )
}
