import React, { useState, useRef } from 'react'
import VideoEmbedModel from './models'

interface VideoEmbedProps {
  data?: VideoEmbedModel
  containerProps?: string[]
}

export default function VideoEmbed({ data, containerProps }: VideoEmbedProps) {
  if (!data || !data.videoId) return

  const [showPlaceholderImage, setShowPlaceholderImage] = useState(true)
  const iframeRef = useRef<HTMLIFrameElement>(null)

  let iframeSrc = ''
  let queryParams = new URLSearchParams()

  // global settings
  queryParams.append('autoplay', `${data.autoplay ? 1 : 0}`)
  queryParams.append('controls', `${data.controls ? 1 : 0}`)
  queryParams.append('loop', `${data.loop ? 1 : 0}`)
  queryParams.append('playsinline', `${data.playsInline ? 1 : 0}`)

  switch (data.videoService) {
    case 'vimeo':
      queryParams.append('background', `${data.background ? 1 : 0}`)
      queryParams.append('muted', `${data.muted ? 1 : 0}`)
      queryParams.append('transparent', `${data.transparent ? 1 : 0}`)
      queryParams.append('portrait', `${data.portrait ? 1 : 0}`)
      queryParams.append('title', `${data.titleParam ? 1 : 0}`)
      queryParams.append('byline', `${data.byline ? 1 : 0}`)
      iframeSrc = `https://player.vimeo.com/video/${data.videoId}?${queryParams.toString()}`
      break
    case 'youtube':
      queryParams.append('enablejsapi', '1')
      iframeSrc = `https://www.youtube.com/embed/${data.videoId}?${queryParams.toString()}`
      break
  }

  if (iframeSrc === '') return

  function handlePlaceholderImageClick() {
    setShowPlaceholderImage(false)
    switch (data?.videoService) {
      case 'vimeo':
        iframeRef?.current?.contentWindow?.postMessage(JSON.stringify({ method: 'play' }), '*')
        break
      case 'youtube':
        iframeRef?.current?.contentWindow?.postMessage(
          JSON.stringify({
            event: 'command',
            func: 'playVideo'
          }),
          '*'
        )
        break
    }
  }

  return (
    <div {...containerProps}>
      <div
        style={{
          padding: '56.25% 0 0 0',
          position: 'relative'
        }}>
        {data.enablePlaceholderImage && data.placeholderImage && showPlaceholderImage ? (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 2
            }}
            onClick={handlePlaceholderImageClick}
            onKeyUp={(e) => (e.code === 'Enter' ? handlePlaceholderImageClick() : null)}>
            <img
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                cursor: 'pointer'
              }}
              src={data.placeholderImage}
            />
          </div>
        ) : (
          <></>
        )}
        <iframe
          ref={iframeRef}
          src={iframeSrc}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 1
          }}
          frameBorder='0'
          allow='autoplay; fullscreen; picture-in-picture'
          allowFullScreen={true}></iframe>
      </div>
    </div>
  )
}
