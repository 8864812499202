import React from 'react'
import { useAppDispatch, useAppSelector } from './redux'
import WishListApp from './components/WishListApp'
import NotificationManager from './components/NotificationManager'
import LoadingManager from './components/LoadingManager'
import { useEffect } from 'react'
import { sendEvent } from './redux/slices/app'
import PlacementManager from './components/PlacementManager/PlacementManager'
import PreviewManager from './components/PreviewManager'
import { analytics } from './lib/analytics-plugin-nogin'
import { CartProvider, ShopifyProvider } from '@shopify/hydrogen-react'
import cartFragment from './lib/headless/cartFragment'

function AppLoader() {
  const appReady = useAppSelector((state) => state.app.ready)
  const storefront = useAppSelector((state) => state.app.storefront)
  const enableHeadlessCommerce = useAppSelector((state) => state.app.site?.enableHeadlessCommerce)
  const dispatch = useAppDispatch()

  useEffect(() => {
    //if pdp page, send pdp page view event to anaytlics
    try {
      analytics.plugins.noginPlugin.pdpViewOrNot(window.location)
    } catch (e) {
      console.log(`pdpViewOrNot: ${e}`)
    }
  }, [])

  useEffect(() => {
    if (appReady) {
      dispatch(sendEvent({ source: 'ready' }))
    }
  }, [appReady])

  if (appReady) {
    if (enableHeadlessCommerce && storefront?.url && storefront?.accessToken) {
      return (
        <ShopifyProvider
          storeDomain={`https://${storefront.url}`}
          storefrontToken={storefront.accessToken}
          storefrontApiVersion='2023-10'
          countryIsoCode='US'
          languageIsoCode='EN'>
          <CartProvider cartFragment={cartFragment}>
            <PreviewManager />
            <LoadingManager />
            <NotificationManager />
            <WishListApp />
            <PlacementManager />
          </CartProvider>
        </ShopifyProvider>
      )
    }

    return (
      <CartProvider>
        <PreviewManager />
        <LoadingManager />
        <NotificationManager />
        <WishListApp />
        <PlacementManager />
      </CartProvider>
    )
  }
  return null
}

export default AppLoader
