import React, { ReactNode, useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { WishListAddButtonAppData } from '@nogin/api/src/appdata/wishlist'
import { useAppDispatch, useAppSelector } from '../../../redux'
import { WishListItemPayload } from '../../../types'
import ShopifyImage from '../../../components/ShopifyImage'
import WishListAddButton from '../../../components/WishListAddButton'
import FamilyPicker from './FamilyPicker'
import ProductActions from '../../../components/ProductCard/ProductActions'
import ProductCustomFooter from '../../../components/ProductCard/ProductCustomFooter'
import ProductPrice from '../../../components/ProductCard/ProductPrice'
import ProductTitle from '../../../components/ProductCard/ProductTitle'
import ProductReview from '../../../components/ProductCard/ProductReview'
import { hooks } from '../../../lib/hooks'
import ProductOptionPicker from '../../../components/ProductCard/ProductOptionPicker'
import ProductColorPicker from './ProductColorPicker'
import QuickBuy from '../../../components/ProductCard/QuickBuy'
import { ProductCardLayoutCardLayoutNestedModel } from '../../ProductCardLayout/models'
import Html from '../../Html'
import HtmlModel from '../../Html/models'
import { IntlPrices } from '../../../types'
import classNames from 'classnames'

export interface ButtonAction {
  type: 'primary' | 'secondary' | 'link'
  label: string
  disabled?: boolean
  onClick?: (currentProduct: any) => void
}

interface ProductCardProps {
  product: any
  baseCollection?: string
  actions?: ButtonAction[]
  overlayElements?: ReactNode[]
  layout: 'vertical' | 'horizontal'
  responsive?: boolean
  desktopGridTemplate?: string
  cardLayout?: ProductCardLayoutCardLayoutNestedModel[]
  onChangeVariant?: (variant: any) => void
  showWishListButton?: boolean
  showQuickBuy?: boolean
  source?: string
  settings?: any
  placeholderPaddingBottom?: number
  forceShowQuickBuyButton?: boolean
  position?: number
}

const swatchMatch: string = 'swatch'
const thumbnailMatch: string = 'thumbnail'

const mobileBreakpoint: string = '800px'

export default function ProductCard({
  product,
  baseCollection,
  actions,
  overlayElements,
  layout,
  responsive,
  desktopGridTemplate,
  cardLayout,
  onChangeVariant,
  showWishListButton = false,
  showQuickBuy = false,
  source = 'list',
  settings,
  placeholderPaddingBottom = 100,
  forceShowQuickBuyButton = false,
  position
}: ProductCardProps) {
  const dispatch = useAppDispatch()
  const config = useAppSelector((state) => state.app.config)
  const isMobile = useMediaQuery({ query: `(max-width: ${mobileBreakpoint})` })
  const [currentProduct, setCurrentProduct] = useState(product)
  const [selectedImagePosition, setSelectedImagePosition] = useState(1)
  const [image1, setImage1] = useState<any>()
  const [image2, setImage2] = useState<any>()
  const [family, setFamily] = useState<any[]>([])
  const [variant, setVariant] = useState<any>()
  const [imagePositions, setImagePositions] = useState<number[] | undefined | null>(null)
  const [collectionWishListButton, setCollectionWishListButton] = useState<
    WishListAddButtonAppData | undefined
  >()
  const [wishListItem, setWishListItem] = useState<WishListItemPayload | undefined>()
  const [isMouseHovering, setIsMouseHovering] = useState(false)
  const wishListButtons = useAppSelector((state) => state.wishlist.settings.addButtons)
  const currentListItems = useAppSelector((state) => state.wishlist.items)
  const filterSettings = useAppSelector((state) => state.app.settings.filters)
  const intlPrices = useAppSelector((state) => state.collectionElement.intlPrices)

  useEffect(() => {
    if (product.aggs && showWishListButton) {
      const item = {
        sourceProductId: product.sourceId,
        totalWishedUp: product.aggs.totalWishedUp,
        totalWishedDown: product.aggs.totalWishedDown,
        totalResponseCounts: {},
        customerWished: product.aggs.customerWished,
        customerResponse: product.aggs.customerResponse
      }

      setWishListItem(item)
    }
  }, [product?.aggs, showWishListButton])

  useEffect(() => {
    if (showWishListButton) {
      const button =
        wishListButtons.find((button: any) => button.target === 'collectionImage') || {}
      setCollectionWishListButton(button)
    }
  }, [wishListButtons])

  useEffect(() => {
    let imgPos1: number = -1
    let imgPos2: number = -1

    //search for first explicitly defined thumbnail
    //if not found, fall back to original algorithm
    imgPos1 = currentProduct?.images?.findIndex(
      (img: HTMLImageElement) => !!img.alt?.toLowerCase().includes(thumbnailMatch)
    )

    //if we found a thumbnail using thumbanil match
    //we will only find a second image explicitly using thumbnail match
    if (imgPos1 > -1) {
      imgPos1 += 1
      //search for second explicitly defined thumbnail
      //if not found, return no 2nd image
      imgPos2 = currentProduct?.images
        ?.slice(imgPos1)
        .findIndex((img: HTMLImageElement) => !!img.alt?.toLowerCase().includes(thumbnailMatch))

      if (imgPos2 > -1) {
        imgPos2 += imgPos1 + 1
      }
    } else {
      // find the first image position that is not a swatch
      imgPos1 = currentProduct?.images?.findIndex(
        (img: HTMLImageElement) => !!!img.alt?.toLowerCase().includes(swatchMatch)
      )
      // find second image if the first one exists
      if (imgPos1 > -1) {
        // positions are indexed starting from 1
        imgPos1 += 1

        // find second image that is not a swatch
        imgPos2 = currentProduct?.images
          ?.slice(imgPos1)
          .findIndex((img: HTMLImageElement) => !!!img.alt?.toLowerCase().includes(swatchMatch))

        if (imgPos2 > -1) {
          imgPos2 += imgPos1 + 1
        }
      }
    }

    setImagePositions([imgPos1, imgPos2])
  }, [currentProduct, currentListItems])

  useEffect(() => {
    if (imagePositions) {
      if (imagePositions[0]) {
        const image1 = currentProduct?.images?.find((i: any) => i.position === imagePositions[0])
        setImage1(image1)
      }
      if (imagePositions[1]) {
        const image2 = currentProduct?.images?.find((i: any) => i.position === imagePositions[1])
        setImage2(image2)
      }
    }
  }, [imagePositions])

  useEffect(() => {
    setCurrentProduct(product)

    // move current product to beginning of family
    const filteredProducts = product?.family?.filter((o: any) => o.id !== product.id)
    const family = [product].concat(filteredProducts)
    setFamily(family)

    if (product?.variants.length === 1) {
      setVariant(product.variants[0])
      onChangeVariant?.(product.variants[0])
    }

    // grab the first variant to use for pricing
    // const productVariant = product?.variants[0]
    // setVariant(productVariant)
  }, [product])

  // gets image swatch from alt tag (matching `swatch`)
  function getImageSwatch(images: HTMLImageElement[]) {
    const image = images?.find((image: HTMLImageElement) =>
      image?.alt?.toLowerCase().includes(swatchMatch)
    )
    return image?.src || ''
  }

  if (!currentProduct) {
    return <></>
  }

  let productUrl = `/products/${currentProduct.handle}`
  if (baseCollection) {
    productUrl = `/collections/${baseCollection}${productUrl}`
  }

  const _desktopGridTemplate = desktopGridTemplate || '1fr 1fr'
  const mobileGridTemplate = responsive ? '1fr' : _desktopGridTemplate
  return (
    <div
      className={classNames(`item-${(position || 0) + 1}`)}
      style={{
        display: 'grid',
        gridTemplateColumns:
          isMobile || layout === 'vertical' ? mobileGridTemplate : _desktopGridTemplate,
        gap: 5
      }}
      data-shopify-product-id={currentProduct.sourceId}
      >
      <div
        onMouseEnter={() => setIsMouseHovering(true)}
        onMouseLeave={() => setIsMouseHovering(false)}>
        {cardLayout?.map((cardItem, cardItemIndex) => {
          switch (cardItem.type) {
            case 'image':
              return (
                <div style={{ width: '100%' }} key={`${cardItem.type}-${cardItemIndex}`}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        hooks.run(`catalog.${source}.header`, { product: currentProduct })
                          ?.content || ''
                    }}
                  />
                  <a href={productUrl} title={product.title}>
                    <div
                      style={{
                        width: '100%',
                        height: 'auto',
                        paddingBottom: image1
                          ? `${(image1.height / image1.width) * 100}%`
                          : placeholderPaddingBottom + '%',
                        backgroundColor: '#f0f0f0',
                        position: 'relative'
                      }}
                      onMouseEnter={() => {
                        if (image2 && !isMobile) {
                          const alt = image2?.alt?.toLowerCase() || ''
                          if (!alt.includes(swatchMatch)) {
                            setSelectedImagePosition(2)
                          }
                        }
                      }}
                      onMouseLeave={() => {
                        setSelectedImagePosition(1)
                      }}>
                      {image1 && (
                        <ShopifyImage
                          src={`${image1.src}`}
                          height={Math.floor(image1.height / 4)}
                          width={Math.floor(image1.width / 4)}
                          alt={currentProduct.title}
                          style={{
                            position: 'absolute',
                            width: '100%',
                            height: 'auto',
                            top: 0,
                            left: 0,
                            display: selectedImagePosition === 1 ? 'block' : 'none'
                          }}
                          loading={position && position > 4 ? 'lazy' : 'eager'}
                        />
                      )}
                      {image2 && !isMobile && (
                        <ShopifyImage
                          src={`${image2.src}`}
                          width={Math.floor(image2.width / 4)}
                          height={Math.floor(image2.height / 4)}
                          alt={currentProduct.title}
                          style={{
                            position: 'absolute',
                            width: '100%',
                            height: 'auto',
                            top: 0,
                            left: 0,
                            display: selectedImagePosition === 2 ? 'block' : 'none'
                          }}
                          loading={position && position > 4 ? 'lazy' : 'eager'}
                        />
                      )}
                      <div
                        style={{
                          position: 'absolute',
                          width: '100%',
                          height: '100%',
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0
                        }}
                        dangerouslySetInnerHTML={{
                          __html:
                            hooks.run(`catalog.${source}.overlay`, { product: currentProduct })
                              ?.content || ''
                        }}></div>
                      <div
                        style={{
                          position: 'absolute',
                          width: '100%',
                          height: '100%',
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          pointerEvents: 'none'
                        }}>
                        <div
                          style={{
                            float: 'right',
                            position: 'relative',
                            margin: 10,
                            pointerEvents: 'all',
                            zIndex: 1
                          }}>
                          {collectionWishListButton && (
                            <div style={{ marginBottom: 5 }}>
                              <WishListAddButton
                                settings={collectionWishListButton}
                                counts={{
                                  up: wishListItem?.totalWishedUp,
                                  down: wishListItem?.totalWishedDown,
                                  current: wishListItem?.customerWished
                                }}
                                payload={{
                                  productId: currentProduct.sourceId,
                                  elementLabel: 'Product'
                                }}
                                onSelect={(args) => {
                                  let score = args.score || 0
                                  let newTotalWishedUp = product.aggs.totalWishedUp
                                  let newTotalWishedDown = product.aggs.totalWishedDown

                                  switch (score) {
                                    case -1:
                                      newTotalWishedDown++
                                      // If switching votes, remove count for previous vote
                                      if (product.aggs.customerWished === 1 && newTotalWishedUp > 0)
                                        newTotalWishedUp--
                                      break
                                    case 1:
                                      newTotalWishedUp++
                                      // If switching votes, remove count for previous vote
                                      if (
                                        product.aggs.customerWished === -1 &&
                                        newTotalWishedDown > 0
                                      )
                                        newTotalWishedDown--
                                      break
                                    case 0:
                                      if (product.aggs.customerWished === 1) {
                                        newTotalWishedUp--
                                      } else if (product.aggs.customerWished === -1) {
                                        newTotalWishedDown--
                                      }
                                      break
                                  }

                                  const item = {
                                    sourceProductId: product.sourceId,
                                    totalResponseCounts: {},
                                    totalWishedUp: newTotalWishedUp,
                                    totalWishedDown: newTotalWishedDown,
                                    customerWished: score,
                                    customerResponse: product.aggs.customerResponse
                                  }

                                  setWishListItem(item)
                                }}
                              />
                            </div>
                          )}
                          {overlayElements?.map((action) => action)}
                        </div>
                        {(cardItem.showQuickbuyImageOverlay ||
                          cardItem.showQuickbuyImageOverlay === undefined) &&
                          (forceShowQuickBuyButton ||
                            (showQuickBuy && filterSettings?.enableQuickBuy)) && (
                            <QuickBuy
                              product={currentProduct}
                              variant={variant}
                              isMouseOver={isMouseHovering}
                              mobileBreakpoint={mobileBreakpoint}
                              cardLayout={cardLayout}
                              overlay={true}
                            />
                          )}
                      </div>
                    </div>
                  </a>
                </div>
              )
            case 'title':
              return (
                <ProductTitle
                  key={`${cardItem.type}-${cardItemIndex}`}
                  productUrl={productUrl}
                  currentProduct={currentProduct}
                />
              )
            case 'color':
              return (
                <ProductColorPicker
                  key={`${cardItem.type}-${cardItemIndex}`}
                  currentProduct={currentProduct}
                  getImageSwatch={getImageSwatch}
                  setImagePositions={setImagePositions}
                  useMouseOverChange={cardItem.colorUseMouseOverSwatchChange}
                  onChangeVariant={(variant: any) => {
                    setVariant(variant)
                    onChangeVariant?.(variant)
                    //setColorVariantUrl(`?variant=${variant.sourceId}`)
                  }}
                  swatchStyle={cardItem.colorSwatchStyle}
                  swatchLimit={cardItem.colorSwatchLimit}
                  desktopSwatchLimit={cardItem.colorDesktopSwatchLimit}
                  desktopSwatchStyle={cardItem.colorDesktopSwatchStyle}
                  desktopSwatchStyleEnabled={cardItem.colorDesktopSwatchStyleEnabled}
                  desktopSwatchCountRevealTrigger={cardItem.colorDesktopSwatchCountRevealTrigger}
                  strikeThroughOos={cardItem.colorStrikeThroughOos}
                  hideOosSwatches={cardItem.colorHideOosSwatches}
                  mobileBreakpoint={mobileBreakpoint}
                  isMouseHovering={isMouseHovering}
                />
              )
            case 'family':
              return (
                <FamilyPicker
                  key={`${cardItem.type}-${cardItemIndex}`}
                  family={family}
                  currentProduct={currentProduct}
                  setCurrentOption={setCurrentProduct}
                  getImageSwatch={getImageSwatch}
                  useMouseOverChange={cardItem.useMouseOverSwatchChange}
                  swatchStyle={cardItem.swatchStyle}
                  isMouseHovering={isMouseHovering}
                  mobileBreakpoint={mobileBreakpoint}
                  swatchLimit={cardItem.swatchLimit}
                  desktopSwatchLimit={cardItem.desktopSwatchLimit}
                  desktopSwatchStyle={cardItem.desktopSwatchStyle}
                  desktopSwatchStyleEnabled={cardItem.desktopSwatchStyleEnabled}
                  desktopSwatchCountRevealTrigger={cardItem.desktopSwatchCountRevealTrigger}
                  strikeThroughOos={cardItem.strikeThroughOos}
                  hideOosSwatches={cardItem.hideOosSwatches}
                />
              )
            case 'price':
              return (
                <ProductPrice
                  key={`${cardItem.type}-${cardItemIndex}`}
                  product={currentProduct}
                  config={config}
                  variant={variant}
                  intlPrices={intlPrices?.find(
                    (intlPrice: IntlPrices) => intlPrice.sourceId == currentProduct.sourceId
                  )}
                  showFromPrice={cardItem.showFromPrice}
                  priceRangeFormat={cardItem.priceRangeFormat}
                  showPercentSign={cardItem.showPercentSign}
                  removeTrailingZeros={cardItem.removeTrailingZeros}
                />
              )
            case 'custom-footer':
              return (
                <ProductCustomFooter
                  key={`${cardItem.type}-${cardItemIndex}`}
                  currentProduct={currentProduct}
                  source={source}
                />
              )
            case 'actions':
              return (
                <ProductActions
                  key={`${cardItem.type}-${cardItemIndex}`}
                  actions={actions}
                  currentProduct={currentProduct}
                />
              )
            case 'options':
              return (
                <ProductOptionPicker
                  key={`${cardItem.type}-${cardItemIndex}`}
                  product={currentProduct}
                  variant={variant}
                  cardLayout={cardLayout}
                  onChangeVariant={(variant: any) => {
                    setVariant(variant)
                    onChangeVariant?.(variant)
                  }}
                />
              )
            case 'quickbuy':
              return (
                <QuickBuy
                  key={`${cardItem.type}-${cardItemIndex}`}
                  product={currentProduct}
                  variant={variant}
                  isMouseOver={isMouseHovering}
                  overlay={false}
                  mobileBreakpoint={mobileBreakpoint}
                  visibility={cardItem.quickbuyVisibility}
                  cardLayout={cardLayout}
                />
              )
            case 'html':
              const htmlModel = new HtmlModel()
              htmlModel.html = cardItem.html
              return (
                <Html
                  data={htmlModel}
                  key={`${cardItem.type}-${cardItemIndex}`}
                  context={{ ...currentProduct }}
                />
              )
            case 'reviews':
              const reviewSettings = {
                stars: cardItem.stars,
                height: cardItem.starsHeight,
                width: cardItem.starsWidth,
                color1: cardItem.colorFilledStars,
                color2: cardItem.colorUnfilledStars,
                displayScore: cardItem.displayScore,
                displayCount: cardItem.displayCount,
                showExactStars: cardItem.showExactStars
              }
              return cardItem.hideReviewsAverageUntil &&
                product.metaFields?.find(
                  (item: any) => item.reviews_average <= cardItem.hideReviewsAverageUntil
                ) ? null : (
                <ProductReview
                  key={`${cardItem.type}-${cardItemIndex}`}
                  metaFields={product.metaFields}
                  settings={reviewSettings}
                />
              )
          }
        })}
      </div>
    </div>
  )
}
