import axios from 'axios'
import { session } from '../main'
import getUnixTime from 'date-fns/getUnixTime'
import { Analytics } from 'analytics'
import { debounceWithCompare } from  './helpers'
import { isbot } from 'isbot'

// callback function that sends "getanalytics page view" data for PLP to the kafka endpoint
const trackEventPLP = async (payload: any) => {
  const { meta, properties, session, anonymousId, userId, options } = payload
  await axios.post('/events/pv', {
    meta,
    properties: {
      ...properties,
      sessionId: session?.sessionId
    },
    anonymousId,
    userId,
    options
  })
}

// callback function that sends "getanalytics" tracking events data to the kafka endpoint
const trackEventElement = async (payload: any) => {
  const { event, meta, properties, session, anonymousId, userId, options } = payload
  await axios.post('/events/tk', {
    event,
    meta,
    properties: {
      ...properties,
      sessionId: session?.sessionId
    },
    anonymousId,
    userId,
    options
  })
}

// callback function that sends "getanalytics page view" data for PDP to the kafka endpoint
const trackEventPDP = async (payload: any) => {
  if (import.meta.env.VITE_ENABLE_ANALYTICS_TRACKING === '0') {
    return
  }
  const { session, handle } = payload
  const epoch: number = getUnixTime(new Date())
  await axios.post('/events/pv', {
    meta: {
      ts: (epoch * 1000)
    },
    properties: {
      sessionId: session?.sessionId,
      sourceId: '',
      productId: '',
      title: '',
      url: window.location.href,
      path: window.location.pathname,
      referrer: '',
      handle
    },
    anonymousId: '',
    userId: '',
    options: {}
  })
}

/*
 * A 'plugin' file is needed for each getanalytics consumer
 * you can call the 'track' event handler with an optional debounce timeout
 *      analytics.track('button_click', {
 *         key: 'add_to_cart',
 *         slug: 'Striped Shirt',
 *         debounceTimeout: 6000
 *       })
 */
export function noginAnaylticsPlugin(userConfig: any = {}) {
  const name = 'noginPlugin'
  const config = {
    org: userConfig.org
  }

  return {
    name,
    // everything below 'name' is optional
    config,
    // standard events (optional)
    page: async ({ payload }: { payload: any }) => {
      if (import.meta.env.VITE_ENABLE_ANALYTICS_TRACKING === '0') {
        return
      }
      //ignore page views coming from element editor preview
      if (!isbot(navigator.userAgent?.toLowerCase()) && !payload?.properties?.path?.startsWith('/preview')) {
        //payload contains { meta, properties, anonymousId, userId, options }
        await debounceWithCompare(trackEventPLP, {...payload, session}, 2000, ['meta'])
      }
    },
    track: async ({ payload }: { payload: any }) => {
      if (import.meta.env.VITE_ENABLE_ANALYTICS_TRACKING === '0') {
        return
      }
      //ignore page views coming from element editor preview
      if (!isbot(navigator.userAgent?.toLowerCase()) && !payload?.properties?.location?.includes('/preview')) {
        //payload contains { event, meta, properties, anonymousId, userId, options }
        const timeout: number = payload?.properties?.debounceTimeout || 2000
        await debounceWithCompare(trackEventElement, {...payload, session}, timeout, ['meta'])
      }
    },
    //custom methods
    methods: {
      log() {
        console.log(`${config.org}`)
      },
      async pdpViewOrNot(loc: Location) {
        if (import.meta.env.VITE_ENABLE_ANALYTICS_TRACKING === '0') {
          return
        }
        let isProductPage = false
        if (loc.pathname.startsWith('/products')) {
          isProductPage = true
        } else if (loc.pathname.startsWith('/collections') && loc.pathname.indexOf('/products') > -1) {
          isProductPage = true
        }
        const handle: string = loc.pathname.split('/').pop() || ''
        if (isProductPage && handle) {
          await debounceWithCompare(trackEventPDP, {session, handle}, 2000)
        }
      }
    }
  }
}

//multiple plugins can be defined to write to many datasources (add name to plugins: in analytics.ts)
export const pluginTwo = {
  name: 'nogin',
  methods: {
    postData() {
      console.log('posting data')
    },
  },
  page: () => { console.log('page view fired') }
}


export const analytics: any = Analytics({
  app: 'nogin-analytics',
  plugins: [
    noginAnaylticsPlugin({
      org: 'nogin'
    })
  ]
})
