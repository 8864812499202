import React from 'react';
import Button from '../Button';
import { ButtonAction } from './ProductCard';

interface ProductActionsProps {
  actions?: ButtonAction[];
  currentProduct: any;
}

export default function ProductActions({ actions, currentProduct }: ProductActionsProps) {
  return <div style={{ marginTop: 20, display: 'flex', flexDirection: 'column', gap: 10 }}>
    {actions?.map((action, i) => (
      <Button key={i} type={action.type} disabled={action.disabled} onClick={() => action.onClick?.(currentProduct)}>
        {action.label}
      </Button>
    ))}
  </div>;
}
