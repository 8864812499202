import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import ChatProductCard from './components/ChatProductCard'
import { loadStreamChatLibrary } from '../../../StreamChatContext'
let MessageOptions: any = () => null,
  Attachment: any = () => null,
  MessageTimestamp: any = () => null
interface ChatMessageProps {
  mobileBreakpoint: string
  useMessageContext: any
  useBreakpoint: any
}

//https://getstream.io/chat/docs/sdk/react/message-components/message_ui/
export default function ChatMessage({
  mobileBreakpoint,
  useMessageContext,
  useBreakpoint
}: ChatMessageProps) {
  const { message }: { message: any } = useMessageContext()
  const { device } = useBreakpoint()

  useEffect(() => {
    loadStreamChatLibrary(({ StreamChatReact }: any) => {
      ;({ MessageOptions, Attachment, MessageTimestamp } = StreamChatReact)
    })
  }, [])

  if (!message?.text && !message.products) {
    return null
  }

  return (
    <MessageWrapper className='chat-message' mobileBreakpoint={mobileBreakpoint}>
      <AvatarWrapper>
        <Avatar className={device === 'full' ? 'default' : 'small'}>
          <span>{message?.user?.name ? message?.user?.name[0] : null}</span>
        </Avatar>
      </AvatarWrapper>
      <MessageContent>
        <MessageUserName>{message?.user.name}</MessageUserName>
        <MessageTextWrapper>
          {message?.text && <MessageText>{message?.text}</MessageText>}
          {message?.products &&
            message?.products.map((product: any) => (
              <ChatProductCard
                key={product.id}
                product={product}
                mobileBreakpoint={mobileBreakpoint}
              />
            ))}
          <Attachment attachments={[]} />
          <MessageOptions />
        </MessageTextWrapper>
        <MessageTimestampWrapper className='chat-message-timestamp'>
          <MessageTimestamp calendar />
        </MessageTimestampWrapper>
      </MessageContent>
    </MessageWrapper>
  )
}

const MessageWrapper = styled.div<{ mobileBreakpoint: string }>`
  display: flex;
  margin-top: 15px;
  ${({ mobileBreakpoint }) => `
   @media (max-width: ${mobileBreakpoint}){
    margin: 12px 0;
   }
  `}
`
const AvatarWrapper = styled.div`
  margin-right: 12px;
  margin-top: 4px;
`

const Avatar = styled.span`
  margin: 0;
  padding: 0;
  font-size: 14px;
  list-style: none;
  position: relative;
  display: inline-block;
  overflow: hidden;
  color: #fff;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
  background: #ccc;
  width: 32px;
  height: 32px;
  line-height: 32px;

  &.small {
    width: 24px;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  span {
    transform: scale(1) translateX(-50%);
    color: white;
  }
`

const MessageContent = styled.div``

const MessageUserName = styled.div`
  font-weight: bold;
  line-height: 20px;
`

const MessageTextWrapper = styled.div``

const MessageText = styled.div`
  line-height: 22px;
`

const MessageTimestampWrapper = styled.div`
  color: gray;
  font-size: 85%;
  line-height: 18px;
`
