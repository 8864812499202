import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { useMediaQuery } from 'react-responsive'
import SwatchItem, {
  StyledOutOfStockStrikeThrough,
  StyledSwatchContainer,
  StyledSwatchListContainer,
  SwatchParentComponent
} from './SwatchItem'
import SwatchCount from './SwatchCount'

interface FamilyPickerProps {
  family: any[]
  getImageSwatch: (images: any[]) => string
  setCurrentOption: React.Dispatch<any>
  currentProduct: any
  useMouseOverChange?: boolean
  swatchStyle: string
  isMouseHovering: boolean
  mobileBreakpoint: string
  swatchLimit: number
  desktopSwatchStyle: string
  desktopSwatchLimit: number
  desktopSwatchStyleEnabled: boolean
  desktopSwatchCountRevealTrigger: string
  strikeThroughOos: boolean
  hideOosSwatches: boolean
}

enum PickerStyle {
  group = 'swatchGroup',
  count = 'swatchCount',
  standard = 'standard'
}

const defaultMarginBottom = '10px'

export default function FamilyPicker({
  family,
  isMouseHovering,
  swatchStyle,
  mobileBreakpoint,
  swatchLimit,
  desktopSwatchStyle,
  desktopSwatchLimit = 5,
  desktopSwatchStyleEnabled,
  desktopSwatchCountRevealTrigger = 'hover',
  strikeThroughOos = false,
  hideOosSwatches = false,
  useMouseOverChange,
  currentProduct,
  ...props
}: FamilyPickerProps) {
  const isMobile = useMediaQuery({ query: `(max-width: ${mobileBreakpoint})` })
  const isDesktop = useMediaQuery({ query: `(min-width: ${mobileBreakpoint})` })
  const [countLimit, setCountLimit] = useState(
    swatchStyle || desktopSwatchStyle === PickerStyle.count
      ? isDesktop
        ? desktopSwatchStyleEnabled
          ? desktopSwatchLimit
          : swatchLimit
        : swatchLimit
      : currentProduct.options.length
  )

  const [currentSwatchStyle, setCurrentSwatchStyle] = useState(
    isDesktop && desktopSwatchStyle ? desktopSwatchStyle : swatchStyle
  )
  const [isMouseHoveringOnDesktop, setIsMouseHoveringOnDesktop] = useState(
    isDesktop && isMouseHovering
  )

  useEffect(() => {
    setCountLimit(
      swatchStyle || desktopSwatchStyle === PickerStyle.count
        ? isDesktop
          ? desktopSwatchStyleEnabled
            ? desktopSwatchLimit
            : swatchLimit
          : swatchLimit
        : currentProduct.options.length
    )
  }, [swatchLimit])

  useEffect(() => {
    setIsMouseHoveringOnDesktop(isDesktop && isMouseHovering)
  }, [isDesktop, isMouseHovering])

  useEffect(() => {
    setCurrentSwatchStyle(() => {
      if (isDesktop && desktopSwatchStyleEnabled && desktopSwatchStyle) {
        return desktopSwatchStyle
      }
      return swatchStyle
    })

    setCountLimit((countState: any) => {
      if (
        isDesktop &&
        desktopSwatchStyleEnabled &&
        desktopSwatchStyle === PickerStyle.count &&
        !isMouseHoveringOnDesktop &&
        desktopSwatchCountRevealTrigger === 'hover'
      ) {
        return desktopSwatchLimit
      } else if (isMobile && swatchStyle === PickerStyle.count) {
        return swatchLimit
      } else if (
        isDesktop &&
        desktopSwatchStyleEnabled &&
        desktopSwatchStyle === PickerStyle.count &&
        isMouseHoveringOnDesktop &&
        desktopSwatchCountRevealTrigger === 'hover'
      ) {
        return family.length
      } else if (
        isDesktop &&
        !desktopSwatchStyleEnabled &&
        swatchStyle === PickerStyle.count &&
        isMouseHoveringOnDesktop
      ) {
        return family.length
      } else if (
        isDesktop &&
        !desktopSwatchStyleEnabled &&
        swatchStyle === PickerStyle.count &&
        !isMouseHoveringOnDesktop
      ) {
        return swatchLimit
      }
      return countState
    })
  }, [
    isMouseHoveringOnDesktop,
    isDesktop,
    isMobile,
    swatchStyle,
    desktopSwatchStyleEnabled,
    desktopSwatchStyle,
    swatchLimit,
    desktopSwatchLimit
  ])

  const onClickSwatchCount = () => {
    setCountLimit((countState: number) => {
      return countState === family.length ? swatchLimit : family.length
    })
  }

  return (
    <StyledFamilyPicker mobileBreakpoint={mobileBreakpoint} swatchStyle={currentSwatchStyle}>
      <StyledSwatchListContainer>
        {family.map((product, index) => {
          let isStikeThroughOosVisible = strikeThroughOos && product.inventoryQuantity < 1
          if (currentSwatchStyle === PickerStyle.count)
            isStikeThroughOosVisible = isStikeThroughOosVisible && index < countLimit
          return (
            <StyledSwatchContainer key={index}>
              <StyledOutOfStockStrikeThrough
                isVisible={isStikeThroughOosVisible && !hideOosSwatches}
                isSelected={product.id === currentProduct.id}
                className='oos-swatch-strike-through'
              />
              <SwatchItem
                key={index}
                option={product}
                swatchStyle={currentSwatchStyle}
                isMouseHovering={isMouseHoveringOnDesktop}
                isHidden={currentSwatchStyle === PickerStyle.count && index >= countLimit}
                mobileBreakpoint={mobileBreakpoint}
                isShowingAllSwatches={countLimit === family.length}
                isDesktop={isDesktop}
                selectedOption={currentProduct}
                useMouseOverChange={useMouseOverChange}
                swatchParentComponent={SwatchParentComponent.PRODUCT_FAMILY}
                hideSwatch={hideOosSwatches && product.inventoryQuantity < 1}
                {...props}
              />
            </StyledSwatchContainer>
          )
        })}
      </StyledSwatchListContainer>
      {currentSwatchStyle === PickerStyle.count &&
        family.length > swatchLimit &&
        ((isDesktop && !isMouseHoveringOnDesktop && desktopSwatchCountRevealTrigger === 'hover') ||
          (isDesktop && desktopSwatchCountRevealTrigger === 'click') ||
          isMobile) && (
          <div
            onClick={onClickSwatchCount}
            onKeyUp={(e) => (e.code === 'Enter' ? onClickSwatchCount() : null)}>
            <SwatchCount
              countLimit={countLimit}
              total={family.length}
              mobileBreakpoint={mobileBreakpoint}
              isMobile={isMobile}></SwatchCount>
          </div>
        )}
    </StyledFamilyPicker>
  )
}
interface FamilyPickerStyleProps {
  mobileBreakpoint: string
  swatchStyle: string
}

const StyledFamilyPicker = styled.div<FamilyPickerStyleProps>`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin-top: 10px;
  ${({ mobileBreakpoint, swatchStyle }) => `
    ${swatchStyle === PickerStyle.count && `margin-bottom: ${defaultMarginBottom};`}
    @media (max-width: ${mobileBreakpoint}) {
      flex-direction: column;
      align-items: baseline;
      flex-wrap: nowrap;
    }
  `}
`
