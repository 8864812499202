import React from 'react'
import classNames from 'classnames'

interface PaginateButtonProps {
  pageIndex: number
  title: string
  onClick: () => void
  disabled: boolean
  active: boolean
}

export default function PaginateButton({
  pageIndex,
  title,
  onClick,
  disabled,
  active
}: PaginateButtonProps) {
  // href is for SEO, but navigation actually occurs with the onClick handler
  const href = window.location.origin + window.location.pathname + `?page=${pageIndex}`

  const onClickPaginationButton = (
    e?: React.MouseEvent<HTMLAnchorElement, MouseEvent> | React.KeyboardEvent<HTMLAnchorElement>
  ) => {
    e?.preventDefault()
    if (!disabled) {
      onClick()
    }
  }

  return (
    <a
      href={href}
      className={classNames(['btn-paginate', title, { disabled, active }])}
      onClick={onClickPaginationButton}
      onKeyUp={(e) => (e.code === 'Enter' ? onClickPaginationButton(e) : null)}>
      {title}
    </a>
  )
}
