import React, { useState, useEffect } from 'react'

// Debounce taken from this solution
// https://stackoverflow.com/a/59481266/4906991
export default (value: any, timeout: number) => {
  const [state, setState] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => setState(value), timeout)

    return () => clearTimeout(handler)
  }, [value, timeout])

  return state
}
