import React, { useState, useEffect, useContext } from 'react'
import queryString from 'query-string'
import { findNestedElement, useReactQuery } from '../../lib/helpers'
import { useAppDispatch, useAppSelector } from '../../redux'
import CollectionModel from './models'
import { LayoutContext } from '../Layout'
import {
  setParams,
  setSortBy,
  load,
  setPageIndex,
  setSearch,
  setFilterOptions,
  setOutOfStock,
  setEnableCollapse,
  setIsSwatchListFiltered,
  setInfiniteScrollPageIndex,
  setMinReviewsAverage
} from '../../redux/slices/collection-element'
import { Elements } from '..'
import CollectionFilterModel from '../CollectionFilter/models'
import CollectionProductListingModel from '../CollectionProductListing/models'
import ProductCardLayoutModel from '../ProductCardLayout/models'

interface CollectionProps {
  data?: CollectionModel
  containerProps?: string[]
  productIds?: string[]
}

const defaultSort = 'manual'

export default function CollectionLayout({ data, containerProps, productIds }: CollectionProps) {
  const dispatch = useAppDispatch()
  const query = useReactQuery()
  const [initLoad, setInitLoad] = useState(true)
  const search = useAppSelector((state) => state.collectionElement.search)
  const isSearchCleared = useAppSelector((state) => state.collectionElement.isSearchCleared)
  const params = useAppSelector((state) => state.collectionElement.params)
  const pageIndex = useAppSelector((state) => state.collectionElement.pageIndex)
  const pageSize = useAppSelector(
    (state) => parseInt(state.collectionElement.pageSize?.toString()) || 0
  )
  const totalProducts = useAppSelector((state) => state.collectionElement.totalProducts)
  const context = useContext(LayoutContext)
  const infiniteScrollPageIndex = useAppSelector(
    (state) => state.collectionElement.infiniteScrollPageIndex
  )

  useEffect(() => {
    const filters = findNestedElement<CollectionFilterModel>(context, 'CollectionFilter')
    dispatch(setFilterOptions(filters))

    const listing = getCollectionProductListing(context)
    if (listing) {
      dispatch(setOutOfStock(listing.showOos))
      dispatch(setEnableCollapse(listing.enableCollapse))
      dispatch(setIsSwatchListFiltered(listing.isSwatchListFiltered))
    }

    dispatch(
      setMinReviewsAverage(
        getProductCardLayout(context)?.cardLayout?.find((component) => component.type === 'reviews')
          ?.minReviewsAverage || 0
      )
    )
  }, [data?.elements])

  // updates url based on filter & sort parameters
  useEffect(() => {
    // parse url
    let queries = queryString.parseUrl(window.location.href, {
      arrayFormat: 'bracket'
    })

    // update page
    const listing = getCollectionProductListing(context)
    const currentPageIndex = getIsInfiniteScrollEnabled(listing, totalProducts)
      ? infiniteScrollPageIndex
      : pageIndex

    if (!initLoad && parseInt(queries.query.page?.toString() || '0') !== currentPageIndex) {
      queries.query.page = currentPageIndex.toString()
    }

    if (queries.query.sortBy && queries.query.sortBy === defaultSort) {
      delete queries.query.sortBy
    }

    // isSearchCleared allows user to clear out a search
    // without empty search query unnecessarily triggering a search
    // on its own
    if (search !== '' || (isSearchCleared && search === '')) {
      queries.query.q = search
    }

    // stringify url
    const url = queryString.stringifyUrl(queries, {
      arrayFormat: 'bracket'
    })

    if (url) {
      window.history.pushState(queries.query, document.title, url)
    }

    setInitLoad(false)
  }, [pageIndex, search, isSearchCleared, infiniteScrollPageIndex])

  // parses url and updates parameters
  useEffect(() => {
    // parse qs
    const parsedParams = queryString.parseUrl(window.location.href, {
      arrayFormat: 'bracket'
    }).query

    delete parsedParams['']

    // update page state and remove from params
    const listing = getCollectionProductListing(context)
    if (parsedParams.page) {
      const pageParam = parseInt(parsedParams.page.toString())
      if (getIsInfiniteScrollEnabled(listing, totalProducts)) {
        dispatch(setInfiniteScrollPageIndex(pageParam))
        //Delete 'page' param if on the initial page load
        //because the presences of page param prevents the collection from loading.
        //However, the initial page load should load the collection
        //whereas subsequent page changes are only for the user
        //to keep track of what page they are on and should not call the API
        if (initLoad) {
          delete parsedParams['page']
        }
      } else {
        dispatch(setPageIndex(pageParam))
        delete parsedParams['page']
      }
    }

    // update sortby state and remove from params
    if (parsedParams.sortBy) {
      dispatch(setSortBy(parsedParams.sortBy.toString()))
    }
    delete parsedParams['sortBy']

    if (parsedParams.q) {
      dispatch(setSearch(parsedParams.q.toString()))
    }
    const params = { ...parsedParams }
    dispatch(setParams(params as any))
  }, [query])

  // loads data from api
  useEffect(() => {
    const listing = getCollectionProductListing(context)
    const isInfiniteScrollEnabled = getIsInfiniteScrollEnabled(listing, totalProducts)
    //If infinite scroll is enabled, the collection products should not be loaded again if the 'page' param changes
    //This is because the 'page' param is only to keep track of where the user scrolled to if they share the URL
    //of the current page they are viewing. It is does not determine which products are fetched.
    //pageSize determines which products are fetched for infinite scroll
    if (!initLoad && !(isInfiniteScrollEnabled && params?.page)) {
      dispatch(
        load({
          productIds,
          hideLoadingIndicator: isInfiniteScrollEnabled
        })
      )
    }
  }, [params, data, pageSize])

  return (
    <div {...containerProps}>
      <Elements name='Elements' data={data} field='elements' />
    </div>
  )
}

const getCollectionProductListing = (context: any) => {
  const listings = findNestedElement<CollectionProductListingModel>(
    context,
    'CollectionProductListing'
  )
  return listings?.[0]
}

export const getIsInfiniteScrollEnabled = (
  listing: CollectionProductListingModel,
  totalProducts: number
) => {
  return (
    listing?.paginationMethod === 'infiniteScroll' &&
    (!listing?.isInfiniteScrollCapped ||
      (listing?.isInfiniteScrollCapped && listing?.maxInfiniteScrollProducts > totalProducts))
  )
}

const getProductCardLayout = (context: any) => {
  const layouts = findNestedElement<ProductCardLayoutModel>(context, 'ProductCardLayout')
  return layouts?.[0]
}
