import React, { ReactElement } from 'react'
import ShareIconModel from './models'
import { FaFacebook, FaPinterest, FaTwitter, FaEnvelope, FaPrint } from 'react-icons/fa'
import { Element } from '../../elements'
import styled from '@emotion/styled'

interface ShareIconProps {
  data: ShareIconModel
  containerProps?: any
}

export default function ShareIcon({ data, containerProps }: ShareIconProps) {
  const locationHref = window.location.href
  const documentTitle = window.document.title

  const SOCIAL_DATA: { [key: string]: { link: string; icon: any } } = {
    facebook: {
      link: `https://www.facebook.com/sharer/sharer.php?u=${locationHref}`,
      icon: <FaFacebook />
    },
    pinterest: {
      link: `https://www.pinterest.com/pin/create/button/?url=${locationHref}`,
      icon: <FaPinterest />
    },
    twitter: {
      link: `https://twitter.com/intent/tweet?text=&url=${locationHref}`,
      icon: <FaTwitter />
    },
    email: {
      link: `mailto:?subject=${documentTitle}&body=${locationHref}`,
      icon: <FaEnvelope />
    },
    print: {
      link: '',
      icon: <FaPrint />
    }
  }

  const name = data?.shareVia
  const image = data?.image

  return (
    <div {...containerProps}>
      <ShareMethod name={name} link={SOCIAL_DATA[name]?.link}>
        <>
          {image?.imagePath ? (
            <Element name='ResponsiveImage' data={image} />
          ) : (
            <StyledIconContainer
              fontSize={data.fontSize}
              textColor={data.textColor}
              textColorHover={data.textColorHover}
            >
              {SOCIAL_DATA[name]?.icon}
            </StyledIconContainer>
          )}
        </>
      </ShareMethod>
    </div>
  )
}

const ShareMethod = ({
  name,
  link = '',
  children
}: {
  name: string
  link: string
  children: ReactElement
}) => {
  return (
    <>
      {name === 'print' ? (
        <div
          onClick={() => {
            window.print()
          }}
        >
          {children}
        </div>
      ) : (
        <a href={link} target='_blank'>
          {children}
        </a>
      )}
    </>
  )
}

const StyledIconContainer = styled.div<{
  fontSize: string
  textColor: string
  textColorHover: string
}>`
  cursor: pointer;
  font-size: ${({ fontSize }) => fontSize};
  color: ${({ textColor }) => textColor};
  &:hover {
    color: ${({ textColorHover }) => textColorHover};
  }
`
