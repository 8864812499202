import React from 'react'
import styled from '@emotion/styled'
import ContentModel from './models'
import { Elements } from '../index'

interface ContentProps {
  data?: ContentModel
  containerProps?: string[]
}

const ContentWrapper = styled.div<ContentProps>`
  padding-top: ${(props) => props.data?.style?.paddingTop}px;
  padding-right: ${(props) => props.data?.style?.paddingRight}px;
  padding-bottom: ${(props) => props.data?.style?.paddingBottom}px;
  padding-left: ${(props) => props.data?.style?.paddingLeft}px;
  margin-top: ${(props) => props.data?.style?.marginTop}px;
  margin-right: ${(props) => props.data?.style?.marginRight}px;
  margin-bottom: ${(props) => props.data?.style?.marginBottom}px;
  margin-left: ${(props) => props.data?.style?.marginLeft}px;
  background-color: ${(props) => props.data?.style?.backgroundColor};
  border-width: ${(props) => props.data?.style?.borderThickness !== '0px' ? `${props.data?.style?.borderThickness}px` : `${props.data?.style?.borderTop}px ${props.data?.style?.borderRight}px ${props.data?.style?.borderBottom}px ${props.data?.style?.borderLeft}px`};
  border-color: ${(props) => props.data?.style?.borderColor};
  border-radius: ${(props) => props.data?.style?.borderRadius}px;
`

export default function Content({ data, containerProps }: ContentProps) {
  return (
    <ContentWrapper {...containerProps} data={data}>
      <Elements name='Content' data={data} field='elements' />
    </ContentWrapper>
  )
}
