import React from 'react'
import styled from '@emotion/styled'

const defaultMarginBottom = '10px'

interface SwatchCountProps {
  countLimit: number
  total: number
  mobileBreakpoint: string
  isMobile: boolean
}

const SwatchCount = ({ countLimit, total, mobileBreakpoint, isMobile }: SwatchCountProps) => {
  const countHidden = total - countLimit
  return (
    <>
      {countHidden > 0 && (
        <ShowSwatchesWrapper
          className='show-swatches-trigger open'
          mobileBreakpoint={mobileBreakpoint}
          countLimit={countLimit}>
          {countLimit > 0 ? (
            <>
              + {countHidden} more color{countHidden > 1 ? 's' : ''}
            </>
          ) : (
            <>
              {countHidden} color{countHidden > 1 ? 's' : ''}
            </>
          )}
        </ShowSwatchesWrapper>
      )}
      {isMobile && countHidden === 0 && (
        <SwatchCountWrapper
          className='show-swatches-trigger close'
          mobileBreakpoint={mobileBreakpoint}
          countLimit={countLimit}>
          - Collapse colors
        </SwatchCountWrapper>
      )}
    </>
  )
}

export default SwatchCount

interface SwatchCountWrapperProps {
  mobileBreakpoint: string
  countLimit: number
}

const SwatchCountWrapper = styled.div<SwatchCountWrapperProps>`
  text-decoration: underline;
  color: dimgray;
  font-style: italic;
  cursor: pointer;
  font-size: 90%;
  ${({ mobileBreakpoint, countLimit }) => `
    content: '${countLimit}';
      @media (min-width: ${mobileBreakpoint}){
        ${
          countLimit == 0 &&
          `
        font-size: 100%;
        height: 20px;
        `
        }
      }
      @media (max-width: ${mobileBreakpoint}){
        margin-bottom: ${defaultMarginBottom};
      }
    `}
`

const ShowSwatchesWrapper = styled(SwatchCountWrapper)`
  ${({ mobileBreakpoint, countLimit = 0 }) => `
      @media (min-width: ${mobileBreakpoint}){
        ${countLimit > 0 && `margin-left: -5px;`}
      }
      @media (max-width: ${mobileBreakpoint}){
        margin-top: 10px;
      }
    `}
`
