import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { useAppDispatch, useAppSelector } from '../../redux'
import { getWishListItems, updateItems } from '../../redux/slices/wishlist'
import { WishListAddButtonAppData, WishListItemPayload } from '../../types'
import WishListAddButton from '../WishListAddButton'

interface ButtonElement {
  el: Element
  setting: WishListAddButtonAppData
  productId?: string
  elementId?: string
}

// Renders custom buttons
export default function WishListAddButtonManager() {
  const [elements, setElements] = useState<ButtonElement[]>([])
  const buttons = useAppSelector((state) => state.wishlist.settings.addButtons)
  const items = useAppSelector((state) => state.wishlist.items)


  const context = useAppSelector((state) => state.app.config.context)
  const dispatch = useAppDispatch()

  useEffect(() => {
    setElements([])

    let newElements: ButtonElement[] = []
    let productIds: string[] = []
    let elementIds: string[] = []

    buttons?.forEach((button: any) => {

      let downvoteButton = {
        ...button,
        icon: 'dis' + button.icon,
        activeIcon: 'dis' + button.activeIcon,
        score: -1
      }

      if (button.target === 'custom' && button.customTarget) {
        const target = button.customTarget
        const els = document.querySelectorAll(target)

        els.forEach((el) => {
          const htmlElement = el as HTMLElement
          const elementId = htmlElement.dataset.elementId
          const productId = htmlElement.dataset.productId || context?.productId

          if (productId) {
            const pid = productId.toString()
            newElements.push({
              el,
              setting: button,
              productId: pid
            })

            if (!productIds.includes(pid)) {
              productIds.push(pid)
            }
          } else if (elementId) {
            const eid = elementId.toString()
            newElements.push({
              el,
              setting: button,
              elementId: eid
            })

            if (!elementIds.includes(eid)) {
              elementIds.push(eid)
            }
          } else {
            console.error(
              'Attribute "data-product-id or data-element-id must be available on custom target element or provided in config context'
            )
          }
        })
      }
    })

    if (productIds.length > 0 || elementIds.length > 0) {
      if (productIds.length) {
        dispatch(getWishListItems({ productIds }))
      } else if (elementIds.length) {
        dispatch(getWishListItems({ elementIds }))
      }
      setElements(newElements)
    }
  }, [buttons, context])

  return (
    <>
      {elements.map((element) => {
        let item: WishListItemPayload | undefined
        let label = ''

        const htmlElement = element.el as HTMLElement

        if (element.productId) {
          item = items.find((item: any) => item?.sourceProductId === element.productId)
          label = 'Product'
        } else if (element.elementId) {
          item = items.find((item: any) => item?.elementId === element.elementId)
          label = item?.elementKey as string
        }

        return ReactDOM.createPortal(
          <WishListAddButton
            settings={element.setting}
            counts={{ up: item?.totalWishedUp, down: item?.totalWishedDown, current: item?.customerWished}}
            payload={{
              productId: (htmlElement.dataset.productId || element.productId?.toString()),
              elementId: (htmlElement.dataset.elementId || element.elementId?.toString()),
              elementKey: htmlElement.dataset.elementKey,
              elementLabel: (htmlElement.dataset.elementLabel || label)
            }}
            onSelect={(args) => {
              const payload={
                productId: (htmlElement.dataset.productId || element.productId?.toString()),
                elementId: (htmlElement.dataset.elementId || element.elementId?.toString()),
                score: args.score || 0
              }
              dispatch(updateItems(payload))
            }}
          />
          ,
          element.el
        )
      })}
    </>
  )
}
