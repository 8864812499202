import React, { MutableRefObject, useState, useEffect } from 'react'
import VideoPlayer from './components/VideoPlayer'
import EventChat from './components/EventChat'
import EventModel from './models'
import styled from '@emotion/styled'
import Html from '../Html'
import HtmlModel from '../Html/models'
import { throttle } from '../../lib/helpers'
import { Element } from '../../elements'
import ContentGallery from '../ContentGallery'

interface EventProps {
  data: EventModel
  containerProps: any
}

const mobileBreakpoint = '950px'

const smallMobileBreakpoint = '600px'

export default function Event({ data, containerProps }: EventProps) {
  const {
    name,
    description,
    playbackUrl,
    playbackStartTime,
    chatChannelId,
    isReplay,
    productSectionTitle,
    contentGalleryElements
  } = data
  const descriptionHtmlModel = new HtmlModel()
  descriptionHtmlModel.html = description
  const productSectionTitleHtmlModel = new HtmlModel()
  productSectionTitleHtmlModel.html = productSectionTitle

  return (
    <EventWrapper {...containerProps}>
      <EventItemWrapperDescription>
        <DescriptionText>
          <h3>{name}</h3>
          <Html data={descriptionHtmlModel} />
        </DescriptionText>
        <ProductView className='live-event-product-view'>
          {contentGalleryElements?.length > 0 && (
            <>
              <hr />
              <ProductDescriptionHTML data={productSectionTitleHtmlModel} />
            </>
          )}
          {contentGalleryElements?.map((contentGallery, i) => (
            <Element data={contentGallery} name='ContentGallery' key={i} />
          ))}
        </ProductView>
      </EventItemWrapperDescription>
      <EventVideoChat
        playbackStartTime={playbackStartTime}
        playbackUrl={playbackUrl}
        isReplay={isReplay}
        chatChannelId={chatChannelId}
      />
    </EventWrapper>
  )
}

interface EventVideoChatProps {
  playbackStartTime: number
  playbackUrl: string
  isReplay: boolean
  chatChannelId: string
}

const EventVideoChat = ({
  playbackStartTime,
  playbackUrl,
  isReplay,
  chatChannelId
}: EventVideoChatProps) => {
  const [videoDuration, setVideoDuration] = useState(0)
  const [isMobileScrollDisabled, setIsMobileScrollDisabled] = useState(true)
  const playerRef = React.useRef<HTMLVideoElement>(null) as MutableRefObject<HTMLVideoElement>

  /**
   * The events the player can listen to are the standard html video events
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/video#events
   */
  const handlePlayerReady = (player: HTMLVideoElement) => {
    playerRef.current = player

    if (playbackStartTime) {
      player.currentTime = playbackStartTime
    }
    player.play()

    player.onprogress = throttle(() => {
      setVideoDuration((videoDurationState) => {
        if (isReplay) {
          return playerRef.current.currentTime || 0
        }
        return videoDurationState
      })
    }, 1000)
  }

  return (
    <>
      <EventItemWrapperVideo
        onClick={() => setIsMobileScrollDisabled(true)}
        onKeyUp={(e) => (e.code === 'Enter' ? setIsMobileScrollDisabled(true) : null)}>
        <VideoPlayer
          playbackUrl={playbackUrl}
          onReady={handlePlayerReady}
          smallMobileBreakpoint={smallMobileBreakpoint}
        />
      </EventItemWrapperVideo>
      <EventItemWrapperChat
        isMobileScrollDisabled={isMobileScrollDisabled}
        onClick={() => setIsMobileScrollDisabled(false)}
        onKeyUp={(e) => (e.code === 'Enter' ? setIsMobileScrollDisabled(false) : null)}>
        <EventChat
          chatChannelId={chatChannelId}
          isReplay={isReplay}
          videoDuration={videoDuration}
          mobileBreakpoint={mobileBreakpoint}
        />
      </EventItemWrapperChat>
    </>
  )
}

const videoHeight = '90vh'
const EventWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: ${videoHeight};
  max-height: 800px;
  max-width: 1260px;
  margin: 0 auto;
  font-size: 14px;
  line-height: 1.5715;
  overflow: hidden;

  @media (max-width: ${smallMobileBreakpoint}) {
    flex-direction: column;
    max-height: none;
    height: auto;
  }
`

const DescriptionText = styled.div`
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  height: 50%;
  &:hover {
    overflow: visible;
    height: 100%;
  }
  @media (max-width: ${smallMobileBreakpoint}) {
    height: auto;
  }
`

const ProductView = styled.div`
  margin-bottom: 30px;
`

const ProductDescriptionHTML = styled(Html)`
  margin: 14px 0;
`

const EventItemWrapper = styled.div`
  flex: 1 1 0;
  width: 30%;
`

const EventItemWrapperDescription = styled(EventItemWrapper)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .slick-slider {
    min-width: auto !important;
  }
  @media (max-width: ${mobileBreakpoint}) {
    font-size: 95%;
  }
  @media (max-width: ${smallMobileBreakpoint}) {
    order: 2;
    flex: 1;
    width: 100%;
    margin-top: 10%;
  }
`

const EventItemWrapperVideo = styled(EventItemWrapper)`
  position: relative;
  margin: 0 14px;
  @media (max-width: ${mobileBreakpoint}) {
    width: 50%;
    margin-right: 0;
  }
  @media (max-width: ${smallMobileBreakpoint}) {
    width: 100%;
    flex: 0 0 100%;
    margin: 0;
    min-height: ${videoHeight};
    .video-js {
      .vjs-tech {
        width: 100%;
        object-fit: cover;
      }
    }
  }
`
const EventItemWrapperChat = styled(EventItemWrapper)<{ isMobileScrollDisabled: boolean }>`
  @media (max-width: ${mobileBreakpoint}) {
    z-index: 2;
    position: absolute;
    left: 50%;
    width: 50%;
    top: 50%;
    height: 50%;
    mask-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 75%,
      rgba(0, 0, 0, 0) 90%
    );
    background: linear-gradient(
      0deg,
      white 0%,
      rgba(255, 255, 255, 0.8) 80%,
      rgba(255, 255, 255, 0) 100%
    );
    overflow: hidden;
    .chat-message {
      padding: 5px 5px;
    }
    ${({ isMobileScrollDisabled }) => `
       ${
         isMobileScrollDisabled &&
         `
       background: none;
       .str-chat__list {
        overflow: hidden;
       }
       .chat-message{
          background-color: rgba(32,32,32,.3);
          border-radius: 10px;
        }
        .chat-message, .chat-message-timestamp{
          color: white;
         }
         .chat-message-product{
           color: black;
         }
      `
       }
    `}
  }
  @media (max-width: ${smallMobileBreakpoint}) {
    width: 100%;
    left: 0;
    left: 50%;
    transform: translate(-50%, 0);
    top: 51vh;
    height: 45vh;
  }
`
