import { HeartOutlined, StarOutlined, StarFilled, HeartFilled, LikeOutlined, DislikeOutlined, LikeFilled, DislikeFilled } from '@ant-design/icons'
import React, { useMemo, useState } from 'react'
import { WishListAddButtonProps } from '../../types'
import { useAppDispatch, useAppSelector } from '../../redux'
import { notification } from '../../redux/slices/app'
import { addWishListItem } from '../../redux/slices/wishlist'
import classNames from 'classnames'
import { truncateLargeNumbers } from '../../lib/helpers'

export default function WishListAddButton({ settings, counts, payload, onSelect }: WishListAddButtonProps) {
  const dispatch = useAppDispatch()
  const [isHover, setHover] = useState(false)
  const [isHoverDown, setHoverDown] = useState(false)
  const wishListManagerSettings = useAppSelector((state) => state.wishlist.settings.manager)
  const notificationSettings = useAppSelector((state) => state.wishlist.settings.notification)
  const customer = useAppSelector((state) => state.app?.customer)

  let color: string, backgroundColor: string, colorDown: string, backgroundColorDown: string

  // If customer liked or voted "up"
  const active = counts?.current == 1;
  if (active) {
    backgroundColor = (!isHover ? settings.activeBackgroundColor : settings.activeHoverBackgroundColor) as string
    color = (!isHover ? settings.activeForegroundColor : settings.activeHoverForegroundColor) as string
  } else {
    backgroundColor = (!isHover ? settings.backgroundColor : settings.hoverBackgroundColor) as string
    color = (!isHover ? settings.foregroundColor : settings.hoverForegroundColor) as string
  }

  // If customer voted "down"
  const activeDown = counts?.current == -1;
  if (activeDown) {
    backgroundColorDown = (!isHoverDown ? settings.activeBackgroundColor : settings.activeHoverBackgroundColor) as string
    colorDown = (!isHoverDown ? settings.activeForegroundColor : settings.activeHoverForegroundColor) as string
  } else {
    backgroundColorDown = (!isHoverDown ? settings.backgroundColor : settings.hoverBackgroundColor) as string
    colorDown = (!isHoverDown ? settings.foregroundColor : settings.hoverForegroundColor) as string
  }

  const isUpDownVote = useMemo(() => {
    return settings.icon?.split("-")[0] === 'like'
  }, [settings])

  const Icon = useMemo(() => {
    switch (active ? settings.activeIcon : settings.icon) {
      case 'like':
        return <LikeOutlined color={color} style={{ fontSize: 20 }} />
      case 'dislike':
        return <DislikeOutlined color={color} style={{ fontSize: 20 }} />
      case 'like-filled':
        return <LikeFilled color={color} style={{ fontSize: 20 }} />
      case 'dislike-filled':
        return <DislikeFilled color={color} style={{ fontSize: 20 }} />
      case 'star':
        return <StarOutlined color={color} style={{ fontSize: 20 }} />
      case 'star-filled':
        return <StarFilled color={color} style={{ fontSize: 20 }} />
      case 'heart-filled':
        return <HeartFilled color={color} style={{ fontSize: 20 }} />
      case 'heart':
      default:
        return <HeartOutlined color={color} style={{ fontSize: 20 }} />
    }
  }, [settings, color, counts])

  const IconDown = useMemo(() => {
    switch (activeDown ? settings.activeIcon : settings.icon) {
      case 'like':
        return <DislikeOutlined color={colorDown} style={{ fontSize: 20 }} />
      case 'like-filled':
        return <DislikeFilled color={colorDown} style={{ fontSize: 20 }} />
      default:
        return null
    }
  }, [settings, color, counts])

  //const position = previewMode ? 'absolute' : 'fixed'
  const _count = counts?.up || 0 as number
  const _countDown = counts?.down || 0 as number

  const onClickWishlistButton = (
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent<HTMLButtonElement>
  ) => {
    e?.preventDefault()
    e?.stopPropagation()

    if (!payload?.elementId && !payload?.productId) { // If not in preview mode
      return false
    } else if (
      !wishListManagerSettings.allowAnonymousUser &&
      !!!customer?.isRegistered
    ) {
      window.location.href = '/account/login'
    } else {
      const newScore = counts?.current !== 1 ? 1 : 0
      const args = { ...payload, score: newScore }
      dispatch(addWishListItem(args))
      onSelect?.(args)

      let title = (payload?.elementLabel || 'Item') + ' '
      if (counts?.current == 1) {
        title += notificationSettings.removeTitle
      } else {
        if (settings?.icon?.split("-")[0] == 'like') {
          title += notificationSettings.titleUp
        } else {
          title += notificationSettings.title
        }
      }
      const { linkUrl, linkTitle } = notificationSettings
      dispatch(notification({ title, linkUrl, linkTitle }))
    }
  }

  const onClickUpVoteButton = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent<HTMLButtonElement>) => {
    e?.preventDefault()
    e?.stopPropagation()

    if (!payload?.elementId && !payload?.productId) { // If not in preview mode
      return false
    } else if (
      !wishListManagerSettings.allowAnonymousUser &&
      !!!customer?.isRegistered
    ) {
      window.location.href = '/account/login'
    } else {
      const newScore = counts?.current !== -1 ? -1 : 0
      const args = { ...payload, score: newScore }
      dispatch(addWishListItem(args))
      onSelect?.(args)

      let title = (payload?.elementLabel || 'Item') + ' '
      if (counts?.current == -1) {
        title += notificationSettings.removeTitle
      } else {
        if (settings?.icon?.split("-")[0] == 'like') {
          title += notificationSettings.titleDown
        } else {
          title += notificationSettings.title
        }
      }
      const { linkUrl, linkTitle } = notificationSettings
      dispatch(notification({ title, linkUrl, linkTitle }))
    }
  }

  return (
    <>
    <button
      type='button' // keeps enter key from submitting ProductCard wishlist form when search textfield is in Focus
      aria-label={(!active ? 'Add To' : 'Remove From') + ' Wish List'}
      onClick={onClickWishlistButton}
      onMouseOver={() => {
        setHover(true)
      }}
      onMouseOut={() => {
        setHover(false)
      }}
      className={classNames('wishlist-button', {
        'up-down-type': isUpDownVote
      })}
      style={{
        cursor: 'pointer',
        transition: 'ease-in-out',
        transitionProperty: 'all',
        transitionDuration: '100ms',
        backgroundColor,
        color,
        borderRadius: (!active ? settings.borderRadius : settings.activeBorderRadius),
        borderColor: (!active ? settings.borderColor : settings.activeBorderColor),
        borderWidth: (!active ? settings.borderWidth : settings.activeBorderWidth) || 0,
        paddingLeft: settings.paddingHorizontal || 0,
        paddingRight: settings.paddingHorizontal || 0,
        paddingTop: settings.paddingVertical || 0,
        paddingBottom: settings.paddingVertical || 0,
        boxShadow: (((settings.shadow && !active) || (settings.activeShadow && active)) && '0px 0px 10px #888') || ''
      }}>
      <div
        style={{
          display: 'flex',
          position: 'relative',
          justifyItems: 'center',
          alignItems: 'center',
          textAlign: 'center',
          gap: 8
        }}>
        {settings.icon !== 'none' && Icon}
        <span className="wishlist-button-label label-up">{!active ? settings.label : settings.activeLabel}</span>

        {settings.showSocialProof && _count > 0 && (
          <div
            className='social-proof'
            style={{
              display: 'inline-flex',
              position: 'absolute',
              transition: 'ease-in-out',
              transitionProperty: 'all',
              transitionDuration: '100ms',
              borderRadius: 999,
              left: 14,
              top: -5,
              paddingLeft: 3,
              paddingRight: 3,
              fontSize: 8,
              fontWeight: 400,
              height: 10,
              backgroundColor: 'white',
              textAlign: 'center',
              color:'black',
              lineHeight: 1,
              justifyItems: 'center',
              alignItems: 'center'
            }}>
              <div>
              {formatCounts(_count, settings.truncateLargeCounts)}
              </div>
          </div>
        )}
      </div>
    </button>
    {settings && isUpDownVote && (
    <button
      type='button' // keeps enter key from submitting ProductCard wishlist form when search textfield is in Focus
      aria-label={(!activeDown ? 'Add To' : 'Remove From') + ' Wish List'}
      onClick={onClickUpVoteButton}
      onKeyUp={(e) => e.code === "Enter" ? onClickUpVoteButton(e): null}
      className={classNames('wishlist-button', {
        'up-down-type up-down-type-down': isUpDownVote
      })}
      onMouseOver={() => {
        setHoverDown(true)
      }}
      onMouseOut={() => {
        setHoverDown(false)
      }}
      style={{
        cursor: 'pointer',
        transition: 'ease-in-out',
        transitionProperty: 'all',
        transitionDuration: '100ms',
        backgroundColor: backgroundColorDown,
        color: colorDown,
        borderRadius: (!activeDown ? settings.borderRadius : settings.activeBorderRadius),
        borderColor: (!activeDown ? settings.borderColor : settings.activeBorderColor),
        borderWidth: (!activeDown ? settings.borderWidth : settings.activeBorderWidth) || 0,
        paddingLeft: settings.paddingHorizontal || 0,
        paddingRight: settings.paddingHorizontal || 0,
        paddingTop: settings.paddingVertical || 0,
        paddingBottom: settings.paddingVertical || 0,
        boxShadow: (((settings.shadow && !activeDown) || (settings.activeShadow && activeDown)) && '0px 0px 10px #888') || ''
      }}>
      <div
        style={{
          display: 'flex',
          position: 'relative',
          justifyItems: 'center',
          alignItems: 'center',
          textAlign: 'center',
          gap: 8
        }}>
        {IconDown}
        <span className="wishlist-button-label label-down">{!activeDown ? settings.labelDown : settings.activeLabelDown}</span>
        {settings.showSocialProof && _countDown > 0 && (
          <div
            className='social-proof'
            style={{
              display: 'inline-flex',
              position: 'absolute',
              transition: 'ease-in-out',
              transitionProperty: 'all',
              transitionDuration: '100ms',
              borderRadius: 999,
              left: 14,
              top: -5,
              paddingLeft: 3,
              paddingRight: 3,
              fontSize: 8,
              fontWeight: 400,
              height: 10,
              backgroundColor: 'white',
              textAlign: 'center',
              color:'black',
              lineHeight: 1,
              justifyItems: 'center',
              alignItems: 'center'
            }}>
              <div>
              {formatCounts(_countDown, settings.truncateLargeCounts)}
              </div>
          </div>
        )}
      </div>
    </button>
    )}
    </>
  )
}

function formatCounts(count: number, isTruncatingLargeCounts = false){
  if(isTruncatingLargeCounts){
    return truncateLargeNumbers(count)
  }
  return count;
}
