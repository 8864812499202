import styled from '@emotion/styled'
import React, { useContext } from 'react'
import { LayoutContext } from '../Layout'
import FeaturedImageGalleryModel from './models'
import StackedView from './views/StackedView'
import { GenericContentModel } from './types'

interface Props {
  data: FeaturedImageGalleryModel
}

const EmptyContainer = styled.div`
  padding: 10px;
  text-align: center;
  border: 2px dashed #e0e0e0;
  border-radius: 10px;
  margin: 2px;
  color: #030303;
  font-size: 12px;
`

export default function FeaturedImageGallery({ data }: Props) {
  const contentData = useContext<GenericContentModel>(LayoutContext)

  if (contentData) {
    switch (data.displayFormat) {
      default:
        return <StackedView key={contentData.slug} contentData={contentData} />
    }
  }
  return <EmptyContainer>Featured Image Gallery Placeholder</EmptyContainer>
}
