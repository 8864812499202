import React from 'react';

/**
 * ProductType
 */
interface ProductTitleProps {
  productUrl: string;
  currentProduct?: any;
}

export default function ProductTitle({ productUrl, currentProduct }: ProductTitleProps) {
  return (
    <div className='product-title'>
      <h2 className='product-title-h2'>
        <a href={productUrl} title={currentProduct?.title}>
        {currentProduct?.title}
        </a>
      </h2>
    </div>
  );
}
