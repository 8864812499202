import React from 'react'
import { Elements } from '../index'
import styled from '@emotion/styled'
import ColumnLayoutModel from './models'
import classNames from 'classnames'

interface ColumnLayoutProps {
  data?: ColumnLayoutModel
  containerProps?: any
}

const ColumnWidthMap: { [key: number]: { [key: string]: number } } = {
  1: {
    '1': 25,
    '2': 75,
    '3': 50,
    '4': 20
  },
  2: {
    '1': 75,
    '2': 25,
    '3': 50,
    '4': 60
  },
  3: {
    '1': 33,
    '2': 75,
    '3': 20,
    '4': 20
  },
  4: {
    '1': 25,
    '2': 75,
    '3': 50,
    '4': 20
  }
}


function calcColumnWidth(column: number, layoutType: string) {
  switch (layoutType) {
    case '5':
      return '33%'
    case '6':
      return '25%'
    default:
      return ColumnWidthMap[column][layoutType] + '%'
  }
}

const Container = styled.div<{ data: ColumnLayoutModel }>`
  display: flex;
  gap: ${(props) => props.data.columnSpacing};
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;

    > .column-1,
    > .column-2,
    > .column-3,
    > .column-4 {
      width: 100% !important;
    }
  }

  > .column-1,
  > .column-2,
  > .column-3,
  > .column-4 {
    display: flex;
    flex-direction: column;
  }

  > .column-1 {
    width: ${(props) => (calcColumnWidth(1, props.data.columns))};
  }

  > .column-2 {
    width: ${(props) => (calcColumnWidth(2, props.data.columns))};
  }

  > .column-3 {
    width: ${(props) => (calcColumnWidth(3, props.data.columns))};
  }

  > .column-4 {
    width: ${(props) => (calcColumnWidth(4, props.data.columns))};
  }
`

export default function ColumnLayout({ data, containerProps }: ColumnLayoutProps) {
  return (
    <Container
      data={data}
      {...containerProps}
      className={classNames(containerProps?.className, 'column-layout')}>
      <div className={classNames('column-1')}>
        <Elements name='Column 1' data={data} field='elementsCol1' />
      </div>
      <div className={classNames('column-2')}>
        <Elements name='Column 2' data={data} field='elementsCol2' />
      </div>
      {(data?.columns === '4' || data?.columns === '5' || data?.columns === '6') && (
        <div className={classNames('column-3')}>
          <Elements name='Column 3' data={data} field='elementsCol3' />
        </div>
      )}
      {data?.columns === '6' && (
        <div className={classNames('column-4')}>
          <Elements name='Column 4' data={data} field='elementsCol4' />
        </div>
      )}
    </Container>
  )
}
