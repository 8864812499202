import React from 'react';
import Stars from './Stars';

interface ProductProps {
  metaFields?: any[]
  settings?: any
}

export default function ProductReview({ metaFields, settings }: ProductProps) {
  let score: number = 0
  let count: number = 0
  //extract the rating from meta fields array
  metaFields?.forEach((item: any) => {
    for (const key of Object.keys(item)) {
      if (key === 'reviews_average') {
        score = +item[key].toString()
      }
      if (key === 'reviews_count') {
        count = +item[key].toString()
      }
    }
  })

  return (
    score > 0 ?
      <Stars
        score={score}
        count={count}
        stars={settings?.stars}
        height={settings?.height}
        width={settings?.width}
        color1={settings?.color1}
        color2={settings?.color2}
        displayScore={settings?.displayScore}
        displayCount={settings?.displayCount}
        showExactStars={settings?.showExactStars}
      />
    : null
  )
}

