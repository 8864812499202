import React, { useState, useEffect, useRef } from 'react'

// React Slick and styling from the original slick-carousel
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'

// Import Settings & Style Models
import CarouselModel from './models'
import ContainerStyleModel from '../ContainerStyle/models'

// Element is important for rendering child elements
import { Element } from '../index'

// Debounce function for doing
import useDebounce from './debounce'

// Grab interfaces and emotion/styled CSS elements
import { CarouselProps, TraverseProps, TraverseParent, CarouselSettingConfig } from './_interfaces'
import CarouselWrapper from './_CarouselWrapper'
import ListElement from './_ListElement'
import { useAppSelector } from '../../redux'
import classNames from 'classnames'

const createParentHashMap = (slides: any, setParentMapFunc: Function) => () => {
  if (!slides || slides.length <= 0) return

  const newMap = new Map()

  // Recursive traversal of elements
  const traverseElement = (parent: TraverseParent, { __elementId, elements }: TraverseProps) => {
    newMap.set(__elementId, parent)
    elements?.forEach((el) => traverseElement(parent, el))
  }
  slides.forEach(({ __elementId, elements }: TraverseProps, index: number) =>
    traverseElement({ id: __elementId, index }, { __elementId, elements })
  )

  setParentMapFunc(newMap)
}

const doDebounce =
  (elId: string, ref: React.MutableRefObject<Slider | null>, map: Map<string, TraverseParent>) =>
  () => {
    if (elId == null) return

    ref?.current?.slickGoTo(map.get(elId)?.index || 0)
  }

export default function Carousel({ data, slides, containerProps }: CarouselProps) {
  // parentMap is used for hover over states, to figure out which parent the current element belongs to
  const [parentMap, setParentMap] = useState(new Map())
  const admin = useAppSelector((state) => state.app.isAdmin)

  const carouselRef = useRef(null) // carouselRef is used to control the carousel using its API

  const currentElementId = containerProps && containerProps['data-current-element-id']
  const debounceCurrentElementId = useDebounce(currentElementId, 150)

  // This useEffect creates a hashMap of each element's parent slide
  useEffect(createParentHashMap(slides || data?.slides, setParentMap), [data?.slides, slides])

  // This useEffect sets the carousel slide according to which element is selected/hovered over
  useEffect(doDebounce(debounceCurrentElementId, carouselRef, parentMap))

  // The Carousel Wrapper can support Container Styling
  // But for now, we can just set it to Container Style Defaults (should be 0 for most values)
  const currentStyle = new ContainerStyleModel()

  const { mobileSettings, desktopSettings, desktopSettingsEnabled } = data as CarouselModel
  const mSettings: CarouselSettingConfig = {
    ...mobileSettings,
    initialSlide: +mobileSettings.initialSlide,
    slidesToScroll: +mobileSettings.slidesToScroll,
    slidesToShow: +mobileSettings.slidesToShow,
    accessibility: true,
    customPaging: (
      () => <ListElement
        dotWidth={mobileSettings.dotWidth}
        dotHeight={mobileSettings.dotHeight}
        dotSpacing={mobileSettings.dotSpacing}
        dotColor={mobileSettings.dotColor}
        dotRadius={mobileSettings.dotRadius}
      />
    )
  }

  // by default we use the mobile setting
  let primarySettings = mSettings
  let secondarySettings: CarouselSettingConfig | undefined

  // if desktop setting is enabled add desktop settings + mobile setting as a breakpoint
  if (desktopSettingsEnabled) {
    secondarySettings = mSettings
    // autoplay speed must be cast as an integer to work properly
    secondarySettings.autoplaySpeed = +secondarySettings.autoplaySpeed

    // Currently disabling autoplay in admin/edit mode, as it's disruptive to other editing
    if(admin) secondarySettings.autoplay = false

    let dSettings: CarouselSettingConfig = {
      ...desktopSettings,
      initialSlide: +desktopSettings.initialSlide,
      slidesToScroll: +desktopSettings.slidesToScroll,
      slidesToShow: +desktopSettings.slidesToShow,
      accessibility: true,
      customPaging: (
        () => <ListElement
          dotWidth={desktopSettings.dotWidth}
          dotHeight={desktopSettings.dotHeight}
          dotSpacing={desktopSettings.dotSpacing}
          dotColor={desktopSettings.dotColor}
          dotRadius={desktopSettings.dotRadius}
        />
      ),
      responsive: [
        {
          breakpoint: 768,
          settings: secondarySettings
        }
      ]
    }
    primarySettings = dSettings
  }

  // autoplay speed must be cast as an integer to work properly
  primarySettings.autoplaySpeed = +primarySettings.autoplaySpeed

  // Currently disabling autoplay in admin/edit mode, as it's disruptive to other editing
  if(admin) primarySettings.autoplay = false
   
  function getSlides() {
    if (slides) {
      return slides
    } else {
      return (
        data?.slides.length > 0 &&
        data?.slides.map((element, i) => {
          // If an element is null, then don't render it
          // This state can occur when a Carousel's child element is added and deleted
          if (element?.name == null) return <></>

          return <Element data={element} key={element.__elementId || i} />
        })
      )
    }
  }

  return (
    <CarouselWrapper primarySetting={primarySettings} secondarySetting={secondarySettings} containerStyle={currentStyle} {...containerProps}>
      <Slider
        className={classNames({
          'desktopEnabled': desktopSettingsEnabled
        })}
        ref={function (this: any, slider: Slider) {
          if (this) this.slider = slider
        }}
        {...primarySettings}
        arrows={true}>
        {getSlides()}
      </Slider>
    </CarouselWrapper>
  )
}
