import React from 'react'
import { WishListLauncherProps } from '../../types'
import WishListLauncherButton from '../WishListLauncherButton'
import { useAppSelector } from '../../redux/index'

export default function WishListLauncher({ settings }: WishListLauncherProps) {
  const listItemTotal = useAppSelector((state) => state.wishlist.settings.listItemTotal)
  const managerSettings = useAppSelector((state) => state.wishlist.settings.manager)

  return (
    <WishListLauncherButton
      settings={settings.launcher}
      count={listItemTotal || 0}
      onClick={() => {
        if (managerSettings.pagePath) {
          window.location.href = managerSettings.pagePath
        }
      }}
    />
  )
}
