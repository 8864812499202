import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../redux'
import { NotificationPayload, removeNotification } from '../../redux/slices/app'
import NotificationItem from '../NotificationItem'
import { CSSTransition } from 'react-transition-group'
//import './style.css'

function Notification({
  payload,
  preview
}: {
  payload: NotificationPayload
  preview: boolean
}) {
  const dispatch = useAppDispatch()
  const [visible, setVisible] = useState(false)
  const settings = useAppSelector((state) => state.app.settings.notification)

  const hideNotification = () => setVisible(false)

  useEffect(() => {
    setVisible(true)

    const t = setTimeout(() => {
      setVisible(false)
    }, 5000)

    return () => {
      clearTimeout(t)
    }
  }, [])

  if(!settings) {
    return <></>
  }

  return (
    <CSSTransition
      in={visible}
      timeout={300}
      classNames='notification'
      unmountOnExit
      appear={true}
      onExited={() => {
        if (!preview) {
          dispatch(removeNotification(payload))
        }
      }}>

      <NotificationItem
        title={payload.title}
        subtitle={payload.subtitle}
        linkTitle={payload.linkTitle}
        linkUrl={payload.linkUrl}
        settings={settings}
        onClose={hideNotification}
      />
    </CSSTransition>
  )
}

Notification.defaultProps = {
  preview: false
}

export default Notification
