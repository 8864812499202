import React, { useContext, useMemo, useState, useEffect } from 'react'
import WishListAddButton from '../../components/WishListAddButton'
import { useAppSelector } from '../../redux'
import { WishListAddButtonAppData, WishListItemPayload } from '../../types'
import { LayoutContext } from '../Layout'
import WishListButtonModel from './models'

interface Props {
  data: WishListButtonModel
  containerProps: any
  dataContext?: any
}

type VoteMethod = 'up' | 'up_down'

export default function WishListButton({ data, containerProps, dataContext = {} }: Props) {
  const { productId } = dataContext
  let context = useContext(LayoutContext)
  const buttonSettings = useAppSelector((state) => state.wishlist.settings.addButtons)
  const customerWishlistItems: WishListItemPayload[] = useAppSelector(
    (state) => state.wishlist.items
  )

  const customerWishlistItem =
    customerWishlistItems.length > 0 && dataContext?.productId
      ? customerWishlistItems?.find((item) => item?.sourceProductId === dataContext?.productId)
      : {}

  let counts: any = context?.__aggs || customerWishlistItem

  const [votes, setVotes] = useState({
    totalWishedUp: counts?.totalWishedUp || 0,
    totalWishedDown: counts?.totalWishedDown || 0,
    customerWished: counts?.customerWished || 0
  })

  useEffect(() => {
    counts = context?.__aggs || customerWishlistItem
    setVotes({
      totalWishedUp: counts?.totalWishedUp || 0,
      totalWishedDown: counts?.totalWishedDown || 0,
      customerWished: counts?.customerWished || 0
    })

  }, [customerWishlistItems.length])



  const elementLabel = dataContext?.productId
    ? 'Product'
    : context?._contentType?.label || context?._layout?._contentType?.label || 'Unknown'

  const settings = useMemo<WishListAddButtonAppData | undefined>(() => {
    return buttonSettings.find((btn) => btn.customTarget === `.` + data.className)
  }, [data.className, buttonSettings])

  if (!settings || !context) return <></>

  return (
    <div {...containerProps}>
      <WishListAddButton
        settings={settings as WishListAddButtonAppData}
        counts={{
          up: votes.totalWishedUp,
          down: votes.totalWishedDown,
          current: votes.customerWished
        }}
        payload={{ elementId: context.__id, elementKey: productId ? undefined : context.__type , elementLabel, productId }}
        onSelect={(args) => {
          let score = args.score || 0
          let newTotalWishedUp = votes.totalWishedUp
          let newTotalWishedDown = votes.totalWishedDown

          switch (score) {
            case -1:
              newTotalWishedDown++
              // If switching votes, remove count for previous vote
              if (votes.customerWished === 1 && newTotalWishedUp > 0)
                newTotalWishedUp--
              break
            case 1:
              newTotalWishedUp++
              // If switching votes, remove count for previous vote
              if (votes.customerWished === -1 && newTotalWishedDown > 0)
                newTotalWishedDown--
              break
            case 0:
              if (votes.customerWished === 1) {
                newTotalWishedUp--
              } else if (votes.customerWished === -1) {
                newTotalWishedDown--
              }
              break
          }

          setVotes({
            totalWishedUp: newTotalWishedUp,
            totalWishedDown: newTotalWishedDown,
            customerWished: score
          })
        }}
      />
    </div>
  )
}
