import React, { useState, useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import * as helpers from './helpers'

interface Props {
  maxWidth?: string
}

const Container = styled.div<Props>`
  display: block;
  margin-bottom: 1em;
  max-width: ${(props) => (props.maxWidth)};
  position: relative;

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  &::after {
    border-bottom: 2px solid rgb(0 0 0 / 75%);
    border-right: 2px solid rgb(0 0 0 / 75%);
    content: "";
    display: block;
    height: 12px;
    pointer-events: none;
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translate(0, -65%) rotate(45deg);
    width: 12px;
  }

  .combo-input {
    background-color: #f5f5f5;
    border: 2px solid rgb(0 0 0 / 75%);
    border-radius: 4px;
    display: block;
    font-size: 0.9em;
    min-height: 2em;
    padding: 8px 10px 8px;
    text-align: left;
    width: 100%;
    padding-right: 50px;
  }

  .open .combo-input {
    border-radius: 4px 4px 0 0;
  }

  .combo-input:focus {
    border-color: #0067b8;
    box-shadow: 0 0 4px 2px #0067b8;
    outline: 4px solid transparent;
  }

  .combo-menu {
    background-color: #f5f5f5;
    border: 1px solid rgb(0 0 0 / 75%);
    border-radius: 0 0 4px 4px;
    display: none;
    max-height: 300px;
    overflow-y: scroll;
    left: 0;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 100;
    font-size: 0.9em;
  }

  &.open .combo-menu {
    display: block;
  }

  .combo-option {
    padding: 10px 12px 12px;
  }

  .combo-option:hover {
    background-color: rgb(0 0 0 / 10%);
  }

  .combo-option.option-current {
    outline: 3px solid #0067b8;
    outline-offset: -3px;
  }

  .combo-option[aria-selected="true"] {
    padding-right: 30px;
    position: relative;
  }

  .combo-option[aria-selected="true"]::after {
    border-bottom: 2px solid #000;
    border-right: 2px solid #000;
    content: "";
    height: 16px;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translate(0, -50%) rotate(45deg);
    width: 8px;
  }
`

interface SelectProps extends React.HTMLAttributes<HTMLDivElement> 
{
  maxWidth?: string
  title: string
  idBase: string
  options: any[]
  defaultIndex?: number,
  onChange: any
}

export default function SelectCustom({
  maxWidth = '250px',
  title = 'Combo Box',
  idBase = 'combo',
  options,
  defaultIndex = 0,
  onChange
}: SelectProps) {
  const containerRef = useRef<HTMLDivElement>(null)
  const comboboxRef = useRef<HTMLDivElement>(null)
  const listboxRef = useRef<HTMLDivElement>(null)

  const SelectActions = helpers.SelectActions

  const [currentIndex, setCurrentIndex ] = useState(defaultIndex)
  const [isOpen, setIsOpen] = useState(false)

  var searchString: string = ''
  var searchTimeout: any = null

  useEffect(() => {
    setCurrentIndex(defaultIndex)
  }, [defaultIndex])

  const onComboBlur = (
    event: React.FocusEvent
  ) => {
    // do nothing if relatedTarget is contained within listboxEl
    if (listboxRef.current?.contains(event.relatedTarget)) {
      return
    }

    // select current option and close
    if (isOpen) {
      selectOption(currentIndex);
      updateMenuState(false, false);
    }
  }

  const onComboClick = (
    event: React.MouseEvent<HTMLDivElement>
  ) => {
    updateMenuState(!isOpen, false);
  }

  const onComboKeyDown = (
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    const { key } = event
    const max = options.length - 1;

    const action = helpers.getActionFromKey(event, isOpen);

    switch (action) {
      case SelectActions.Last:
      case SelectActions.First:
        updateMenuState(true);
      // intentional fallthrough
      case SelectActions.Next:
      case SelectActions.Previous:
      case SelectActions.PageUp:
      case SelectActions.PageDown:
        event.preventDefault();
        return onOptionChange(
          helpers.getUpdatedIndex(currentIndex, max, action)
        );
      case SelectActions.CloseSelect:
        event.preventDefault();
        selectOption(currentIndex);
      // intentional fallthrough
      case SelectActions.Close:
        event.preventDefault();
        return updateMenuState(false);
      case SelectActions.Type:
        return onComboType(key);
      case SelectActions.Open:
        event.preventDefault();
        return updateMenuState(true);
    }
  }

  const getSearchString = (char: string) => {
    // reset typing timeout and start new timeout
    // this allows us to make multiple-letter matches, like a native select
    if (typeof searchTimeout === 'number') {
      window.clearTimeout(searchTimeout);
    }
  
    searchTimeout = window.setTimeout(() => {
      searchString = ''
    }, 500);
  
    // add most recent letter to saved search string
    searchString = searchString.concat(char)
    return searchString;
  }

  const onComboType = (letter: string) => {
    // open the listbox if it is closed
    updateMenuState(true);

    const tmpOptions = options.map((o) => o.title)
  
    // find the index of the first matching option
    const tmpSearchString = getSearchString(letter)
    const searchIndex = helpers.getIndexByLetter(
      tmpOptions,
      tmpSearchString,
      currentIndex + 1
    );
  
    // if a match was found, go to it
    if (searchIndex >= 0) {
      onOptionChange(searchIndex);
    }
    // if no matches, clear the timeout and search string
    else {
      window.clearTimeout(searchTimeout);
      searchString = ''
    }
  }

  const onOptionChange = (index: number) => {
    // update state
    setCurrentIndex(index)

    onChange(options[index].type)
  
    // update aria-activedescendant
    comboboxRef.current?.setAttribute('aria-activedescendant', `${idBase}-option-${index}`);

    // update active option styles
    const tmpOptions = containerRef.current?.querySelectorAll('[role=option]');

    if(tmpOptions) {
      // ensure the new option is in view
      if (helpers.isScrollable(listboxRef.current)) {
        helpers.maintainScrollVisibility(tmpOptions[index], listboxRef.current);
      }
    
      // ensure the new option is visible on screen
      // ensure the new option is in view
      if (!helpers.isElementInView(tmpOptions[index])) {
        tmpOptions[index].scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }
    }
  }

  const onOptionClick = (index: number) => {
    onOptionChange(index);
    selectOption(index);
    updateMenuState(false);
  }

  const onOptionMouseDown = () => {
    // Clicking an option will cause a blur event,
    // but we don't want to perform the default keyboard blur action
    // ignoreBlur = true)
  }

  const selectOption = (index: number) => {
    // update state
    setCurrentIndex(index)
  }

  const updateMenuState = (open: boolean, callFocus: boolean = true) => {
    if (isOpen === open) {
      return
    }

    setIsOpen(open)
    
    // update activedescendant
    const activeID = open ? `${idBase}-option-${currentIndex}` : '';
    comboboxRef.current?.setAttribute('aria-activedescendant', activeID);
  
    if (activeID === '' && !helpers.isElementInView(comboboxRef.current)) {
      comboboxRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  
    // move focus back to the combobox, if needed
    callFocus && comboboxRef.current?.focus();
  }

  return (
    <>
      <label id={`${idBase}-combo-label`} className="combo-label">
        {title}
      </label>
      <Container 
        className={`combo js-select ${isOpen ? 'open' : ''}`} 
        maxWidth={maxWidth}
        ref={containerRef}>
        <div 
          aria-controls={`${idBase}-listbox`}  
          aria-expanded={isOpen} 
          aria-haspopup="listbox"
          aria-labelledby={`${idBase}-combo-label`}
          key={`${idBase}-combobox`} 
          id={`${idBase}-combobox`} 
          className='combo-input'
          role="combobox" 
          tabIndex={0}
          onBlur={onComboBlur}
          onClick={onComboClick}
          onKeyDown={onComboKeyDown}
          ref={comboboxRef}>
            <span className="combo-current">{options[currentIndex].title}</span>
          </div>
        <div 
          className="combo-menu" 
          role="listbox" 
          aria-labelledby={`${idBase}-combo-label`}
          key={`${idBase}-listbox`} 
          id={`${idBase}-listbox`} 
          tabIndex={-1}
          ref={listboxRef}>
            {options.map((o, i) => 
              <div
                role="option"
                key={`${idBase}-option-${i}`}
                id={`${idBase}-option-${i}`}
                className={`combo-option ${currentIndex === i ? 'option-current' : ''}`}
                data-value={o.type}
                aria-selected={currentIndex === i ? true : false}
                onClick={() => onOptionClick(i)}
                onMouseDown={onOptionMouseDown}>
                {o.title}
              </div>
            )}
        </div>
      </Container>
    </>
  )
}
