import { formatMoney, formatNumber } from 'accounting'
import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import { IntlPrices } from '../../types'
import { Money } from '@shopify/hydrogen-react'

interface ProductPriceProps {
  product: any
  config: any
  variant: any
  intlPrices?: IntlPrices
  showFromPrice?: boolean
  priceRangeFormat?: string
  showPercentSign?: boolean
  removeTrailingZeros?: boolean
}

interface DisplayPrice {
  currencyCode: any
  price: string
  compareAt: string
  percent: string
  minPrice: string
  maxPrice: string
}

export default function ProductPrice({
  product,
  config,
  variant,
  intlPrices,
  showFromPrice,
  priceRangeFormat,
  showPercentSign,
  removeTrailingZeros
}: ProductPriceProps) {
  const _variant = variant || product.variants.find((v: any) => v.inventoryQuantity > 0) || product.variants[0]

  const displayPrice = useMemo<DisplayPrice>(() => {
    let returnPrice = {
      currencyCode: 'USD',
      price: '',
      compareAt: '',
      percent: '',
      minPrice: '',
      maxPrice: ''
    }

    const currencyCode = intlPrices ? config.currency?.isoCode || intlPrices.priceV2.currencyCode : 'USD'

    // if intlPrices is not null, display the international prices
    if (intlPrices) {
      if (intlPrices.priceV2) {
        returnPrice = {
          currencyCode: currencyCode,
          price: `${intlPrices.priceV2.amount?.replace(/\.0$/, '')} `,
          compareAt: intlPrices.compareAtPriceV2 && intlPrices.compareAtPriceV2.amount ?
                      `${intlPrices.compareAtPriceV2.amount?.replace(/\.0$/, '')} `
                      : '',
          percent: intlPrices.compareAtPriceV2?.amount && intlPrices.priceV2?.amount ?
            formatNumber( ((+intlPrices.priceV2.amount / +intlPrices.compareAtPriceV2.amount) * 100 - 100) * -1 )
            : '',
          minPrice: `${intlPrices.priceRange.minVariantPrice.amount?.replace(/\.0$/, '')} `,
          maxPrice: `${intlPrices.priceRange.maxVariantPrice.amount?.replace(/\.0$/, '')} `,
        }
      }
    } else {
      // format price based on priceNumber
      const price = (_variant?.priceNumeric / 100).toString()
      const compareAt = _variant?.compareAtPrice && (_variant?.compareAtPrice).toString()
      const percent =
        _variant?.compareAtPrice &&
        formatNumber( (((_variant?.priceNumeric / 100) / _variant?.compareAtPrice) * 100 - 100) * -1 )
      const minPrice = product?.minPrice && (product?.minPrice / 100).toString()
      const maxPrice = product?.maxPrice && (product?.maxPrice / 100).toString()

      returnPrice = {
        currencyCode,
        price,
        compareAt,
        percent,
        minPrice,
        maxPrice
      }
    }

    return returnPrice
  }, [config.currency, config.isLoaded, product, _variant, intlPrices])

  return (
    <div className='pricing'>
      {!variant && showFromPrice === true && product.minPrice !== product.maxPrice ?
        <>
        {priceRangeFormat === 'minmax' ? 
          <span className='price'>
            <Money 
              data={{ amount: displayPrice.minPrice, currencyCode: displayPrice.currencyCode}} 
              as="span" 
              withoutTrailingZeros={removeTrailingZeros}
            /> - 
            <Money 
              data={{ amount: displayPrice.maxPrice, currencyCode: displayPrice.currencyCode}} 
              as="span" 
              withoutTrailingZeros={removeTrailingZeros}
            />
          </span>
          :
          <span className='price'>
            {'from '}
            <Money 
              data={{ amount: displayPrice.minPrice, currencyCode: displayPrice.currencyCode}} 
              as="span" 
              withoutTrailingZeros={removeTrailingZeros}
            />
          </span>
        }
        </>
      :
        <>
        <span className='price'>
          <Money 
            data={{ amount: displayPrice.price, currencyCode: displayPrice.currencyCode}} 
            as="span" 
            withoutTrailingZeros={removeTrailingZeros}
          />
        </span>
        {displayPrice.compareAt && displayPrice.compareAt !== '$0.00' && (
          <>
            {showPercentSign === true ?
              <Percent className='price-percent'>{displayPrice.percent}%</Percent>
              : <></>
            }
            <CompareAtPrice className='compare-at-price'>
              <Money 
                data={{ amount: displayPrice.compareAt, currencyCode: displayPrice.currencyCode}} 
                as="span" 
                withoutTrailingZeros={removeTrailingZeros}
              />
            </CompareAtPrice>
          </>
        )}
        </>
      }
    </div>
  )
}

const Percent = styled.span`
  font-size: 90%;
  padding-left: 5px;
  color: gray;
`

const CompareAtPrice = styled.span`
  text-decoration: line-through;
  font-size: 90%;
  padding-left: 5px;
  color: gray;
`
