import React, { useEffect, useState } from 'react'
import AnimateHeight from 'react-animate-height'
import classNames from 'classnames'
import styled from '@emotion/styled'
import ColorPicker from './ColorPicker'
import ListPicker from './ListPicker'
import SortPicker from './SortPicker'
import CollectionFilterModel from '../models'
import PricePicker from './PricePicker'
import ReviewsAveragePicker from './ReviewsAveragePicker'
import ReviewsCountPicker from './ReviewsCountPicker'
import { setLastOpenPicker } from '../../../redux/slices/collection-element'
import { useAppDispatch, useAppSelector } from '../../../redux'

interface Props {
  filter: CollectionFilterModel
  defaultSelected: string[]
  facetedData: any
  onPicked: (items: any[]) => void
}

export interface SubPickerProps extends Props {
  setPickerVisibility: React.Dispatch<React.SetStateAction<boolean>>
}

const Container = styled.div<{ data: CollectionFilterModel }>`
  position: relative;

  .picker-title {
    display: flex;
    align-items: center;
    padding: 5px 0px;
    cursor: pointer;
    font-size: ${(props) => props.data.titleFontSize};
    color: ${(props) => props.data.titleFontColor};
  }

  .active-count {
    height: ${(props) => props.data.coundtBadgeHeight};
    width: ${(props) => props.data.countBadgeWidth};
    border-radius: ${(props) => props.data.countBadgeBorderRadius};
    background-color: ${(props) => props.data.countBadgeBackgroundColor};
    border: ${(props) => props.data.countBadgeBorderThickness} solid
      ${(props) => props.data.countBadgeBorderColor};
    font-size: ${(props) => props.data.countBadgeFontSize};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: ${(props) => props.data.countBadgeSpacing};
    color: ${(props) => props.data.countBadgeFontColor};
  }

  .spacer {
    flex-grow: 1;
  }

  .picker-title-icon {
    padding: 5px;
    font-size: ${(props) => props.data.titleFontSize};
    color: ${(props) => props.data.titleFontColor};
  }

  .show-more-button {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50px;
    background: white;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.show-more {
    .picker-container-inner {
      max-height: ${(props) => props.data.maxHeight} !important;
    }
    .picker-item-container {
      overflow: hidden;
    }
    &.show-more-expanded {
      .picker-container-inner {
        max-height: unset !important;
      }
    }
  }
`

function Picker({ filter, defaultSelected, facetedData, onPicked }: Props) {
  const [isPickerOpen, setPickerOpen] = useState(false)
  const [showMore, setShowMore] = useState(false)
  const [currentSelection, setCurrentSelection] = useState(defaultSelected)
  const [isVisible, setVisible] = useState(true)
  const dispatch = useAppDispatch()
  const lastOpenPicker = useAppSelector((state) => state.collectionElement.lastOpenPicker)

  // handle auto collapse initial load
  useEffect(() => {
    if (filter.defaultClosed === 'off') {
      setPickerOpen(true)
    } else if (filter.defaultClosed === 'always') {
      setPickerOpen(false)
    } else {
      // check for mobile size
      if (window.innerWidth < 768) {
        setPickerOpen(false)
      } else {
        setPickerOpen(true)
      }
    }
  }, [filter.defaultClosed])

  useEffect(() => {
    setCurrentSelection(defaultSelected)
  }, [defaultSelected])

  useEffect(() => {
    if (isPickerOpen) {
      dispatch(setLastOpenPicker(filter.name))
    }
  }, [isPickerOpen])

  useEffect(() => {
    if (lastOpenPicker !== filter.name && filter.autoClose) {
      setPickerOpen(false)
    }
  }, [lastOpenPicker])

  let PickerComponent
  switch (filter.filterType) {
    case 'tag':
    case 'option':
      switch (filter.displayType) {
        case 'color-picker':
          PickerComponent = ColorPicker
          break
        default:
          PickerComponent = ListPicker
      }
      break
    case 'sorter':
      PickerComponent = SortPicker
      break
    case 'price':
      PickerComponent = PricePicker
      break
    case 'reviews':
      PickerComponent =
        filter?.reviewsProperty === 'reviews_average' ? ReviewsAveragePicker : ReviewsCountPicker
      break
    default:
      PickerComponent = ListPicker
  }

  function _onPicked(selected: any) {
    setCurrentSelection(selected)
    onPicked(selected)
  }

  const onClickTitle = (
    e?: React.MouseEvent<HTMLAnchorElement, MouseEvent> | React.KeyboardEvent<HTMLAnchorElement>
  ) => {
    e?.preventDefault()
    setPickerOpen(!isPickerOpen)
  }

  const maxHeight = filter.maxHeight || 'unset'

  return (
    <Container
      data={filter}
      className={classNames(`picker-container ${filter.displayType}-outer-container`, {
        open: isPickerOpen,
        'show-more': filter.showMoreButton,
        'show-more-expanded': showMore
      })}
      style={{
        display: !isVisible ? 'none' : undefined
      }}>
      {!filter.hideTitle && (
        <a
          className='picker-title'
          onClick={onClickTitle}
          onKeyUp={(e) => (e.code === 'Enter' ? onClickTitle(e) : null)}>
          <span className='picker-title-name'>{filter.name}</span>
          {filter.showItemCounts &&
            currentSelection &&
            Array.isArray(currentSelection) &&
            currentSelection.length > 0 && (
              <span className='active-count'>{currentSelection.length}</span>
            )}
          <div className='spacer'></div>
          <PickerIcon isOpen={isPickerOpen} />
        </a>
      )}
      <AnimateHeight duration={250} height={isPickerOpen ? 'auto' : 0}>
        <div
          className='picker-container-inner'
          style={{
            display: 'flex',
            flexDirection: 'column',
            maxHeight,
            lineHeight: 'normal'
          }}>
          <PickerComponent
            filter={filter}
            defaultSelected={defaultSelected}
            onPicked={_onPicked}
            setPickerVisibility={setVisible}
            facetedData={facetedData}
          />
        </div>
        {filter.showMoreButton && (
          <div
            className='show-more-button'
            onClick={(e) => {
              setShowMore(!showMore)
            }}>
            <span>{showMore ? 'Show Less' : 'Show More'}</span>
          </div>
        )}
      </AnimateHeight>
    </Container>
  )
}

interface PickerIconProps {
  isOpen: boolean
}

function PickerIcon({ isOpen }: PickerIconProps) {
  return <span className='picker-title-icon'>{isOpen ? '-' : '+'}</span>
}

export default Picker
