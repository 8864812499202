import React, { useContext, useMemo } from 'react'
import ContentGalleryModel from '../models'
import Carousel from '../../Carousel/index'
import CarouselModel from '../../Carousel/models'
import ProductCardLayoutModel from '../../ProductCardLayout/models'
import { LayoutContext } from '../../Layout'
import cuid from 'cuid'
import styled from '@emotion/styled'
import GenericCard from '../../ContentType/GenericCard'

interface Props {
  data: ContentGalleryModel
  items: any[]
}

const SlideContainer = styled.div`
  padding: 10px;

  @media screen and (max-width: 768px) {
    padding: 5px;
  }
`

export default function CarouselView({ data, items }: Props) {
  const parentContext = useContext(LayoutContext)

  const cardLayout = useMemo(() => {
    const cardLayoutId = data?._contentType?.extraData?.cardLayout
    if (cardLayoutId) {
      return parentContext?.__layouts?.find((layout: any) => layout.__id === cardLayoutId)
    }
  }, [parentContext, data._contentType?.extraData?.cardLayout])

  const slides = useMemo(() => {
    return items.map((item: any, i: number) => (
        <div key={i}>
          <SlideContainer>
            <GenericCard
                data={data}
                cardLayout={cardLayout}
                slug={item.slug}
                parentData={{
                  ...item.data,
                  __type: data?._contentType?.name,
                  _contentType: data?._contentType,
                  __id: item.id,
                  __aggs: item.aggs,
                  __updatedAt: item.updatedAt,
              }}
              />
          </SlideContainer>
        </div>
      )
    )
  }, [items])

  const mappedData: CarouselModel = useMemo(
    () => ({
      name: '',
      __id: cuid(),
      __elementId: cuid(),
      __type: 'Carousel',
      carouselType: 'content',
      productCardLayout: new ProductCardLayoutModel(),
      slides: [],
      mobileSettings: data.mobileSettings,
      desktopSettings: data.desktopSettings,
      desktopSettingsEnabled: data.desktopSettingsEnabled
    }),
    [data.mobileSettings, data.desktopSettings, data.desktopSettingsEnabled]
  )

  return (slides.length > 0 && <Carousel data={mappedData} slides={slides}></Carousel>) || <></>
}
