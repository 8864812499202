import React from 'react'
import classNames from 'classnames'
import { SubPickerProps } from './Picker'
import styled from '@emotion/styled'
import CollectionFilterModel from '../models'
import { ListTitle } from './ListPicker'
import { useAppSelector } from '../../../redux'
import queryString from 'query-string'
import SelectCustom from '../../../components/SelectCustom'

const Container = styled.div<{ data: CollectionFilterModel }>`
  flex-grow: 1;
  overflow: auto;
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(${(props) => props.data.textGridColumns}, minmax(0, 1fr));
  flex-wrap: wrap;
  font-size: ${(props) => props.data.textFontSize};
  color: ${(props) => props.data.textFontColor};
  gap: ${(props) => props.data.textSpacing};

  .text-picker {
    color: ${(props) => props.data.textFontColor};
    text-decoration: none;
    border: ${(props) => props.data.textBorderThickness} solid
      ${(props) => props.data.textBorderColor};
    border-radius: ${(props) => props.data.textBorderRadius};
    padding: ${(props) => props.data.textPadding};
    background-color: ${(props) => props.data.textBackgroundColor};
    display: flex;
    align-items: center;
    justify-content: ${(props) => props.data.textContentAlignment};
    text-align: ${(props) => {
      switch (props.data.textContentAlignment) {
        case 'flex-start':
          return 'left'
        case 'center':
          return 'center'
        case 'flex-end':
          return 'right'
      }
    }};
  }

  .active {
    color: ${(props) => props.data.textFontSelectedColor};
    border: ${(props) => props.data.textBorderThickness} solid
      ${(props) => props.data.textBorderSelectedColor};
    background-color: ${(props) => props.data.textBackgroundSelectedColor};
  }

  .disabled {
    opacity: ${(props) => props.data.textDisabledOpacity};
  }

  .picker-item-row {
    display: flex;
    gap: 5px;
    align-items: center;
  }
`

export default function SortPicker({
  filter,
  defaultSelected,
  onPicked
}: Pick<SubPickerProps, 'filter' | 'defaultSelected' | 'onPicked'>) {
  switch (filter.displayType) {
    case 'dropdown':
      return (
        <SortSelectPicker filter={filter} defaultSelected={defaultSelected} onPicked={onPicked} />
      )
    default:
      return (
        <SortListPicker filter={filter} defaultSelected={defaultSelected} onPicked={onPicked} />
      )
  }
}

function SortSelectPicker({
  filter,
  defaultSelected,
  onPicked
}: Pick<SubPickerProps, 'filter' | 'defaultSelected' | 'onPicked'>) {
  const _defaultSelected = (defaultSelected?.length > 0 && defaultSelected[0]) || ''
  const search = useAppSelector((state) => state.collectionElement.search)
  const defaultSort = useAppSelector((state) => state.collectionElement.currentCollection?.sortBy)
  const querySortSelection = useAppSelector((state) => state.collectionElement.sortBy)
  const parsedParams = queryString.parseUrl(window.location.href, {
    arrayFormat: 'bracket'
  }).query

  const sortOptions = [
    ...(search
      ? [
          {
            type: 'relevance',
            title: 'Relevance'
          }
        ]
      : []),
    ...filter.sortOptions
  ]

  const findDefaultSort = parsedParams.sortBy?.toString()
    ? querySortSelection
    : (_defaultSelected === 'manual' ? '' : _defaultSelected) ||
      (defaultSort === 'manual' ? '' : defaultSort) ||
      sortOptions[0].type
  const currentSort = sortOptions.find((so) => so.type === findDefaultSort)
  const currentSortIndex = sortOptions.findIndex((so) => so.type === findDefaultSort)

  return (
    <div>
      <SelectCustom
        maxWidth='200px'
        title='Sort By'
        idBase='sortby'
        defaultIndex={currentSortIndex !== -1 ? currentSortIndex : 0}
        onChange={onPicked}
        options={sortOptions}
      />
    </div>
  )
}

function SortListPicker({
  filter,
  defaultSelected,
  onPicked
}: Pick<SubPickerProps, 'filter' | 'defaultSelected' | 'onPicked'>) {
  const _defaultSelected = (defaultSelected?.length > 0 && defaultSelected[0]) || false
  const defaultSort = useAppSelector(
    (state) => state.collectionElement.currentCollection?.sortBy || state.collectionElement.sortBy
  )
  const parsedParams = queryString.parseUrl(window.location.href, {
    arrayFormat: 'bracket'
  }).query

  return (
    <Container data={filter} className='picker-item-container'>
      {filter.sortOptions
        .filter((sorter) => sorter.type != 'manual' || defaultSort === 'manual')
        .map((sorter, i: number) => (
          <a
            href='#'
            onClick={(e) => {
              e.preventDefault()
              onPicked([sorter.type])
            }}
            onKeyUp={(e) => (e.code === 'Enter' ? onPicked([sorter.type]) : null)}
            key={i}
            className={classNames('text-picker', {
              active: sorter.type === (parsedParams.sortBy?.toString() || _defaultSelected)
            })}>
            <ListTitle
              showIcon={filter.textShowIcon}
              name={sorter.title}
              isActive={sorter.type === (parsedParams.sortBy?.toString() || _defaultSelected)}
              itemCount={0}
            />
          </a>
        ))}
    </Container>
  )
}
