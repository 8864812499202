import React, { useCallback, useMemo } from 'react'
import styled from '@emotion/styled'
import { useAppDispatch, useAppSelector } from '../../redux'
import { resetFilters, setFilters } from '../../redux/slices/collection-element'
import CollectionActiveFilterListModel from './models'

interface DisplayFilter {
  key: string
  label: string
  value: string
  filter: any
}

interface Props {
  data: CollectionActiveFilterListModel
  containerProps: any
}

const FilterContainer = styled.div<{ data: CollectionActiveFilterListModel }>`
  display: flex;
  gap: 0.25rem;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;

  .filter-tags {
    display: flex;
    gap: 0.25rem;
    flex-direction: row;
    flex-wrap: wrap;

    .filter-item {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
      font-size: ${(props) => props.data.filterTagStyle.fontSize};
      background-color: ${(props) => props.data.filterTagStyle.backgroundColor};
      color: ${(props) => props.data.filterTagStyle.textColor};
      border: ${(props) => props.data.filterTagStyle.borderThickness} solid
        ${(props) => props.data.filterTagStyle.borderColor};
      border-radius: ${(props) => props.data.filterTagStyle.borderRadius};
      padding-left: ${(props) => props.data.filterTagStyle.paddingLeft};
      padding-right: ${(props) => props.data.filterTagStyle.paddingRight};
      padding-top: ${(props) => props.data.filterTagStyle.paddingTop};
      padding-bottom: ${(props) => props.data.filterTagStyle.paddingBottom};

      &:hover {
        color: ${(props) => props.data.filterTagStyle.textColorHover};
        background-color: ${(props) => props.data.filterTagStyle.backgroundColorHover};
        border: ${(props) => props.data.filterTagStyle.borderThickness} solid
          ${(props) => props.data.filterTagStyle.borderColorHover};
      }

      span.icon {
        color: ${(props) => props.data.filterTagStyle.textColor};
        font-size: ${(props) => props.data.filterTagStyle.fontSize};
        text-decoration: none;

        &:hover {
          color: ${(props) => props.data.filterTagStyle.textColorHover};
        }
      }
    }
  }

  .clear-all {
    cursor: pointer;
    font-size: ${(props) => props.data.filterTagClearAllButtonStyle.fontSize};
    background-color: ${(props) => props.data.filterTagClearAllButtonStyle.backgroundColor};
    color: ${(props) => props.data.filterTagClearAllButtonStyle.textColor};
    border: ${(props) => props.data.filterTagClearAllButtonStyle.borderThickness} solid
      ${(props) => props.data.filterTagClearAllButtonStyle.borderColor};
    border-radius: ${(props) => props.data.filterTagClearAllButtonStyle.borderRadius};
    padding-left: ${(props) => props.data.filterTagClearAllButtonStyle.paddingLeft};
    padding-right: ${(props) => props.data.filterTagClearAllButtonStyle.paddingRight};
    padding-top: ${(props) => props.data.filterTagClearAllButtonStyle.paddingTop};
    padding-bottom: ${(props) => props.data.filterTagClearAllButtonStyle.paddingBottom};

    &:hover {
      color: ${(props) => props.data.filterTagClearAllButtonStyle.textColorHover};
      background-color: ${(props) => props.data.filterTagClearAllButtonStyle.backgroundColorHover};
      border: ${(props) => props.data.filterTagClearAllButtonStyle.borderThickness} solid
        ${(props) => props.data.filterTagClearAllButtonStyle.borderColorHover};
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export default function FilterActiveList({ data, containerProps }: Props) {
  const dispatch = useAppDispatch()
  const params = useAppSelector((state) => state.collectionElement.params)
  const filters = useAppSelector((state) => state.collectionElement.filters)

  // convert style:XYZ to XYZ
  const filterParams = useMemo<DisplayFilter[]>(() => {
    const processed: DisplayFilter[] = []
    for (const [key, val] of Object.entries(params)) {
      // find settings for this filter
      const filter = filters.find((f) => f.name === key)
      if (Array.isArray(val) && filter) {
        for (const filterValue of val) {
          let label
          switch (filter?.filterType) {
            case 'price':
              const [minPrice, maxPrice] = filterValue.split(':')
              const _minPrice = +minPrice / 100
              const _maxPrice = +maxPrice / 100
              label =
                filter?.priceRanges.find(
                  (price) => +price.minPrice === _minPrice && +price.maxPrice === _maxPrice
                )?.title || ''
              break
            case 'sorter':
              label = filter?.sortOptions.find((option) => option.type === filterValue)?.title || ''
              break
            default:
              label =
                filter.displayType === 'single-item'
                  ? filter?.singleItemText || filter?.name
                  : filterValue.replace(filter?.tagPrefix, '')
          }
          processed.push({
            key,
            label,
            value: filterValue,
            filter
          })
        }
      }
    }
    return processed
  }, [params, filters])

  const onFilterRemoveClickHandler = useCallback(
    (filterParam: DisplayFilter) => {
      const newParams = params[filterParam.key].filter((f: string) => f !== filterParam.value)
      dispatch(setFilters({ filter: filterParam.filter, items: newParams }))
    },
    [params, filters, filterParams, data]
  )

  const onClickFilterRemove = (
    param: any,
    e?: React.MouseEvent<HTMLDivElement, MouseEvent> | React.KeyboardEvent<HTMLDivElement>
  ) => {
    e?.preventDefault()
    e?.stopPropagation()
    onFilterRemoveClickHandler(param)
  }

  if (filterParams.length === 0) {
    return null
  }

  return (
    <FilterContainer {...containerProps} data={data}>
      {data?.showTitle && <span>{data?.filterTagTitle}</span>}
      <div className='filter-tags'>
        {filterParams.map((param) => (
          <div
            key={param.value}
            className='filter-item'
            aria-label='remove filter'
            onClick={(e) => onClickFilterRemove(param, e)}
            onKeyUp={(e) => (e.code === 'Enter' ? onClickFilterRemove(param, e) : null)}>
            {data.showFilterLabel && <span>{param.key}:</span>} {param.label}{' '}
            <span className='icon'>×</span>
          </div>
        ))}
      </div>
      {data.showClearAllButton && (
        <button
          onClick={() => dispatch(resetFilters())}
          onKeyUp={(e) => (e.code === 'Enter' ? dispatch(resetFilters()) : null)}
          className='clear-all'>
          clear all
        </button>
      )}
    </FilterContainer>
  )
}
