import React from 'react';

/**
 * FamilyPicker Component
 */
interface FamilyPickerProps {
  family: any[]
  getImageSwatch: (images: any[]) => string
  setCurrentProduct: React.Dispatch<any>
  currentProduct: any
  useMouseOverChange?: boolean
}

export default function FamilyPicker({
  family, getImageSwatch, setCurrentProduct, useMouseOverChange, currentProduct
}: FamilyPickerProps) {
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: 10 }}>
      {family?.map((product, i) => {
        return (
          <div
            key={i}
            style={{
              backgroundImage: `url(${getImageSwatch(product.images)})`,
              backgroundColor: '#f0f0f0',
              borderWidth: 2,
              borderStyle: 'solid',
              borderColor: product.id === currentProduct.id ? 'black' : 'white',
              height: 20,
              width: 20,
              borderRadius: 30,
              marginRight: 10,
              marginBottom: 10,
              cursor: 'pointer'
            }}
            onMouseEnter={() => {
              if (useMouseOverChange) {
                setCurrentProduct(product);
              }
            }}
            onClick={() => {
              setCurrentProduct(product);
            }}
            onKeyUp={(e) => {
              if (e.code === 'Enter') {
                setCurrentProduct(product);
              }
            }}/>
        );
      })}
    </div>
  );
}
