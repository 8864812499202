import React, { useEffect, useState } from 'react'
import ProductCardLayoutModel from './models'
import { useAppSelector } from '../../redux'
import ProductCard from '../CollectionProductListing/components/ProductCard'

interface ProductCardLayout {
  data: ProductCardLayoutModel
  product?: any
  containerProps?: string[]
}

export default function ProductCardLayout({ data, product, containerProps }: ProductCardLayout) {
  const baseCollection = useAppSelector((state) => state.collectionElement.baseCollection)
  const products = useAppSelector((state) => state.collectionElement.products)
  const currentProduct = product ? product : products[0]

  if (!currentProduct) {
    return <div className='my-5'>Unable to render Product Card, no product found</div>
  }

  return (
    <ProductCard
      source='list'
      product={currentProduct}
      baseCollection={baseCollection}
      layout='vertical'
      responsive={true}
      forceShowQuickBuyButton={true}
      overlayElements={[
        <div
          className='nogin-wishlist-product'
          data-product-id={currentProduct.sourceId}
          key={`product-wishlist-${currentProduct.id}`}></div>
      ]}
      showWishListButton={true}
      cardLayout={data.cardLayout}
    />
  )
}
