import React, { useState, useEffect, useRef, useCallback } from 'react'
import ReactDOM from 'react-dom'
import queryString from 'query-string'
import Picker from '../Picker'
import ProductListing from '../ProductListing'
import SortPicker from '../SortPicker'
import { useReactQuery } from '../../lib/helpers'
import classNames from 'classnames'
import { useAppDispatch, useAppSelector } from '../../redux'

import {
  setConfig,
  setParams,
  setSortBy,
  load,
  setPageIndex,
  setBaseCollection,
  setSearch,
  resetFilters,
  setFilters
} from '../../redux/slices/collection'

const defaultSort = 'manual'

function FilterApp({ config }) {
  const dispatch = useAppDispatch()
  const query = useReactQuery()
  const [initLoad, setInitLoad] = useState(true)
  const [loading, setLoading] = useState(false)
  const containerRef = useRef(null)

  const content = useAppSelector((state) => state.collection.content)
  const search = useAppSelector((state) => state.collection.search)
  const products = useAppSelector((state) => state.collection.products)
  const totalProducts = useAppSelector((state) => state.collection.totalProducts)
  const facetedData = useAppSelector((state) => state.collection.facetedData)
  const params = useAppSelector((state) => state.collection.params)
  const sortBy = useAppSelector((state) => state.collection.sortBy)
  const baseCollection = useAppSelector((state) => state.collection.baseCollection)
  const pageIndex = useAppSelector((state) => state.collection.pageIndex)

  function hasFilters() {
    return (
      Object.keys(params).length > 0 || sortBy !== defaultSort || search !== '' || pageIndex !== 1
    )
  }

  useEffect(() => {
    dispatch(setConfig(config))
  }, [])

  // updates url based on filter & sort parameters
  useEffect(() => {
    // parse url
    let queries = queryString.parseUrl(window.location.href, {
      arrayFormat: 'bracket'
    })

    // update page
    if (!initLoad && parseInt(queries.query.page) !== pageIndex) {
      queries.query.page = pageIndex
    }

    if (queries.query.sortBy && queries.query.sortBy === defaultSort) {
      delete queries.query.sortBy
    }

    if (search !== '') {
      queries.query.q = search
    } else {
      //delete queries.query.q
    }

    // stringify url
    const url = queryString.stringifyUrl(queries, {
      arrayFormat: 'bracket'
    })

    if (url) {
      window.history.pushState(queries.query, document.title, url)
    }

    setInitLoad(false)
  }, [pageIndex, search])

  // parses url and updates parameters
  useEffect(() => {
    // if base collection
    let baseUrl = window.location.pathname.split('?')[0]
    let baseUrlSplit = baseUrl.split('/')
    const urlPos = baseUrlSplit.indexOf('collections')
    if (urlPos && baseUrlSplit[urlPos + 1] && baseUrlSplit[urlPos + 1] !== 'all') {
      dispatch(setBaseCollection(baseUrlSplit[urlPos + 1]))
    }

    // parse qs
    const parsedParams = queryString.parseUrl(window.location.href, {
      arrayFormat: 'bracket'
    }).query

    // update page state and remove from params
    if (parsedParams.page) {
      dispatch(setPageIndex(parseInt(parsedParams.page)))
    }
    delete parsedParams['page']

    // update sortby state and remove from params
    if (parsedParams.sortBy) {
      //setSortBy(parsedParams.sortBy)
      dispatch(setSortBy(parsedParams.sortBy))
    }
    delete parsedParams['sortBy']

    if (parsedParams.q) {
      dispatch(setSearch(parsedParams.q))
    }

    const params = { ...parsedParams }
    dispatch(setParams(params))
  }, [query])

  // loads data from api
  useEffect(() => {
    if (!initLoad) {
      dispatch(load())
    }
  }, [params])

  function onSortHandler(value) {
    dispatch(setSortBy(value))

    let newParam = { ...params }
    newParam['sortBy'] = value
    dispatch(setParams(newParam))

    // // update browser history
    const qs = queryString.stringify(newParam, { arrayFormat: 'bracket' })
    window.history.pushState(newParam, '', window.location.href.split('?')[0] + (qs && '?' + qs))
  }

  function onResetFilters(e) {
    e?.preventDefault()
    e?.stopPropagation()

    if (hasFilters()) {
      dispatch(resetFilters())
    }
  }

  const checkFilterDependency = useCallback(
    (filter) => {
      if (filter.dependencies) {
        let dependenciesSelected = false
        for (const dependency of filter.dependencies) {
          if (Array.isArray(params[dependency]) && params[dependency].length > 0) {
            dependenciesSelected = true
          } else {
            dependenciesSelected = false
          }
        }
        return dependenciesSelected
      }
      return true
    },
    [products]
  )

  return (
    <div>
      <h3>
        {config.title}{' '}
        {config.resetButton.enabled && !config.resetButton.target && (
          <button
            className={classNames(config.resetButton.class, {
              disabled: !hasFilters()
            })}
            onClick={onResetFilters}
            onKeyUp={(e) => (e.code === 'Enter' ? onResetFilters(e) : null)}
            role='Reset Button'>
            {config.resetButton.label || 'Reset Filters'}
          </button>
        )}
      </h3>
      <div ref={containerRef}></div>
      <div>
        {config.filters.map((filter, i) => {
          let showFilter = true
          const filterCollections = filter.collections || []
          const collectionName = baseCollection || 'all'
          if (filterCollections.length > 0 && !filterCollections.includes(collectionName)) {
            showFilter = false
          }

          // handle dependencies
          if (showFilter) {
            showFilter = checkFilterDependency(filter)
          }

          if (showFilter) {
            let defaultSelected = params[filter.name]
            if (filter.type === 'sorter') {
              defaultSelected = sortBy
            }

            const PickerElement = (
              <Picker
                key={i}
                config={config}
                settings={filter}
                type={filter.type}
                displayType={filter.displayType}
                name={filter.name}
                items={filter.options}
                defaultSelected={defaultSelected}
                onPicked={(items) => {
                  dispatch(setFilters({ filter, items }))
                }}
                facetedData={facetedData}
              />
            )

            // target loads filter into a separate portal element
            if (filter.target) {
              return ReactDOM.createPortal(PickerElement, document.getElementById(filter.target))
            } else {
              return PickerElement
            }
          } else {
            return null
          }
        })}
      </div>
      {document.getElementById('nogin-search-bar') &&
        ReactDOM.createPortal(
          <input
            type='text'
            placeholder='Product Search'
            style={{ padding: 10, width: 400 }}
            value={search}
            onChange={(e) => {
              setSearch(e.target.value)
              dispatch(setPageIndex(1))
            }}
          />,
          document.getElementById('nogin-search-bar')
        )}
      {document.getElementById('nogin-product-list') &&
        ReactDOM.createPortal(
          <ProductListing
            baseCollection={baseCollection}
            products={products}
            config={config}
            pageIndex={pageIndex}
            totalProducts={totalProducts}
            onPageChange={(page) => {
              dispatch(setPageIndex(page))
            }}
            content={content}
          />,
          document.getElementById('nogin-product-list')
        )}
      {document.getElementById('nogin-sort-picker') &&
        ReactDOM.createPortal(
          <SortPicker
            config={config}
            displayType='sort-picker'
            defaultSelected={sortBy}
            onPicked={onSortHandler}
          />,
          document.getElementById('nogin-sort-picker')
        )}
      {config.resetButton.enabled &&
        config.resetButton.target &&
        ReactDOM.createPortal(
          <button
            className={classNames(config.resetButton.class, {
              disabled: !hasFilters()
            })}
            onClick={onResetFilters}
            onKeyUp={(e) => (e.code === 'Enter' ? onResetFilters() : null)}
            role='Reset Button'>
            {config.resetButton.label || 'Reset Filters'}
          </button>,
          document.getElementById(config.resetButton.target)
        )}
    </div>
  )
}

export default FilterApp
