import React, { useState, useEffect, useCallback } from 'react'
import SearchBarModel from './models'
import styled from '@emotion/styled'
import { AiOutlineSearch, AiFillCloseCircle } from 'react-icons/ai'
import { debounce } from '../../lib/helpers'
import { setSearch, setPageIndex, setIsSearchCleared } from '../../redux/slices/collection-element'
import { useAppDispatch } from '../../redux'
import ContainerStyleModel from '../ContainerStyle/models'
import { useMediaQuery } from 'react-responsive'

interface SearchBarProps {
  data: SearchBarModel
  containerProps?: string[]
}

export default function SearchBar(props: SearchBarProps) {
  const dispatch = useAppDispatch()
  const isDesktop = useMediaQuery({ query: `(min-width: 768px)` })
  const [val, setVal] = useState('')
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    setIsLoaded(true)
  }, [])

  useEffect(() => {
    //This check allows initial search terms from the query params,
    //handled by CollectionLayout, to be loaded first.
    //Any subsequent search bar searches are handled here.
    if(isLoaded){
      debounceSearch(val)
    }
  }, [val])

  const debounceSearch = useCallback(
    debounce((search: string) => {
      doSearch(search)
    }, 1000),
    []
  )

  const doSearch = (search: string) => {
    dispatch(setSearch(search))
    dispatch(setIsSearchCleared(search === ''))
    dispatch(setPageIndex(1))
  }

  return (
    <SearchBarWrapper
      {...props.containerProps}
      styles={
        isDesktop && props.data?.desktopStyleEnabled ? props.data.desktopStyle : props.data.style
      }
    >
      <SearchBarInput
        type='text'
        placeholder={props.data?.placeholderText}
        value={val}
        //light input sanitization
        onChange={(e) => setVal(e.target.value.replace(/[<>\[\]{}()<>:;]/gi, ' ')  )}
        styles={
          isDesktop && props.data?.desktopStyleEnabled ? props.data.desktopStyle : props.data.style
        }
        iconplacement={props.data?.searchIconPlacement}
      />
      {val && (
        <ClearIcon
          className='clear-icon'
          onClick={() => setVal('')}
          iconplacement={props.data?.searchIconPlacement}
        />
      )}
      <SearchIcon
        className='search-icon'
        onClick={() => doSearch(val)}
        iconplacement={props.data?.searchIconPlacement}
      />
    </SearchBarWrapper>
  )
}

/**
 * Styles like width, top, left, and display are applied to the wrapper
 * so that these properties update the placement, size, and visibility of both
 * the input and its icons
 */
const SearchBarWrapper = styled.div<{ styles: ContainerStyleModel }>`
  display: flex;
  align-items: center;
  width: ${({ styles }) => (styles?.widthType === 'auto' ? 'auto' : styles?.widthValue)};
  top: ${({ styles }) => (styles?.topType === 'auto' ? 'auto' : styles?.topValue)};
  left: ${({ styles }) => (styles?.leftType === 'auto' ? 'auto' : styles?.leftValue)};
  ${({ styles }) => styles?.visibility === 'hidden' && `display: none;`}
`

const SearchBarInput = styled.input<{ styles: ContainerStyleModel; iconplacement: string }>`
  width: 100%;
  height: ${({ styles }) => (styles?.heightType === 'auto' ? 'auto' : styles?.heightValue)};
  padding-top: ${({ styles }) => styles?.paddingTop};
  padding-bottom: ${({ styles }) => styles?.paddingBottom};

  //Adds extra to padding right so that what the user types
  //does not show underneath icons on the right
  padding-right: ${({ styles, iconplacement }) =>
    iconplacement === 'before'
      ? `calc(${styles.paddingRight} + 16px)`
      : `calc(${styles.paddingRight} + 45px)`};

  //Adds extra to padding left so that what the user types
  //does not show underneath the icons on the left
  padding-left: ${({ styles, iconplacement }) =>
    iconplacement === 'before' ? `calc(${styles.paddingLeft} + 26px)` : styles.paddingLeft};

  margin-top: ${({ styles }) => styles?.marginTop};
  margin-right: ${({ styles }) => styles?.marginRight};
  margin-bottom: ${({ styles }) => styles?.marginBottom};
  margin-left: ${({ styles }) => styles?.marginLeft};
  background-color: ${({ styles }) => styles?.backgroundColor};
  border-width: ${({ styles }) =>
    `${styles?.borderTop || '1px'} ${styles?.borderRight || '1px'} ${
      styles?.borderBottom || '1px'
    } ${styles?.borderLeft || '1px'}`};
  border-style: solid;
  border-color: ${({ styles }) => styles?.borderColor};
  border-radius: ${({ styles }) => styles?.borderRadius};
`

const ClearIcon = styled(AiFillCloseCircle)<{ iconplacement: string }>`
  position: absolute;
  right: ${({ iconplacement }) => (iconplacement === 'before' ? `12px` : `32px`)};
  color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
`

const SearchIcon = styled(AiOutlineSearch)<{ iconplacement: string }>`
  position: absolute;
  right: ${({ iconplacement }) => (iconplacement === 'before' ? `auto` : `12px`)};
  left: ${({ iconplacement }) => (iconplacement === 'before' ? `12px` : `auto`)};
  cursor: pointer;
`
