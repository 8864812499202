import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

interface ColorPickerProps {
  type: 'option' | 'tag' | 'price'
  settings: any
  items: any[]
  onPicked: (item: any) => void
  defaultSelected: any
  facetedData: any
  setPickerVisibility: (visible: boolean) => void
}

function ColorPicker({
  type,
  settings,
  items,
  onPicked,
  defaultSelected,
  facetedData,
  setPickerVisibility
}: ColorPickerProps) {
  const [selected, setSelected] = useState(defaultSelected)
  const [_items, setItems] = useState<any>([])

  useEffect(() => {
    setSelected(defaultSelected)
  }, [defaultSelected])

  useEffect(() => {
    if (settings.mode && settings.mode === 'automatic') {
      // build item list from faceted data
      let autoItems: any[] = []
      const tagPrefix = settings.prefix
      if (facetedData && facetedData.tags && facetedData.tags[tagPrefix]) {
        const tags = facetedData.tags[tagPrefix]
        for (const tag of tags) {
          autoItems.push(tag.tag)
        }

        if (
          Array.isArray(settings.displayOrder) &&
          settings.displayOrder.length > 0
        ) {
          let newSort = settings.displayOrder.map((o: any) =>
            settings.prefix ? settings.prefix + o : o
          )

          // remove items that don't exist in auto items
          newSort = newSort.filter((o: any) => autoItems.indexOf(o) > -1)

          const showDisplayOrderItemsOnly =
            settings.showDisplayOrderItemsOnly || false

          let regularSortItems: any[] = []
          if (!showDisplayOrderItemsOnly) {
            // get all items that don't require sorting
            let regularSortItems = autoItems.filter(
              (o) => newSort.indexOf(o) === -1
            )

            // store these items using basic ascii sort
            regularSortItems.sort()
          }

          // merge with ordered items
          autoItems = [...newSort, ...regularSortItems]
        } else {
          autoItems.sort()
        }

        setItems(autoItems)

        if (autoItems.length === 0) {
          setPickerVisibility(false)
        } else {
          setPickerVisibility(true)
        }
      }
    } else {
      // manual mode loads data from settings
      items.sort()
      setItems(items)
    }
  }, [defaultSelected, facetedData, settings])

  function getFacetCount(item: any) {
    switch (type) {
      case 'option':
        //defaultSelected = optionState[filter.name]
        break
      case 'tag':
        if (settings.mode === 'automatic' && settings.prefix) {
          if (
            facetedData &&
            facetedData.tags &&
            facetedData.tags[settings.prefix]
          ) {
            const match = facetedData.tags[settings.prefix].find(
              (t: any) => t.tag === item
            )
            return (match && match.count) || 0
          }
        }
        break
      case 'price':
        //defaultSelected = priceState[filter.name]
        break
    }
  }

  function isActive(item: any) {
    return selected.indexOf(item) !== -1
  }

  function getNameFromTag(tag: string) {
    const tagPrefix = settings.prefix
    return tag.replace(tagPrefix, '')
  }

  return (
    <div className='color-picker-container'>
      {_items.map((item: any, i: number) => {
        const itemCount = getFacetCount(item)

        if (settings.hideEmpty && itemCount <= 0) {
          return null
        }

        const disabled = typeof itemCount !== 'undefined' && itemCount <= 0

        const onClickItem = () => {
          if (!disabled) {
            let newSelected
            if (!isActive(item)) {
              newSelected = [...selected, item]
            } else {
              newSelected = [...selected.filter((o: any) => o !== item)]
            }

            if (onPicked) {
              onPicked(newSelected)
            }

            setSelected(newSelected)
          }
        }

        return (
          <div
            key={i}
            className={classNames('color-picker-item-container', {
              active: isActive(item),
              disabled: disabled
            })}>
            <div className='color-picker-item-swatch-container'>
              <div
                onClick={onClickItem}
                onKeyUp={(e) => e.code === "Enter" ? onClickItem() : null}
                className={classNames('color-picker', {
                  active: isActive(item),
                  [getNameFromTag(item)]: true
                })}
                style={{
                  borderColor: isActive(item) ? null : item.color,
                  backgroundColor: item.color,
                  cursor: !disabled ? 'pointer' : 'default'
                }}></div>
            </div>
            <small>{getNameFromTag(item)}</small>
          </div>
        )
      })}
    </div>
  )
}

ColorPicker.defaultProps = {
  defaultSelected: []
}

export default ColorPicker
