// remove me later
import React from 'react'
import { useAppDispatch, useAppSelector } from '../../redux'
import { setFilters, setSortBy } from '../../redux/slices/collection-element'
import Picker from './components/Picker'
import CollectionFiltersModel from './models'

const defaultSort = 'manual'

interface Props {
  data: CollectionFiltersModel
  containerProps: any
}

export default function CollectionFilter({ data, containerProps }: Props) {
  const params = useAppSelector((state) => state.collectionElement.params)
  const sortBy = useAppSelector((state) => state.collectionElement.currentCollection?.sortBy || state.collectionElement.sortBy)
  const baseCollection = useAppSelector((state) => state.collectionElement.baseCollection)
  const facetedData = useAppSelector((state) => state.collectionElement.facetedData)
  const dispatch = useAppDispatch()

  let showFilter = true
  //const filterCollections = filter.collections || []
  const collectionName = baseCollection || 'all'
  // if (filterCollections.length > 0 && !filterCollections.includes(collectionName)) {
  //   showFilter = false
  // }
  if (showFilter && params) {
    let defaultSelected = params[data.name]
    if (data.filterType === 'sorter') {
      defaultSelected = params[data.name] ?? [sortBy]
    }

    return (
      <div {...containerProps}>
        <Picker
          filter={data}
          defaultSelected={defaultSelected}
          onPicked={(items: any) => {
            dispatch(setFilters({ filter: data, items }))

            if (data.filterType === 'sorter') {
              dispatch(setSortBy(items[0]))
            }
          }}
          facetedData={facetedData}
        />
      </div>
    )
  } else {
    return <></>
  }
}
