import React, { useMemo } from 'react'
import ContentGalleryModel from '../models'
import Carousel from '../../Carousel/index'
import CarouselModel from '../../Carousel/models'
import ProductCardLayoutModel from '../../ProductCardLayout/models'
import cuid from 'cuid'
import ProductCard from '../../CollectionProductListing/components/ProductCard'

interface Props {
  data: ContentGalleryModel
  items: any[]
}
export default function CarouselView({ data, items }: Props) {
  const slides = useMemo(() => {
    return items.map((item: any, i: number) => {
      return (
        <div key={i}>
          <ProductCard
            key={item.id}
            source='list'
            product={item}
            baseCollection={data._collection?.slug}
            layout='vertical'
            responsive={true}
            showQuickBuy={false}
            showWishListButton={true}
            cardLayout={data?.productCardLayout?.cardLayout}
          />
        </div>
      )
    })
  }, [items])

  const mappedData: CarouselModel = useMemo(
    () => ({
      name: '',
      __id: cuid(),
      __elementId: cuid(),
      __type: 'Carousel',
      carouselType: 'content',
      productCardLayout: new ProductCardLayoutModel(),
      slides: [],
      mobileSettings: data.mobileSettings,
      desktopSettings: data.desktopSettings,
      desktopSettingsEnabled: data.desktopSettingsEnabled
    }),
    [data.mobileSettings, data.desktopSettings, data.desktopSettingsEnabled]
  )

  return (slides.length > 0 && <Carousel data={mappedData} slides={slides}></Carousel>) || <></>
}
