import React from 'react';

interface Props {
  score: number,
  count: number,
  stars?: number,
  height?: number,
  width?: number,
  color1?: string,
  color2?: string,
  displayScore?: boolean,
  displayCount?: boolean,
  showExactStars?: boolean
}

export default function Stars({
  score,
  count,
  stars = 5,
  height = 20,
  width = 20,
  color1 = '#ff3366',
  color2 = '#999999',
  displayScore = false,
  displayCount = false,
  showExactStars = false
}: Props) {

  const starWidth = {
    height: height,
    width: width
  }

  const filled = {
    ...starWidth,
    color: color1
  }

  const unfilled = {
    ...starWidth,
    color: color2
  }

  function formatScore(score: number): string {
    let scoreStr: string = score.toString()
    if (scoreStr.indexOf('.') < 0) {
      scoreStr += '.0'
    }
    return scoreStr
  }

  const StarPath = () => {
    return (
      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 
        1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 
        1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 
        1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" 
      />
    )
  }

  const FullStars = () => {
    //display full stars, partial stars, and un-filled stars
    return (
      <div className="star-group star-group-full">
      {Array.apply(0, Array(stars)).map(function (item, i) {
        const svgClass = (score > i && score < (i + 1) ? 'star-partial' : (score >= (i + 1) ? 'star-whole' : 'star-empty'))
        return (
          <svg key={i} xmlns="http://www.w3.org/2000/svg" style={(score >= (i + 1) ? filled : unfilled)}
              className={svgClass} viewBox="0 0 20 20"
              fill={(score > i && score < (i + 1) ? 'url(#grad)' : 'currentColor')}>
            <linearGradient id="grad" x1="0" x2="100%" y1="0" y2="0">
                <stop offset="50%" stopColor={color1} />
                <stop offset="50%" stopColor={color2} />
            </linearGradient>
            <StarPath />
          </svg>
        )
      })}
      </div>
    )
  }

  const ExactStars = () => {
    //display only the exact numbers of stars earned (no partial or un-filled stars)
    return (
      <div className="star-group star-group-exact">
      {Array.apply(0, Array(Math.trunc(score))).map(function (item, i) {
        return (
          <svg key={i} xmlns="http://www.w3.org/2000/svg" style={filled}
              className="star-whole" viewBox="0 0 20 20" fill="currentColor">
            <StarPath />
          </svg>
        )
      })}
      </div>
    )
  }

  return (
    <div className="review-stars">
      {showExactStars ? <ExactStars /> : <FullStars />}
      {displayScore && <div className="score-display">{formatScore(score)}</div>}
      {displayCount && <div className="count-display">({count})</div>}
    </div>
  )
}