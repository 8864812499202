import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../redux'
import CollectionPageSizePickerModel from './models'
import {
  setPageIndex,
  setPageSize
} from '../../redux/slices/collection-element'
import { setCustomerPreferences } from '../../redux/slices/app'
import SelectCustom from '../../components/SelectCustom'

interface Props {
  data: CollectionPageSizePickerModel
  containerProps: any
}

export default function CollectionPageSizePicker({ data, containerProps }: Props) {
  const dispatch = useAppDispatch()
  let pageSizes = Array.from({length: +data.numPageSizes}, (v, k) => {
    return ((k+1) * data.pageSize)
  })
  const customerPreferences = useAppSelector((state) => state.app.customer?.preferences)
  const currentPageSize = useAppSelector((state) => state.collectionElement.pageSize)

  useEffect(() => {
    if(customerPreferences?.pageSize) {
      dispatch(setPageSize(customerPreferences.pageSize))
    }
  })

  const changePageSize = (pageSize: number) => {
    dispatch(setPageSize(pageSize))
    dispatch(setPageIndex(1))
    window.scrollTo({ top: 0, behavior: 'smooth' })
    dispatch(setCustomerPreferences({
      pageSize: pageSize
    }))
  }

  const options = pageSizes.map((ps) => ({
    type: ps,
    title: ps
  }))
  const defaultPageSizeIndex = pageSizes.findIndex((ps) => ps === +currentPageSize)

  return (
    <SelectCustom
      maxWidth="130px"
      title="View"
      idBase="pagesize"
      defaultIndex={defaultPageSizeIndex !== -1 ? defaultPageSizeIndex : 0}
      onChange={changePageSize}
      options={options}
    />
  )
}
