import React from 'react'
import classNames from 'classnames'

interface SortPickerProps {
  config: any
  displayType?: string
  defaultSelected: any
  onPicked: (picked: string) => void
}

function SortPicker({ config, displayType, defaultSelected, onPicked }: SortPickerProps) {
  switch (displayType) {
    case 'sort-picker':
      return (
        <SortSelectPicker
          config={config}
          defaultSelected={defaultSelected}
          onPicked={onPicked}
        />
      )
    default:
      return (
        <SortListPicker
          config={config}
          defaultSelected={defaultSelected}
          onPicked={onPicked}
        />
      )
  }
}

function SortSelectPicker({ config, defaultSelected, onPicked }: SortPickerProps) {
  return (
    <select
      value={defaultSelected}
      onChange={(e) => {
        console.log(e.target.value)
        onPicked(e.target.value)
      }}>
      {config.sorters.map((sorter: any, i: number) => (
        <option key={i} value={sorter.type}>
          {sorter.label}
        </option>
      ))}
    </select>
  )
}

function SortListPicker({ config, defaultSelected, onPicked }: SortPickerProps) {
  const onClickSorter = (
    sorter: any,
    e?: React.MouseEvent<HTMLAnchorElement, MouseEvent> | React.KeyboardEvent<HTMLAnchorElement>
  ) => {
    e?.preventDefault()
    onPicked(sorter.type)
  }

  return (
    <div className='picker-item-container'>
      {config.sorters.map((sorter: any, i: number) => (
        <a
          href='#'
          onClick={(e) => onClickSorter(sorter, e)}
          onKeyUp={(e) => e.code === "Enter" ? onClickSorter(sorter, e) : null}
          key={i}
          //value={sorter.type}
          className={classNames('list-picker', {
            active: sorter.type === defaultSelected
          })}>
          {sorter.label}
        </a>
      ))}
    </div>
  )
}

SortPicker.defaultProps = {
  onPicked: () => {},
  value: 'manual'
}

export default SortPicker
