import React, { useEffect, useState, createContext } from 'react'
import AnimateHeight from 'react-animate-height'
import classNames from 'classnames'
import styled from '@emotion/styled'
import CategoryTreeModel from './models'
import { Elements } from '..'

interface Props {
  data: CategoryTreeModel
}

const Container = styled.div<{ data: CategoryTreeModel }>`
  .picker-title,
  .picker-title:visited {
    display: flex;
    align-items: center;
    padding: 5px 0px;
    cursor: pointer;
    font-size: ${(props) => props.data.titleFontSize};
    color: ${(props) => props.data.titleFontColor};
  }

  .spacer {
    flex-grow: 1;
  }

  .picker-children,
  .picker-children a {
    font-size: ${(props) => props.data.itemFontSize};
    color: ${(props) => props.data.itemFontColor};
  }

  .item-title {
    padding-top: ${(props) => props.data.paddingTop};
    padding-right: ${(props) => props.data.paddingRight};
    padding-bottom: ${(props) => props.data.paddingBottom};
  }

  .child-container {
    padding-left: ${(props) => props.data.paddingLeft};
  }
`

export const CategoryTreeContext = createContext<{ currentTreePath: string[] | undefined }>({
  currentTreePath: undefined
})

function CategoryTree({ data }: Props) {
  const [isPickerOpen, setPickerOpen] = useState(!data.defaultClosed)
  const [currentTreePath, setCurrentTreePath] = useState()

  // handle auto collapse initial load
  useEffect(() => {
    if (data.defaultClosed === 'always') {
      setPickerOpen(false)
    } else {
      const treePath =
        data?.expandOnCurrentSlug &&
        getCurrentCollectionTreePath(
          window?.location?.pathname?.split('/')?.pop(),
          [data.__elementId],
          data.children
        )
      if (data.defaultClosed === 'off') {
        setPickerOpen(true)
        setCurrentTreePath(treePath)
      } else {
        // check for mobile size
        if (window.innerWidth < 768) {
          setPickerOpen(false)
        } else {
          setPickerOpen(true)
          setCurrentTreePath(treePath)
        }
      }
    }
  }, [data.defaultClosed])

  const maxHeight = data.maxHeight || 'auto'

  const onClickTitle = (
    e?: React.MouseEvent<HTMLAnchorElement, MouseEvent> | React.KeyboardEvent<HTMLAnchorElement>
  ) => {
    e?.preventDefault()
    setPickerOpen(!isPickerOpen)
  }

  return (
    <Container data={data} className={classNames(`picker-container`)}>
      {!data.hideTitle && (
        <a
          className='picker-title'
          onClick={onClickTitle}
          onKeyUp={(e) => (e.code === 'Enter' ? onClickTitle(e) : null)}>
          <span className='picker-title-name'>{data.title}</span>
          <div className='spacer'></div>
          <PickerIcon isOpen={isPickerOpen} />
        </a>
      )}
      <AnimateHeight duration={250} height={isPickerOpen ? 'auto' : 0}>
        <div
          className='picker-children'
          style={{
            display: 'flex',
            flexDirection: 'column',
            maxHeight,
            overflowX: 'hidden',
            lineHeight: 'normal'
          }}>
          <CategoryTreeContext.Provider value={{ currentTreePath }}>
            <Elements data={data} field='children' />
          </CategoryTreeContext.Provider>
        </div>
      </AnimateHeight>
    </Container>
  )
}

interface PickerIconProps {
  isOpen: boolean
}

function PickerIcon({ isOpen }: PickerIconProps) {
  return <span className='picker-title-icon'>{isOpen ? '-' : '+'}</span>
}

function getCurrentCollectionTreePath(
  currentSlug: string | undefined,
  path: any[],
  categoryChildren: any[]
): any {
  const child = categoryChildren?.find((child) => child?._collection?.slug === currentSlug)
  if (currentSlug && child) {
    path.push(child.__elementId)
    return { path, isFound: true }
  } else if (categoryChildren) {
    for (const child of categoryChildren) {
      const currentPath = [...path, child?.__elementId]
      const item = getCurrentCollectionTreePath(currentSlug, currentPath, child?.children)
      if (item?.isFound) {
        return item.path
      }
    }
  }
}

export default CategoryTree
