import React, { useEffect, useMemo, useState } from 'react'
import { hooks } from '../../../lib/hooks'
import slugify from 'slugify'
import { useMediaQuery } from 'react-responsive'
import styled from '@emotion/styled'

import SwatchItem, {
  StyledOutOfStockStrikeThrough,
  StyledSwatchContainer,
  StyledSwatchListContainer,
  SwatchParentComponent
} from './SwatchItem'
import SwatchCount from './SwatchCount'

/**
 * FamilyPicker Component
 */
interface Props {
  getImageSwatch: (images: any[]) => string
  setImagePositions: React.Dispatch<[number, number]>
  onChangeVariant: (variant: any) => void
  currentProduct: any
  useMouseOverChange?: boolean
  swatchStyle: string
  swatchLimit: number
  isMouseHovering: boolean
  desktopSwatchStyle: string
  mobileBreakpoint: string
  desktopSwatchLimit: number
  desktopSwatchStyleEnabled: boolean
  desktopSwatchCountRevealTrigger: string
  strikeThroughOos: boolean
  hideOosSwatches: boolean
}

enum PickerStyle {
  group = 'swatchGroup',
  count = 'swatchCount',
  standard = 'standard'
}

const COLOR_OPTION_NAME = 'color'

interface ColorOption {
  name: string
  values: string[]
  position: number
}

/**
 * Handles color picking when product family is not used
 * @param param0
 * @returns
 */
export default function ProductColorPicker({
  getImageSwatch,
  useMouseOverChange,
  currentProduct,
  setImagePositions,
  onChangeVariant,
  swatchStyle,
  swatchLimit,
  mobileBreakpoint,
  desktopSwatchStyle,
  desktopSwatchLimit = 5,
  isMouseHovering,
  desktopSwatchStyleEnabled,
  desktopSwatchCountRevealTrigger = 'hover',
  strikeThroughOos = false,
  hideOosSwatches = false,
  ...props
}: Props) {
  const isMobile = useMediaQuery({ query: `(max-width: ${mobileBreakpoint})` })
  const isDesktop = useMediaQuery({ query: `(min-width: ${mobileBreakpoint})` })
  const [selectedColor, setSelectedColor] = useState('')
  const [colorOption, setColorOption] = useState<ColorOption | null | undefined>(null)
  const [countLimit, setCountLimit] = useState(
    swatchStyle || desktopSwatchStyle === PickerStyle.count
      ? isDesktop
        ? desktopSwatchStyleEnabled
          ? desktopSwatchLimit
          : swatchLimit
        : swatchLimit
      : currentProduct.variants.length
  )

  const [currentSwatchStyle, setCurrentSwatchStyle] = useState(
    isDesktop && desktopSwatchStyle ? desktopSwatchStyle : swatchStyle
  )
  const [isMouseHoveringOnDesktop, setIsMouseHoveringOnDesktop] = useState(
    isDesktop && isMouseHovering
  )

  useEffect(() => {
    setCountLimit(
      swatchStyle || desktopSwatchStyle === PickerStyle.count
        ? isDesktop
          ? desktopSwatchStyleEnabled
            ? desktopSwatchLimit
            : swatchLimit
          : swatchLimit
        : currentProduct.options.length
    )
  }, [swatchLimit])

  const colorOptions = useMemo(() => {
    // get the color option
    const option = currentProduct.options.find(
      (p: ColorOption) => p.name.toLowerCase() === COLOR_OPTION_NAME
    ) as ColorOption

    if (!option) return []

    setColorOption(option)

    if (option.values.length > 0) {
      setSelectedColor(option.values[0])
    }
    return option.values
  }, [currentProduct])

  useEffect(() => {
    if (colorOptions && selectedColor && colorOption) {
      const variant = getVariant(selectedColor)
      onChangeVariant(variant)

      if (variant?.imageId) {
        const productImageIndex = currentProduct.images.findIndex(
          (img: any) => img.sourceid === variant.imageId.toString()
        )

        if (productImageIndex > -1) {
          setImagePositions([productImageIndex + 1, -1])
        }
      }
    }
  }, [colorOptions, selectedColor])

  useEffect(() => {
    setIsMouseHoveringOnDesktop(isDesktop && isMouseHovering)
  }, [isDesktop, isMouseHovering])

  useEffect(() => {
    setCurrentSwatchStyle(() => {
      if (isDesktop && desktopSwatchStyleEnabled && desktopSwatchStyle) {
        return desktopSwatchStyle
      }
      return swatchStyle
    })

    setCountLimit((countState: any) => {
      if (
        isDesktop &&
        desktopSwatchStyleEnabled &&
        desktopSwatchStyle === PickerStyle.count &&
        !isMouseHoveringOnDesktop &&
        desktopSwatchCountRevealTrigger === 'hover'
      ) {
        return desktopSwatchLimit
      } else if (isMobile && swatchStyle === PickerStyle.count) {
        return swatchLimit
      } else if (
        isDesktop &&
        desktopSwatchStyleEnabled &&
        desktopSwatchStyle === PickerStyle.count &&
        isMouseHoveringOnDesktop &&
        desktopSwatchCountRevealTrigger === 'hover'
      ) {
        return colorOptions.length
      } else if (
        isDesktop &&
        !desktopSwatchStyleEnabled &&
        swatchStyle === PickerStyle.count &&
        isMouseHoveringOnDesktop
      ) {
        return colorOptions.length
      } else if (
        isDesktop &&
        !desktopSwatchStyleEnabled &&
        swatchStyle === PickerStyle.count &&
        !isMouseHoveringOnDesktop
      ) {
        return swatchLimit
      }

      return countState
    })
  }, [
    isMouseHoveringOnDesktop,
    isDesktop,
    isMobile,
    swatchStyle,
    desktopSwatchStyleEnabled,
    desktopSwatchStyle,
    swatchLimit,
    desktopSwatchLimit
  ])

  function getSwatch(color: string) {
    // check if swatch exists in images
    const swatchAlt = `swatch-${slugify(color, { strict: true, lower: true })}`
    const imageSwatch = currentProduct.images?.find((img: any) => img.alt === swatchAlt)?.src

    const swatch = imageSwatch || hooks.run(`catalog.swatch`, color)
    if (swatch.startsWith('http')) {
      return `${swatch}`
    }
    return color.toLowerCase()
  }

  function getVariant(color: string) {
    if (colorOption) {
      const position = colorOption.position
      const variant = currentProduct?.variants.find((v: any) => v[`option${position}`] === color)
      return variant
    }
  }

  function onClickColorOptions(color: string) {
    setSelectedColor(color)
    const variant = getVariant(color)
    onChangeVariant(variant)
  }

  const onClickSwatchCount = () => {
    setCountLimit((countState: number) => {
      return countState === colorOptions.length ? swatchLimit : colorOptions.length
    })
  }

  return (
    <StyledSwatchesPicker>
      <StyledSwatchListContainer>
        {colorOptions?.map((color: string, index) => {
          let variantInventory = currentProduct?.variants.find(
            (v: { option1: string }) => v.option1 === color
          )?.inventoryQuantity
          let isStikeThroughOosVisible = strikeThroughOos && variantInventory < 1
          if (currentSwatchStyle === PickerStyle.count)
            isStikeThroughOosVisible = isStikeThroughOosVisible && index < countLimit
          return (
            <StyledSwatchContainer key={`color-${color}_${index}`}>
              <StyledOutOfStockStrikeThrough
                isVisible={isStikeThroughOosVisible && !hideOosSwatches}
                isSelected={color === selectedColor}
                className='oos-swatch-strike-through'
              />
              <SwatchItem
                key={`color-${color}_${index}`}
                option={color}
                setCurrentOption={onClickColorOptions}
                mobileBreakpoint={mobileBreakpoint}
                isDesktop={isDesktop}
                isShowingAllSwatches={countLimit === colorOptions.length}
                selectedOption={selectedColor}
                getImageSwatch={getSwatch}
                swatchStyle={currentSwatchStyle}
                isMouseHovering={isMouseHovering}
                swatchParentComponent={SwatchParentComponent.PRODUCT_BASIC}
                useMouseOverChange={useMouseOverChange}
                isHidden={currentSwatchStyle === PickerStyle.count && index >= countLimit}
                hideSwatch={hideOosSwatches && variantInventory < 1}
                {...props}
              />
            </StyledSwatchContainer>
          )
        })}
      </StyledSwatchListContainer>
      {currentSwatchStyle === PickerStyle.count &&
        colorOptions.length > swatchLimit &&
        ((isDesktop && !isMouseHoveringOnDesktop && desktopSwatchCountRevealTrigger === 'hover') ||
          (isDesktop && desktopSwatchCountRevealTrigger === 'click') ||
          isMobile) && (
          <div
            onClick={onClickSwatchCount}
            onKeyUp={(e) => (e.code === 'Enter' ? onClickSwatchCount() : null)}>
            <SwatchCount
              countLimit={countLimit}
              total={colorOptions.length}
              mobileBreakpoint={mobileBreakpoint}
              isMobile={isMobile}></SwatchCount>
          </div>
        )}
    </StyledSwatchesPicker>
  )
}

const StyledSwatchesPicker = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 5px;
  align-items: center;
}
`
