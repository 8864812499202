import React, { useCallback } from 'react'
import styled from '@emotion/styled'
import CategoryTreeItemModel from './models'
import CategoryTreeCollectionItemModel from './models'
import { FiChevronDown } from 'react-icons/fi'
import { useAppSelector } from '../../redux'

interface Props {
  data: CategoryTreeCollectionItemModel
  containerProps?: string[]
}

const Container = styled.ul<{ data: CategoryTreeItemModel; currentSlug: string | undefined }>`
  list-style-type: none;
  margin: 0;
  padding: 0;

  > li {
    > a {
      display: flex;
      text-decoration: none;
      align-items: center;
      ${({ currentSlug, data }) =>
        currentSlug && currentSlug === data._collection?.slug && 'font-weight: bold;'}

      > span {
        display: flex;
        visibility: hidden;
      }
    }
  }
`

function CategoryTreeCollectionItem({ data, containerProps }: Props) {
  const isAdmin = useAppSelector((state) => state.app.isAdmin)
  const slug = data._collection?.slug
  const currentSlug = window?.location?.pathname?.split('/')?.pop()

  const onClickHandler = useCallback((e: React.SyntheticEvent) => {
    if (isAdmin) {
      e.preventDefault()
      e.stopPropagation()
      return false
    }
  }, [])

  if (slug) {
    const href = '/collections/' + slug
    const title = data.title || data._collection?.name
    return (
      <Container data={data} currentSlug={currentSlug} {...containerProps}>
        <li>
          <a
            href={href}
            aria-label={`Link to ${title}`}
            title={title}
            className='item-title'
            onClick={onClickHandler}
          >
            <span>
              <FiChevronDown />
            </span>
            {title}
          </a>
        </li>
      </Container>
    )
  }

  return <></>
}

export default CategoryTreeCollectionItem
