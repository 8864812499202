import React from 'react'
import styled from '@emotion/styled'
import CollectionLinksModel from '../../elements/CollectionLinks/models'
import HtmlModel from '../../elements/Html/models'
import Html from '../../elements/Html'
import { Element } from '../../elements/index'


interface Props {
  data?: CollectionLinksModel
  containerProps?: any
}

interface CollectionLinksComponentProps {
  collectionLinks: { category: string; urls: Array<{ title: string; url: string }> } | null
  data?: any
  containerProps?: string[]
}

const CollectionLinksWrapper = styled.div<Props>`
  display: inline-block;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  color: #000; /* Text color */
  background-color: #fff; /* Background color */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Light shadow */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s, box-shadow 0.3s;
  width: ${(props) =>
    props.data?.style?.widthType === 'auto' ? 'auto' : props.data?.style?.widthValue};
  height: ${(props) =>
    props.data?.style?.heightType === 'auto' ? 'auto' : props.data?.style?.heightValue};
  top: ${(props) => (props.data?.style?.topType === 'auto' ? 'auto' : props.data?.style?.topValue)};
  padding-top: ${(props) => props.data?.style?.paddingTop};
  padding-right: ${(props) => props.data?.style?.paddingRight};
  padding-bottom: ${(props) => props.data?.style?.paddingBottom};
  padding-left: ${(props) => props.data?.style?.paddingLeft};
  margin-top: ${(props) => props.data?.style?.marginTop};
  margin-right: ${(props) => props.data?.style?.marginRight};
  margin-bottom: ${(props) => props.data?.style?.marginBottom};
  margin-left: ${(props) => props.data?.style?.marginLeft};
  background-color: ${(props) => props.data?.style?.backgroundColor ?? 'f9f9f9'};
  border-width: ${(props) =>
    props.data?.style?.borderThickness !== '0px'
      ? props.data?.style?.borderThickness
      : `${props.data?.style?.borderTop} ${props.data?.style?.borderRight} ${props.data?.style?.borderBottom} ${props.data?.style?.borderLeft}`};
  border-color: ${(props) => props.data?.style?.borderColor};
  border-radius: ${(props) => props.data?.style?.borderRadius};
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0; /* Slightly darker background on hover */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
  }

  @media (max-width: 768px) {
    display: ${(props) => (props.data?.style.visibility === 'visible' ? 'block' : 'none')};
  }
`

const Link = styled.a`
  text-decoration: none;
  color: #000;
`

const StyledUnorderedList = styled.ul`
  padding-left: 0;
`

const CollectionLinksComponent: React.FC<CollectionLinksComponentProps> = ({
  collectionLinks,
  data,
  containerProps
}) => {
  if (!collectionLinks || collectionLinks?.urls.length === 0) {
    return <div></div>
  }
  const htmlModel = new HtmlModel()
  htmlModel.html = data.customMessage

  return (
    <div className='collection-links-container'>
      <h3 className='collection-links-title'>
      <Html
        data={htmlModel}
        context={{ name: collectionLinks.category }}
      />
      </h3>
      <StyledUnorderedList className='collection-links-unordered-list'>
        {collectionLinks.urls.map((url, index) => (
          <CollectionLinksWrapper key={index} className='collection-links' data={data} {...containerProps}>
            <Link href={url.url}>
              {url.title}
            </Link>
          </CollectionLinksWrapper>
        ))}
      </StyledUnorderedList>
    </div>
  )
}

export default CollectionLinksComponent
