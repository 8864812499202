import React, { useEffect } from 'react'
import ProductCard from '../ProductCard'
import PaginateButton from '../PaginateButton'
import styled from '@emotion/styled'
import { useAppSelector } from '../../redux'
import CollectionInlineBannerModel from '../../elements/CollectionInlineBanner/models'
import { analytics } from '../../main'
import { Element } from '../../elements'

interface ProductListingProps {
  products: any[]
  baseCollection: string
  config: any
  pageIndex: number
  totalProducts: number
  onPageChange: (page: number) => void
  content?: any
}

interface InlineBannerProps {
  colspanDesktop: number
  rowspanDesktop: number
  colspanMobile: number
  rowspanMobile: number
}

function range(size: number, startAt: number = 0): number[] {
  return [...Array(size).keys()].map((i) => i + startAt)
}

const InlineBannerContainer = styled.div<InlineBannerProps>`
  display: grid;
  grid-template-columns: 1fr;
  gap: 5;
  grid-column: span ${(props) => props.colspanDesktop || 1};
  grid-row: span ${(props) => props.rowspanDesktop || 1};

  @media screen and (max-width: 768px) {
    grid-column: span ${(props) => props.colspanMobile || 1};
    grid-row: span ${(props) => props.rowspanMobile || 1};
  }
`

export function InlineBanner({
  content,
  position,
  offset
}: {
  content: any
  position: number
  offset: number
}) {
  const banner = content?.collectionBanner?.find((b: CollectionInlineBannerModel) => {
    return (
      (+b.position === position && b.showOnAllPages) ||
      (+b.position === position + offset && !b.showOnAllPages)
    )
  })

  if (banner) {
    const _banner = banner as CollectionInlineBannerModel

    return (
      <InlineBannerContainer
        colspanDesktop={_banner.columnSpan}
        colspanMobile={_banner.columnSpanMobile}
        rowspanDesktop={_banner.rowSpan}
        rowspanMobile={_banner.rowSpanMobile}>
        <div className='inline-banner'>
          <Element name='ResponsiveImage' data={banner.image} />
        </div>
      </InlineBannerContainer>
    )
  }

  return null
}

function ProductListing({
  products,
  baseCollection,
  config,
  pageIndex,
  totalProducts,
  onPageChange,
  content
}: ProductListingProps) {
  const loading = useAppSelector((state) => state.collection.loading)
  const pageSize = config.pagination.pageSize || 10
  const pages = Math.ceil(totalProducts / pageSize) + 1

  // pages shown in pagination picker
  const MAX_NEIGHBORS = 2
  let activePages: number[] = []
  if (totalProducts > 0) {
    const startPage = Math.max(1, pageIndex - MAX_NEIGHBORS)
    const endPage = Math.min(pages, pageIndex + MAX_NEIGHBORS + 1)
    activePages = range(endPage - startPage, startPage)
  }

  // show ... on left side
  const showSpillLeft = activePages[0] > 2

  // show ... on right side
  const showSpillRight = activePages[activePages.length - 1] < pages - 2

  useEffect(() => {
    // Track page views for PLP (legacy)
    analytics.page({})
  }, [])

  return (
    <div
      className='App'
      style={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative'
      }}>
      {/* Products: {totalProducts} */}
      <div
        style={{ gap: 10 }}
        data-columns-desktop={config.grid.columns.desktop}
        data-columns-mobile={config.grid.columns.mobile}>
        {products.length > 0
          ? products.map((product, i) => (
              <React.Fragment key={`RF-${product.id}-${i + 1}`}>
                {content?.collectionBanner?.length > 0 && (
                  <InlineBanner
                    key={`banner-${i + 1}`}
                    content={content}
                    position={i + 1}
                    offset={(pageIndex - 1) * pageSize}
                  />
                )}
                <ProductCard
                  key={product.id}
                  source='list'
                  product={product}
                  baseCollection={baseCollection}
                  layout='vertical'
                  useMouseOverChange={config.useMouseOverChange}
                  responsive={true}
                  showQuickBuy={true}
                  overlayElements={[
                    <div
                      className='nogin-wishlist-product'
                      data-product-id={product.sourceId}
                      key={`product-wishlist-${product.id}`}></div>
                  ]}
                  showWishListButton={true}
                  contentLayout={
                    config.cardLayout || ['title', 'price', 'family', 'actions', 'custom-footer']
                  }
                  settings={config.reviews}
                  position={i}
                />
              </React.Fragment>
            ))
          : !loading && <div className='empty-message'>{config.emptyMessage}</div>}
      </div>
      {pages > 1 && (
        <div className='pagination' style={{ display: 'flex' }}>
          <PaginateButton
            pageIndex={pageIndex - 1}
            title='Prev'
            onClick={() => {
              onPageChange(pageIndex - 1)
            }}
            disabled={pageIndex === 1}
            active={false}
          />
          {showSpillLeft && (
            <PaginateButton
              pageIndex={1}
              title='1'
              onClick={() => {
                onPageChange(1)
              }}
              disabled={false}
              active={false}
            />
          )}
          {showSpillLeft && '...'}
          {activePages.map((i) => {
            return (
              <PaginateButton
                pageIndex={i}
                key={i}
                title={i.toString()}
                onClick={() => {
                  onPageChange(i)
                }}
                disabled={false}
                active={pageIndex === i}
              />
            )
          })}
          {showSpillRight && '...'}
          {showSpillRight && (
            <PaginateButton
              pageIndex={pages - 1}
              title={(pages - 1).toString()}
              onClick={() => {
                onPageChange(pages - 1)
              }}
              disabled={false}
              active={false}
            />
          )}
          <PaginateButton
            pageIndex={pageIndex + 1}
            title={'Next'}
            onClick={() => {
              onPageChange(pageIndex + 1)
            }}
            disabled={pageIndex === pages - 1}
            active={false}
          />
        </div>
      )}
    </div>
  )
}

ProductListing.defaultProps = {
  onPageChange: () => {}
}

export default ProductListing
