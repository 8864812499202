import styled from '@emotion/styled'
import React, { useContext, useMemo } from 'react'
import ContentGalleryModel from '../models'
import { LayoutContext } from '../../Layout'
import GenericCard from '../../ContentType/GenericCard'

interface Props {
  data: ContentGalleryModel
  items: any[]
}

const Container = styled.div<{ data: ContentGalleryModel }>`
  display: flex;
  flex-direction: column;
  position: relative;

  > .card-wrapper {
    display: grid;
    grid-template-columns: ${(props) => `repeat(${props.data.gridColumnsDesktop}, 1fr)`};
    gap: ${(props) => props.data.gridSpacing};
    max-width: 100%;
  }

  @media screen and (max-width: 768px) {
    > .card-wrapper {
      grid-template-columns: ${(props) => `repeat(${props.data.gridColumnsMobile}, 1fr)`};
      gap: ${(props) => props.data.gridSpacing};
    }
  }

  .element-card {
    border: 1px solid #000;
  }
`

export default function GridView({ data, items }: Props) {
  const parentContext = useContext(LayoutContext)

  const cardLayout = useMemo(() => {
    const cardLayoutId = data?._contentType?.extraData?.cardLayout
    if (cardLayoutId) {
      return parentContext?.__layouts?.find((layout: any) => layout.__id === cardLayoutId)
    }
  }, [parentContext, data._contentType?.extraData?.cardLayout])

  return (
    <Container data={data}>
      <div className='card-wrapper'>
        {items.map((item: any, i: number) => {
          return  (
            <GenericCard
              key={i}
              data={data}
              cardLayout={cardLayout}
              slug={item.slug}
              parentData={{ 
                ...item.data, 
                __type: data?._contentType?.name,
                _contentType: data?._contentType,
                __id: item.id,
                __aggs: item.aggs,
                __updatedAt: item.updatedAt,
              }}
            />
          )
        })}
      </div>
    </Container>
  )
}
