import React, { useEffect, useMemo, useState } from 'react'
import { hooks } from '../../lib/hooks'
import { spinalcase } from 'stringcase'
import slugify from 'slugify'
import classNames from 'classnames'

/**
 * FamilyPicker Component
 */
interface Props {
  getImageSwatch: (images: any[]) => string
  setImagePositions: React.Dispatch<[number, number]>
  onChangeVariant: (variant: any) => void
  currentProduct: any
  useMouseOverChange?: boolean
}

const COLOR_OPTION_NAME = 'color'

interface ColorOption {
  name: string
  values: string[]
  position: number
}

/**
 * Handles color picking when product family is not used
 * @param param0
 * @returns
 */
export default function ProductColorPicker({
  getImageSwatch,
  useMouseOverChange,
  currentProduct,
  setImagePositions,
  onChangeVariant
}: Props) {
  const [selectedColor, setSelectedColor] = useState('')
  const [colorOption, setColorOption] = useState<ColorOption | null | undefined>(null)

  const colorOptions = useMemo(() => {
    // get the color option
    const option = currentProduct.options.find(
      (p: ColorOption) => p.name.toLowerCase() === COLOR_OPTION_NAME
    ) as ColorOption

    if (!option) return []

    setColorOption(option)

    if (option.values.length > 0) {
      setSelectedColor(option.values[0])
    }
    return option.values
  }, [currentProduct])

  useEffect(() => {
    if (colorOptions && selectedColor && colorOption) {
      const variant = getVariant(selectedColor)
      onChangeVariant(variant)

      if (variant?.imageId) {
        const productImageIndex = currentProduct.images.findIndex(
          (img: any) => img.sourceid === variant.imageId.toString()
        )

        if (productImageIndex > -1) {
          setImagePositions([productImageIndex + 1, -1])
        }
      }
    }
  }, [colorOptions, selectedColor])

  function getSwatch(color: string) {
    // check if swatch exists in images
    const swatchAlt = `swatch-${slugify(color, { strict: true, lower: true })}`
    const imageSwatch = currentProduct.images?.find((img: any) => img.alt === swatchAlt)?.src

    const swatch = imageSwatch || hooks.run(`catalog.swatch`, color)
    if (swatch.startsWith('http')) {
      return `url(${swatch})`
    }
    return color.toLowerCase()
  }

  function getVariant(color: string) {
    if (colorOption) {
      const position = colorOption.position
      const variant = currentProduct?.variants.find((v: any) => v[`option${position}`] === color)
      return variant
    }
  }

  function onClickColorOptions(color: string) {
    setSelectedColor(color)
    const variant = getVariant(color)
    onChangeVariant(variant)
  }

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: 10 }}>
      {colorOptions?.map((color: string) => {
        return (
          <div
            key={color}
            className={classNames('swatch', spinalcase(color))}
            style={{
              background: getSwatch(color),
              borderWidth: 2,
              borderStyle: 'solid',
              borderColor: selectedColor === color ? 'black' : 'white',
              height: 20,
              width: 20,
              borderRadius: 30,
              marginRight: 10,
              marginBottom: 10,
              cursor: 'pointer'
            }}
            onMouseEnter={() => {
              if (useMouseOverChange) {
                onClickColorOptions(color)
              }
            }}
            onClick={() => onClickColorOptions(color)}
            onKeyUp={(e) => (e.code === 'Enter' ? onClickColorOptions(color) : null)}
          />
        )
      })}
    </div>
  )
}
