import React, { useState, useEffect, useRef, useCallback } from 'react'
import axios, { CancelTokenSource } from 'axios'
import ProductCard from '../ProductCard'
import { useAppSelector } from '../../redux'
import { useCancelToken } from '../../lib/helpers'
import IntlPricesLoader from '../../components/IntlPricesLoader/'
import { debounce } from '../../lib/helpers'
import { capitalcase, titlecase } from 'stringcase'
import cuid from 'cuid'

export default function SearchApp({ config }: { config: any }) {
  const stateAppSettings = useAppSelector((state) => state.app.settings)
  const [isOpen, setOpen] = useState(false)
  const [keyword, setKeyword] = useState('')
  const [loading, setLoading] = useState(false)
  const [products, setProducts] = useState([])
  const [collections, setCollections] = useState([])
  const [pages, setPages] = useState([])
  const [matches, setMatches] = useState(0)
  const [pageSize, setPageSize] = useState(5)
  const component = useRef<HTMLDivElement | null>(null)
  const { newCancelToken } = useCancelToken()
  const redirectToRef = useRef(null)
  const searchId = cuid()
  const hideOOSSwatchSearch = useAppSelector((state) => state.app.settings.hideOOSSwatchSearch?.hideOOSSwatchesEnabled) || false
  const [hideOOSSwatches, setHideOOSSwatches] = useState(false)
  const [enableSearchCollapse, setEnableSearchCollapse] = useState(false)
  
  useEffect(() => {
    setEnableSearchCollapse(stateAppSettings?.searchBarAppData?.searchCollapsingEnabled || false)
  }, [stateAppSettings])
  
  useEffect(() => {
    function documentClickHandler(e: Event) {
      if (component.current && !component?.current?.contains(e.target as Node)) {
        setOpen(false)
      }
    }

    document.addEventListener('click', documentClickHandler)
    return () => {
      document.removeEventListener('click', documentClickHandler)
    }
  }, [component])

  async function load(query: string = keyword) {
    setLoading(true)
    try {
      if (query !== '') {
        const path = '/products/es/search'
        const response = await axios.get(path, {
          params: {
            s: query,
            pageSize,
            searchCollections: true,
            searchPages: true,
            searchRedirects: true,
            enableCollapse: enableSearchCollapse
          },
          cancelToken: newCancelToken()
        })

        setHideOOSSwatches(hideOOSSwatchSearch)

        if(response.data.redirectTo){
          redirectToRef.current = response.data.redirectTo
        }

        if (response.data.items) {
          setProducts(response.data.items)
        } else {
          setProducts([])
        }
        if (response.data.collections) {
          setCollections(response.data.collections)
        } else {
          setCollections([])
        }
        if (response?.data?.pages) {
          setPages(response.data.pages)
        } else {
          setPages([])
        }
        setMatches(response.data.total)
      } else {
        setProducts([])
        setCollections([])
        setPages([])
        setMatches(0)
        setHideOOSSwatches(false)
      }
      setTimeout(() => {
        setLoading(false)
      }, 500)
    } catch (e) {
      setLoading(false)
      if (axios.isCancel(e)) return
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    debouncedLoad(keyword)
  }, [keyword])

  const onEnter = async () => {
    await load(keyword)
    if(redirectToRef.current){
      window.location.href = redirectToRef.current
    }
    else{
      window.location.href = `/search?q=${keyword}`
    }
  }

  const debouncedLoad = useCallback(
    debounce((keyword: string) => {
      load(keyword)
    }, 1000),
    [enableSearchCollapse]
  )

  function renderSearchResult() {
    if (matches === 0 && !loading && keyword.length > 2) {
      return <span>There are no matches for "{keyword}"</span>
    }

    return (
      <>
        {collections?.length > 0 && (
            <section>
              <h5 className='search-category'>Collections</h5>
              <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
                {collections.slice(0, 5).map((collection: any) => (
                  <li
                    style={{ display: 'block', float: 'initial', marginBottom: 10 }}
                    key={collection.id}>
                    <a href={`/collections/${collection.slug}`}>{collection.title}</a>
                  </li>
                ))}
              </ul>
            </section>
          )}
        {products?.length > 0 && (
          <section>
            <IntlPricesLoader items={products} />
            <h5 className='search-category'>Products</h5>
            <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
              {products.map((product: any) => (
                <li
                  style={{ display: 'block', float: 'initial', marginBottom: 10 }}
                  key={product.id}>
                  <ProductCard
                    source='search'
                    product={product}
                    layout='horizontal'
                    responsive={false}
                    desktopGridTemplate='1fr 8fr'
                    contentLayout={config?.cardLayout || ['title', 'price', 'family']}
                    showQuickBuy={false}
                    hideOOSSwatch={hideOOSSwatches}
                  />
                </li>
              ))}
            </ul>
          </section>
        )}
        {pages?.length > 0 && (
            <section>
              <h5 className='search-category'>Blogs & Pages</h5>
              <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
                {pages.slice(0, pageSize).map((page: any) => (
                  <li
                    style={{ display: 'block', float: 'initial', marginBottom: 10 }}
                    key={page.id}>
                    <a href={`${page.url}`}>{titlecase(page.title)}</a>
                  </li>
                ))}
              </ul>
            </section>
          )}
      </>
    )
  }

  return (
    <div ref={component} style={{position:'relative'}}>
      <label htmlFor={`searchbox-${searchId}`} style={{position:'absolute',top:'-1000px',left:'-1000px'}}>Search</label>
      <input
        type='text'
        id={`searchbox-${searchId}`}
        name='q'
        className='searchbox'
        placeholder='Search'
        style={{ padding: 10, width: '100%' }}
        value={keyword}
        autoComplete='off'
        onFocus={() => {
          if (!isOpen && keyword !== '') {
            setOpen(true)
          }
        }}
        onChange={(e) => {
          setLoading(true)
          setKeyword(e?.target?.value)
          if (!isOpen) {
            setOpen(true)
          }
        }}
        onKeyDown={(e) => {
          if(e.key === 'Enter'){
              onEnter()
            }
          }
        }
      />
      {keyword && (
        <div
          style={{
            background: 'white',
            width: '100%',
            padding: 10,
            zIndex: 2,
            display: isOpen ? 'block' : 'none'
          }}>
          {loading && matches === 0 ? 'Searching...' : renderSearchResult()}
          {!loading && matches > pageSize && (
            <a href={`/search?q=${keyword}`}>View all {matches} matches</a>
          )}
        </div>
      )}
    </div>
  )
}
