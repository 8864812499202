import React, { useState, useEffect, useContext } from 'react'
import CollectionLinksComponent from '../../components/CollectionLinks'
import { useAppDispatch, useAppSelector } from '../../redux'
import { loadCollectionLinks } from '../../redux/slices/collection-element'
import { LayoutContext } from '../Layout'
import styled from '@emotion/styled'

interface ContentProps {
  data?: any
  containerProps?: string[]
}

type CollectionLink = { url: string; title: string }

type Collections = {
  [key: string]: Array<CollectionLink>
}

const EmptyContainer = styled.div`
  padding: 10px;
  border: 2px dashed #e0e0e0;
  border-radius: 10px;
  margin: 2px;
  color: #030303;
  font-size: 12px;
`

export default function CollectionLinks({ data, containerProps }: ContentProps) {
  const layoutContext = useContext(LayoutContext)
  const dispatch = useAppDispatch()
  const { collectionLinks } = useAppSelector((state) => state.collectionElement)

  const isAdmin = useAppSelector((state) => state.app.isAdmin)
  const config = useAppSelector((state) => state.app.config)
  const [categoryLinks, setCategoryLinks] = useState<{
    category: string
    urls: CollectionLink[]
  } | null>(null)

  useEffect(() => {
    dispatch(loadCollectionLinks())
  }, [data])

  useEffect(() => {
    if (collectionLinks?.data) {
      const links = getCategoryLinks()
      setCategoryLinks(links)
    }
  }, [collectionLinks])

  const getCategoryLinks = () => {
    if (!layoutContext) {
      return null
    }

    const slug = layoutContext?.slug || ''
    const collections: Collections = collectionLinks?.data as unknown as Collections
    for (const [category, links] of Object.entries(collections)) {
      //This allows CollectionLinks to work even if one slug is the substring of another slug.
      //i.e. if the collections /womens-pants and /womens-pants-straight-leg exist,
      //if the collection the user is on is the slug /womens-pants then one url should be removed as the "current collection" url rather than both urls.
      const currentCollection =
        links.find((link) => link?.url === `/collections/${slug}`) ||
        links.filter((link) => link?.url?.includes(slug))?.[0]
      if (currentCollection?.url) {
        return {
          category,
          urls: links
            .filter((link) => !link?.url.includes(currentCollection.url) && link?.title)
            .slice(0, 10)
        }
      }
    }
    return collections?.['Miscellaneous']
      ? {
          category: 'Miscellaneous',
          urls: collections?.['Miscellaneous'].slice(0, 10)
        }
      : null
  }

  return (
    <>
      {isAdmin && !layoutContext ? (
        <EmptyContainer>
          CollectionLinks Placeholder Preview
          <CollectionLinksComponent
            collectionLinks={{
              category: 'Collection Name',
              urls: [
                { title: 'Collection Name 1', url: '/collections/new-arrivals' },
                { title: 'Collection Name 2', url: '/collections/sale' }
              ]
            }}
            data={data}
            containerProps={containerProps}
          />
        </EmptyContainer>
      ) : (
        <CollectionLinksComponent
          collectionLinks={categoryLinks}
          data={data}
          containerProps={containerProps}
        />
      )}
    </>
  )
}
