import React, { ReactNode, useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { WishListAddButtonAppData } from '../../../../api/src/appdata/wishlist'
import { useAppDispatch, useAppSelector } from '../../redux'
import { notification } from '../../redux/slices/app'
import { addWishListItem } from '../../redux/slices/wishlist'
import { WishListItemPayload, IntlPrices } from '../../types'
import ShopifyImage from '../ShopifyImage'
import WishListAddButton from '../WishListAddButton'
import FamilyPicker from './FamilyPicker'
import ProductActions from './ProductActions'
import ProductCustomFooter from './ProductCustomFooter'
import ProductPrice from './ProductPrice'
import ProductTitle from './ProductTitle'
import ProductReview from './ProductReview'
import { hooks } from '../../lib/hooks'
import ProductOptionPicker from './ProductOptionPicker'
import ProductColorPicker from './ProductColorPicker'
import QuickBuy from './QuickBuy'

type ProductContentElementType =
  | 'title'
  | 'price'
  | 'family'
  | 'options'
  | 'actions'
  | 'custom-footer'
  | 'reviews'
  | 'color'

export interface ButtonAction {
  type: 'primary' | 'secondary' | 'link'
  label: string
  disabled?: boolean
  onClick?: (currentProduct: any) => void
}

interface ProductCardProps {
  product: any
  baseCollection?: string
  actions?: ButtonAction[]
  overlayElements?: ReactNode[]
  layout: 'vertical' | 'horizontal'
  useMouseOverChange?: boolean
  responsive?: boolean
  desktopGridTemplate?: string
  contentLayout?: ProductContentElementType[]
  onChangeVariant?: (variant: any) => void
  showWishListButton?: boolean
  hideOOSSwatch?: boolean
  showQuickBuy?: boolean
  source?: string
  settings?: any
  position?: number
}

// allows customize which elements to show and the order
const DefaultContentElements: ProductContentElementType[] = [
  'title',
  'price',
  'family',
  // 'options',
  'actions',
  'custom-footer',
  'reviews'
]

const swatchMatch: string = 'swatch'
const thumbnailMatch: string = 'thumbnail'

export default function ProductCard({
  product,
  baseCollection,
  actions,
  overlayElements,
  layout,
  useMouseOverChange,
  responsive,
  desktopGridTemplate,
  contentLayout = DefaultContentElements,
  onChangeVariant,
  showWishListButton = false,
  showQuickBuy = false,
  hideOOSSwatch = false,
  source = 'list',
  settings,
  position
}: ProductCardProps) {
  const dispatch = useAppDispatch()
  const config = useAppSelector((state) => state.app.config)
  const isMobile = useMediaQuery({ query: '(max-width: 800px)' })
  const [currentProduct, setCurrentProduct] = useState(product)
  const [selectedImagePosition, setSelectedImagePosition] = useState(1)
  const [image1, setImage1] = useState<any>()
  const [image2, setImage2] = useState<any>()
  const [family, setFamily] = useState<any[]>([])
  const [variant, setVariant] = useState<any>()
  const [colorVariantUrl, setColorVariantUrl] = useState('')
  const [imagePositions, setImagePositions] = useState<number[] | undefined | null>(null)
  const [collectionWishListButton, setCollectionWishListButton] = useState<
    WishListAddButtonAppData
  >({ icon: 'none' })

  const [wishListItem, setWishListItem] = useState<WishListItemPayload | undefined>()
  const wishListButtons = useAppSelector((state) => state.wishlist.settings.addButtons)
  const currentListItems = useAppSelector((state) => state.wishlist.items)
  const filterSettings = useAppSelector((state) => state.app.settings.filters)
  //this legacy ProductCard still called by elements wishlist, so consider both sources in state
  const intlPrices = useAppSelector((state) => 
    state.collection.intlPrices && state.collection.intlPrices.length > 0
      ? state.collection.intlPrices
      : state.collectionElement.intlPrices
  )

  useEffect(() => {
    if (showWishListButton) {
      const button =
        wishListButtons.find((button: any) => button.target === 'collectionImage') || {}
      setCollectionWishListButton(button)
    }
  }, [wishListButtons])

  useEffect(() => {
    let imgPos1: number = -1
    let imgPos2: number = -1

    //search for first explicitly defined thumbnail
    //if not found, fall back to original algorithm
    imgPos1 = currentProduct?.images?.findIndex(
      (img: HTMLImageElement) => !!img.alt?.toLowerCase().includes(thumbnailMatch)
    )

    //if we found a thumbnail using thumbanil match
    //we will only find a second image explicitly using thumbnail match
    if (imgPos1 > -1) {
      imgPos1 += 1
      //search for second explicitly defined thumbnail
      //if not found, return no 2nd image
      imgPos2 = currentProduct?.images
        ?.slice(imgPos1)
        .findIndex((img: HTMLImageElement) => !!img.alt?.toLowerCase().includes(thumbnailMatch))

      if (imgPos2 > -1) {
        imgPos2 += imgPos1 + 1
      }
    } else {
      // find the first image position that is not a swatch
      imgPos1 = currentProduct?.images?.findIndex(
        (img: HTMLImageElement) => !!!img.alt?.toLowerCase().includes(swatchMatch)
      )
      // find second image if the first one exists
      if (imgPos1 > -1) {
        // positions are indexed starting from 1
        imgPos1 += 1

        // find second image that is not a swatch
        imgPos2 = currentProduct?.images
          ?.slice(imgPos1)
          .findIndex((img: HTMLImageElement) => !!!img.alt?.toLowerCase().includes(swatchMatch))

        if (imgPos2 > -1) {
          imgPos2 += imgPos1 + 1
        }
      }
    }

    setImagePositions([imgPos1, imgPos2])
  }, [currentProduct, currentListItems])

  useEffect(() => {
    if (imagePositions) {
      if (imagePositions[0]) {
        const image1 = currentProduct?.images?.find((i: any) => i.position === imagePositions[0])
        setImage1(image1)
      }
      if (imagePositions[1]) {
        const image2 = currentProduct?.images?.find((i: any) => i.position === imagePositions[1])
        setImage2(image2)
      }

      if (showWishListButton) {
        const item = currentListItems.find(
          (item: any) => item?.sourceProductId === currentProduct.sourceId
        )
        setWishListItem(item)
      }
    }
  }, [imagePositions])

  useEffect(() => {
    setCurrentProduct(product)

    // move current product to beginning of family
    const filteredProducts = product?.family?.filter((o: any) => o.id !== product.id)
    let family = [product].concat(filteredProducts)
    if (hideOOSSwatch) family = family?.filter((o: any) => o.inventoryQuantity > 0)

    setFamily(family)

    if(product?.variants.length === 1) {
      setVariant(product.variants[0])
      onChangeVariant?.(product.variants[0])
    }

    // grab the first variant to use for pricing
    // const productVariant = product?.variants[0]
    // setVariant(productVariant)
  }, [product])

  // gets image swatch from alt tag (matching `swatch`)
  function getImageSwatch(images: any[]) {
    const image = images?.find((image) => image?.alt?.toLowerCase().includes(swatchMatch))
    return image?.src || ''
  }

  if (!currentProduct) {
    return <></>
  }

  let productUrl = `/products/${currentProduct.handle}${colorVariantUrl}`
  if (baseCollection) {
    productUrl = `/collections/${baseCollection}${productUrl}`
  }

  const _desktopGridTemplate = desktopGridTemplate || '1fr 1fr'
  const mobileGridTemplate = responsive ? '1fr' : _desktopGridTemplate

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns:
          isMobile || layout === 'vertical' ? mobileGridTemplate : _desktopGridTemplate,
        gap: 5
      }}>
      <div style={{ width: '100%' }}>
        <div
          dangerouslySetInnerHTML={{
            __html:
              hooks.run(`catalog.${source}.header`, { product: currentProduct })?.content || ''
          }}
        />
        <a href={productUrl} title={product.title}>
          <div
            style={{
              width: '100%',
              height: 'auto',
              paddingBottom: image1 ? `${(image1.height / image1.width) * 100}%` : '100%',
              backgroundColor: '#f0f0f0',
              position: 'relative'
            }}
            onMouseEnter={() => {
              if (image2) {
                const alt = image2?.alt?.toLowerCase() || ''
                if (!alt.includes(swatchMatch)) {
                  setSelectedImagePosition(2)
                }
              }
            }}
            onMouseLeave={() => {
              setSelectedImagePosition(1)
            }}>
            {image1 && (
              <ShopifyImage
                src={`${image1.src}`}
                height={Math.floor(image1.height / 4)}
                width={Math.floor(image1.width / 4)}
                alt={currentProduct.title}
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: 'auto',
                  top: 0,
                  left: 0,
                  display: selectedImagePosition === 1 ? 'block' : 'none'
                }}
                loading={position && position > 4 ? 'lazy' : 'eager'}
              />
            )}
            {image2 && (
              <ShopifyImage
                src={`${image2.src}`}
                width={Math.floor(image2.width / 4)}
                height={Math.floor(image2.height / 4)}
                alt={currentProduct.title}
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: 'auto',
                  top: 0,
                  left: 0,
                  display: selectedImagePosition === 2 ? 'block' : 'none'
                }}
                loading={position && position > 4 ? 'lazy' : 'eager'}
              />
            )}
            <div
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
              }}
              dangerouslySetInnerHTML={{
                __html:
                  hooks.run(`catalog.${source}.overlay`, { product: currentProduct })?.content || ''
              }}></div>
            <div
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                pointerEvents: 'none'
              }}>
              <div
                style={{
                  float: 'right',
                  position: 'relative',
                  margin: 10,
                  pointerEvents: 'all',
                  zIndex: 1
                }}>
                {collectionWishListButton && (
                  <div style={{ marginBottom: 5 }}>
                    <WishListAddButton
                      settings={collectionWishListButton}
                      counts={{
                        up: wishListItem?.totalWishedUp,
                        down: wishListItem?.totalWishedDown,
                        current: wishListItem?.customerWished
                      }}
                      payload={{ productId: currentProduct.sourceId, elementLabel: 'Product' }}
                      onSelect={(args) => {
                        let score = args.score || 0
                        let newTotalWishedUp = wishListItem?.totalWishedUp || 0
                        let newTotalWishedDown = wishListItem?.totalWishedDown || 0

                        switch (score) {
                          case -1:
                            newTotalWishedDown++
                            // If switching votes, remove count for previous vote
                            if (wishListItem?.customerWished === 1 && newTotalWishedUp > 0)
                              newTotalWishedUp--
                            break
                          case 1:
                            newTotalWishedUp++
                            // If switching votes, remove count for previous vote
                            if (wishListItem?.customerWished === -1 && newTotalWishedDown > 0)
                              newTotalWishedDown--
                            break
                          case 0:
                            if (wishListItem?.customerWished === 1) {
                              newTotalWishedUp--
                            } else if (wishListItem?.customerWished === -1) {
                              newTotalWishedDown--
                            }
                            break
                        }

                        setWishListItem({
                          totalWishedUp: newTotalWishedUp,
                          totalWishedDown: newTotalWishedDown,
                          customerWished: score
                        } as WishListItemPayload)
                      }}
                    />
                  </div>
                )}
                {overlayElements?.map((action) => action)}
              </div>
              {showQuickBuy && filterSettings?.enableQuickBuy && (
                <QuickBuy 
                  product={currentProduct} 
                  variant={variant} 
                  isMouseOver={selectedImagePosition === 2} 
                  cardLayout={contentLayout}
                  overlay={true}
                />
              )}
            </div>
          </div>
        </a>
      </div>
      <div>
        {contentLayout.map((elementType) => {
          switch (elementType) {
            case 'title':
              return (
                <ProductTitle
                  key={elementType}
                  productUrl={productUrl}
                  currentProduct={currentProduct}
                />
              )
            case 'color':
              return (
                <ProductColorPicker
                  key={elementType}
                  currentProduct={currentProduct}
                  getImageSwatch={getImageSwatch}
                  setImagePositions={setImagePositions}
                  useMouseOverChange={useMouseOverChange}
                  onChangeVariant={(variant: any) => {
                    setVariant(variant)
                    onChangeVariant?.(variant)
                    setColorVariantUrl(`?variant=${variant.sourceId}`)
                  }}
                />
              )
            case 'family':
              return (
                <FamilyPicker
                  key={elementType}
                  family={family}
                  currentProduct={currentProduct}
                  setCurrentProduct={setCurrentProduct}
                  getImageSwatch={getImageSwatch}
                  useMouseOverChange={useMouseOverChange}
                />
              )
            case 'price':
              return (
                <ProductPrice
                  key={elementType}
                  product={currentProduct}
                  config={config}
                  variant={variant}
                  intlPrices={intlPrices?.find((intlPrice: IntlPrices) => intlPrice.sourceId == currentProduct.sourceId)}
                />
              )
            case 'custom-footer':
              return (
                <ProductCustomFooter
                  key={elementType}
                  currentProduct={currentProduct}
                  source={source}
                />
              )
            case 'actions':
              return (
                <ProductActions
                  key={elementType}
                  actions={actions}
                  currentProduct={currentProduct}
                />
              )
            case 'options':
              return (
                <ProductOptionPicker
                  key={elementType}
                  product={currentProduct}
                  variant={variant}
                  cardLayout={contentLayout}
                  onChangeVariant={(variant: any) => {
                    setVariant(variant)
                    onChangeVariant?.(variant)
                  }}
                />
              )
          }
        })}
        {settings?.enabled && <ProductReview metaFields={product.metaFields} settings={settings} />}
      </div>
    </div>
  )
}
