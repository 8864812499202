import React from 'react'
import { Elements } from '..'
import LayoutModel from './models'

interface Props {
  data?: LayoutModel
  containerProps?: string[]
  productIds?: string[]
}

export default function DefaultLayout({ data, containerProps }: Props) {
  return (
    <div {...containerProps}>
      {data?.elements && <Elements name='Elements' data={data} field='elements' />}
    </div>
  )
}