import styled from '@emotion/styled'
import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { FiX } from 'react-icons/fi'
import { useAppDispatch, useAppSelector } from '../../redux'
import { togglePreview, debug } from '../../redux/slices/app'

const previewSelectorId = 'ng-preview-bar'

export default function PreviewManager() {
  const isPreview = useAppSelector((state) => state.app.isPreview)
  const [previewElement, setPreviewElement] = useState<HTMLDivElement>()
  const [isReady, setReady] = useState(false)

  useEffect(() => {
    let previewBar = document.getElementById(previewSelectorId)

    if (!previewBar) {
      // need to create a new bar
      previewBar = document.createElement('div')
      previewBar.id = previewSelectorId

      const body = document.getElementsByTagName('body')[0]
      body.insertAdjacentElement('beforebegin', previewBar)
    }

    setPreviewElement(previewBar as HTMLDivElement)
  }, [])

  return isPreview && previewElement ? ReactDOM.createPortal(<PreviewBar />, previewElement) : <></>
}

const PreviewBarWrapper = styled.div`
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  box-shadow: 0px 0px 4px #e0eff5;
  background-color: #eeeeee;
  color: #4a4a4a;
  font-size: 14px;

  .logo {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    color: rgba(249, 115, 22);
  }

  .action {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
  }
`

const ButtonWrapper = styled.button`
  background-color: none;
  background: none;
  color: #4a4a4a;
  border: none;
  cursor: pointer;
  font-size: 22px;
  display: flex;
  align-items: center;
`

function PreviewBar() {
  const dispatch = useAppDispatch()
  const isDebug = useAppSelector((state) => state.app.debug)

  return (
    <PreviewBarWrapper>
      <div className='logo'>
        <img
          src='https://dev.app.nogin.com/images/IntelligentCommerce-logo-beta_1@3x.svg'
          height={30}
        />{' '}
        Preview
      </div>
      <div className='action'>
        <div>
          <input
            type='checkbox'
            checked={isDebug}
            onChange={(el) => {
              dispatch(debug(el.target.checked))
            }}
          />{' '}
          Highlight
        </div>
        <ButtonWrapper
          onClick={() => {
            dispatch(togglePreview(null))
          }}>
          <FiX />
        </ButtonWrapper>
      </div>
    </PreviewBarWrapper>
  )
}
