import React, { useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { useAppSelector } from '../../redux'
import Layout from '../Layout'

interface Props {
  data?: any
  containerProps?: string[]
}

export default function GenericPage({ data, containerProps }: Props) {
  const site = useAppSelector(state => state.app.site)
  
  const pageLayout = useMemo(() => {

    let pageLayoutId: string
    if(data?._contentType?.name === 'Template') {
      pageLayoutId = data?._elementType?.extraData?.pageLayout
    }
    else {
      pageLayoutId = data?._contentType?.extraData?.pageLayout
    }

    if (pageLayoutId) {
      return data?.__layouts?.find((l: any) => l.__id === pageLayoutId)
    }
  }, [data?.__layouts])

  return (
    <div {...containerProps}>
      {data?._contentType && (
        <Helmet>
          <title>{data?.name}{ site.title && ` | ${site.title}` }</title>
          <meta property="og:type" content="article"/>
          <meta property="og:url" content={window.location.href} />
          <meta property="og:site_name" content={window.location.host} />
          {site.description && <meta name="description" content={site.description} />}
          {site.description &&  <meta property="og:description" content={site.description} />}
          <meta property="og:title" content={site.title} />
          {data?.featuredImages?.length > 0 && data.featuredImages[0].image &&  <meta property="og:image" content={data.featuredImages[0].image.imagePath} />}
        </Helmet>
      )}
      <Layout
        data={pageLayout}
        parentData={{ ...data, __type: data?._contentType?.name }}
      />
    </div>
  )
}