import axios from 'axios'
import ContentGalleryModel from './models'
import { spinalcase } from 'stringcase'
import { ContextConfig } from '../../types'

export async function loadCollection(data: ContentGalleryModel) {
  if (data?._collection) {
    try {
      const url = '/products/es/search'
      const response = await axios.get(url, {
        params: {
          baseCollection: data._collection.slug,
          pageIndex: 1,
          sortBy: data._collection.sortBy,
          pageSize: data.limit,
          faceted: false,
          outOfStock: false
        }
      })

      return response.data
    } catch (e) {
      console.log(e)
    }
  }
}

export async function loadRelatedProducts(data: ContentGalleryModel, productId: string, idType: 'id' | 'sourceId') {
  try {
    const response = await axios.get('/products/es/search/related/' + productId, {
      params: {
        pageSize: data.limit,
        idType
      }
    })
    return response.data
  } catch (e) {
    console.log(e)
  }
}

export async function loadProductsAutomatic(data: ContentGalleryModel) {
  try {
    const url = '/products/es/search'
    const response = await axios.get(url, {
      params: {
        pageIndex: 1,
        pageSize: data.limit,
        faceted: false,
        outOfStock: false,
        sortBy: data.automaticProductListType
      }
    })
    return response.data
  } catch (e) {
    console.log(e)
  }
}

export async function loadProducts(data: ContentGalleryModel) {
  if (data?.productIds) {
    try {
      const url = '/products/es/search-selection'
      const response = await axios.get(url, {
        params: {
          pageIndex: 1,
          pageSize: data.productIds.length,
          faceted: false,
          outOfStock: false,
          productIds: data.productIds
        }
      })

      return response.data
    } catch (e) {
      console.log(e)
    }
  }
}

export async function loadRecentlyViewedProducts(data: ContentGalleryModel) {
  try {
    //get recently viewed products from Clickhouse
    const url = '/products/view/recent'
    let response = await axios.get(url, {
      params: {
        pageSize: data.limit
      }
    })

    //get product data for all recently viewed products
    let productIds: string[] = []
    if (response?.data?.items?.length) {
      //first, extract all the cuid producIds from the CH results
      productIds = response.data.items.map((item: any) => item[0])
      const url = '/products/es/search-selection'
      response = await axios.get(url, {
        params: {
          pageIndex: 1,
          pageSize: productIds.length,
          faceted: false,
          outOfStock: true,
          productIds: productIds
        }
      })
    }

    return response.data
  } catch (e) {
    console.log(e)
  }
}

export async function loadGenericContentType(data: ContentGalleryModel) {
  if (data?._contentType?.name) {
    try {
      // build filter conditions
      const conditions: string[] = []
      data?.conditions.forEach((condition) => {
        if (condition?.field && condition?.comparator && condition?.value) {
          conditions.push(condition.field + '::' + condition.comparator + '::' + condition.value)
        }
      })

      // build sort
      let sortColumn = 'updatedAt'
      let sortDir = 'DESC'
      if (data?.sortBy) {
        const sortConfig = data?.sortBy.split('::')
        sortColumn = sortConfig[0]
        sortDir = sortConfig[1]
      }

      const elementName = spinalcase(data?._contentType?.name)
      const response = await axios.get(`/element/data/${elementName}`, {
        params: {
          pageSize: data?.limit,
          conditions,
          sortColumn,
          sortDir
        }
      })

      return response.data
    } catch (e) {
      console.log(e)
    }
  }
}
