import React, { useContext, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../redux'
import CollectionModel from './models'
import { setBaseCollection, setCurrentCollection, setInlineBannerArray} from '../../redux/slices/collection-element'
import Layout, { LayoutContext } from '../Layout'

interface CollectionProps {
  data?: CollectionModel
  containerProps?: string[]
}

export default function Collection({ data, containerProps }: CollectionProps) {
  const dispatch = useAppDispatch()
  const layoutDataFromContext = useAppSelector((state) => state.app.config.context?.extra?.collectionLayout)
  
  useEffect(() => {
    dispatch(setBaseCollection(data?.slug || ''))
    if (data) {
      dispatch(setCurrentCollection(data))
    }
    //Inline banner
    const collectionBanners = Array.isArray(data?.collectionBanner)? data?.collectionBanner : []
    dispatch(setInlineBannerArray(collectionBanners))
  }, [data])

  const layout = layoutDataFromContext ?? data?._layout
  
  return (
    <div {...containerProps}>
      <Layout data={layout} parentData={data} />
    </div>
  )
}
