import React, { useState, useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import { AiOutlineClose } from 'react-icons/ai'

interface GuestUserInputProps {
  onClose?: () => void
  onSubmit: (name: string) => void
  mobileBreakpoint: string
}

export default function GuestUserInput({
  onClose,
  onSubmit,
  mobileBreakpoint
}: GuestUserInputProps) {
  const [name, setName] = useState('')
  const inputRef = useRef<any>(null)

  useEffect(() => {
    inputRef.current.focus({
      cursor: 'start'
    })
  }, [])

  return (
    <GuestUserInputWapper mobileBreakpoint={mobileBreakpoint}>
      {onClose && (
        <GuestUserInputClose
          mobilebreakpoint={mobileBreakpoint}
          onClick={onClose}
          onKeyUp={(e) => (e.code === 'Enter' ? onClose() : null)}
        />
      )}
      <h2>Enter a name</h2>
      <p>Before you start chatting, let us know who we're talking to.</p>
      <input
        className='name-input'
        placeholder='Enter a name'
        value={name}
        onChange={(event: any) => setName(event.target.value)}
        ref={inputRef}
      />
      {onSubmit && (
        <button
          className='submit-button'
          disabled={name === ''}
          onClick={() => onSubmit(name)}
          onKeyUp={(e) => (e.code === 'Enter' ? onSubmit(name) : null)}>
          Submit
        </button>
      )}
      {/* <GuestUserInputDescription>Or sign in to your account.</GuestUserInputDescription> */}
    </GuestUserInputWapper>
  )
}

const GuestUserInputWapper = styled.div<{ mobileBreakpoint: string }>`
  position: absolute;
  z-index: 1000;
  background-color: white;
  height: 100%;
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .name-input {
    border-radius: 28px;
    box-shadow: 0 4px 8px rgb(50 50 50 / 15%);
    margin-bottom: 20px;
    border: none;
    padding: 8px;
  }
  .submit-button {
    border-radius: 28px;
    background-color: #4c8226;
    color: white;
    padding: 8px;
    border: 0;
    &[disabled] {
      color: rgba(0, 0, 0, 0.25);
      background: #f5f5f5;
      border-color: #d9d9d9;
      text-shadow: none;
      box-shadow: none;
    }
  }

  ${({ mobileBreakpoint }) => `
   @media (max-width: ${mobileBreakpoint}){
    font-size: 85%;
    width: 82%;
   }
  `}
`

const GuestUserInputDescription = styled.div`
  text-align: center;
`

const GuestUserInputClose = styled(AiOutlineClose)<{ mobilebreakpoint: string }>`
  position: absolute;
  top: 20px;
  right: 30px;
  font-size: 20px;
  cursor: pointer;
  ${({ mobilebreakpoint }) => `
   @media (max-width: ${mobilebreakpoint}){
    top: 35%;
   }
  `}
`
