import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { useAppDispatch, useAppSelector } from '../../redux'
import CollectionGridToggleModel from './models'
import { setCustomerPreferences } from '../../redux/slices/app'

interface Props {
  data: CollectionGridToggleModel
  containerProps: any
}

const Container = styled.div<Props>`
  position: relative;
  display: inline-block;
  padding: 5px;
  -webkit-user-select: none;
  user-select: none;

  > div {
    width: 20px;
    height: 10px;
    display: inline-flex;
    margin-right: 5px;
    cursor: pointer;
    padding: 5px;
    box-sizing: content-box;
  }

  > div > div {
    flex: 1;
    width: 5px;
    height: 100%;
    display: inline-block;
    margin-right: 2px;
    background: #888;
  }
`

export default function CollectionGridToggle({ data, containerProps }: Props) {
  const dispatch = useAppDispatch()
  const customerPreferences = useAppSelector((state) => state.app.customer?.preferences)

  useEffect(() => {
    if(customerPreferences?.gridColumns) {
      toggleGrid(customerPreferences.gridColumns)
    }
  }, [])

  const toggleGrid = (gridColumns: number) => {
    const collectionListing = document.querySelector<HTMLElement>('.element-collection-product-listing .collection-products')
    dispatch(setCustomerPreferences({
      gridColumns: gridColumns
    }))
    if(collectionListing)
      collectionListing.style.gridTemplateColumns = `repeat(${gridColumns}, 1fr)`
  }

  const onToggleGridClick = (
    event?: React.MouseEvent<HTMLDivElement>
  ) => {
    if(event?.currentTarget.dataset.value) {
      toggleGrid(parseInt(event?.currentTarget.dataset.value))
    }
  }

  return (
    <Container {...containerProps}>
      {data.gridColumns.map((g) => 
        <div className={+g.columns === customerPreferences?.gridColumns ? 'active' : ''} onClick={onToggleGridClick} data-value={+g.columns}>
          {[...Array(+g.columns).keys()].map((c) => <div></div>)}
        </div>
      )}
    </Container>
  )
}
