import React, { useContext, useMemo } from 'react'
import { LayoutContext } from '../Layout'
import PlacementModel from './models'
import { camelcase } from 'stringcase'
import { Elements, Element } from '..'
import styled from '@emotion/styled'

interface Props {
  data: PlacementModel
  containerProps: any
}

const EmptyContainer = styled.div`
  padding: 10px;
  text-align: center;
  border: 2px dashed #e0e0e0;
  border-radius: 10px;
  margin: 2px;
  color: #030303;
  font-size: 12px;
`

export default function Placement({ data, containerProps }: Props) {
  const value = useContext(LayoutContext)
  const fieldName = useMemo(() => camelcase(data.name), [data.name])

  if (!value) return <EmptyContainer {...containerProps}>Placement: {data.name}</EmptyContainer>

  function getComponent() {
    //console.log(value)
    if (Array.isArray(value[fieldName])) {
      return <Elements name='Placement' data={value} field={fieldName} />
    } else {
      return <Element name={value[fieldName]?.__type} data={value[fieldName]} />
    }
  }
  
  return (
    <div {...containerProps}>
      {getComponent()}
    </div>
  )
}
