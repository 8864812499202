import React from 'react'
import { useAppSelector } from '../../redux'
import Notification from '../Notification'

const edgeMargin = 15

export default function NotificationManager() {
  const notificationSettings = useAppSelector(
    (state) => state.app.settings.notification
  )

  const notifications = useAppSelector((state) => state.app.notifications)
  const location = notificationSettings?.location || 'bottomRight'

  return (
    <div
      style={{
        position: 'fixed',
        left: (location.indexOf('Left') > -1 && edgeMargin) || undefined,
        right: (location.indexOf('Right') > -1 && edgeMargin) || undefined,
        bottom: (location.indexOf('bottom') > -1 && edgeMargin) || undefined,
        top: (location.indexOf('top') > -1 && edgeMargin) || undefined,
        display: 'flex',
        flexDirection: location.indexOf('top') > -1 ? 'column-reverse' : 'column'
      }}>
      {notifications.map((n: any) => (
        <Notification key={n._id} payload={n} />
      ))}
    </div>
  )
}
