import React from 'react'
import ContentTypeModel from './models'
import { Elements } from '../index'

interface ContentTypeProps {
  data?: ContentTypeModel
  containerProps?: string[]
}

export default function Content({ data, containerProps }: ContentTypeProps) {
  return (
    <>
      <div {...containerProps}>
        <Elements data={data} field='header' />
        <div className='flex justify-center border-gray-400 border-2 border-dashed rounded-md p-10 my-5 text-gray-500'>
          Placeholder
        </div>
        <Elements data={data} field='footer' />
      </div>
    </>
  )
}
