import React, { useEffect, useState } from 'react'
import ContentGalleryModel from './models'
import GridView from './views/GridView'
import CarouselView from './views/CarouselView'
import { loadCollection, loadGenericContentType, loadProducts, loadProductsAutomatic, loadRelatedProducts, loadRecentlyViewedProducts } from './dataLoaders'
import ProductGridView from './views/ProductGridView'
import ProductCarouselView from './views/ProductCarouselView'
import { useAppSelector } from '../../redux'
import IntlPricesLoader from '../../components/IntlPricesLoader/'

interface Props {
  data?: ContentGalleryModel
  containerProps?: string[]
}


export default function ContentGallery({ data, containerProps }: Props) {
  const [items, setItems] = useState([])
  const [previewAvailable, setPreviewAvailable] = useState(true)
  const sessionContext = useAppSelector(state => state.app.config.context)
  const isAdmin = useAppSelector(state => state.app.isAdmin)

  useEffect(() => {
    async function load() {
      setPreviewAvailable(true)

      let elementDataSet
      switch (data?.contentType) {
        case '#schema:Collection':
          elementDataSet = await loadCollection(data)
        break
        case 'product':
          if (data?.productListType === 'manual') {
            elementDataSet = await loadProducts(data)
          } else {
            if (data?.automaticProductListType === 'related') {
              let productId = sessionContext?.productId?.toString()
              let idType: 'sourceId' | 'id' = 'sourceId'
              
              if (!!!data?.useRelatedProductForPreviewOnly) {
                if (isAdmin && data?.relatedProductIds.length > 0) {
                  productId = data?.relatedProductIds[0]
                  idType = 'id'
                }
              } else {
                if (data?.relatedProductIds.length > 0) {
                  productId = data?.relatedProductIds[0]
                  idType = 'id'
                }
              }

              if (productId) {
                elementDataSet = await loadRelatedProducts(data, productId, idType)
              }
            } else if (data?.automaticProductListType === 'recently-viewed') {
              elementDataSet = await loadRecentlyViewedProducts(data)
            } else {
              elementDataSet = await loadProductsAutomatic(data)
            }
          }
        break
        default:
          if (data?._contentType?.name) {
            elementDataSet = await loadGenericContentType(data)
          }
      } 

      if (elementDataSet) {
        setItems(elementDataSet.items)
      }
    }

    load()
  }, [data])

  if (!previewAvailable) {
    return <div style={{ border: '1px dashed black', padding: 10, margin: 5, borderRadius: 5 }}>Related Products Preview Not Available</div>
  }

  if (data?.contentType === '#schema:Collection' || data?.contentType === 'product') {
    if (data?.displayFormat === 'grid') {
      return <>
                <IntlPricesLoader items={items} />
                <ProductGridView data={data} items={items} {...containerProps} />
              </>
    } else if (data?.displayFormat === 'carousel') {
      return <>
                <IntlPricesLoader items={items} />
                <ProductCarouselView data={data} items={items} {...containerProps} />
              </>
    }
  } else {
    if (data?.displayFormat === 'grid') {
      return <GridView data={data} items={items} {...containerProps} />
    } else if (data?.displayFormat === 'carousel') {
      return <CarouselView data={data} items={items} {...containerProps} />
    }
  }

  return <></>
}


