import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

interface ListPickerProps {
  type: 'option' | 'tag' | 'price'
  settings: any
  items: any[]
  displayType: string
  onPicked: (item: any) => void
  defaultSelected: any
  facetedData: any
  setPickerVisibility: (visible: boolean) => void
}

function ListPicker({
  type,
  settings,
  items,
  displayType,
  onPicked,
  defaultSelected,
  facetedData,
  setPickerVisibility
}: ListPickerProps) {
  const [selected, setSelected] = useState(defaultSelected)
  const [_items, setItems] = useState<any>([])

  useEffect(() => {
    setSelected(defaultSelected)
  }, [defaultSelected])

  useEffect(() => {
    if (settings.mode && settings.mode === 'automatic') {
      // build item list from faceted data
      let autoItems: any[] = []
      if (settings.type === 'tag') {
        const tagPrefix = settings.prefix
        if (facetedData && facetedData.tags && facetedData.tags[tagPrefix]) {
          const tags = facetedData.tags[tagPrefix]
          for (const tag of tags) {
            autoItems.push(tag.tag)
          }
          autoItems.sort()
        }
      } else if (settings.type === 'option') {
        if (facetedData && facetedData.options && Array.isArray(facetedData.options)) {
          for (const option of facetedData.options) {
            autoItems.push(option.option)
          }
        }
      }

      if (Array.isArray(settings.displayOrder) && settings.displayOrder.length > 0) {
        // add prefix to tags
        let newSort = settings.displayOrder.map((o: any) =>
          settings.prefix ? settings.prefix + o : o
        )

        // remove items that don't exist in auto items
        newSort = newSort.filter((o: any) => autoItems.indexOf(o) > -1)

        const showDisplayOrderItemsOnly = settings.showDisplayOrderItemsOnly || false

        let regularSortItems = []
        if (!showDisplayOrderItemsOnly) {
          // get all items that don't require sorting
          regularSortItems = autoItems.filter((o) => newSort.indexOf(o) === -1)

          // store these items using basic ascii sort
          regularSortItems.sort()
        }

        // merge with ordered items
        autoItems = [...newSort, ...regularSortItems]
      }

      setItems(autoItems)

      if (autoItems.length === 0) {
        setPickerVisibility(false)
      } else {
        setPickerVisibility(true)
      }
    } else {
      // manual mode loads data from settings
      ;[...items].sort()
      setItems(items)
    }
  }, [items, defaultSelected, facetedData, settings])

  function getFacetCount(item: any) {
    switch (type) {
      case 'option':
        if (facetedData && Array.isArray(facetedData.options)) {
          const option = facetedData.options.find((o: any) => o.option == item)
          return option ? option.count : 0
        }
      case 'tag':
        if (facetedData && facetedData.tags && facetedData.tags[settings.prefix]) {
          if (settings.mode === 'automatic' && settings.prefix) {
            const match = facetedData.tags[settings.prefix].find((t: any) => t.tag === item)
            return (match && match.count) || 0
          }
        }
        break
      case 'price':
        // TODO: we currently don't handle faceted price search
        break
      default:
    }
  }

  function isActive(item: any) {
    return selected.indexOf(item) !== -1
  }

  //
  function getNameFromValue(value: any) {
    let newValue
    if (typeof value === 'string') {
      newValue = value
    } else if (value && typeof value === 'object') {
      newValue = value.label
    }

    if (newValue && settings.prefix !== undefined) {
      newValue = newValue.replace(settings.prefix, '')
    }
    return newValue
  }

  function getItemValue(item: any) {
    let itemValue
    if (typeof item === 'string') {
      itemValue = item
    } else if (item && item.value) {
      itemValue = item.value
    }
    return itemValue
  }

  const onClickPickerItem = (
    disabled: boolean,
    item: any,
    e?: React.MouseEvent<HTMLAnchorElement, MouseEvent> | React.KeyboardEvent<HTMLAnchorElement>
  ) => {
    e?.preventDefault()
    if (!disabled) {
      let newSelected
      const itemValue = getItemValue(item)
      if (!isActive(itemValue)) {
        newSelected = [...selected, itemValue]
      } else {
        newSelected = [...selected.filter((o: any) => o !== itemValue)]
      }

      if (onPicked) {
        onPicked(newSelected)
      }

      setSelected(newSelected)
    }
  }

  return (
    <div className='picker-item-container'>
      {_items.map((item: any, i: number) => {
        //ignore delimed strings with no second value
        if (typeof item === 'string' && item.endsWith(':')) {
          return null
        }

        const itemCount = getFacetCount(item)

        if (settings.hideEmpty && itemCount <= 0) {
          return null
        }

        const disabled = typeof itemCount !== 'undefined' && itemCount <= 0
        if (disabled) {
          return (
            <span
              key={i}
              className={classNames(displayType, {
                active: isActive(getItemValue(item)),
                disabled: disabled
              })}>
              <ListTitle
                name={getNameFromValue(item)}
                isActive={isActive(getItemValue(item))}
                itemCount={0}
              />
            </span>
          )
        }

        return (
          <a
            key={i}
            href='#'
            className={classNames(displayType, {
              active: isActive(getItemValue(item)),
              disabled: disabled
            })}
            onClick={(e) => onClickPickerItem(disabled, item, e)}
            onKeyUp={(e) => (e.code === 'Enter' ? onClickPickerItem(disabled, item, e) : null)}>
            <ListTitle
              name={getNameFromValue(item)}
              isActive={isActive(getItemValue(item))}
              itemCount={settings.showItemCounts ? itemCount : 0}
            />
          </a>
        )
      })}
    </div>
  )
}

ListPicker.defaultProps = {
  defaultSelected: []
}

interface ListTitleProps {
  name: string
  isActive: boolean
  itemCount: number
}

function ListTitle({ name, isActive, itemCount }: ListTitleProps) {
  return (
    <>
      {name}
      {itemCount > 0 && <span className='item-count'> ({itemCount}) </span>}
      <span className='picker-item-icon'>{!isActive ? `☐` : `☒`}</span>
    </>
  )
}

export default ListPicker
