import React, { useContext, useMemo } from 'react'
import BreadcrumbModel from './models'
import styled from '@emotion/styled'
import { LayoutContext } from '../Layout'
import HtmlModel from '../Html/models'
import Html from '../Html'
import cuid from 'cuid'
import { Element } from '../index'

interface Props {
  data?: BreadcrumbModel
  parentData?: any
  containerProps?: string[]
}

interface BreadcrumbWrapperProps {}

const BreadcrumbWrapper = styled.div<BreadcrumbWrapperProps>``

export default function Breadcrumb({ data, containerProps }: Props) {
  const value = useContext(LayoutContext)

  const secondaryLink = useMemo(() => {
    const contentType = value?._layout?._contentType
    // builds base url ex: /pages using base prefix defined in content type
    const parts = ['']
    if (contentType?.slugPrefix) {
      parts.push(contentType?.slugPrefix)
    }
    if (contentType?.slug) {
      parts.push(contentType?.slug)
    }
    if (value?.slug) {
      parts.push(value?.slug)
    }

    if (parts.length > 1) {
      return (
        <>
          <span>{data?.separator}</span> <a href={parts.join('/')}>{value?.name}</a>
        </>
      )
    }
  }, [value])

  return (
    <BreadcrumbWrapper {...containerProps}>
      <a href={data?.homeLink}>{data?.homeTitle}</a>{' '}
      {data?.childBreadcrumbs?.map(({html, __elementId}) => {
        const htmlModel = new HtmlModel()
        htmlModel.html = html
        htmlModel.__elementId = __elementId
        return <ChildBreadcrumb key={__elementId}><span>{data?.separator}</span>{' '}<Element name='Html' data={htmlModel} />{' '}</ChildBreadcrumb>
      })}
      {secondaryLink}
    </BreadcrumbWrapper>
  )
}

const ChildBreadcrumb = styled.span`
  div{
    display: inline-block;
  }
  p{
    display: inline;
  }
`
