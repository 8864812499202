import React from 'react'
import { useAppSelector } from '../../redux'
import Loading from '../Loading'

export default function LoadingManager() {
  const loading = useAppSelector((state) => state.app.loading)
  const settings = useAppSelector((state) => state.app.settings.loader)

  return loading ? (
    <div
      style={{
        display: 'flex',
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 2147483647
      }}>
      <Loading settings={settings} loading={loading} />
    </div>
  ) : (
    <></>
  )
}
