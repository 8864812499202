/**
* THIS FILE WAS AUTOGENERATED USING NOGIN CLI
**/


import ContainerStyleModel from '../ContainerStyle/models'


export default class HtmlModel {
  __id: string = ''
  __type: 'Html' = 'Html'
  __elementId: string = ''
  html: string = ''
  style: ContainerStyleModel = new ContainerStyleModel()
}

