import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import styled from '@emotion/styled'
import { SubPickerProps } from './Picker'
import CollectionFilterModel from '../models'

const Container = styled.div<{ data: CollectionFilterModel }>`
  flex-grow: 1;
  overflow: auto;
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(${(props) => props.data.textGridColumns}, minmax(0, 1fr));
  flex-wrap: wrap;
  font-size: ${(props) => props.data.textFontSize};
  color: ${(props) => props.data.textFontColor};
  gap: ${(props) => props.data.textSpacing};

  .text-picker,
  .single-item {
    color: ${(props) => props.data.textFontColor};
    text-decoration: none;
    border: ${(props) => props.data.textBorderThickness} solid
      ${(props) => props.data.textBorderColor};
    border-radius: ${(props) => props.data.textBorderRadius};
    padding: ${(props) => props.data.textPadding};
    background-color: ${(props) => props.data.textBackgroundColor};
    display: flex;
    align-items: center;
    justify-content: ${(props) => props.data.textContentAlignment};
    text-align: ${(props) => {
      switch (props.data.textContentAlignment) {
        case 'flex-start':
          return 'left'
        case 'center':
          return 'center'
        case 'flex-end':
          return 'right'
      }
    }};
  }

  .active {
    color: ${(props) => props.data.textFontSelectedColor};
    border: ${(props) => props.data.textBorderThickness} solid
      ${(props) => props.data.textBorderSelectedColor};
    background-color: ${(props) => props.data.textBackgroundSelectedColor};
  }

  .disabled {
    opacity: ${(props) => props.data.textDisabledOpacity};
  }

  .picker-item-row {
    display: flex;
    gap: 5px;
    align-items: center;

    &.showing-count {
      justify-content: space-between;
      width: 100%;
    }
  }

  /* toggle switch styles from https://www.w3schools.com/howto/howto_css_switch.asp */
  /* The switch - the box around the slider */
  .picker-item-icon-switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 17px;
  }

  /* Hide default HTML checkbox */
  .picker-item-icon-switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .picker-item-icon-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .picker-item-icon-slider:before {
    position: absolute;
    content: '';
    height: 13px;
    width: 13px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .picker-item-icon-slider {
    background-color: #2196f3;
  }

  input:focus + .picker-item-icon-slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .picker-item-icon-slider:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
  }

  /* Rounded sliders */
  .picker-item-icon-slider.picker-item-icon-slider-round {
    border-radius: 17px;
  }

  .picker-item-icon-slider.picker-item-icon-slider-round:before {
    border-radius: 50%;
  }
`

function ListPicker({
  filter,
  defaultSelected,
  facetedData,
  onPicked,
  setPickerVisibility
}: SubPickerProps) {
  const [selected, setSelected] = useState(defaultSelected)
  const [_items, setItems] = useState<any>([])

  useEffect(() => {
    if (defaultSelected) {
      setSelected(filter.selectable == 'single' ? [defaultSelected[0]] : defaultSelected)
    } else {
      setSelected([])
    }
  }, [defaultSelected])

  useEffect(() => {
    if (filter.mode === 'automatic') {
      // build item list from faceted data
      let autoItems: any[] = []
      if (filter.filterType === 'tag') {
        const tagPrefix = filter.tagPrefix
        if (facetedData && facetedData.tags && facetedData.tags[tagPrefix]) {
          let tags = [...facetedData.tags[tagPrefix]]

          for (const tag of tags) {
            autoItems.push(tag.tag)
          }
        }
      } else if (filter.filterType === 'option') {
        if (facetedData && facetedData.options && Array.isArray(facetedData.options)) {
          for (const option of facetedData.options) {
            autoItems.push(option.option)
          }
        }
      }

      if (filter.sortBy === 'alpha') {
        autoItems.sort()
      }

      if (filter.sortBy === 'custom' && filter.options.length > 0) {
        // build list of color prefix
        let newSort = filter.options.map((option) =>
          filter.filterType === 'tag' && filter.tagPrefix
            ? filter.tagPrefix + option?.name
            : option?.name
        )

        // remove items that don't exist in auto items
        newSort = newSort.filter(
          (o: any) =>
            autoItems.map((i) => toLowerCaseIfPossible(i)).indexOf(toLowerCaseIfPossible(o)) > -1
        )

        let regularSortItems: any[] = []
        if (!filter.textOnlyShowWithMapping) {
          // get all items that don't require sorting
          regularSortItems = autoItems.filter(
            (o) =>
              newSort.map((i) => toLowerCaseIfPossible(i)).indexOf(toLowerCaseIfPossible(o)) === -1
          )

          // store these items using basic ascii sort
          regularSortItems.sort()
        }

        // merge with ordered items
        autoItems = [...newSort, ...regularSortItems]
      }

      //if enabled, sort numerically those sizes starting with a number
      if (filter.sortBy === 'numerical') {
        const regIsNumber = /^\d+/
        const regIsXL = /^\d(XL)/
        let letterSizes: string[] = []
        let numericSizes: { sizeNum: number; sizeStr: string }[] = []

        //split sizes into numeric-prefix sizes and alpha (letter) sizes
        for (const item of autoItems) {
          //test if first chars are numbers
          if (regIsNumber.test(item) && !regIsXL.test(item)) {
            const num = item.match(regIsNumber)
            numericSizes.push({ sizeNum: num, sizeStr: item })
          } else {
            letterSizes.push(item)
          }
        }

        //sort alpha-based sizes by their pre-arranged order
        if (letterSizes.length > 0) {
          //presort and/or set the default sort
          letterSizes.sort()
          //sort letter sizes by customer order
          if (filter.sizesSortExpression) {
            const sizes: string[] = filter.sizesSortExpression.toLowerCase().split(',')
            letterSizes.sort((a: string, b: string) => {
              const aIndex = getSortIndex(a, sizes)
              const bIndex = getSortIndex(b, sizes)
              return aIndex - bIndex
            })
          }
        }

        //sort numeric sizes & merge with alpha sizes back into autoItems
        if (numericSizes.length > 0 || letterSizes.length > 0) {
          numericSizes.sort((a, b) => a.sizeNum - b.sizeNum)
          autoItems = numericSizes.map((item) => item.sizeStr).concat(letterSizes)
        }
      }

      setItems(autoItems)

      if (autoItems.length === 0 && filter.hideEmpty) {
        setPickerVisibility(false)
      } else {
        setPickerVisibility(true)
      }
    } else {
      // manual mode loads data from settings
      let filterItems = filter.options.map((option) => option.name)

      if (filter.sortBy === 'alpha') {
        filterItems.sort()
      }

      // [...items].sort()
      setItems(filterItems)
      setPickerVisibility(filterItems.length > 0)
    }
  }, [defaultSelected, facetedData, filter]) /*items,*/

  function getFacetCount(item: any) {
    switch (filter.filterType) {
      case 'option':
        if (facetedData && Array.isArray(facetedData.options)) {
          const option = facetedData.options.find(
            (o: any) => toLowerCaseIfPossible(o.option) == toLowerCaseIfPossible(item)
          )
          return option ? option.count : 0
        }
      case 'tag':
        if (facetedData && facetedData.tags && facetedData.tags[filter.tagPrefix]) {
          if (filter.mode === 'automatic' && filter.tagPrefix) {
            const match = facetedData.tags[filter.tagPrefix].find(
              (t: any) => toLowerCaseIfPossible(t.tag) === toLowerCaseIfPossible(item)
            )
            return (match && match.count) || 0
          }
        }
        break
      case 'price':
        // TODO: we currently don't handle faceted price search
        break
      default:
    }
  }

  function isActive(item: any) {
    return selected.indexOf(item) !== -1
  }

  function getSortIndex(str: string = '', sizes: string[] = []): number {
    str = str.toLowerCase()
    let index: number = sizes.indexOf(str)
    if (index === -1) {
      const aStr = str.split(' ')[0]
      index = sizes.indexOf(aStr)
    }
    return index
  }

  function getNameFromValue(value: any) {
    let newValue
    if (typeof value === 'string') {
      newValue = value
    } else if (value && typeof value === 'object') {
      newValue = value.label
    }

    if (newValue && filter.tagPrefix !== undefined) {
      newValue = newValue.replace(filter.tagPrefix, '')
    }
    return newValue
  }

  function getItemValue(item: any) {
    let itemValue
    if (typeof item === 'string') {
      itemValue = item
    } else if (item && item.value) {
      itemValue = item.value
    }
    return itemValue
  }

  const onClickItem = (
    disabled: boolean,
    item: any,
    e?: React.MouseEvent<HTMLAnchorElement, MouseEvent> | React.KeyboardEvent<HTMLAnchorElement>
  ) => {
    e?.preventDefault()

    if (!disabled) {
      let newSelected: string[]
      const itemValue = getItemValue(item)
      if (!isActive(itemValue)) {
        newSelected = filter.selectable == 'single' ? [itemValue] : [...selected, itemValue]
      } else {
        newSelected = [...selected.filter((o: any) => o !== itemValue)]
      }

      if (onPicked) {
        onPicked(newSelected)
      }

      setSelected(newSelected)
    }
  }

  return (
    <Container data={filter} className='picker-item-container'>
      {_items.map((item: any, i: number) => {
        if (filter.displayType === 'single-item' && i > 0) {
          return null
        }

        //ignore delimed strings with no second value
        if (typeof item === 'string' && item.endsWith(':')) {
          return null
        }

        const itemCount = getFacetCount(item)

        if (filter.hideEmpty && itemCount <= 0) {
          return null
        }

        const disabled = typeof itemCount !== 'undefined' && itemCount <= 0
        if (disabled) {
          return (
            <span
              key={i}
              className={classNames(filter.displayType, {
                active: isActive(getItemValue(item)),
                disabled: disabled
              })}>
              <ListTitle
                showIcon={filter.textShowIcon}
                iconType={filter.textIconType}
                name={
                  filter.displayType === 'single-item'
                    ? filter.singleItemText
                    : getNameFromValue(item)
                }
                isActive={isActive(getItemValue(item))}
                itemCount={0}
              />
            </span>
          )
        }

        return (
          <a
            key={i}
            href='#'
            className={classNames(filter.displayType, {
              active: isActive(getItemValue(item)),
              disabled: disabled
            })}
            onClick={(e) => onClickItem(disabled, item, e)}
            onKeyUp={(e) => (e.code === 'Enter' ? onClickItem(disabled, item, e) : null)}>
            <ListTitle
              showIcon={filter.textShowIcon}
              iconType={filter.textIconType}
              name={
                filter.displayType === 'single-item'
                  ? filter.singleItemText
                  : getNameFromValue(item)
              }
              isActive={isActive(getItemValue(item))}
              itemCount={filter.textShowFacetCounts ? itemCount : 0}
            />
          </a>
        )
      })}
    </Container>
  )
}

ListPicker.defaultProps = {
  defaultSelected: []
}

interface ListTitleProps {
  name: string
  isActive: boolean
  itemCount: number
  showIcon: boolean
  iconType?: string
}

export function ListTitle({ name, isActive, itemCount, showIcon, iconType }: ListTitleProps) {
  return (
    <div
      className={classNames('picker-item-row', {
        'showing-count': itemCount > 0
      })}>
      {showIcon &&
        (iconType === 'toggle' ? (
          <label className='picker-item-icon picker-item-icon-switch'>
            <input type='checkbox' checked={isActive} />
            <span className='picker-item-icon-slider picker-item-icon-slider-round'></span>
          </label>
        ) : (
          <span className='picker-item-icon picker-item-icon-checkbox'>
            {!isActive ? `☐` : `☒`}
          </span>
        ))}
      <span className='picker-item-name'>{name}</span>
      {itemCount > 0 && <span className='item-count'> {itemCount} </span>}
    </div>
  )
}

export default ListPicker

function toLowerCaseIfPossible(item: any) {
  return item?.toLowerCase?.() || item
}
