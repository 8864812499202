import axios from 'axios'
import React, { useCallback, useState, useEffect } from 'react'
import styled from '@emotion/styled'
import classNames from 'classnames'
import CollectionSettingModel from '../../elements/CollectionSetting/models'
import { useAppDispatch, useAppSelector } from '../../redux'
import { notification, sendEvent, toggleLoading } from '../../redux/slices/app'
import { setIsQuickBuyOpen } from '../../redux/slices/collection-element'
import Button from '../Button'
import { useMediaQuery } from 'react-responsive'
import ProductOptionPicker, { ValueContainer } from './ProductOptionPicker'
import { FiX } from 'react-icons/fi'

interface QuickBuyContainerProps {
  isMenuOpen: boolean
  isOutOfStock: boolean
  overlay: boolean
  settings?: CollectionSettingModel
}

const QuickBuyContainer = styled.div<QuickBuyContainerProps>`
  position: ${(props => props.overlay ? 'absolute' : 'relative')};
  bottom: 0px;
  pointer-events: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${(props) =>
    props.isMenuOpen
      ? props.settings?.backgroundColor || 'rgba(255, 255, 255, 0.5)'
      : 'transparent'};

  .quick-buy-button {
    padding-top: ${(props) => props.settings?.quickBuyButtonStyle?.paddingTop};
    padding-right: ${(props) => props.settings?.quickBuyButtonStyle?.paddingRight};
    padding-bottom: ${(props) => props.settings?.quickBuyButtonStyle?.paddingBottom};
    padding-left: ${(props) => props.settings?.quickBuyButtonStyle?.paddingLeft};
    margin-top: ${(props) => props.settings?.quickBuyButtonStyle?.marginTop};
    margin-right: ${(props) => props.settings?.quickBuyButtonStyle?.marginRight};
    margin-bottom: ${(props) => props.settings?.quickBuyButtonStyle?.marginBottom};
    margin-left: ${(props) => props.settings?.quickBuyButtonStyle?.marginLeft};
    background-color: ${(props) => props.settings?.quickBuyButtonStyle?.backgroundColor};
    border-width: ${(props) => props.settings?.quickBuyButtonStyle?.borderThickness};
    border-color: ${(props) => props.settings?.quickBuyButtonStyle?.borderColor};
    border-radius: ${(props) => props.settings?.quickBuyButtonStyle?.borderRadius};
    color: ${(props) => props.settings?.quickBuyButtonStyle?.textColor};
    width: ${(props) => props.settings?.quickBuyButtonStyle?.widthValue || '100%'};

    &:hover {
      background-color: ${(props) => props.isOutOfStock ? 'unset' : props.settings?.quickBuyButtonStyle?.backgroundColorHover};
      border-color: ${(props) => props.settings?.quickBuyButtonStyle?.borderColorHover};
      color: ${(props) => props.settings?.quickBuyButtonStyle?.textColorHover};
    }
  }

  @media (max-width: 768px) {
    pointer-events: ${(props) =>
      props.overlay && props.settings?.mobileVisibility === 'visible' ? 'none' : 'auto'};
    justify-content: ${(props) =>
      !props.overlay || props.settings?.mobileVisibility === 'click' ? 'flex-end' : 'inherit'};
    height: ${(props) => (props.overlay && props.settings?.mobileVisibility === 'click' ? '100%' : 'auto')};

    .quick-buy-button {
      display: ${(props) => (props.overlay && props.settings?.mobileVisibility === 'hidden' ? 'none' : 'block')};
      pointer-events: ${(props) =>
        !props.overlay || props.settings?.mobileVisibility === 'visible' ? 'auto' : 'none'};
    }
  }

  .variant-option-picker {
    margin: 10px;
  }

  .close-menu-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 0 5px 5px;
    pointer-events: auto;
    z-index: 2;
    color: ${(props) => props.settings?.quickBuyButtonStyle?.textColor};
  }
`
interface QuickBuyProps {
  product: any
  variant: any
  isMouseOver: boolean
  overlay: boolean
  mobileBreakpoint?: string
  visibility?: string
  cardLayout: any
}

export default function QuickBuy({
  product,
  variant,
  isMouseOver = false,
  mobileBreakpoint = '768px',
  visibility = 'both',
  overlay,
  cardLayout
}: QuickBuyProps) {
  const dispatch = useAppDispatch()
  const isMobile = useMediaQuery({ query: `(max-width: ${mobileBreakpoint})` })
  const filterSettings = useAppSelector((state) => state.app.settings.filters)
  const [isButtonVisible, setIsButtonVisible] = useState(getIsButtonVisible())
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isOptions, setIsOptions] = useState(false)
  const isOutOfStock = product?.inventoryQuantity <= 0

  useEffect(() => {
    dispatch(setIsQuickBuyOpen(isMenuOpen))
  }, [isMenuOpen])

  useEffect(() => {
    setIsButtonVisible(getIsButtonVisible())
    setIsMenuOpen(false)
  }, [isMobile])

  useEffect(() => {
    // check if any options have more than one value
    if(product.variants.length > 1) {
      setIsOptions(true)
    }
  }, [product])

  useEffect(() => {
    if(overlay) {
      setIsButtonVisible((isVisible) => {
        if (isMobile) {
          return filterSettings?.mobileVisibility === 'visible'
        }
        return filterSettings?.desktopVisibility === 'hover' ? isMouseOver : isVisible
      })
    }
  }, [isMouseOver])

  const handleAddToCartClick = useCallback(async (variant: any) => {
    const data = {
      id: +variant.sourceId,
      quantity: 1
    }

    setIsButtonVisible(true)
    setIsMenuOpen(false)
    dispatch(toggleLoading(true))

    // @ts-ignore check if running on shopify site
    if (typeof Shopify !== 'undefined') {
      try {
        // create axios client without a base url to call
        // shopify api when embedded in client site
        const shopifyClient = axios.create({
          baseURL: '',
          params: {},
          timeout: 3000
        })
        await shopifyClient.post('/cart/add.js', data)
      } catch (e) {
        console.log(e)
        dispatch(notification({ type: 'error', title: 'Could not add item to cart' }))
      }
    } else {
      console.log('Debug: This feature can only be used on a live store')
    }

    dispatch(sendEvent({ source: `quickbuy.addtocart`, data: { product } }))
    dispatch(toggleLoading(false))
  }, [])

  function getIsButtonVisible() {
    if(overlay) {
      return (
        (filterSettings?.enableQuickBuy === true &&
        (isMobile
          ? filterSettings?.mobileVisibility === 'visible'
          : filterSettings?.desktopVisibility === 'visible' ||
            (filterSettings?.desktopVisibility === 'hover' && isMouseOver)))
      )
    } else {
      return (
        (filterSettings?.enableQuickBuy === true &&
          (visibility === 'both' || 
            (isMobile
              ? visibility === 'mobile'
              : visibility === 'desktop'
            )
          )
        )
      )
    }
  }

  return (
    <QuickBuyContainer
      className='quickbuy-container'
      isMenuOpen={isMenuOpen}
      settings={filterSettings}
      isOutOfStock={isOutOfStock}
      overlay={overlay}
      onClick={(e) => {
        if (
          overlay && 
          isMobile &&
          filterSettings?.mobileVisibility === 'click' &&
          filterSettings?.onClickMobile === 'menu'
        ) {
          e?.preventDefault()
          e?.stopPropagation()
          setIsButtonVisible(false)
          setIsMenuOpen(true)
          dispatch(sendEvent({ source: `quickbuy.open`, data: { product } }))
        } else if (
          overlay && 
          isMobile &&
          filterSettings?.mobileVisibility === 'click' &&
          filterSettings?.onClickMobile === 'button'
        ) {
          e?.preventDefault()
          e?.stopPropagation()
          setIsButtonVisible((isVisible) => {
            if (isVisible) {
              dispatch(sendEvent({ source: `quickbuy.open`, data: { product } }))
            }
            return !isVisible
          })
          setIsMenuOpen(isButtonVisible)
        }
      }}
    > 
      {(isButtonVisible) && (
        <div className='button-container'>
          <Button
            className={classNames('quick-buy-button', { 'expanded': isMenuOpen })}
            type='primary'
            onMouseOver={(e) => {
              if(overlay) {
                e?.preventDefault()
                e?.stopPropagation()
                if(!isOutOfStock){
                  dispatch(sendEvent({ source: `quickbuy.open`, data: { product } }))
                  setIsButtonVisible(false)
                  setIsMenuOpen(true)
                }

              }
            }}
            onClick={(e) => {
              if(!overlay) {
                e?.preventDefault()
                e?.stopPropagation()
                if(!isOutOfStock){
                  if(isMenuOpen) {
                    setIsMenuOpen(false)
                  } else {
                    dispatch(sendEvent({ source: `quickbuy.open`, data: { product } }))
                    setIsMenuOpen(true)
                  }
                }
              }
            }}
          >
            { isOutOfStock ? (filterSettings?.quickBuyButtonSoldOutTitle || 'Sold Out') : (filterSettings?.quickBuyButtonTitle || 'Quick Buy') }
          </Button>
        </div>
      )}
      {isMenuOpen && (!overlay || !isButtonVisible) && (
        <div>
          {isOptions ? (
            <div
              onMouseLeave={(e) => {
                if(overlay) {
                  setIsButtonVisible(true)
                  setIsMenuOpen(false)
                }
              }}
            >
              <ProductOptionPicker
                key={'options'}
                product={product}
                variant={variant}
                cardLayout={cardLayout}
                onChangeVariant={(variant: any) => {
                  if (variant) {
                    handleAddToCartClick(variant)
                  }
                }}
              />
            </div>
          ) : (
            <div className='flex flex-col items-center variant-option-picker'>
              <ValueContainer settings={filterSettings}>
                <Button
                  key='buynow'
                  type='primary'
                  size='sm'
                  className='option-button'
                  onClick={(e) => {
                    e?.preventDefault()
                    if (product.variants[0]) {
                      handleAddToCartClick(product.variants[0])
                    }
                  }}
                >
                  Add to Cart
                </Button>
              </ValueContainer>
            </div>
          )}
        </div>
      )}
      {isMobile &&
        overlay &&
        isMenuOpen &&
        filterSettings?.mobileVisibility === 'click' &&
        filterSettings?.onClickMobile === 'menu' && (
          <div
            className='close-menu-button'
            onClick={(e) => {
              e?.preventDefault()
              e?.stopPropagation()
              setIsButtonVisible(getIsButtonVisible())
              setIsMenuOpen(false)
            }}
          >
            <FiX />
          </div>
        )}
    </QuickBuyContainer>
  )
}
