import styled from '@emotion/styled'
import React from 'react'
import { Element } from '../..'
import { GenericContentModel } from '../types'

interface Props {
  contentData: GenericContentModel
}

interface ContainerProps {
  imageCount: number
}

const Container = styled.div<ContainerProps>`
  display: block;
  position: relative;
  height: auto;
  overflow: hidden;

  .img-wrapper:first-of-type {
    display: block;
    position: relative;
    z-index: 2;
    transition: ease-in-out;
    transition-duration: 250ms;

    &:hover {
      opacity: ${(props) => (props.imageCount > 1 ? 0 : 1)};
    }
  }

  .img-wrapper:not(:first-of-type) {
    z-index: 1;
    position: absolute;
    top: 0px;
  }
`

export default function StackedView({ contentData }: Props) {
  const images = contentData?.featuredImages?.slice(0, 2) || []

  return (
    <Container imageCount={images.length}>
      {images.map((fi: any, i: number) => (
        <a className='img-wrapper' href={contentData?.url} key={i}>
          <Element name='ResponsiveImage' data={fi.image} />
        </a>
      ))}
    </Container>
  )
}
