import React, { useContext, useEffect } from 'react'
import CollectionFilter from '../CollectionFilter'
import { LayoutContext } from '../Layout'
import CollectionFilterGroupModel from '../CollectionFilterGroup/models'
import { useAppDispatch } from '../../redux'
import { setFilterOptions } from '../../redux/slices/collection-element'
import CollectionFilterGroupDisplayModel from './models'
import styled from '@emotion/styled'

interface Props {
  data: CollectionFilterGroupDisplayModel
  containerProps: any
}

const ContainerWrapper = styled.div<
  Pick<CollectionFilterGroupDisplayModel, 'displayDirection' | 'gapSpacing'>
>`
  display: flex;
  flex-direction: ${(props) => props.displayDirection};
  gap: ${(props) => props.gapSpacing};
`

export default function CollectionFilterGroupDisplay({ data, containerProps }: Props) {
  const dispatch = useAppDispatch()
  const context = useContext(LayoutContext)
  const filters = context?.__collectionFilters || []

  useEffect(() => {
    dispatch(setFilterOptions(filters))
  }, [filters])

  if (filters?.length > 0) {
    return (
      <ContainerWrapper
        className='filter-group'
        {...containerProps}
        displayDirection={data.displayDirection}
        gapSpacing={data.gapSpacing}>
        {filters.map((filter: CollectionFilterGroupModel) =>
          data.group === filter.group ? (
            <CollectionFilter
              data={{ ...filter.filterSettings, name: filter.name }}
              containerProps={{}}
            />
          ) : (
            <></>
          )
        )}
      </ContainerWrapper>
    )
  }

  return <></>
}
