import { isPromise } from "./helpers"

type HookCallback = (data: any) => HookResponsePayload

interface Hook {
  id: string
  callback: HookCallback
}

interface HookResponsePayload {
  [key: string]: any
}

class Hooks {
  private _hooks: Hook[] = []

  /**
   * Register a callback function to be triggered
   * when a hook is ran
   * @param id 
   * @param callback 
   */
  register(id: string, callback: HookCallback) {
    this._hooks.push({
      id,
      callback
    })
  }

  /**
   * Executes a hook 
   * @param id 
   * @param data 
   * @returns result of all registered hook callback
   */
  run(id: string, data?: any): any {
    const matches = this._hooks.filter((o) => o.id === id)

    if (matches.length > 0) {
      if (import.meta.env.DEV) {
        console.log(`HOOK: running ${id} on ${matches.length} matches`)
      }     
    }

    let newData = data
    for (const hook of matches) {
      newData = hook.callback(newData)
    }

    return newData
  }
}

const hooks = new Hooks()
export { hooks }