import React, { useState, useEffect } from 'react'
import { useCallback } from 'react'
import ReactCardFlip from 'react-card-flip'
import { AiOutlineExport } from 'react-icons/ai'
import { FiTrash } from 'react-icons/fi'
import styled from '@emotion/styled'
import { useAppDispatch, useAppSelector } from '../../redux'
import { notification } from '../../redux/slices/app'
import { addWishListItemToCart, moveWishListItem, removeCustomerWishListItem } from '../../redux/slices/wishlist'
import { WishListCustomerItemPayload } from '../../types'
import Button from '../Button'
import ProductCard from '../ProductCard'
import { Element } from '../../elements'

interface WishListItemProps {
  item: WishListCustomerItemPayload
  editable?: boolean
}

const WishListMoveCard = styled.div`
  border: 1px solid #e0e0e0;
  width: 100%;
  height: 100%;

  > div {
    padding: 10px;
    height: 100%;
  }

  ul {
    list-style: none !important;
    padding-left: 0px !important;
  }

  li {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .spacer {
    flex: 1;
  }

  .actions {
    position: relative;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .actions > Button {
    width: 100%;
  }
`

const ElementWishListCard = styled.div`
  margin-bottom: 50px;
  position: relative;
`

export default function WishListItem({ item, editable = true }: WishListItemProps) {
  const dispatch = useAppDispatch()
  const currentListId = useAppSelector((state) => state.wishlist.customer.currentListId)
  const [currentList, setCurrentList] = useState<any>(null)
  const lists = useAppSelector((state) => state.wishlist.customer.lists)
  const [currentVariant, setCurrentVariant] = useState<any>(null)
  const [flipped, setFlipped] = useState(false)
  const notificationSettings = useAppSelector((state) => state.wishlist.settings.notification)

  const handleAddToCart = useCallback(
    async () => {
      dispatch(addWishListItemToCart({ item, variant: currentVariant }))
    },
    [item, currentVariant, currentListId]
  )

  useEffect(() => {
    const list = lists.find((l) => l.id === currentListId)
    setCurrentList(list)
  }, [currentListId])

  return (
    <>
    {item?.sourceProductId && (
      <ReactCardFlip isFlipped={flipped} flipDirection='horizontal'>
      <ProductCard
        product={item.product}
        source='wishlist'
        key={item.id}
        layout='vertical'
        responsive={true}
        contentLayout={['title', 'price', 'custom-footer', 'family', 'options', 'actions']}
        onChangeVariant={(variant) => {
          setCurrentVariant(variant)
        }}
        overlayElements={[
          // <div style={{ width: '100%', marginBottom: 5 }} key='notification'>
          //   <Button type='link'>
          //     <AiOutlineBell size={20} />
          //   </Button>
          // </div>,
          editable && (
            <div style={{ width: '100%', marginBottom: 5 }} key='move'>
              <Button
                ariaLabel="Edit"
                type='link'
                onClick={(e) => {
                  e?.preventDefault()
                  setFlipped(true)
                }}>
                <AiOutlineExport size={20} />
              </Button>
            </div>
          ),
          editable && (
            <Button
              type='link'
              key='remove'
              ariaLabel='Delete'
              onClick={async (e) => {
                e?.preventDefault()
                if (window.confirm('Are you sure you want to delete this?')) {
                  dispatch(
                    removeCustomerWishListItem({
                      listId: currentListId as string,
                      itemId: item.id
                    })
                  )

                  const title = notificationSettings.removeTitle
                  dispatch(notification({ title }))
                }
              }}>
              <FiTrash size={20} />
            </Button>
          )
        ]}
        actions={[
          {
            type: 'primary',
            label: 'Add To Cart',
            disabled: !currentVariant || currentVariant?.inventoryQuantity === 0,
            onClick: handleAddToCart
          }
        ]}
      />
      <WishListMoveCard>
        <div>
          Which list do you want to move this to?
          <div className='spacer'>
            <ul>
              {lists.map(
                (list: any) =>
                  (list.id !== currentListId) && (list.elementKey === currentList?.elementKey) && (
                    <li key={list.id}>
                      <Button
                        type='link'
                        style={{ width: '100%' }}
                        onClick={(e) => {
                          e?.preventDefault()
                          dispatch(moveWishListItem({ itemId: item.id, listId: list.id }))
                          setFlipped(false)
                        }}>
                        {list.name}
                      </Button>
                    </li>
                  )
              )}
            </ul>
          </div>
          <div className='actions'>
            <Button
              type='primary'
              onClick={(e) => {
                e?.preventDefault()
                setFlipped(false)
              }}>
              Cancel
            </Button>
          </div>
        </div>
      </WishListMoveCard>
    </ReactCardFlip>
    )}
    {item?.elementId && (
      <ElementWishListCard>
        <Element name={item.elementKey} slug={item.element.data.slug} isCardLayout={true}/>
      </ElementWishListCard>
    )}
    </>
  )
}
