import React, { createContext, useEffect } from 'react'
import { appendScriptTagToBody } from '../../lib/helpers'

interface ContextState {
  client: any
  isClientReady: boolean
  setChatGuestUsername: Function
  initStreamChat: Function
  isLibraryLoaded: boolean
}

export const StreamChatClientContext = createContext({
  client: null,
  isClientReady: false,
  setChatGuestUsername: () => {},
  initStreamChat: () => {},
  isLibraryLoaded: false
} as ContextState)

const customer = {
  id: '',
  name: '',
  image: '',
  streamChatToken: ''
}
const getstreamApiKey = 'rp6vq4pzf5at'

export const StreamChatContextProvider = ({ children }: any) => {
  const [isLibraryLoaded, setIsLibraryLoaded] = React.useState(false)
  const [client, setClient] = React.useState<any>()
  const [isClientReady, setIsClientReady] = React.useState(false)

  useEffect(() => {
    return () => {
      client?.disconnectUser()
    }
  }, [])

  const initStreamChat = function (callback: Function = () => {}) {
    loadStreamChatLibrary(({ StreamChat, StreamChatReact }: any) => {
      setIsLibraryLoaded(true)
      if (!client || !isClientReady) {
        initClient(StreamChat)
      }
      callback({ StreamChat, StreamChatReact })
    })
  }

  const initClient = async function (StreamChat: any) {
    let streamChatClient: any
    if (getstreamApiKey && !client) {
      streamChatClient = StreamChat.getInstance(getstreamApiKey)
      if (customer?.id) {
        const userToken = customer.streamChatToken
        const user = {
          id: customer?.id?.toString(),
          name: customer.name,
          image: customer.image
        }
        await streamChatClient.connectUser(user, userToken)
      } else {
        await streamChatClient.connectAnonymousUser()
      }
    }
    setClient((clientState: any) => (clientState ? clientState : streamChatClient))
    setIsClientReady(true)
  }

  const setChatGuestUsername = async (guestUserName: string) => {
    setIsClientReady(false)
    await client?.disconnectUser()
    await client?.setGuestUser({
      id: Math.floor(Math.random() * Math.floor(100000000)).toString(),
      name: guestUserName
    })
    setIsClientReady(true)
  }

  return (
    <StreamChatClientContext.Provider
      value={{ isClientReady, client, setChatGuestUsername, initStreamChat, isLibraryLoaded }}
    >
      {children}
    </StreamChatClientContext.Provider>
  )
}

const streamScriptId = 'stream-script'
const streamReactScriptId = 'stream-react-script'
const streamReactCSSId = 'stream-react-css'

let isBaseLibraryLoaded = false
let isReactLibraryLoaded = false
export const loadStreamChatLibrary = (callback: Function): any => {
  const streamScriptElement = document.getElementById(streamScriptId)
  const streamReactScriptElement = document.getElementById(streamReactScriptId)
  const streamChatCSSElement = document.getElementById(streamReactCSSId)
  if (!window.React) {
    window.React = React
  }

  //Libraries have already been loaded so callback can be called
  if (streamScriptElement && streamReactScriptElement) {
    const { StreamChat, StreamChatReact } = window as any
    if (StreamChat && StreamChatReact) {
      callback({ StreamChat, StreamChatReact })
    } else if (!StreamChat && !StreamChatReact) {
      appendStreamScript(() => {
        isBaseLibraryLoaded = true
        callCallback()
      })
      appendStreamReactScript(() => {
        isReactLibraryLoaded = true
        callCallback()
      })
    } else if (!StreamChat && StreamChatReact) {
      isReactLibraryLoaded = true
      appendStreamScript(() => {
        isBaseLibraryLoaded = true
        callCallback()
      })
    } else if (StreamChat && !StreamChatReact) {
      isBaseLibraryLoaded = true
      appendStreamReactScript(() => {
        isReactLibraryLoaded = true
        callCallback()
      })
    }
  }

  if (!streamScriptElement) {
    appendStreamScript(() => {
      isBaseLibraryLoaded = true
      callCallback()
    })
  }

  if (!streamReactScriptElement) {
    appendStreamReactScript(() => {
      isReactLibraryLoaded = true
      callCallback()
    })
  }

  if (!streamChatCSSElement) {
    const link = document.createElement('link') as any
    link.type = 'text/css'
    link.rel = 'stylesheet'
    link.href = 'https://unpkg.com/@stream-io/stream-chat-css@2.9.0/dist/css/index.css'
    link.id = streamReactCSSId
    document.head.appendChild(link)
  }

  //Libraries have been loaded for the first time
  function callCallback() {
    if (isBaseLibraryLoaded && isReactLibraryLoaded) {
      const { StreamChat, StreamChatReact } = window as any
      callback({ StreamChat, StreamChatReact })
    }
  }
}

const appendStreamScript = (onload: Function) => {
  const scriptSettings = {
    src: 'https://unpkg.com/stream-chat@6.5.0/dist/browser.full-bundle.min.js',
    id: streamScriptId,
    onload
  }
  appendScriptTagToBody(scriptSettings)
}

const appendStreamReactScript = (onload: Function) => {
  const scriptSettings = {
    src: 'https://unpkg.com/stream-chat-react@8.1.1/dist/browser.full-bundle.min.js',
    id: streamReactScriptId,
    onload
  }
  appendScriptTagToBody(scriptSettings)
}
