import styled from '@emotion/styled'
import { DotProps } from './_interfaces'

const ListElement = styled.div<DotProps>`
  width: ${(props) => props?.dotWidth || "30px"};
  height: ${(props) => props?.dotHeight || "15px"};
  margin: 0px ${(props) => props?.dotSpacing || "1px"};
  background-color: ${(props) => props?.dotColor || 'rgba(225,225,225,1)'};
  border-radius: ${(props) => props?.dotRadius || 0};
`
export default ListElement