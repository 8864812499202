import React, { useEffect, useRef, useState, useCallback, Ref } from 'react'
import ProductCard from './components/ProductCard'
import PaginateButton from '../../components/PaginateButton'
import { useAppDispatch, useAppSelector } from '../../redux'
import styled from '@emotion/styled'
import {
  setPageIndex,
  setPageSize,
  setInfiniteScrollPageIndex,
  setIsMobile
} from '../../redux/slices/collection-element'
import { toggleLoading } from '../../redux/slices/app'
import CollectionProductListingModel from './models'
import { Element } from '../index'
import classNames from 'classnames'
import { analytics } from '../../main'
import CollectionInlineBannerModel from '../../elements/CollectionInlineBanner/models'
import { debounce } from '../../lib/helpers'
import { getIsInfiniteScrollEnabled } from '../Layout/CollectionLayout'
import { useMediaQuery } from 'react-responsive'

class CollectionProductListingModelLegacy extends CollectionProductListingModel {
  cardLayout: any
}

interface Props {
  data: CollectionProductListingModelLegacy
  containerProps: any
}

function range(size: number, startAt: number = 0): number[] {
  return [...Array(size).keys()].map((i) => i + startAt)
}

const Container = styled.div<{ data: CollectionProductListingModel }>`
  display: flex;
  flex-direction: column;
  position: relative;

  > .collection-products {
    display: grid;
    grid-template-columns: ${(props) => `repeat(${props.data.gridColumnsDesktop}, 1fr)`};
    gap: ${(props) => props.data.gridSpacing};

    /* &.empty {
      display: flex;
      grid-template-columns: unset;
      flex-direction: column;
    } */
  }

  @media screen and (max-width: 768px) {
    > .collection-products {
      grid-template-columns: ${(props) => `repeat(${props.data.gridColumnsMobile}, 1fr)`};
      gap: ${(props) => props.data.gridSpacing};
    }
  }
`

export default function ProductListing({ data, containerProps }: Props) {
  const dispatch = useAppDispatch()
  const isLoaded = useAppSelector((state) => state.collectionElement.isLoaded)
  const products = useAppSelector((state) => state.collectionElement.products)
  const totalProducts = useAppSelector((state) => state.collectionElement.totalProducts)
  const pageIndex = useAppSelector((state) => state.collectionElement.pageIndex)
  const pageSize = useAppSelector((state) => state.collectionElement.pageSize)
  const originalPageSizeRef = useRef(pageSize)
  const content = useAppSelector((state) => state.collectionElement.content)
  const placeholderRef = useRef<HTMLDivElement>(null)
  const collectionElementsRef = useRef<HTMLDivElement>(null)
  const inlineBannerArray = useAppSelector((state) => state.collectionElement.inlineBannerArray)
  const isMobile = useAppSelector((state) => state.collectionElement.isMobile)

  const calculateInlineBannerSize = (inlineBannerArray: any, isShowAllPages: boolean) => {
    let value = 0
    inlineBannerArray.forEach((banner: any) => {
        //inline banners without visibility settings defined will be displayed by default
        if ((isMobile && banner?.visibleOnMobile) || (isMobile && banner?.visibleOnMobile !== false)) {
          if ((isShowAllPages && banner?.showOnAllPages) || (!isShowAllPages && !banner?.showOnAllPages)) 
            value = value + (banner?.columnSpanMobile * banner?.rowSpanMobile)
        }

        if ((!isMobile && banner?.visibleOnDesktop) || (!isMobile && banner?.visibleOnDesktop !== false)) {
          if ((isShowAllPages && banner?.showOnAllPages) || (!isShowAllPages && !banner?.showOnAllPages)) 
            value = value + (banner?.columnSpan * banner?.rowSpan)
        }
    })
    return value
  }

  const sizeShowAllPagesBanners = inlineBannerArray ? calculateInlineBannerSize(inlineBannerArray,true) : 0
  const sizeDoNotShowAllPagesBanners = inlineBannerArray ? calculateInlineBannerSize(inlineBannerArray,false) : 0
  let pages = Math.ceil((totalProducts + sizeDoNotShowAllPagesBanners) / (pageSize - sizeShowAllPagesBanners))

  if (getIsInfiniteScrollEnabled(data, totalProducts)) {
    pages = Math.ceil((totalProducts + sizeDoNotShowAllPagesBanners) / (originalPageSizeRef.current - sizeShowAllPagesBanners))
  }

  useEffect(() => {
    //This useEffect is here so that changes from form editor automatically update the UI
    pages = Math.ceil((totalProducts + sizeDoNotShowAllPagesBanners) / (pageSize - sizeShowAllPagesBanners))
    dispatch(setPageSize(data.paginationSize))
    dispatch(setPageIndex(1))
  }, [
    data.paginationSize,
    data.maxInfiniteScrollProducts,
    data.isInfiniteScrollCapped,
    data.paginationMethod
  ])

  // pages shown in pagination picker
  const MAX_NEIGHBORS = 2
  let activePages: number[] = []
  if (totalProducts > 0) {
    const startPage = Math.max(1, pageIndex - MAX_NEIGHBORS)
    const endPage = Math.min(pages, pageIndex + MAX_NEIGHBORS + 1)
    activePages = range(endPage - startPage + 1, startPage)
  }

  // show ... on left side
  const showSpillLeft = activePages[0] > 2

  // show ... on right side
  const showSpillRight = activePages[activePages.length - 1] < pages - 2

  const placeholderHeight = placeholderRef?.current?.clientHeight || 0.1
  const placeholderWidth = placeholderRef?.current?.clientWidth || 0.1
  const placeholderPaddingBottom = (placeholderHeight / placeholderWidth) * 100

  useEffect(() => {
    // Track page views for collections PLP
    analytics.page({})
  }, [])

  const navigateToPage = (pageIndex: number) => {
    dispatch(setPageIndex(pageIndex))
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const OuterContainer = getIsInfiniteScrollEnabled(data, totalProducts)
    ? InfiniteScrollContainer
    : Container

  return (
    <OuterContainer
      pageSize={pageSize}
      collectionElementsRef={collectionElementsRef}
      pages={pages}
      data={data}
      originalPageSize={originalPageSizeRef.current}
      {...containerProps}
    >
      {/* Products: {totalProducts} */}
      {isLoaded && products.length === 0 && totalProducts === 0 && (
        <div className='empty-message'>{data.emptyMessage}</div>
      )}

      <div
        className={classNames('collection-products', {
          empty: products.length === 0
        })}
        ref={collectionElementsRef}
      >
        {isLoaded &&
          products.length === 0 &&
          data?.placeholderImage?.imagePath &&
          [...Array(+pageSize).keys()].map((i: number) => (
            <div key={`placeholder${i}`} style={{}} ref={placeholderRef}>
              <Element name='ResponsiveImage' data={data.placeholderImage} />
            </div>
          ))}
        <CollectionItems
          products={products}
          content={content}
          placeholderPaddingBottom={placeholderPaddingBottom}
          data={data}
          pageIndex={pageIndex}
          pageSize={+pageSize}
        />
      </div>
      {products.length > 0 && pages > 1 && !getIsInfiniteScrollEnabled(data, totalProducts) && (
        <div className='pagination' style={{ display: 'flex' }}>
          <PaginateButton
            pageIndex={pageIndex - 1}
            title='Prev'
            onClick={() => {
              navigateToPage(pageIndex - 1)
            }}
            disabled={pageIndex === 1}
            active={false}
          />
          {showSpillLeft && (
            <PaginateButton
              pageIndex={1}
              title='1'
              onClick={() => {
                navigateToPage(1)
              }}
              disabled={false}
              active={false}
            />
          )}
          {showSpillLeft && '...'}
          {activePages.map((i) => {
            return (
              <PaginateButton
                pageIndex={i}
                key={i}
                title={i.toString()}
                onClick={() => {
                  navigateToPage(i)
                }}
                disabled={false}
                active={pageIndex === i}
              />
            )
          })}
          {showSpillRight && '...'}
          {showSpillRight && (
            <PaginateButton
              pageIndex={pages - 1}
              title={(pages - 1).toString()}
              onClick={() => {
                navigateToPage(pages - 1)
              }}
              disabled={false}
              active={false}
            />
          )}
          <PaginateButton
            pageIndex={pageIndex + 1}
            title={'Next'}
            onClick={() => {
              navigateToPage(pageIndex + 1)
            }}
            disabled={pageIndex === pages}
            active={false}
          />
        </div>
      )}
    </OuterContainer>
  )
}

function CollectionItems({
  products,
  content,
  placeholderPaddingBottom,
  data,
  pageIndex,
  pageSize
}: {
  products: any
  content: any
  placeholderPaddingBottom: number
  data: CollectionProductListingModelLegacy
  pageIndex: number
  pageSize: number
}) {
  const dispatch = useAppDispatch()
  const baseCollection = useAppSelector((state) => state.collectionElement.baseCollection)
  const allCollectionBanners = Array.isArray(content?.collectionBanner)
    ? content?.collectionBanner
    : []
  //Filter collection banner based on view
  const mobileBreakpoint = '768px'
  const isMobile = useMediaQuery({ query: `(max-width: ${mobileBreakpoint})` })
  dispatch(setIsMobile(isMobile))

  const collectionBanners = isMobile ? 
    allCollectionBanners.filter((banner: CollectionInlineBannerModel) => banner.visibleOnMobile || banner?.visibleOnMobile !== false) :
    allCollectionBanners.filter((banner: CollectionInlineBannerModel) => banner.visibleOnDesktop || banner?.visibleOnDesktop !== false)

  const offset =
    (pageIndex - 1) *
    (pageSize +
      collectionBanners.filter((banner: CollectionInlineBannerModel) => banner.showOnAllPages)
        .length)
  let itemsLength = pageSize
  collectionBanners.forEach((banner: CollectionInlineBannerModel) => {
    const bannerIndex = getBannerIndex(banner)
    if (
      banner.showOnAllPages ||
      (!banner.showOnAllPages && bannerIndex >= 0 && bannerIndex < itemsLength)
    ) {
      itemsLength++
    }
  })

  function getBannerIndex(banner: CollectionInlineBannerModel) {
    return banner.showOnAllPages ? banner.position - 1 : banner.position - offset - 1
  }

  return (
    <>
      {collectionBanners
        .filter((banner: CollectionInlineBannerModel) => {
          const bannerIndex = getBannerIndex(banner)
          return (
            banner.showOnAllPages ||
            (!banner.showOnAllPages && bannerIndex >= 0 && bannerIndex < itemsLength)
          )
        })
        .map((banner: CollectionInlineBannerModel) => {
          const bannerIndex = getBannerIndex(banner)
          //ElementId is appended to React key in case two inline banners are set to the same position
          return (
            <InlineBannerContainer
              key={`banner-${bannerIndex}-${banner.__elementId}`}
              colspanDesktop={banner.columnSpan}
              colspanMobile={banner.columnSpanMobile}
              rowspanDesktop={banner.rowSpan}
              rowspanMobile={banner.rowSpanMobile}
              position={bannerIndex}
            >
              <div className='inline-banner'>
              <Element name='ResponsiveImage' data={banner.image} elementId={banner.__elementId}/>
              </div>
            </InlineBannerContainer>
          )
        })
        .concat(
          products.map((product: any, i: number) => (
            <ProductCard
              key={product.sourceId}
              source='list'
              product={product}
              baseCollection={baseCollection}
              layout='vertical'
              responsive={true}
              showQuickBuy={true}
              overlayElements={[
                <div
                  className='nogin-wishlist-product'
                  data-product-id={product.sourceId}
                  key={`product-wishlist-${product.id}`}
                ></div>
              ]}
              showWishListButton={true}
              cardLayout={data?.productCardLayout?.cardLayout || data?.cardLayout}
              // settings={config.reviews}
              placeholderPaddingBottom={placeholderPaddingBottom}
              position={i}
            />
          ))
        )
        .sort((itemA: any, itemB: any) => itemA?.props?.position - itemB?.props?.position)}
    </>
  )
}

interface InlineBannerProps {
  colspanDesktop: number
  rowspanDesktop: number
  colspanMobile: number
  rowspanMobile: number
  position?: number
}

const InlineBannerContainer = styled.div<InlineBannerProps>`
  display: grid;
  grid-template-columns: 1fr;
  gap: 5;
  grid-column: span ${(props) => props.colspanDesktop || 1};
  grid-row: span ${(props) => props.rowspanDesktop || 1};

  @media screen and (max-width: 768px) {
    grid-column: span ${(props) => props.colspanMobile || 1};
    grid-row: span ${(props) => props.rowspanMobile || 1};
  }
`

const InfiniteScrollContainer = ({
  pageSize,
  collectionElementsRef,
  pages,
  originalPageSize,
  children,
  data
}: {
  pageSize: number
  collectionElementsRef: any
  pages: number
  originalPageSize: number
  children: any
  data: CollectionProductListingModelLegacy
}) => {
  const dispatch = useAppDispatch()
  const isLoaded = useAppSelector((state) => state.collectionElement.isLoaded)
  const products = useAppSelector((state) => state.collectionElement.products)
  const totalProducts = useAppSelector((state) => state.collectionElement.totalProducts)
  const infiniteScrollPageIndex = useAppSelector(
    (state) => state.collectionElement.infiniteScrollPageIndex
  )
  //loadedPageIndex keeps track of number of pages that have been loaded
  //but not necessarily the page the user is currently viewing
  const [loadedPageIndex, setLoadedPageIndex] = useState(1)
  const [isScrollingToBottom, setIsScrollingToBottom] = useState(false)
  const [isLoadingNextPage, setIsLoadingNextPage] = useState(false)
  const [containerHeight, setContainerHeight] = useState(0)
  const collectionElementsPageDOMRect = useRef<any>(null)
  const isInitialLoadProductsRef = useRef(true)
  const isInitialLoadScrollRef = useRef(true)
  //Map of page index to the Y value where that page starts
  const pageIndexesRef = useRef<any>({})
  const previousScrollY = useRef(0)

  useEffect(() => {
    if (isScrollingToBottom) {
      window.scrollTo(0, document.body.scrollHeight)
    }

    const onInfiniteScroll = () => {
      onScroll(isScrollingToBottom, isLoadingNextPage, infiniteScrollPageIndex, containerHeight)
    }

    window.addEventListener('scroll', onInfiniteScroll)

    return () => {
      window.removeEventListener('scroll', onInfiniteScroll)
    }
  }, [isScrollingToBottom, isLoadingNextPage, infiniteScrollPageIndex, products.length, containerHeight])

  useEffect(() => {
    function handleResize() {
      const singlePageHeight =
        collectionElementsPageDOMRect.current?.top -
        (document.querySelector(`.item-${pageSize}`) as HTMLElement)?.offsetTop
      const lastPageHeight =
        ((totalProducts % originalPageSize) / originalPageSize) *
        collectionElementsPageDOMRect.current?.height
      setContainerHeight(singlePageHeight * (pages - 1) + lastPageHeight)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    setIsLoadingNextPage(false)

    if (isInitialLoadScrollRef.current) {
      //Calculates the height of one page so that an estimate can be made for what the
      //length of the page might be when all the products are loaded so that the
      //scroll bar looks to scroll smoothly as more products are loaded in
      if (isLoaded && products.length >= pageSize) {
        dispatch(toggleLoading(true))
        collectionElementsPageDOMRect.current =
          collectionElementsRef.current?.getBoundingClientRect()
        pageIndexesRef.current[1] = collectionElementsPageDOMRect.current?.top
        isInitialLoadProductsRef.current = false
        setLoadedPageIndex(infiniteScrollPageIndex)
        const newPageSize = infiniteScrollPageIndex * originalPageSize
        dispatch(setPageSize(newPageSize))
        const lastPageHeight =
          ((totalProducts % originalPageSize) / originalPageSize) *
          collectionElementsPageDOMRect.current?.height
        setContainerHeight((currentHeight) => currentHeight > 0 ? currentHeight :
          collectionElementsPageDOMRect.current?.height * (pages - 1) + lastPageHeight
        )
      }

      //Scrolls to the first product on the page number given by the 'page' url query param.
      //This only needs to be called if requesting a page other than the first page shown by default.
      if (
        infiniteScrollPageIndex > 1 &&
        (products.length >= infiniteScrollPageIndex * originalPageSize ||
          (products.length > 0 && totalProducts > 0 && products.length === totalProducts))
      ) {
        const pageFirstItemIndex = (infiniteScrollPageIndex - 1) * originalPageSize + 1
        const itemIndex =
          pageFirstItemIndex > totalProducts ? totalProducts - 1 : pageFirstItemIndex
        document.querySelector(`.item-${itemIndex}`)?.scrollIntoView(true)
        dispatch(toggleLoading(false))
      } else if (infiniteScrollPageIndex === 1) {
        dispatch(toggleLoading(false))
      }
    }
  }, [products.length, isLoaded, infiniteScrollPageIndex])

  const onScroll = (
    isScrollingToBottom: boolean,
    isLoadingNextPage: boolean,
    infiniteScrollPageIndex: number,
    containerHeight: number
  ) => {
    if (!isScrollingToBottom && !isLoadingNextPage) {
      const newPageIndex = loadedPageIndex + 1
      const newPageSize = newPageIndex * originalPageSize
      const singlePageHeight = containerHeight / pages
      if (
        //Halfway through scrolling through the previous page, load the next set of products
        window.scrollY >= (singlePageHeight * (loadedPageIndex - 1) - (singlePageHeight / 2)) &&
        totalProducts > products.length &&
        newPageSize > products.length
      ) {
        setLoadedPageIndex(newPageIndex)
        dispatch(setPageSize(newPageSize))
        setIsLoadingNextPage(true)
      }

      if (!isInitialLoadScrollRef.current) {
        if (window.scrollY > previousScrollY.current) {
          //scrolling down - change page once user scrolls below the start of the next page
          const nextPageIndexTarget = Math.min(infiniteScrollPageIndex + 1, pages)
          let pageStartY = pageIndexesRef.current[nextPageIndexTarget]
          if (!Number.isNaN(pageStartY)) {
            const offsetTop = (
              document.querySelector(
                `.item-${infiniteScrollPageIndex * originalPageSize}`
              ) as HTMLElement
            )?.offsetTop
            pageIndexesRef.current[nextPageIndexTarget] = offsetTop
            pageStartY = offsetTop
          }
          if (window.scrollY > pageStartY) {
            dispatch(setInfiniteScrollPageIndex(Math.min(nextPageIndexTarget, pages)))
          }
        } else {
          //scrolling up - change page once user has scrolled above the start of current page
          const currentPageIndexTarget = Math.max(infiniteScrollPageIndex, 1)
          let pageStartY = pageIndexesRef.current[currentPageIndexTarget]
          if (!Number.isNaN(pageStartY)) {
            const offsetTop = (
              document.querySelector(
                `.item-${(currentPageIndexTarget - 1) * originalPageSize}`
              ) as HTMLElement
            )?.offsetTop
            pageIndexesRef.current[currentPageIndexTarget] = offsetTop
            pageStartY = offsetTop
          }
          if (window.scrollY < pageStartY) {
            dispatch(setInfiniteScrollPageIndex(Math.max(currentPageIndexTarget - 1, 1)))
          }
        }
        previousScrollY.current = window.scrollY
      }
      isInitialLoadScrollRef.current = false
    }

    if (isScrollingToBottom) {
      setTimeout(() => {
        setIsScrollingToBottom(false)
      }, 5000)
    }

    if (isInitialLoadScrollRef.current) {
      setTimeout(() => {
        isInitialLoadScrollRef.current = false
      }, 5000)
    }
  }

  return (
    <Container data={data} style={{ minHeight: `${containerHeight}px` }}>
      {children}
      <InfiniteScrollNavigation
        onScrollToBottom={() => {
          setContainerHeight(
            collectionElementsPageDOMRect.current?.height * infiniteScrollPageIndex
          )
          setIsScrollingToBottom(true)
        }}
      />
    </Container>
  )
}

const InfiniteScrollNavigation = ({ onScrollToBottom = () => {} }) => {
  const [isScrollNavigationVisible, setIsScrollNavigationVisible] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    window.addEventListener('scrollend', onScrollEnd)

    return () => {
      window.addEventListener('scroll', onScroll)
      window.addEventListener('scrollend', onScrollEnd)
    }
  }, [])

  const onScroll = () => {
    setIsScrollNavigationVisible(true)
  }

  const onScrollEnd = useCallback(
    debounce(() => {
      setIsScrollNavigationVisible(false)
    }, 3000),
    []
  )

  return (
    <>
      {isScrollNavigationVisible && (
        <ScrollEscapeNavigation className='infinite-scroll-escape-navigation'>
          <ScrollNavigationButton
            className='jump-to-top'
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' })
            }}
          >
            Jump to Top
          </ScrollNavigationButton>
          <ScrollNavigationButton className='jump-to-bottom' onClick={() => onScrollToBottom()}>
            Jump to Footer
          </ScrollNavigationButton>
        </ScrollEscapeNavigation>
      )}
    </>
  )
}

const ScrollEscapeNavigation = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  z-index: 1;
  right: 0;
  margin-bottom: 5px;
`

const ScrollNavigationButton = styled.div`
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  background-color: black;
  color: white;
  margin-left: 5px;
  cursor: pointer;
`
