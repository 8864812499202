import React, { useContext } from 'react'
import { LikeFilled, DislikeFilled } from '@ant-design/icons'
import WishListItemCountsModel from './models'
import styled from '@emotion/styled'
import { LayoutContext } from '../Layout'
import { useAppSelector } from '../../redux'

interface WishListItemCountsProps {
  data: WishListItemCountsModel
  containerProps: any
}

export default function WishListItemCounts({ data, containerProps }: WishListItemCountsProps) {
  let context = useContext(LayoutContext)
  const elementVotes = useAppSelector(
    (state) => state.wishlist.customer.session.elementVotes[context?.__id]
  )

  return (
    <WishListItemCountsWrapper className='wish-list-item-counts' {...containerProps}>
      {(typeof elementVotes?.votes?.totalWishedUp !== 'undefined'|| typeof context?.__aggs?.totalWishedUp !== 'undefined') && (
        <CountsLabelWrapper className='wish-list-item-counts-label up-vote-counts-label'>
          <LikeFilled className='vote-icon' color='#000' />
          <div className='vote-label'>
            {elementVotes?.votes?.totalWishedUp || context?.__aggs?.totalWishedUp}
          </div>
        </CountsLabelWrapper>
      )}
      {(typeof elementVotes?.votes?.totalWishedDown !== 'undefined' || typeof context?.__aggs?.totalWishedDown !== 'undefined') && (
        <CountsLabelWrapper className='wish-list-item-counts-label down-vote-counts-label'>
          <DislikeFilled className='vote-icon' color='#000' />
          <div className='vote-label'>
            {elementVotes?.votes?.totalWishedDown || context?.__aggs?.totalWishedDown}
          </div>
        </CountsLabelWrapper>
      )}
    </WishListItemCountsWrapper>
  )
}

const WishListItemCountsWrapper = styled.div`
  display: flex;
`

const CountsLabelWrapper = styled.div`
  display: flex;
  align-items: center;

  &.down-vote-counts-label {
    margin-left: 8px;
  }
  .vote-icon{
    font-size: 12px;
  }
  .vote-label {
    margin-left: 3px;
    font-size: 14px;
  }
`
