import React, { useCallback, useState } from 'react'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import styled from '@emotion/styled'
import { useAppDispatch, useAppSelector } from '../../redux'
import Button from '../Button'
import { notification, toggleLoading } from '../../redux/slices/app'
import ReCAPTCHA from 'react-google-recaptcha'

/**
 *
 * @TODO
 * - add customer to redux
 * -
 */

interface WishListShareFormProps {
  listId: string
  onFinish?: () => void
  onCancel?: () => void
}

const Form = styled.form`
  max-width: 400px;

  input,
  textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
  }

  span {
    display: inline-block;
    margin-bottom: 10px;
  }

  Button {
    margin-right: 5px;
  }
`

const recaptchaSiteKey: string = import.meta.env.VITE_RECAPTCHA_SITE_KEY as string

export default function WishListShareForm({ listId, onFinish, onCancel }: WishListShareFormProps) {
  const dispatch = useAppDispatch()
  const [errorMessage, setErrorMessage] = useState('')
  const [showForm, setShowForm] = useState(recaptchaSiteKey ? false : true)
  const [captcha, setCaptcha] = useState('')
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()

  const onSubmit = handleSubmit(async (data) => {
    try {
      dispatch(toggleLoading(true))

      await axios.post(`/customers/wishlist/${listId}/share`, {
        toEmail: data.toEmail,
        message: data.message,
        captcha
      })

      onFinish?.()
      dispatch(notification({ type: 'success', title: 'List shared successfully'}))
    } catch (e: any) {
      const errorData = e.response?.data as any
      setErrorMessage(errorData.message)
    } finally {
      dispatch(toggleLoading(false))      
    }
  })

  const onChange = (token: string | null) => {
    setCaptcha(token?.toString() ?? '')
    setShowForm(true)
  }

  const handleExpire = () => {
    setShowForm(false)
  }

  return (
    <Form onSubmit={onSubmit}>
      <span>Share your list with a friend</span>
      {errorMessage && <div className='errorMessage'>{errorMessage}</div>}
      {showForm && (
        <>
          <div>
            {errors?.toEmail?.message && <span className='errorMessage'>{errors?.toEmail?.message.toString()}</span>}
            <input
              type='text'
              placeholder='Send to email'
              autoComplete='off'
              {...register('toEmail', {
                required: { value: true, message: 'To Email is required' },
                pattern: { value: /^\S+@\S+$/i, message: 'Please enter a valid email' }
              })}
            />
          </div>
          <div>
            <textarea
              placeholder='Check out my wish list'
              {...register('message')}></textarea>
          </div>
          <div>
            <Button type='primary' htmlType='submit'>Send</Button>
            <Button type='link' onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </>
      )}
      {recaptchaSiteKey && (
        <ReCAPTCHA 
          sitekey={recaptchaSiteKey}
          onChange={onChange}
          onExpired={handleExpire}
        />
      )}
    </Form>
  )
}
