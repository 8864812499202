import cuid from 'cuid'
import cookie from 'js-cookie'
import axios from 'axios'

const COOKIE_NAME = 'nogin_session'
const COOKIE_EXP = 1/48 // 30 minutes

export default class NoginSession {
  private _sessionId: string
  private _isNewSession: boolean

  constructor() {
    //get cookie
    this._sessionId = cookie.get(COOKIE_NAME) as string
    this._isNewSession = false
    //if no sessionId value, set it
    if (!this._sessionId) {
      this.setSession()
      this._isNewSession = true
    }
    //update cart with session id, if necessary
    this.forceCartUpdate()
  }

  public get sessionId(): string {
    //extend session 30 minutes
    cookie.set(COOKIE_NAME, this._sessionId, { expires: COOKIE_EXP })
    return this._sessionId
  }

  public get isNewSession(): boolean {
    return this._isNewSession
  }

  forceCartUpdate(): void {
    const cookieName = 'nogin_session_update'
    const timerCookie = cookie.get(cookieName) as string
    //if no timer cookie...
    if (!timerCookie) {
      //update cart
      this.cartUpdate()
      //create 5 minute timer cookie
      const fiveMinutes = new Date(new Date().getTime() + 5 * 60 * 1000)
      cookie.set(cookieName, this._sessionId, { expires: fiveMinutes })
    }
  }

  setSession(): void {
    this._sessionId = cuid()
    cookie.set(COOKIE_NAME, this._sessionId, { expires: COOKIE_EXP })
  }

  cartUpdate(): void {
    const sesId = this._sessionId
    const ret = (async () => {
      async function run() {
        try {
          const axiosClient = axios.create({
            baseURL: '',
            params: null,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          })
          const data: string = `attributes%5Bnogin_sid%5D=${sesId}`
          await axiosClient.post(`/cart/update.js`, data)

        } catch (e) {
          console.log(`error updating cart attributes with session: `, e)
        }
      }
      await run()
    })()
  }
}
