import React, { useCallback, useState } from 'react'
import { FiMoreVertical, FiTrash, FiSettings, FiPlus, FiMail } from 'react-icons/fi'
import SortableList, { SortableItem, SortableKnob } from 'react-easy-sort'
import styled from '@emotion/styled'
import { useAppDispatch, useAppSelector } from '../../redux'
import {
  changeCustomerWishListPosition,
  deleteCustomerWishList,
  getCustomerWishListItemsForList,
  getCustomerWishLists,
  updateSortBy
} from '../../redux/slices/wishlist'
import WishListForm from '../WishListForm'
import Button from '../Button'
import Select from '../Select'
import WishListShareForm from '../WishListShareForm'
import CustomerConnectForm from '../CustomerConnectForm'
import { WishListCustomerListPayload } from '../../types'

/**
 * Styled Components
 */
interface WishListHeaderProps {
  borderColor?: string
}

const WishListHeader = styled.header<WishListHeaderProps>`
  margin-bottom: 20px;
  text-align: left;
  position: relative;

  > div {
  }

  .line {
    position: absolute;
    border-bottom: ${(props) => `1px solid ${props.borderColor}`};
    bottom: 0px;
    width: 100%;
    height: 1px;
    z-index: 0;
  }
`

interface WishListHeaderItemProps {
  active?: boolean,
  color?: string,
  backgroundColor?: string
}

const WishListHeaderItem = styled.div<WishListHeaderItemProps>`
  cursor: pointer;
  position: relative;
  flex-shrink: 0;
  display: flex-shrink;
  margin: 8;
  user-select: none;
  margin-right: 10px;
  color: ${(props) => (props.active ? props.backgroundColor : props.color)};
  background-color: ${(props) => (props.active ? props.color : props.backgroundColor)};
  border: ${(props) => `1px solid ${props.backgroundColor}`};
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom: 0px;
  text-align: center;
  align-items: center;

  > div {
    display: flex;
    padding: 15px;
  }

  .spacer {
    width: 20px;
  }
`
const WishListSubMenu = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  gap: 5px;
`

type FormType = 'none' | 'edit' | 'share'

interface WishListManagerHeaderProps {
  lists: WishListCustomerListPayload[]
  editable?: boolean
  onWishListChanged?: () => void
}

/**
 * Renders Wish List Manager Header
 *
 * @param lists
 * @returns
 */
export default function WishListManagerHeader({
  lists,
  editable = true,
  onWishListChanged
}: WishListManagerHeaderProps) {
  const dispatch = useAppDispatch()
  const [formType, setFormType] = useState<FormType>('none')
  const [formEdit, setFormEdit] = useState(false)
  const currentListId = useAppSelector((state) => state.wishlist.customer.currentListId)
  const listSortBy = useAppSelector((state) => state.wishlist.customer.listSortBy)
  const customer = useAppSelector((state) => state.app.customer)
  const managerSettings = useAppSelector((state) => state.wishlist.settings.manager)
  const loading = useAppSelector(state => state.app.loading)

  // toggle list tab
  const handleListNameClick = useCallback(
    (list: WishListCustomerListPayload) => {
      dispatch(getCustomerWishListItemsForList({ listId: list.id, pageIndex: 1 }))
    },
    [lists]
  )

  const handleDeleteClick = useCallback(
    async (e: React.KeyboardEvent<HTMLButtonElement> | React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => {
      e?.preventDefault()
      if (window.confirm('Are you sure you want to delete this?')) {
        dispatch(
          deleteCustomerWishList({
            id: currentListId as string
          })
        )
      }
    },
    [currentListId]
  )

  const onClickCreateNewList = () => {
    setFormEdit(false)
    setFormType('edit')
  }

  return (
    <>
      <WishListHeader
        borderColor={
          managerSettings.allowMultipleLists && editable
            ? managerSettings.tabBackgroundColor
            : 'transparent'
        }>
        <div className='line'></div>
        <div>
          {(!managerSettings.allowMultipleLists || !editable) && lists.length > 0 && lists[0]?.id && (
            <h2>{lists[0].name}</h2>
          )}
          {managerSettings.allowMultipleLists && editable && (
            <SortableList
              onSortEnd={(oldIndex, newIndex) => {
                dispatch(changeCustomerWishListPosition({ oldIndex, newIndex }))
              }}
              className='wishListHeader'
              draggedItemClassName='dragged'>
              {lists.map((list) => (
                <SortableItem key={list.id}>
                  <WishListHeaderItem
                    active={list.id === currentListId}
                    aria-label={`Switch to ${list.name}`}
                    backgroundColor={managerSettings.tabBackgroundColor}
                    color={managerSettings.tabForegroundColor}
                    key={list.id}
                    onClick={() => handleListNameClick(list)}
                    onKeyUp={(e) => e.code === "Enter" ? handleListNameClick(list) : null }>
                    <div>
                      <div>
                        {list.name} {list.itemCount > 0 && `(${list.itemCount})`}
                      </div>
                      <div className='spacer'></div>
                      <SortableKnob>
                        <div>
                          <FiMoreVertical className='dragged' />
                        </div>
                      </SortableKnob>
                    </div>
                  </WishListHeaderItem>
                </SortableItem>
              ))}
              <a
                href='#'
                onClick={onClickCreateNewList}
                onKeyUp={(e) => e.code === "Enter" ? onClickCreateNewList() : null }
                aria-label={`Create new list`}
                key='new-list'
                style={{}}>
                <WishListHeaderItem
                  backgroundColor={managerSettings.tabBackgroundColor}
                  color={managerSettings.tabForegroundColor}>
                  <div>
                    <div style={{ visibility: 'hidden' }}>&nbsp;</div>
                    <FiPlus />
                    <div style={{ visibility: 'hidden' }}>&nbsp;</div>
                  </div>
                </WishListHeaderItem>
              </a>
            </SortableList>
          )}
        </div>
      </WishListHeader>
      {formType === 'edit' && (
        <WishListForm
          updateId={formEdit ? currentListId : undefined}
          onSuccess={(id) => {
            onWishListChanged?.()
            setFormType('none')
            dispatch(getCustomerWishListItemsForList({ listId: id, pageIndex: 1 }))
          }}
          onCancel={() => setFormType('none')}
        />
      )}
      {formType === 'share' && currentListId && customer?.isRegistered && (
        <WishListShareForm
          listId={currentListId}
          onFinish={() => setFormType('none')}
          onCancel={() => setFormType('none')}
        />
      )}
      {formType === 'share' && currentListId && !customer?.isRegistered && (
        <CustomerConnectForm
          message='Please login to share your list'
          returnUrl={managerSettings.pagePath || '/pages/wishlist'}
          onCancel={() => setFormType('none')}
        />
      )}
      {lists.length > 0 && lists[0]?.id && (
        <WishListSubMenu>
          {editable && (
            <>
              {managerSettings.allowSharingEmail && (
                <Button
                  ariaLabel='Email'
                  type='link'
                  onClick={() => {
                    setFormType('share')
                  }}>
                  <FiMail style={{ fontSize: 20 }} />
                </Button>
              )}
              {managerSettings.allowSharingEmail && (
                <span style={{ marginTop: 10, opacity: 0.3 }}>|</span>
              )}
              {/* <Button type='link'>
            <FiSquare style={{ fontSize: 20 }} />
          </Button> */}
              {managerSettings.allowMultipleLists && (
                <Button type='link' onClick={handleDeleteClick} ariaLabel='Delete'>
                  <FiTrash style={{ fontSize: 20 }} />
                </Button>
              )}
              <Button
                type='link'
                onClick={() => {
                  setFormType('edit')
                  setFormEdit(true)
                }}
                ariaLabel='Edit'>
                <FiSettings style={{ fontSize: 20 }} />
              </Button>
            </>
          )}
          <div style={{ flex: 1 }}></div>
          <Select
            ghost={true}
            value={listSortBy}
            onChange={(e) => {
              dispatch(updateSortBy(e.target.value))
            }}>
            <option value='created-desc'>Newest</option>
            <option value='created'>Oldest</option>
            <option value='name-asc'>Alphabetical (A-Z)</option>
            <option value='name-desc'>Alphabetical (Z-A)</option>
            <option value='stock-asc'>Stock (Lowest - Highest)</option>
            <option value='stock-desc'>Stock (Highest - Lowest)</option>
            <option value='price-asc'>Price (Lowest - Highest)</option>
            <option value='price-desc'>Price (Highest - Lowest)</option>
          </Select>
        </WishListSubMenu>
      )}
    </>
  )
}
