import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from "../../redux"
import { IntlPrices } from '../../types'
import { getInternationalPrices } from '../../lib/helpers'
import { setIntlPrices } from '../../redux/slices/collection-element'

interface IntlProps {
  items: any[]
}

/*
 * this function component gets international prices and saves them to redux state
*/
function IntlPricesLoader({ items }: IntlProps) {
  const dispatch = useAppDispatch()
  const iso = useAppSelector((state) => state.app.config.currency?.isoCountryCode) || ''
  const shopUrl = useAppSelector((state) => state.app.config.shopify?.storefront?.shopUrl) || ''
  const storeToken = useAppSelector((state) => state.app.config.shopify?.storefront?.token) || ''

  useEffect(() => {
    //ProductCard expects intlPrices array to be in redux state.collection.intlPrices
    async function load() {
      try {
        //get product ids for every product (to send to graphql)
        const gidPids: string[] = items?.map((item: any) => `gid://shopify/Product/${item?.sourceId}` )
        //eliminate duplicate product id's from results, if any
        let uniqueGidPids: Set<string> = new Set(gidPids)
        //add unqiue product id's for every variant (family)
        items?.forEach((item: any) => {
          item?.family?.map((item: any) => {
            uniqueGidPids.add(`gid://shopify/Product/${item.sourceId}`)
          })
        })
        
        //attach international prices for all unqiue product id's to results
        const resp: IntlPrices[] = await getInternationalPrices(Array.from(uniqueGidPids), iso, storeToken, shopUrl)
        dispatch(setIntlPrices(resp))
      } catch (e) {
        console.log(e)
      }
    }
    if (iso && iso != 'US' && items && items.length > 0)  {
      load()
    }
  }, [items])

  return (
    <></>
  )
}

IntlPricesLoader.defaultProps = {
  onPageChange: () => {}
}

export default IntlPricesLoader