import React, { useEffect, useState } from 'react'
import ColorPicker from '../ColorPicker'
import ListPicker from '../ListPicker'
import SortPicker from '../SortPicker'
import AnimateHeight from 'react-animate-height'
import classNames from 'classnames'

function Picker(props: any) {
  const defaultClosed = props.settings.defaultClosed || 'off'

  const [isPickerOpen, setPickerOpen] = useState(defaultClosed === 'off' ? true : false)
  const [currentSelection, setCurrentSelection] = useState(props.defaultSelected)
  const [isVisible, setVisible] = useState(true)

  // handle auto collapse initial load
  useEffect(() => {
    if (defaultClosed === 'off') {
      setPickerOpen(true)
    } else if (defaultClosed === 'always') {
      setPickerOpen(false)
    } else {
      // check for mobile size
      if (window.innerWidth < 768) {
        setPickerOpen(false)
      } else {
        setPickerOpen(true)
      }
    }
  }, [props.settings.defaultClosed])

  useEffect(() => {
    setCurrentSelection(props.defaultSelected)
  }, [props.defaultSelected])

  let PickerComponent
  switch (props.displayType) {
    case 'color-picker':
      PickerComponent = ColorPicker
      break
    case 'sort-list-picker':
    case 'sort-picker':
      PickerComponent = SortPicker
      break
    default:
      PickerComponent = ListPicker
  }

  function onPicked(selected: any) {
    setCurrentSelection(selected)
    props.onPicked(selected)
  }

  const maxHeight = props.settings.maxHeight || 'auto'

  const onClickPickerTitle = (
    e?: React.MouseEvent<HTMLAnchorElement, MouseEvent> | React.KeyboardEvent<HTMLAnchorElement>
  ) => {
    e?.preventDefault()
    setPickerOpen(!isPickerOpen)
  }

  return (
    <div
      className={classNames(
        `picker-container ${props.displayType}-outer-container`,
        props.settings.class
      )}
      style={{
        display: !isVisible ? 'none' : undefined
      }}>
      {!props.settings.hideTitle && (
        <a
          className='picker-title'
          onClick={onClickPickerTitle}
          onKeyUp={(e) => (e.code === 'Enter' ? onClickPickerTitle(e) : null)}>
          {currentSelection && Array.isArray(currentSelection) && currentSelection.length > 0 && (
            <span className='active-count'>{currentSelection.length}</span>
          )}
          {props.name} <PickerIcon isOpen={isPickerOpen} />
        </a>
      )}
      <AnimateHeight duration={250} height={isPickerOpen ? 'auto' : 0}>
        <div style={{ overflowY: 'auto', maxHeight, overflowX: 'hidden' }}>
          <PickerComponent {...{ ...props, onPicked }} setPickerVisibility={setVisible} />
        </div>
      </AnimateHeight>
    </div>
  )
}

interface PickerIconProps {
  isOpen: boolean
}

function PickerIcon({ isOpen }: PickerIconProps) {
  return <span className='picker-title-icon'>{isOpen ? '-' : '+'}</span>
}

export default Picker
