import React from 'react'
import styled from '@emotion/styled'

export default function ChatProductCard({ product, mobileBreakpoint }: any) {
  return (
    <ProductCardWrapper className='chat-message-product'>
      <ProductCardContentWrapper>
        <ProductCardFamilyName>{product.nameFragments.family}</ProductCardFamilyName>
        {product.nameFragments.style && (
          <ProductCardStyleTypeName>
            {product.nameFragments.style}
            {product.nameFragments.type && <span> • {product.nameFragments.type}</span>}
          </ProductCardStyleTypeName>
        )}
        <ProductCardColorName>{product.nameFragments.color}</ProductCardColorName>
        <ProductCardPrice>${product.prices.final}</ProductCardPrice>
      </ProductCardContentWrapper>
      {/* <ProductCardImage
        src={`${product.thumbnail}`}
        mobileBreakpoint={mobileBreakpoint}
      /> */}
    </ProductCardWrapper>
  )
}

const ProductCardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #f7f6f6;
  padding: 5px;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgb(50 50 50 / 15%);
  font-size: 85%;
  align-items: center;
  margin-bottom: 5px;
`

const ProductCardContentWrapper = styled.div`
  padding: 0 5px;
`

const ProductCardFamilyName = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.3px;
`

const ProductCardStyleTypeName = styled.div`
  font-size: 95%;
`

const ProductCardColorName = styled.div`
  font-size: 95%;
`

const ProductCardPrice = styled.div`
  font-weight: bold;
  font-size: 95%;
`

const ProductCardImage = styled.img<{ mobileBreakpoint: string }>`
  width: 30%;
  ${({ mobileBreakpoint }) => `
   @media (max-width: ${mobileBreakpoint}){
    width: 20%;
   }
  `}
`
