import React from 'react'
import { LoaderAppData } from '@nogin/api/src/appdata/appearance'
import styles from '@emotion/styled'

export interface LoadingProps {
  settings?: LoaderAppData
  loading?: boolean
}

export default function Loading({ settings, loading = false }: LoadingProps) {
  if (loading) {
    return (
      <div
        style={{
          margin: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          color: settings?.foregroundColor
        }}>
        <div
          style={{
            margin: 'auto',
            display: 'flex',
            alignItems: 'center',
            justifyItems: 'center',
            backgroundColor: settings?.backgroundColor,
            borderRadius: settings?.borderRadius,
            height: settings?.height,
            width: settings?.width,
            paddingLeft: settings?.paddingHorizontal,
            paddingRight: settings?.paddingHorizontal,
            paddingTop: settings?.paddingVertical,
            paddingBottom: settings?.paddingVertical,
            borderWidth: settings?.borderWidth,
            borderColor: settings?.borderColor,
            boxShadow: (settings?.shadow && '0px 0px 10px #D0D0D0') || ''
          }}
          className={`animate-${settings?.animation}`}>
          {settings?.icon === 'type1' && <LoaderType1 fill={settings?.foregroundColor as string} />}
          {settings?.icon === 'custom' && (
            <CustomIcon
              svg={settings?.customIcon as string}
              fill={settings?.foregroundColor as string}
            />
          )}
        </div>
        {settings?.label}
      </div>
    )
  } else {
    return <></>
  }
}

const SVG = styles.div`
svg {
  width: 100%;
}
`

function CustomIcon({ svg, fill }: { svg: string; fill: string }) {
  return (
    <SVG
      dangerouslySetInnerHTML={{
        __html: svg
      }}
      style={{ fill }}></SVG>
  )
}

export function LoaderType1({ fill }: { fill: string }) {
  return (
    <svg
      version='1.1'
      id='loader-1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 40 40'
      style={{ fill }}>
      <path
        opacity='0.2'
        d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'
      />
      <path
        d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
C22.32,8.481,24.301,9.057,26.013,10.047z'></path>
    </svg>
  )
}
