import React, { CSSProperties, ReactNode, useMemo } from 'react'
import styled from '@emotion/styled'

/**
 * Typescript Props
 */

type ButtonSize = 'xs' | 'sm' | 'normal' | 'lg' | 'xl'

export interface ButtonProps {
  type: 'primary' | 'secondary' | 'link'
  htmlType?: 'button' | 'submit' | 'reset'
  onMouseOver?: (
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent<HTMLButtonElement>
  ) => void 
  onClick?: (
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent<HTMLButtonElement>
  ) => void
  children: ReactNode
  disabled?: boolean
  style?: CSSProperties
  size?: ButtonSize
  className?: string
  ariaLabel?: string
}

interface ButtonSizeProps {
  padding: number
  fontSize: number
  lineHeight: number
}

interface ButtonContainerProps {
  buttonType: string
  disabled?: boolean
  sizeProps: ButtonSizeProps
}

/**
 * Styled Components
 */
const ButtonContainer = styled.button<ButtonContainerProps>`
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  padding: ${(props) => `${props.sizeProps.padding}px`};
  font-size: ${(props) => `${props.sizeProps.fontSize}px`};
  line-height: ${(props) => `${props.sizeProps.lineHeight}px`};
  background-color: white;
  border-radius: 3px;
  opacity: ${(props) => (props.disabled ? 0.3 : 1.0)};
  border: 1px solid
    ${(props) =>
      props.buttonType !== 'link' ? (props.disabled ? '#e0e0e0' : '#333') : 'transparent'};

  &:enabled:hover {
    background-color: black;
    color: white;
  }
`

export default function Button({
  type,
  onMouseOver,
  onClick,
  children,
  disabled,
  style,
  className,
  htmlType = 'button',
  size = 'normal',
  ariaLabel
}: ButtonProps) {
  const buttonSizeProps = useMemo(() => {
    const defaults = {
      padding: 10,
      fontSize: 15,
      lineHeight: 15
    }

    function resize(percentage: number) {
      return {
        padding: Math.floor(defaults.padding * percentage),
        fontSize: Math.floor(defaults.fontSize * percentage),
        lineHeight: defaults.lineHeight * percentage
      }
    }

    switch (size) {
      case 'xs':
        return resize(0.7)
      case 'sm':
        return resize(0.85)
      case 'lg':
        return resize(1.15)
      case 'xl':
        return resize(1.3)
      default:
        return defaults
    }
  }, [size])

  return (
    <ButtonContainer
      className={className}
      onMouseOver={(e: any) => {
        !disabled && onMouseOver?.(e)
      }}
      onClick={(e: any) => {
        !disabled && onClick?.(e)
      }}
      onKeyUp={(e) => e.code === "Enter" && !disabled && onClick?.(e)}
      disabled={disabled}
      style={style}
      type={htmlType}
      sizeProps={buttonSizeProps}
      buttonType={type}
      aria-label={ariaLabel}
    >
      {children}
    </ButtonContainer>
  )
}
