import React from 'react'
import styled from '@emotion/styled'
import classNames from 'classnames'
import { spinalcase } from 'stringcase'

const defaultMarginBottom = '10px'

export enum SwatchParentComponent {
  PRODUCT_FAMILY = 'productFamily',
  PRODUCT_BASIC = 'productBasic'
}

enum PickerStyle {
  group = 'swatchGroup',
  count = 'swatchCount',
  standard = 'standard'
}
interface SwatchItemProps {
  option: any
  getImageSwatch: (images: any[] | any) => string
  selectedOption: any
  useMouseOverChange?: boolean
  setCurrentOption: React.Dispatch<any>
  swatchStyle: string
  isMouseHovering?: boolean
  mobileBreakpoint?: string
  isHidden?: boolean
  isShowingAllSwatches: boolean
  isDesktop: boolean
  swatchParentComponent: SwatchParentComponent
  hideSwatch: boolean
}

const SwatchItem = ({
  option,
  useMouseOverChange,
  setCurrentOption,
  mobileBreakpoint,
  isDesktop,
  selectedOption,
  swatchParentComponent = SwatchParentComponent.PRODUCT_FAMILY,
  hideSwatch,
  ...props
}: SwatchItemProps) => {
  return (
    <StyledSwatchItem
      className={classNames(
        'swatch',
        swatchParentComponent === SwatchParentComponent.PRODUCT_BASIC && [`${spinalcase(option)}`],
        swatchParentComponent === SwatchParentComponent.PRODUCT_FAMILY
          ? { active: option.id === selectedOption.id }
          : { active: option === selectedOption }
      )}
      option={option}
      mobileBreakpoint={mobileBreakpoint}
      onMouseEnter={() => {
        setCurrentOption((optionState: any) => {
          if (isDesktop && useMouseOverChange) {
            return option
          }
          return optionState
        })
      }}
      onClick={() => {
        setCurrentOption(option)
      }}
      onKeyUp={(e) => (e.code === 'Enter' ? setCurrentOption(option) : null)}
      selectedOption={selectedOption}
      swatchParentComponent={swatchParentComponent}
      hideSwatch={hideSwatch}
      {...props}
    />
  )
}

export default SwatchItem

interface SwatchItemStyleProps {
  option: any
  getImageSwatch: (images: any[]) => string
  selectedOption: any
  swatchStyle: string
  isMouseHovering?: boolean
  mobileBreakpoint?: string
  isHidden?: boolean
  isShowingAllSwatches: boolean
  swatchParentComponent: SwatchParentComponent
  hideSwatch: boolean
}

const StyledSwatchItem = styled.div<SwatchItemStyleProps>`
  display: ${({ hideSwatch }) => {
      return hideSwatch ? `none` : `block` 
    }};
  background-image: url(${({ swatchParentComponent, getImageSwatch, option }) => {
    return swatchParentComponent === SwatchParentComponent.PRODUCT_FAMILY
      ? getImageSwatch(option.images)
      : getImageSwatch(option)
  }});
  background-color: #f0f0f0;
  border-style: solid;
  border-color: ${({ swatchParentComponent, option, selectedOption }) => {
    return swatchParentComponent === SwatchParentComponent.PRODUCT_FAMILY
      ? option.id === selectedOption.id
        ? 'black'
        : 'white'
      : option === selectedOption
      ? 'black'
      : 'white'
  }};
  border-width: 2px;
  height: 20px;
  width: 20px;
  border-radius: 30px;
  margin-right: 10px;
  margin-bottom: ${defaultMarginBottom};
  cursor: pointer;
  ${({ swatchStyle, isMouseHovering, mobileBreakpoint }) =>
    swatchStyle === PickerStyle.group &&
    `
      transition: margin 150ms linear;
      @media (min-width: ${mobileBreakpoint}) {
        margin-right: -0.75em;
        border-width: 1px;
        ${isMouseHovering && `margin-right: 0.25em;`}
      }
  `}
  ${({ swatchStyle, isHidden, isMouseHovering, mobileBreakpoint, isShowingAllSwatches }) =>
    swatchStyle === PickerStyle.count &&
    `
    margin-bottom: 0;
    ${isHidden && `display: none;`}
    @media (max-width: ${mobileBreakpoint}) {
      ${isShowingAllSwatches && `margin-bottom: ${defaultMarginBottom};`}
    }
  `}
`

export const StyledSwatchContainer = styled.div`
  position: relative;
`

export const StyledOutOfStockStrikeThrough = styled.div<{
  isVisible: boolean
  isSelected: boolean
}>`
  display: none;
  ${({ isVisible }) => isVisible && `display: block`};
  position: absolute;
  ${({ isSelected }) => (isSelected ? `left: 0px` : `left: 0px`)};
  ${({ isSelected }) => (isSelected ? `height: 28px` : `height: 24px`)};
  ${({ isSelected }) => (isSelected ? `top: 50%` : `top: 50%`)};
  transform: rotate(45deg) translate(-65%, -65%);
  background-color: rgb(51, 51, 51);
  width: 1.5px;
  box-shadow: 1px 1px 1px #e0e0e0;
`
export const StyledSwatchListContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
