import React, { useCallback, useEffect, useState, useContext } from 'react'
import AnimateHeight from 'react-animate-height'
import styled from '@emotion/styled'
import CategoryTreeItemModel from './models'
import { Elements } from '..'
import { FiChevronDown, FiChevronRight } from 'react-icons/fi'
import { CategoryTreeContext } from '../CategoryTree'

interface Props {
  data: CategoryTreeItemModel
  containerProps?: string[]
}

const Container = styled.ul<{ data: CategoryTreeItemModel }>`
  list-style-type: none;
  margin: 0;
  padding: 0;

  > li {
    > a {
      display: flex;
      text-decoration: none;
      align-items: center;

      > span {
        display: flex;
        visibility: ${(props) => (props.data.children.length > 0 ? 'visible' : 'hidden')};
      }
    }
  }
`

function CategoryTreeItem({ data, containerProps }: Props) {
  const context = useContext(CategoryTreeContext)
  const [isOpen, setOpen] = useState(false)

  useEffect(() => {
    // if null or not an array, don't do anything
    if(!context?.currentTreePath || !Array.isArray(context.currentTreePath) ) return
    
    if(context.currentTreePath?.includes(data?.__elementId)){
      setOpen(true)
    }
  }, [context.currentTreePath])

  const itemClickHandler = useCallback(() => {
    setOpen(!isOpen)
  }, [isOpen])

  return (
    <Container data={data} {...containerProps}>
      <li>
        <a
          href='#'
          className='item-title'
          title={data.title}
          aria-label={`${isOpen ? 'Close' : 'Open'} ${data.title} Category`}
          onClick={itemClickHandler}>
          <span>{isOpen ? <FiChevronDown /> : <FiChevronRight />}</span>
          {data.title}
        </a>
        <div className='child-container'>
          <AnimateHeight duration={250} height={isOpen ? 'auto' : 0}>
            <Elements data={data} field='children' />
          </AnimateHeight>
        </div>
      </li>
    </Container>
  )
}

export default CategoryTreeItem
