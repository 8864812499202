import React, { useEffect, useState, useContext, useRef } from 'react'
import ChatMessage from './components/ChatMessage'
import styled from '@emotion/styled'
import GuestUserInput from './components/GuestUserInput'
import { StreamChatClientContext, loadStreamChatLibrary } from '../../StreamChatContext'
import { loadAllMessagesForReplay } from '../../../../lib/event/helpers'
let Chat: any = () => null,
  Channel: any = () => null,
  MessageList: any = () => null,
  MessageInput: any = () => null,
  Window: any = () => null,
  Thread: any = () => null,
  useMessageContext: any = () => null,
  useBreakpoint: any = () => null

interface EventChatProps {
  chatChannelId: string
  isReplay: boolean
  videoDuration: number
  mobileBreakpoint: string
}

export default function EventChat({
  chatChannelId,
  isReplay,
  videoDuration,
  mobileBreakpoint
}: EventChatProps) {
  const { client, setChatGuestUsername, isClientReady, initStreamChat, isLibraryLoaded } =
    useContext(StreamChatClientContext)
  const [channel, setChannel] = useState<any>({})
  const [isGuestUserInputVisible, setIsGuestUserInputVisible] = useState(false)
  const [replayMessages, setReplayMessages] = useState<any[]>([])
  const [chatStartTime, setChatStartTime] = useState(0)
  const channelMessages = useRef<any[]>([])

  useEffect(() => {
    initStreamChat(({ StreamChatReact }: any) => {
      ;({
        Chat,
        Channel,
        MessageList,
        MessageInput,
        Window,
        Thread,
        useMessageContext,
        useBreakpoint
      } = StreamChatReact)
    })
  }, [])

  useEffect(() => {
    const queryChannels = async () => {
      if (isClientReady) {
        try {
          const filter = {
            cid: {
              $in: [`livestream:${chatChannelId}`]
            }
          }
          const channelQuery = (await client?.queryChannels(filter)) || []
          const currentChannel = channelQuery[0]
          if (isReplay) {
            await loadAllMessagesForReplay(channelQuery)
            setChatStartTime(currentChannel?.state?.messages[0].created_at.getTime())
            channelMessages.current =
              channelMessages.current.length > 0
                ? channelMessages.current
                : currentChannel?.state?.messages
            currentChannel.on('message.new', addNewReplayMessage)
          }
          setChannel(currentChannel)
        } catch (err) {
          console.log(err)
        }
      } else {
        /*
         * Removes channel data so that getstream can reconnect to the channel with a new user
         */
        setChannel({})
      }
    }
    if (isLibraryLoaded) {
      queryChannels()
    }
    return () => {
      if (isClientReady) {
        channel?.off && channel?.off('message.new', addNewReplayMessage)
      }
    }
  }, [isClientReady, client?.userID])

  useEffect(() => {
    setReplayMessages(() => {
      return (
        channelMessages.current.filter((message: any) => {
          const messageTime =
            typeof message.created_at === 'string'
              ? new Date(message.created_at).getTime()
              : message.created_at?.getTime()
          return chatStartTime + videoDuration * 1000 > messageTime || message.isSentDuringReplay
        }) || []
      )
    })
  }, [videoDuration])

  const addNewReplayMessage = (event: any) => {
    if (isReplay) {
      const lastMessageId = replayMessages[replayMessages.length - 1]?.id
      const lastMessageIndex = channelMessages.current.findIndex(({ id }) => id === lastMessageId)
      //Adding new created_at date so that the messages are unique in the list
      const newMessage = { ...event.message, created_at: new Date(), isSentDuringReplay: true }
      //Add new message after last message that has been shown in replay to the channel messages list
      channelMessages.current.splice(lastMessageIndex + 1, 0, newMessage)
      setReplayMessages((replayMessagesState) => [...replayMessagesState, newMessage])
    }
  }

  const setGuestUser = async (name: string) => {
    await setChatGuestUsername(name)
    setIsGuestUserInputVisible(false)
  }

  const onClickMessageInput = () => {
    if (client.user.role === 'anonymous') {
      setIsGuestUserInputVisible(true)
    }
  }

  return (
    <EventChatWrapper mobileBreakpoint={mobileBreakpoint}>
      {
        //Show loading skeleton if client is not ready or channel messages are loading
        !isClientReady || (isReplay && channelMessages.current.length === 0) ? (
          <div className='messaging str-chat'>
            <CustomHeader />
            <ListSkeleton>
              {[...Array(4)].map((item, index) => (
                <Skeleton key={index} />
              ))}
            </ListSkeleton>
          </div>
        ) : (
          <>
            {isGuestUserInputVisible && (
              <GuestUserInput
                onClose={() => setIsGuestUserInputVisible(false)}
                onSubmit={(name) => setGuestUser(name)}
                mobileBreakpoint={mobileBreakpoint}
              />
            )}
            <Chat client={client}>
              <Channel
                channel={channel}
                EmojiIcon={() => null}
                FileUploadIcon={() => null}
                acceptedFiles={[]}
                Message={() => (
                  <ChatMessage
                    mobileBreakpoint={mobileBreakpoint}
                    useMessageContext={useMessageContext}
                    useBreakpoint={useBreakpoint}
                  />
                )}>
                <Window>
                  <CustomHeader />
                  <MessageList
                    hideDeletedMessages={true}
                    messages={isReplay ? replayMessages : undefined}
                  />
                  <div
                    onClick={onClickMessageInput}
                    onKeyUp={(e) => (e.code === 'Enter' ? onClickMessageInput() : null)}>
                    <MessageInput grow={true} />
                  </div>
                </Window>
                <Thread />
              </Channel>
            </Chat>
          </>
        )
      }
    </EventChatWrapper>
  )
}

const CustomHeader = () => {
  return (
    <div className='str-chat__header-livestream'>
      <h3>Chat</h3>
    </div>
  )
}

const Skeleton = () => {
  return (
    <SkeletonWrapper>
      <div className='skeleton-header'>
        <span className='avatar'></span>
      </div>
      <div className='skeleton-content'>
        <div className='skeleton-title'></div>
        <ul className='skeleton-paragraph'>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
    </SkeletonWrapper>
  )
}

const textareaBorderRadius = '28px'

const EventChatWrapper = styled.div<{ mobileBreakpoint: string }>`
  height: 100%;

  .str-chat-channel {
    max-height: 100%;
    &.messaging .str-chat__main-panel {
      padding: 0;
    }
  }

  .messaging {
    &.str-chat {
      background-color: transparent;

      .str-chat__header-livestream {
        border-radius: 0;
        padding-left: 20px;
        min-height: 20px;
        padding-top: 0;
        box-shadow: 0px 9px 7px 0px white;
        background-color: white;
        padding-bottom: 0;
      }
      .str-chat__list .str-chat__reverse-infinite-scroll {
        padding-top: 5px;
      }
    }
  }

  .str-chat__header-livestream-left--members {
    display: none;
  }

  .str-chat__typing-indicator {
    display: none;
  }

  .str-chat__date-separator {
    padding: 0;
  }

  /* BEGIN: Override styles for MessageList */
  .messaging.str-chat .str-chat__list {
    padding: 0 20px;
  }
  .str-chat__li {
    margin: 0 !important;
  }
  /* END: Override styles for MessageList */

  /* BEGIN: Override styles for MessageInput */
  .str-chat__input-flat {
    border-top: 1px solid #e8e7e6;
    padding-left: 5px;
    padding-right: 5px;
  }
  .str-chat .str-chat__textarea > textarea {
    padding: 12px 20px;
    border-radius: ${textareaBorderRadius};
    background-color: white;
    border: none;
    box-shadow: 0 4px 8px rgb(50 50 50 / 15%);
    min-height: 10px;
    max-height: 40px;
    &:focus {
      border-radius: ${textareaBorderRadius};
      box-shadow: 0 4px 8px rgb(50 50 50 / 15%);
      max-height: none;
    }
  }
  /* END: Override styles for MessageInput */

  /* BEGIN: Mobile styles */
  ${({ mobileBreakpoint }) => `
   @media (max-width: ${mobileBreakpoint}){
    .messaging {
      &.str-chat{
        .str-chat__header-livestream {
          display: none;
        }
        .str-chat__list{
          background: transparent;
          padding: 0 15px;
        }
        .str-chat__li--single, .str-chat__li--top, .str-chat__li--bottom{
          margin: 0;
          font-size: 95%;
        }
      }
    }
    .str-chat .str-chat__textarea > textarea {
      font-size: 95%;
      padding: 7px 15px;
    }
   }
  `}/*END: Mobile styles */
`

const ListSkeleton = styled.div`
  padding: 0px 20px;
`

const SkeletonWrapper = styled.div`
  display: flex;

  @keyframes ant-skeleton-loading {
    0% {
      background-position: 100% 50%;
    }
    to {
      background-position: 0 50%;
    }
  }

  .skeleton-header {
    display: table-cell;
    padding-right: 16px;
    vertical-align: top;
  }

  .avatar {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    line-height: 40px;
    display: inline-block;
    vertical-align: top;
    background: rgba(190, 190, 190, 0.2);
  }

  .skeleton-content {
    display: table-cell;
    width: 100%;
    vertical-align: top;

    .skeleton-title {
      width: 50%;
      margin-top: 12px;
      height: 16px;
      background: rgba(190, 190, 190, 0.2);
      border-radius: 4px;
    }

    .skeleton-paragraph {
      margin-top: 28px;
      padding: 0;

      > li {
        width: 100%;
        height: 16px;
        list-style: none;
        background: linear-gradient(
          90deg,
          rgba(190, 190, 190, 0.2) 25%,
          rgba(129, 129, 129, 0.24) 37%,
          rgba(190, 190, 190, 0.2) 63%
        );
        border-radius: 4px;
        background-size: 400% 100%;
        animation: ant-skeleton-loading 1.4s ease infinite;

        + li {
          margin-top: 16px;
        }
      }
    }
  }
`
