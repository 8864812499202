import React, { useMemo } from 'react'
import Layout from '../Layout'

interface Props {
  data?: any
  containerProps?: string[]
  cardLayout?: any
  slug?: string
  parentData?: any
}

/**
 * Draws the card layout of a custom content type element
 *
 * @param props -  Passed in object with the following properties:
 * @param props.data - the data of the specific element the card will render
 * @param props.cardLayout - the element's card layout. If not provided, an attempt will be made to extract it from data
 * @param props.slug - the slug of the specific element. If not provided, an attempt will be made to extract it from data
 * @param props.parentData - data that will be used in the layout context. If not provided, an attempt will be made to extract it from data
 */
export default function GenericCard({ data, cardLayout, slug, parentData, containerProps }: Props) {
  const itemSlug = slug ? slug : data?.slug
  const layoutParentData = parentData ? parentData : { ...data, __type: data?._contentType?.name }

  const currentLayout = cardLayout
    ? cardLayout
    : useMemo(() => {
        const genericLayout = data?._contentType?.extraData?.cardLayout

        // Need to test
        // let genericLayout: any
        // if (data?._contentType?.name === 'Template') {
        //   genericLayout = data?._elementType?.extraData?.cardLayout
        // } else {
        //   genericLayout = data?._contentType?.extraData?.cardLayout
        // }

        if (genericLayout) {
          return data?.__layouts?.find((l: any) => l.__id === genericLayout)
        }
      }, [data?.__layouts])

  // builds base url ex: /pages using base prefix defined in content type
  const baseUrlParts = useMemo(() => {
    const parts = ['']

    if (data?._contentType?.slugPrefix) {
      parts.push(data?._contentType?.slugPrefix)
    }

    return parts
  }, [data?._contentType?.slugPrefix])

  const urlParts: any[] = [...baseUrlParts]
  urlParts.push(data._contentType?.slug)
  urlParts.push(itemSlug)
  const url = `${urlParts.join('/')}`

  return (
    <Layout data={currentLayout} parentData={{ ...layoutParentData, url }} {...containerProps} />
  )
}
