import React, { JSXElementConstructor, ReactNode, useEffect, useState } from 'react'
import PollModel, {
  PollAnswersTextNestedModel,
  PollAnswersImageNestedModel,
  PollAnswersColorNestedModel
} from './models'
import { useAppDispatch, useAppSelector } from '../../redux'
import { getWishListItems, addWishListItem } from '../../redux/slices/wishlist'
import ResponsiveImage from '../ResponsiveImage'
import styled from '@emotion/styled'
import { BsCheck2 } from 'react-icons/bs'
import Html from '../Html'
import HtmlModel from '../Html/models'

interface PollProps {
  data: PollModel
  containerProps?: string[]
}

export default function Poll({ data, containerProps }: PollProps) {
  const [answerOrder, setAnswerOrder] = useState<number[]>([])
  const [selection, setSelection] = useState<{ [key: string]: boolean }>({})
  const [counts, setCounts] = useState<{ [key: string]: number }>({})
  const [submitted, setSubmitted] = useState(false)
  const dispatch = useAppDispatch()
  const items = useAppSelector((state) => state.wishlist.items)

  const descriptionHtmlModel = new HtmlModel()
  descriptionHtmlModel.html = data?.description

  useEffect(() => {
    let order =
      data.answerType === 'text' && data.answersText
        ? [...data.answersText.keys()]
        : data.answerType === 'image' && data.answersImage
        ? [...data.answersImage.keys()]
        : data.answerType === 'color' && data.answersColor
        ? [...data.answersColor.keys()]
        : ([] as number[])

    if (data.randomizeAnswerOrder && order?.length > 0) {
      for (let i = order.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1))
        const temp = order[i]
        order[i] = order[j]
        order[j] = temp
      }
    }

    setAnswerOrder(order)
  }, [
    data.randomizeAnswerOrder,
    data.answersText?.length,
    data.answersImage?.length,
    data.answersColor?.length
  ])

  useEffect(() => {
    // Load customer existing poll answers...
    dispatch(getWishListItems({ elementIds: [data.__id] }))
  }, [])

  // Once getWishListItems returns, set selections
  useEffect(() => {
    const item = items.find((item: any) => item?.elementId === data.__id)
    if (item?.totalResponseCounts && Object.keys(item.totalResponseCounts).length > 0) {
      for (const [answerSlug, score] of Object.entries(item.totalResponseCounts)) {
        counts[answerSlug] = score as number
      }
      setCounts({ ...counts })
    }

    if (item?.customerResponse && Object.keys(item?.customerResponse).length > 0) {
      for (const [answerSlug, score] of Object.entries(item?.customerResponse)) {
        if (score == 1) {
          selection[answerSlug] = true
        }
      }
      setSubmitted(true)
      setSelection({ ...selection })
    }
  }, [items])

  const clickOption = (answerSlug: string) => {
    if (submitted) return false
    selection[answerSlug] = !selection[answerSlug]
    setSelection({ ...selection })
    counts[answerSlug] = (counts[answerSlug] || 0) + (selection[answerSlug] ? 1 : -1)
    setCounts({ ...counts })
    if (!data.allowMultipleAnswers) {
      submitPollAnswers()
    }
  }

  const submitPollAnswers = () => {
    let payload = {
      elementId: data.__id,
      elementKey: 'Poll',
      elementLabel: 'Poll',
      response: selection
    }
    dispatch(addWishListItem(payload))
    setSubmitted(true)
  }

  const onClickPollAnswer = (
    buttonData: any,
    e?: React.MouseEvent<HTMLDivElement, MouseEvent> | React.KeyboardEvent<HTMLDivElement>
  ) => {
    e?.preventDefault()
    e?.stopPropagation()
    clickOption(buttonData?.slug)
  }

  const PollButton = ({
    buttonData,
    Visual = () => <></>,
    Label
  }: {
    props: PollProps
    buttonData:
      | PollAnswersTextNestedModel
      | PollAnswersImageNestedModel
      | PollAnswersColorNestedModel
    Visual: React.FC
    Label?: React.FC
  }) => {
    return (
      <PollAnswersWrapper
        data={data}
        className={`answer type-${data.answerType} ${selection[buttonData?.slug] ? 'active' : ''} ${
          submitted && data.showAnswerCounts ? 'count-revealed' : ''
        }
        ${Label || (submitted && data.showAnswerCounts) ? 'has-label' : ''}
        `}
        onClick={(e) => onClickPollAnswer(buttonData, e)}
        onKeyUp={(e) => (e.code === 'Enter' ? onClickPollAnswer(buttonData, e) : null)}
        defaultChecked={true}>
        <div className='answer-content'>
          <Visual />
          <div
            className={`${
              Label || (submitted && data.showAnswerCounts) ? 'answer-label-container' : ''
            }`}>
            {Label && <Label />}
            <span
              className={`answer-counts ${submitted && data.showAnswerCounts ? 'visible' : ''}`}>
              <span>
                {counts[buttonData?.slug] || 0}{' '}
                <span className='votes-label'>
                  vote{counts[buttonData?.slug] !== 1 && <span>s</span>}
                </span>
              </span>
            </span>
          </div>
        </div>
      </PollAnswersWrapper>
    )
  }

  return (
    <div {...containerProps}>
      <h3 className='poll-title'>{data.name}</h3>
      {data.description && (
        <div className='poll-description'>
          <Html data={descriptionHtmlModel} />
        </div>
      )}
      {data.answerType === 'text' && (
        <TextAnswerType data={data} answerOrder={answerOrder} PollButton={PollButton} />
      )}
      {data.answerType === 'image' && (
        <ImageAnswerType data={data} answerOrder={answerOrder} PollButton={PollButton} />
      )}
      {data.answerType === 'color' && (
        <ColorAnswerType data={data} answerOrder={answerOrder} PollButton={PollButton} />
      )}
      {data.allowMultipleAnswers && (
        <input
          type='submit'
          value={submitted ? 'Answered' : 'Submit answers'}
          disabled={submitted}
          className='submit-poll-button'
          onClick={submitPollAnswers}
          onKeyUp={(e) => (e.code === 'Enter' ? submitPollAnswers() : null)}
        />
      )}
    </div>
  )
}

function TextAnswerType({
  data,
  answerOrder,
  PollButton
}: {
  data: PollModel
  answerOrder: number[]
  PollButton: any
}) {
  return (
    <div key={data.slug}>
      {data?.answersText &&
        answerOrder?.map((i) => (
          <PollButton
            key={data.answersText[i]?.slug}
            buttonData={data.answersText[i]}
            Label={() => (
              <span>
                <BsCheck2 className='tick-mark' />
                <span>{data.answersText[i]?.answer}</span>
              </span>
            )}
          />
        ))}
    </div>
  )
}

function ImageAnswerType({
  data,
  answerOrder,
  PollButton
}: {
  data: PollModel
  answerOrder: number[]
  PollButton: any
}) {
  return (
    <div key={data.slug} className='flexed-answers-container'>
      {data?.answersImage &&
        answerOrder?.map((i) => (
          <PollButton
            key={data.answersImage[i]?.slug}
            buttonData={data.answersImage[i]}
            Visual={() => (
              <>
                <ResponsiveImage data={data.answersImage[i]?.image} />{' '}
                <BsCheck2 className='tick-mark' />
              </>
            )}
            Label={
              data.answersImage[i]?.image?.alt
                ? () => <div className='answer-label'>{data.answersImage[i]?.image?.alt}</div>
                : null
            }
          />
        ))}
    </div>
  )
}

function ColorAnswerType({
  data,
  answerOrder,
  PollButton
}: {
  data: PollModel
  answerOrder: number[]
  PollButton: any
}) {
  return (
    <div key={data.slug} className='flexed-answers-container'>
      {data?.answersColor &&
        answerOrder?.map((i) => (
          <PollButton
            key={data.answersColor[i]?.slug}
            buttonData={data.answersColor[i]}
            Visual={() => (
              <>
                <div
                  className='answer-visual'
                  style={{ backgroundColor: data.answersColor[i]?.color }}
                />
                <BsCheck2 className='tick-mark' />
              </>
            )}
            Label={
              data.answersColor[i]?.answer
                ? () => <div className='answer-label'>{data.answersColor[i]?.answer}</div>
                : null
            }
          />
        ))}
    </div>
  )
}

const PollAnswersWrapper = styled.div<PollProps>`
  &.answer[class*='type-'] {
    ${(props) =>
      (props.data?.answersStyle?.topType !== 'auto' ||
        props.data?.answersStyle?.leftType !== 'auto') &&
      `position: relative;`}
    ${(props) =>
      props.data?.answersStyle?.topType !== 'auto' &&
      props.data?.answersStyle?.topType &&
      `top: ${props.data?.answersStyle?.topValue};`}
    ${(props) =>
      props.data?.answersStyle?.leftType !== 'auto' &&
      props.data?.answersStyle?.leftValue &&
      `left: ${props.data?.answersStyle?.leftValue};`}

    ${(props) =>
      props.data?.answersStyle?.paddingTop &&
      parseInt(props.data?.answersStyle?.paddingTop) !== 0 &&
      `padding-top: ${props.data?.answersStyle?.paddingTop};`}
    ${(props) =>
      props.data?.answersStyle?.paddingRight &&
      parseInt(props.data?.answersStyle?.paddingRight) !== 0 &&
      `padding-right: ${props.data?.answersStyle?.paddingRight};`}
    ${(props) =>
      props.data?.answersStyle?.paddingBottom &&
      parseInt(props.data?.answersStyle?.paddingBottom) !== 0 &&
      `padding-bottom: ${props.data?.answersStyle?.paddingBottom};`}
    ${(props) =>
      props.data?.answersStyle?.paddingLeft &&
      parseInt(props.data?.answersStyle?.paddingLeft) !== 0 &&
      `padding-left: ${props.data?.answersStyle?.paddingLeft};`}
    ${(props) =>
      props.data?.answersStyle?.marginTop &&
      parseInt(props.data?.answersStyle?.marginTop) !== 0 &&
      `margin-top: ${props.data?.answersStyle?.marginTop};`}
    ${(props) =>
      props.data?.answersStyle?.marginRight &&
      parseInt(props.data?.answersStyle?.marginRight) !== 0 &&
      `margin-right: ${props.data?.answersStyle?.marginRight};`}
    ${(props) =>
      props.data?.answersStyle?.marginBottom &&
      parseInt(props.data?.answersStyle?.marginBottom) !== 0 &&
      `margin-bottom: ${props.data?.answersStyle?.marginBottom};`}
    ${(props) =>
      props.data?.answersStyle?.marginLeft &&
      parseInt(props.data?.answersStyle?.marginLeft) !== 0 &&
      `margin-left: ${props.data?.answersStyle?.marginLeft};`}


    ${(props) => props.data?.answerTextColor && `color: ${props.data?.answerTextColor};`}
    ${(props) =>
      props.data?.answersStyle?.backgroundColor &&
      `background-color: ${props.data?.answersStyle?.backgroundColor};`}
    ${(props) =>
      props.data?.answersStyle?.borderThickness !== '0px' &&
      `border-width: ${props.data?.answersStyle?.borderThickness};`}
    ${(props) =>
      props.data?.answersStyle?.borderThickness === '0px' &&
      `border-width: ${props.data?.answersStyle?.borderTop} ${props.data?.answersStyle?.borderRight} ${props.data?.answersStyle?.borderBottom} ${props.data?.answersStyle?.borderLeft};`}
    ${(props) =>
      props.data?.answersStyle?.borderColor &&
      `border-color: ${props.data?.answersStyle?.borderColor};`}
    ${(props) =>
      props.data?.answersStyle?.borderRadius &&
      `border-radius: ${props.data?.answersStyle?.borderRadius};`}
    ${(props) => props.data?.answersStyle?.visibility === 'hidden' && `display: none;`}

    &:hover, &.active {
      ${(props) =>
        props.data?.activeAnswerTextColor && `color: ${props.data?.activeAnswerTextColor};`}
      ${(props) =>
        props.data?.activeAnswerBackgroundColor &&
        `background-color: ${props.data?.activeAnswerBackgroundColor};`}
    }
  }
  &.answer.type-text {
    ${(props) =>
      props.data?.answersStyle?.widthType !== 'auto' &&
      props.data?.answersStyle?.widthValue &&
      `width: ${props.data?.answersStyle?.widthValue};`}
    ${(props) =>
      props.data?.answersStyle?.heightType !== 'auto' &&
      props.data?.answersStyle?.heightValue &&
      `height: ${props.data?.answersStyle?.heightValue};`}
  }
  &.answer.type-image,
  &.answer.type-color {
    ${(props) =>
      props.data?.answersStyle?.widthType !== 'auto' &&
      props.data?.answersStyle?.widthValue &&
      `flex: 0 1 ${props.data?.answersStyle?.widthValue};`}
  }
  &.answer.type-image {
    ${(props) =>
      props.data?.answersStyle?.heightType !== 'auto' &&
      props.data?.answersStyle?.heightValue &&
      `
      img {
        height: ${props.data?.answersStyle?.heightValue} !important;
      }
      `}
  }
  &.answer.type-color {
    ${(props) =>
      props.data?.answersStyle?.heightType !== 'auto' &&
      props.data?.answersStyle?.heightValue &&
      `
      .answer-visual{
        padding-top: ${props.data?.answersStyle?.heightValue};
      }
      `}
  }
`
