import React from 'react'
import { Element, Elements } from '../index'
import TemplateModel from './models'

interface TemplateProps {
  data?: TemplateModel
  containerProps?: string[]
}

export default function Template({ data }: TemplateProps) { 
  if (!data?.elements || data?.elements.length === 0) {
    const elementType = data?.elementType.replace('#schema:', '')

    // Prevent rendering of a Template within a Template 
    // Rendering a Template within a Template causes an infinite loop
    if(elementType === 'Template') {
      return <>Unable to render a Template within a Template</>
    }
    return <Element data={data} name={elementType} />
  }

  return <Elements data={data} field='elements' />
}
