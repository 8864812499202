import React, { useRef, useState, useEffect, MutableRefObject } from 'react'
import styled from '@emotion/styled'
import { FiVolume2 } from 'react-icons/fi'
import { appendScriptTagToBody } from '../../../lib/helpers'

interface VideoPlayerProps {
  playbackUrl: string
  onReady: Function
  smallMobileBreakpoint: string
}
export default function VideoPlayer({
  playbackUrl,
  onReady,
  smallMobileBreakpoint
}: VideoPlayerProps) {
  const [isBigUmutedButtonVisible, setIsBigUmutedButtonVisible] = useState(true)
  const videoRef = useRef<HTMLVideoElement>(null) as MutableRefObject<HTMLVideoElement>

  const videoJsOptions = {
    autoplay: true,
    muted: true,
    controls: false,
    liveui: true,
    preload: true,
    bigPlayButton: false,
    aspectRatio: '16:9',
    sources: [
      {
        src: playbackUrl,
        type: 'application/x-mpegURL'
      }
    ]
  }

  useEffect(() => {
    if (playbackUrl) {
      loadVideoJSLibrary(() => {
        videoRef.current.onloadeddata = () => {
          if (videoRef.current.readyState >= HTMLMediaElement.HAVE_CURRENT_DATA) {
            onReady && onReady(videoRef.current)
          }
        }
      })
    }
  }, [])

  const handleClickBigUnmuteButton = () => {
    videoRef.current.muted = false
    setIsBigUmutedButtonVisible(false)
  }

  return (
    <VideoPlayerWrapper smallMobileBreakpoint={smallMobileBreakpoint}>
      <video
        id='video-player'
        ref={videoRef}
        className='video-js'
        data-setup={JSON.stringify(videoJsOptions)}>
        <p className='vjs-no-js'>
          To view this video please enable JavaScript, and consider upgrading to a web browser that
          <a href='https://videojs.com/html5-video-support/' target='_blank'>
            supports HTML5 video
          </a>
        </p>
      </video>
      {isBigUmutedButtonVisible && (
        <div
          className='vjs-mute-control big-unmute-button'
          onClick={handleClickBigUnmuteButton}
          onKeyUp={(e) => (e.code === 'Enter' ? handleClickBigUnmuteButton() : null)}>
          <FiVolume2 className='vjs-icon-placeholder' />
          <span>Click to unmute</span>
        </div>
      )}
    </VideoPlayerWrapper>
  )
}

const videoJsScriptId = 'video-js-script'
export const loadVideoJSLibrary = (callback: Function) => {
  const scriptSettings = {
    src: 'https://vjs.zencdn.net/7.18.1/video.min.js',
    id: videoJsScriptId,
    onload: callback
  }
  appendScriptTagToBody(scriptSettings)
}

const VideoPlayerWrapper = styled.div<{
  smallMobileBreakpoint: string
}>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  margin: 0 auto;

  .video-js {
    width: 100%;
    height: 100%;
    position: relative;
    background: transparent;
    padding-top: 0;

    .vjs-tech {
      position: relative;
      margin: auto;
      height: 100%;
      width: auto;
      padding-bottom: 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      object-fit: cover;

      ${({ smallMobileBreakpoint }) => `
        @media (max-width: ${smallMobileBreakpoint}) {
          width: 100%;
        }
      `}
    }
  }

  .big-unmute-button {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    z-index: 2;
    width: 100%;
    > .vjs-icon-placeholder:before {
      position: initial;
    }
    .vjs-icon-placeholder {
      font-size: 36px;
      margin-bottom: 8px;
    }
    &.hidden {
      display: none !important;
    }
  }
`
