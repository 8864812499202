import React from 'react'
import { hooks } from '../../lib/hooks'

interface ProductCustomFooterProps {
  currentProduct?: any
  source: string
}

export default function ProductCustomFooter({
  currentProduct,
  source
}: ProductCustomFooterProps) {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: hooks.run(`catalog.${source}.footer`, { product: currentProduct })?.content || ''
      }}
    />
  )
}
