import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import WishListLauncher from '../WishListLauncher'
import WishListAddButtonManager from '../WishListAddButtonManager'
import WishListManager from '../WishListManager'
import { getWishListSettings } from '../../redux/slices/wishlist'
import { useAppDispatch, useAppSelector } from '../../redux'

export default function WishListApp() {
  const dispatch = useAppDispatch()
  const config = useAppSelector((state) => state.app.config)
  const wishListReady = useAppSelector((state) => state.wishlist.ready)
  const wishListSettings = useAppSelector((state) => state.wishlist.settings)
  
  useEffect(() => {
    dispatch(getWishListSettings())
  }, [config])

  if (!wishListReady) {
    return null
  }

  function renderApp() {
    const wishlistElement = document.getElementById('nogin-toolbox-wishlist')

    return <>
      <WishListLauncher settings={wishListSettings} />
      <WishListAddButtonManager />
      {
        wishlistElement && ReactDOM.createPortal(<WishListManager />, wishlistElement)
      }
    </>
  }

  if (wishListSettings.launcher.displayType === 'floating') {
    return renderApp()
  } else if (wishListSettings.launcher.displayType === 'embedded') {
    if (!wishListSettings.launcher.target) {
      console.log('Wish List launcher target element not set')
      return null
    }

    const target = wishListSettings.launcher.target as string
    const el = document.querySelector(target)

    if (!el) {
      console.log('Wish List launcher target element not found')
      return null
    }

    return ReactDOM.createPortal(renderApp(), el as HTMLElement)
  }

  return null
}
