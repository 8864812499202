import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import styled from '@emotion/styled'
import { SubPickerProps } from './Picker'
import CollectionFilterModel from '../models'

const Container = styled.div<{ data: CollectionFilterModel }>`
  flex-grow: 1;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  gap: ${(props) => props.data.swatchSpacing};

  .color-swatch {
    box-shadow: inset 0px 0px 0px 2px #fff;
    height: ${(props) => props.data.swatchHeight};
    width: ${(props) => props.data.swatchWidth};
    border-radius: ${(props) => props.data.swatchBorderRadius};
    border: ${(props) => props.data.swatchBorderThickness} solid
      ${(props) => props.data.swatchBorderColor};
    cursor: pointer;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .color-swatch.disabled {
    cursor: unset;
    opacity: ${(props) => props.data.swatchDisabledOpacity};
  }

  .color-swatch.active {
    border: ${(props) => props.data.swatchBorderThickness} solid
      ${(props) => props.data.swatchSelectedBorderColor};
  }

  .color-picker-item-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: stretch;
    text-align: center;
    width: ${(props) => props.data.swatchWidth};
    padding: 1px;
  }

  .color-swatch {
    //background: //linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(208, 20, 20, 0) 100%);
  }

  small {
    font-size: ${(props) => props.data.swatchFontSize};
    margin-top: 5px;
  }
`

export default function ColorPicker({
  filter,
  defaultSelected,
  facetedData,
  onPicked,
  setPickerVisibility
}: SubPickerProps) {
  const [selected, setSelected] = useState(defaultSelected || [])
  const [_items, setItems] = useState<any>([])

  useEffect(() => {
    if (defaultSelected) {
      setSelected(filter.selectable == 'single' ? [defaultSelected[0]] : defaultSelected)
    } else {
      setSelected([])
    }
  }, [defaultSelected])

  useEffect(() => {
    if (filter.mode && filter.mode === 'automatic') {
      // build item list from faceted data
      let autoItems: any[] = []
      const tagPrefix = filter.tagPrefix
      if (facetedData?.tags[tagPrefix]) {
        const tags = facetedData.tags[tagPrefix]
        for (const tag of tags) {
          autoItems.push(tag.tag)
        }

        // when filter sort is set to custom use the color mapping order
        // as the sort order
        if (filter.sortBy === 'custom' && filter.colorMapping.length > 0) {
          // build list of color prefix
          let newSort = filter.colorMapping.map((color) =>
            filter.tagPrefix ? filter.tagPrefix + color?.title : color.title
          )

          // remove items that don't exist in auto items
          newSort = newSort.map((o: any) =>
            autoItems.find((v) => v.toLowerCase() === o.toLowerCase())
          )

          let regularSortItems: any[] = []
          if (!filter.swatchOnlyShowWithMapping) {
            // get all items that don't require sorting
            regularSortItems = autoItems.filter((o) => newSort.indexOf(o) === -1)

            // store these items using basic ascii sort
            regularSortItems.sort()
          }

          // merge with ordered items
          autoItems = [...newSort, ...regularSortItems]
        }

        setItems(autoItems)

        if (autoItems.length === 0 || filter.hideEmpty) {
          setPickerVisibility(false)
        } else {
          setPickerVisibility(true)
        }
      }
    } else {
      // manual mode loads data from settings
      let filterItems = filter.colorMapping.map((color) =>
        filter.tagPrefix ? filter.tagPrefix + color?.title : color.title
      )
      setItems(filterItems)
    }
  }, [selected, facetedData, filter])

  function getFacetCount(item: any) {
    if (filter.mode === 'automatic' && filter.tagPrefix) {
      if (facetedData && facetedData.tags && facetedData.tags[filter.tagPrefix]) {
        const match = facetedData.tags[filter.tagPrefix].find((t: any) => t.tag === item)
        return (match && match.count) || 0
      }
    }
  }

  function isActive(item: any) {
    return selected?.indexOf(item) !== -1
  }

  function getNameFromTag(tag: string) {
    const tagPrefix = filter.tagPrefix
    return tag?.replace(tagPrefix, '')
  }

  function getColorFromName(name: string): string {
    const colorMapping = filter.colorMapping.find(
      (c) => c?.title?.toLowerCase() === name?.toLowerCase()
    )
    if (colorMapping?.swatchType === 'image') {
      return `url(${colorMapping?.backgroundImage})`
    } else {
      // default 'color'
      return colorMapping?.backgroundColor || name?.toLowerCase() || '#d0d0d0'
    }
  }

  const onClickItem = (disabled: boolean, item: any) => {
    if (!disabled) {
      let newSelected
      if (!isActive(item)) {
        newSelected = filter.selectable == 'single' ? [item] : [...selected, item]
      } else {
        newSelected = [...selected.filter((o: any) => o !== item)]
      }

      if (onPicked) {
        onPicked(newSelected)
      }

      setSelected(newSelected)
    }
  }

  return (
    <Container data={filter} className='color-picker-container'>
      {_items.map((item: any, i: number) => {
        const itemCount = getFacetCount(item)

        if (filter.hideEmpty && itemCount <= 0) {
          return null
        }
        setPickerVisibility(true)

        const disabled = typeof itemCount !== 'undefined' && itemCount <= 0
        const name = getNameFromTag(item)
        const color = getColorFromName(name)

        return (
          <div
            key={i}
            className={classNames('color-picker-item-container', {
              active: isActive(item),
              disabled: disabled
            })}>
            <div className='color-picker-item-swatch-container'>
              <div
                onClick={() => onClickItem(disabled, item)}
                onKeyUp={(e) => (e.code === 'Enter' ? onClickItem(disabled, item) : null)}
                className={classNames('color-swatch', {
                  active: isActive(item),
                  [name]: true,
                  disabled
                })}
                style={{
                  background: color
                }}></div>
            </div>
            {!filter.swatchHideLabel && (
              <small
                style={{ cursor: 'pointer' }}
                onClick={() => onClickItem(disabled, item)}
                onKeyUp={(e) => (e.code === 'Enter' ? onClickItem(disabled, item) : null)}>
                {name} {filter?.swatchShowFacetCounts && `(${itemCount})`}
              </small>
            )}
          </div>
        )
      })}
    </Container>
  )
}
