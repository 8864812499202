/**
* THIS FILE WAS AUTOGENERATED USING NOGIN CLI
**/




export default class ProductCardLayoutModel {
  __id: string = ''
  __type: 'ProductCardLayout' = 'ProductCardLayout'
  __elementId: string = ''
  cardLayout: ProductCardLayoutCardLayoutNestedModel[] = [
  {
    "type": "image",
    "useMouseOverSwatchChange": false,
    "swatchStyle": "standard",
    "swatchLimit": 5,
    "className": "",
    "desktopSwatchStyleEnabled": false,
    "strikeThroughOos": false,
    "hideOosSwatches": false,
    "desktopSwatchStyle": "standard",
    "desktopSwatchLimit": 5,
    "html": "",
    "showFromPrice": false,
    "quickbuyVisibility": "both",
    "showQuickbuyImageOverlay": true,
    "priceRangeFormat": "fromprefix",
    "showPercentSign": false,
    "removeTrailingZeros": false,
    "desktopSwatchCountRevealTrigger": "hover",
    "stars": 0,
    "starsHeight": 0,
    "starsWidth": 0,
    "colorFilledStars": "",
    "colorUnfilledStars": "",
    "displayScore": false,
    "displayCount": false,
    "showExactStars": false,
    "minReviewsAverage": 0,
    "hideReviewsAverageUntil": 0,
    "colorSwatchStyle": "standard",
    "colorSwatchLimit": 5,
    "colorDesktopSwatchStyleEnabled": false,
    "colorStrikeThroughOos": false,
    "colorHideOosSwatches": false,
    "colorDesktopSwatchStyle": "standard",
    "colorDesktopSwatchLimit": 5,
    "colorDesktopSwatchCountRevealTrigger": "hover",
    "colorUseMouseOverSwatchChange": false
  },
  {
    "type": "title",
    "useMouseOverSwatchChange": false,
    "swatchStyle": "standard",
    "swatchLimit": 5,
    "className": "",
    "desktopSwatchStyleEnabled": false,
    "strikeThroughOos": false,
    "hideOosSwatches": false,
    "desktopSwatchStyle": "standard",
    "desktopSwatchLimit": 5,
    "html": "",
    "showFromPrice": false,
    "quickbuyVisibility": "both",
    "showQuickbuyImageOverlay": true,
    "priceRangeFormat": "fromprefix",
    "showPercentSign": false,
    "removeTrailingZeros": false,
    "desktopSwatchCountRevealTrigger": "hover",
    "stars": 0,
    "starsHeight": 0,
    "starsWidth": 0,
    "colorFilledStars": "",
    "colorUnfilledStars": "",
    "displayScore": false,
    "displayCount": false,
    "showExactStars": false,
    "minReviewsAverage": 0,
    "hideReviewsAverageUntil": 0,
    "colorSwatchStyle": "standard",
    "colorSwatchLimit": 5,
    "colorDesktopSwatchStyleEnabled": false,
    "colorStrikeThroughOos": false,
    "colorHideOosSwatches": false,
    "colorDesktopSwatchStyle": "standard",
    "colorDesktopSwatchLimit": 5,
    "colorDesktopSwatchCountRevealTrigger": "hover",
    "colorUseMouseOverSwatchChange": false
  },
  {
    "type": "price",
    "useMouseOverSwatchChange": false,
    "swatchStyle": "standard",
    "swatchLimit": 5,
    "className": "",
    "desktopSwatchStyleEnabled": false,
    "strikeThroughOos": false,
    "hideOosSwatches": false,
    "desktopSwatchStyle": "standard",
    "desktopSwatchLimit": 5,
    "html": "",
    "showFromPrice": false,
    "quickbuyVisibility": "both",
    "showQuickbuyImageOverlay": true,
    "priceRangeFormat": "fromprefix",
    "showPercentSign": false,
    "removeTrailingZeros": false,
    "desktopSwatchCountRevealTrigger": "hover",
    "stars": 0,
    "starsHeight": 0,
    "starsWidth": 0,
    "colorFilledStars": "",
    "colorUnfilledStars": "",
    "displayScore": false,
    "displayCount": false,
    "showExactStars": false,
    "minReviewsAverage": 0,
    "hideReviewsAverageUntil": 0,
    "colorSwatchStyle": "standard",
    "colorSwatchLimit": 5,
    "colorDesktopSwatchStyleEnabled": false,
    "colorStrikeThroughOos": false,
    "colorHideOosSwatches": false,
    "colorDesktopSwatchStyle": "standard",
    "colorDesktopSwatchLimit": 5,
    "colorDesktopSwatchCountRevealTrigger": "hover",
    "colorUseMouseOverSwatchChange": false
  },
  {
    "type": "family",
    "useMouseOverSwatchChange": false,
    "swatchStyle": "standard",
    "swatchLimit": 5,
    "className": "",
    "desktopSwatchStyleEnabled": false,
    "strikeThroughOos": false,
    "hideOosSwatches": false,
    "desktopSwatchStyle": "standard",
    "desktopSwatchLimit": 5,
    "html": "",
    "showFromPrice": false,
    "quickbuyVisibility": "both",
    "showQuickbuyImageOverlay": true,
    "priceRangeFormat": "fromprefix",
    "showPercentSign": false,
    "removeTrailingZeros": false,
    "desktopSwatchCountRevealTrigger": "hover",
    "stars": 0,
    "starsHeight": 0,
    "starsWidth": 0,
    "colorFilledStars": "",
    "colorUnfilledStars": "",
    "displayScore": false,
    "displayCount": false,
    "showExactStars": false,
    "minReviewsAverage": 0,
    "hideReviewsAverageUntil": 0,
    "colorSwatchStyle": "standard",
    "colorSwatchLimit": 5,
    "colorDesktopSwatchStyleEnabled": false,
    "colorStrikeThroughOos": false,
    "colorHideOosSwatches": false,
    "colorDesktopSwatchStyle": "standard",
    "colorDesktopSwatchLimit": 5,
    "colorDesktopSwatchCountRevealTrigger": "hover",
    "colorUseMouseOverSwatchChange": false
  },
  {
    "type": "reviews",
    "useMouseOverSwatchChange": false,
    "swatchStyle": "standard",
    "swatchLimit": 5,
    "className": "",
    "desktopSwatchStyleEnabled": false,
    "strikeThroughOos": false,
    "hideOosSwatches": false,
    "desktopSwatchStyle": "standard",
    "desktopSwatchLimit": 5,
    "html": "",
    "showFromPrice": false,
    "quickbuyVisibility": "both",
    "showQuickbuyImageOverlay": true,
    "priceRangeFormat": "fromprefix",
    "showPercentSign": false,
    "removeTrailingZeros": false,
    "desktopSwatchCountRevealTrigger": "hover",
    "stars": 5,
    "starsHeight": 20,
    "starsWidth": 20,
    "colorFilledStars": "#ff0000",
    "colorUnfilledStars": "#000000",
    "displayScore": true,
    "displayCount": false,
    "showExactStars": false,
    "minReviewsAverage": 0,
    "hideReviewsAverageUntil": 0,
    "colorSwatchStyle": "standard",
    "colorSwatchLimit": 5,
    "colorDesktopSwatchStyleEnabled": false,
    "colorStrikeThroughOos": false,
    "colorHideOosSwatches": false,
    "colorDesktopSwatchStyle": "standard",
    "colorDesktopSwatchLimit": 5,
    "colorDesktopSwatchCountRevealTrigger": "hover",
    "colorUseMouseOverSwatchChange": false
  }
]
}


export class ProductCardLayoutCardLayoutNestedModel {
  type: string = 'image'
  quickbuyVisibility: string = 'both'
  showQuickbuyImageOverlay: boolean = true
  showFromPrice: boolean = false
  priceRangeFormat: string = 'fromprefix'
  showPercentSign: boolean = false
  removeTrailingZeros: boolean = false
  colorFilledStars: string = '#ff0000'
  colorUnfilledStars: string = '#000000'
  displayScore: boolean = true
  displayCount: boolean = false
  showExactStars: boolean = false
  minReviewsAverage: number  = 0
  hideReviewsAverageUntil: number  = 0
  stars: number  = 5
  starsHeight: number  = 20
  starsWidth: number  = 20
  html: string = 'undefined'
  className: string = 'undefined'
  colorSwatchStyle: string = 'standard'
  colorSwatchLimit: number  = 5
  colorDesktopSwatchStyleEnabled: boolean = false
  colorStrikeThroughOos: boolean = false
  colorHideOosSwatches: boolean = false
  colorDesktopSwatchStyle: string = 'standard'
  colorDesktopSwatchLimit: number  = 5
  colorDesktopSwatchCountRevealTrigger: string = 'hover'
  colorUseMouseOverSwatchChange: boolean = false
  swatchStyle: string = 'standard'
  swatchLimit: number  = 5
  desktopSwatchStyleEnabled: boolean = false
  strikeThroughOos: boolean = false
  hideOosSwatches: boolean = false
  desktopSwatchStyle: string = 'standard'
  desktopSwatchLimit: number  = 5
  desktopSwatchCountRevealTrigger: string = 'hover'
  useMouseOverSwatchChange: boolean = false
}
