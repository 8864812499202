import styled from '@emotion/styled'
import React from 'react'
import ContentGalleryModel from '../models'
import ProductCard from '../../CollectionProductListing/components/ProductCard'

interface Props {
  data: ContentGalleryModel
  items: any[]
}

const Container = styled.div<{ data: ContentGalleryModel }>`
  display: flex;
  flex-direction: column;
  position: relative;

  > .card-wrapper {
    display: grid;
    grid-template-columns: ${(props) => `repeat(${props.data.gridColumnsDesktop}, 1fr)`};
    gap: ${(props) => props.data.gridSpacing};
    max-width: 100%;
  }

  @media screen and (max-width: 768px) {
    > .card-wrapper {
      grid-template-columns: ${(props) => `repeat(${props.data.gridColumnsMobile}, 1fr)`};
      gap: ${(props) => props.data.gridSpacing};
    }
  }

  .element-card {
    border: 1px solid #000;
  }
`

export default function GridView({ data, items }: Props) {
  return (
    <Container data={data}>
      <div className='card-wrapper'>
        {items.map((item: any, i: number) => {
          return (
            <ProductCard
              key={item.id}
              source='list'
              product={item}
              baseCollection={data._collection?.slug}
              layout='vertical'
              responsive={true}
              showQuickBuy={true}
              overlayElements={[
                <div
                  className='nogin-wishlist-product'
                  data-product-id={item.sourceId}
                  key={`product-wishlist-${item.id}`}></div>
              ]}
              showWishListButton={true}
              cardLayout={data?.productCardLayout?.cardLayout}
            />
          )
        })}
      </div>
    </Container>
  )
}
