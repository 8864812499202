import classNames from 'classnames'
import React, { useCallback, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import CollectionSettingModel from '../../elements/CollectionSetting/models'
import { useAppSelector } from '../../redux'
import Button from '../Button'

export interface ProductOptionPickerProps {
  product: any
  variant: any
  cardLayout: any
  onChangeVariant: (variant: any) => void
}

export const ProductOptionPickerContainer = styled.div`
  .size-label {
    font-size: 11px;
    text-transform: uppercase;
    margin-bottom: 5px;
  }
`
interface ValueContainerProps {
  settings?: CollectionSettingModel
}

export const ValueContainer = styled.div<ValueContainerProps>`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;

  .option-button {
    padding-top: ${(props) => props.settings?.optionPickerButtonStyle?.paddingTop};
    padding-right: ${(props) => props.settings?.optionPickerButtonStyle?.paddingRight};
    padding-bottom: ${(props) => props.settings?.optionPickerButtonStyle?.paddingBottom};
    padding-left: ${(props) => props.settings?.optionPickerButtonStyle?.paddingLeft};
    margin-top: ${(props) => props.settings?.optionPickerButtonStyle?.marginTop};
    margin-right: ${(props) => props.settings?.optionPickerButtonStyle?.marginRight};
    margin-bottom: ${(props) => props.settings?.optionPickerButtonStyle?.marginBottom};
    margin-left: ${(props) => props.settings?.optionPickerButtonStyle?.marginLeft};
    background-color: ${(props) => props.settings?.optionPickerButtonStyle?.backgroundColor};
    border-width: ${(props) => props.settings?.optionPickerButtonStyle?.borderThickness};
    border-color: ${(props) => props.settings?.optionPickerButtonStyle?.borderColor};
    border-radius: ${(props) => props.settings?.optionPickerButtonStyle?.borderRadius};
    color: ${(props) => props.settings?.optionPickerButtonStyle?.textColor};

    &.active {
      background-color: ${(props) => props.settings?.optionPickerActiveButtonStyle?.backgroundColor};
      border-width: ${(props) => props.settings?.optionPickerActiveButtonStyle?.borderThickness};
      border-color: ${(props) => props.settings?.optionPickerActiveButtonStyle?.borderColor};
      border-radius: ${(props) => props.settings?.optionPickerActiveButtonStyle?.borderRadius};
      color: ${(props) => props.settings?.optionPickerActiveButtonStyle?.textColor};
    }

    &:not([disabled]):hover {
      background-color: ${(props) => props.settings?.optionPickerButtonStyle?.backgroundColorHover};
      border-color: ${(props) => props.settings?.optionPickerButtonStyle?.borderColorHover};
      color: ${(props) => props.settings?.optionPickerButtonStyle?.textColorHover};
    }
  }
`

export default function ProductOptionPicker({ product, variant, cardLayout, onChangeVariant }: ProductOptionPickerProps) {
  const filterSettings = useAppSelector((state) => state.app.settings.filters)

  const [option1, setOption1] = useState('')
  const [option2, setOption2] = useState('')
  const [option3, setOption3] = useState('')

  var layoutIncludesColor = false
  if(cardLayout) {
    cardLayout.map((cardItem: any) => {
      if(cardItem.type === 'color') {
        layoutIncludesColor = true
      }
    })
  }

  useEffect(() => {
    if(variant) {
      /* 
        if the ProductCard has a basic color picker
        set color option on variant change 
      */
      if(layoutIncludesColor) {
        product.options.forEach((option: any) => {
          const [optionValue, setOption] = getOptionState(option.position)
          if(option.name.toLowerCase() === 'color') {
            setOption(variant[`option${option.position}`])
          }
        })
      }
    }
  }, [variant])

  useEffect(() => {
    product.options.forEach((option: any) => {
      const [optionValue, setOption] = getOptionState(option.position)
      
      // autoselect and hide options with only 1 value
      if(option.values.length === 1) {
        setOption(option.values[0])
        const variantFromOption = getVariantFromOption(option, option.values[0])
        onChangeVariant(variantFromOption)
      }
      // autoselect color if ProductCard has a basic color picker 
      if(layoutIncludesColor && option.name.toLowerCase() === 'color') {
        if(variant) {
          setOption(variant.options['Color'])
          const variantFromOption = getVariantFromOption(option, option.values[0])
          onChangeVariant(variantFromOption)
        }
      }
    })
  }, [product])


  function getOptionState(
    position: number
  ): [string, React.Dispatch<React.SetStateAction<string>>] {
    const setOption = position === 1 ? setOption1 : position === 2 ? setOption2 : setOption3
    const optionValue = position === 1 ? option1 : position === 2 ? option2 : option3
    return [optionValue, setOption]
  }

  const getVariantFromOption = (option: any, value: string) => {
    // find the variant based on selected values
    const variant = product.variants.find((variant: any) => {
      let isMatch = false
      for (let i = 1; i <= Object.keys(variant.options).length; i++) {
        const key = `option${i}`
        if (i === option.position) {
          if (variant[key] === value) {
            isMatch = true
          } else {
            isMatch = false
          }
        } else if (variant[key] !== null) {
          let compareOption
          switch(i) {
            case 1:
              compareOption = option1
              break;
            case 2:
              compareOption = option2
              break;
            case 3:
              compareOption = option3
              break;
          }

          if (variant[key] === compareOption) {
            isMatch = true
          } else {
            isMatch = false
          }
          if(!isMatch) break
        }
      }

      if (isMatch) {
        return variant
      }
    })

    return variant
  }

  const isOptionAvailable = (option: any, value: string) => {
    let isAvailable = false
    for (const variant of product.variants) {
      for (let i = 1; i <= Object.keys(variant.options).length; i++) {
        if(i === option.position) {
          isAvailable = variant[`option${i}`] === value && variant.inventoryQuantity
        } else {
          const [optionValue, setOption] = getOptionState(i)
          if(optionValue !== '') {
            isAvailable = variant[`option${i}`] === optionValue && variant.inventoryQuantity
          }
        }
        if(!isAvailable) break
      }
      if (isAvailable) break
    }

    return isAvailable
  }

  return (
    <ProductOptionPickerContainer className='variant-option-picker'>
      {product.options.map((option: any) => {
        const [optionValue, setOption] = getOptionState(option.position)
        
        return (
          (option.values.length > 1)
            // don't display color option if ProductCard includes a basic color picker 
            && !(layoutIncludesColor && option.name.toLowerCase() === 'color') 
            && (
            <div key={option.name}>
              {filterSettings?.showOptionLabel && <div className='size-label'>{option.name}</div>}
              <ValueContainer settings={filterSettings}>
                {option.values.map((value: any) => {
                  const isAvailable = isOptionAvailable(option, value)
                  const variant = getVariantFromOption(option, value)
                  const disabled = !isAvailable
                  return (
                    <Button
                      key={value}
                      disabled={disabled}
                      type={optionValue === value ? 'primary' : 'link'}
                      size='sm'
                      className={classNames('option-button', {
                        active: optionValue === value
                      })}
                      onClick={(e) => {
                        e?.preventDefault()
                        setOption(value)
                        if (variant) {
                          onChangeVariant(variant)
                        }
                      }}>
                      {value}
                    </Button>
                  )
                })}
              </ValueContainer>
            </div>
          )
        )
      })}
    </ProductOptionPickerContainer>
  )
}
