import React, { useMemo, useState } from 'react'
import { WishListLauncherAppData } from '../../types'
import { HeartOutlined, StarOutlined } from '@ant-design/icons'

interface WishListLauncherButtonProps {
  settings: WishListLauncherAppData
  onClick?: () => void
  previewMode?: boolean
  count?: number
}

const edgeMargin = 15

export default function WishListLauncherButton({
  settings,
  onClick,
  previewMode,
  count
}: WishListLauncherButtonProps) {
  const [isHover, setHover] = useState(false)

  const Icon = useMemo(() => {
    switch (settings.icon) {
      case 'star':
        return (
          <StarOutlined
            color={settings.foregroundColor}
            style={{ fontSize: 20 }}
          />
        )
      case 'heart':
      default:
        return (
          <HeartOutlined
            color={settings.foregroundColor}
            style={{ fontSize: 20 }}
          />
        )
    }
  }, [settings])

  const position = previewMode ? 'absolute' : 'fixed'

  return (
    <button
      aria-label='Open Wish List'
      onClick={() => onClick?.()}
      onKeyUp={(e) => e.code === "Enter" ? onClick?.() : null}
      onMouseOver={() => {
        setHover(true)
      }}
      onMouseOut={() => {
        setHover(false)
      }}
      style={{
        cursor: 'pointer',
        position: (settings.displayType === 'floating' && position) || undefined,
        transition: 'ease-in-out',
        transitionProperty: 'all',
        transitionDuration: '100ms',
        left:
          (settings.floatingLocation === 'bottomLeft' && edgeMargin) ||
          undefined,
        right:
          (settings.floatingLocation === 'bottomRight' && edgeMargin) ||
          undefined,
        bottom:
          (settings.displayType === 'floating' && edgeMargin) || undefined,
        backgroundColor: isHover
          ? settings.hoverBackgroundColor
          : settings.backgroundColor,
        color: isHover
          ? settings.hoverforegroundColor
          : settings.foregroundColor,
        borderRadius: settings.borderRadius,
        borderColor: settings.borderColor,
        borderWidth: settings.borderWidth || 0,
        paddingLeft: settings.paddingHorizontal || 0,
        paddingRight: settings.paddingHorizontal || 0,
        paddingTop: settings.paddingVertical || 0,
        paddingBottom: settings.paddingVertical || 0,
        boxShadow: (settings.shadow && '0px 0px 10px #888') || ''
      }}>
      <div
        style={{
          display: 'flex',
          position: 'relative',
          justifyItems: 'center',
          alignItems: 'center',
          textAlign: 'center',
          gap: 8
        }}>
        {settings.icon !== 'none' && Icon}
        {settings.showLabel && settings.label}
        
        {settings.showItemCount && (
          <div
            style={{
              display: 'inline-block',
              position: 'absolute',
              transition: 'ease-in-out',
              transitionProperty: 'all',
              transitionDuration: '100ms',
              borderRadius: 333,
              left: 14,
              top: -5,
              paddingLeft: 1,
              paddingRight: 1,
              fontSize: 8,
              fontWeight: 400,
              backgroundColor: isHover
                ? settings.hoverBackgroundColor
                : settings.backgroundColor,
              textAlign: 'center',
              color: isHover
                ? settings.hoverforegroundColor
                : settings.foregroundColor
            }}>
            {count !== undefined && count > 0 && count}
          </div>
        )}
      </div>
    </button>
  )
}
