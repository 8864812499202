import styled from '@emotion/styled'

import { CarouselWrapperProps } from './_interfaces'

const CarouselWrapper = styled.div<CarouselWrapperProps>`
  padding-top: ${(props) => props.containerStyle?.paddingTop};
  padding-right: ${(props) => props.containerStyle?.paddingRight};
  padding-bottom: ${(props) => props.containerStyle?.paddingBottom};
  padding-left: ${(props) => props.containerStyle?.paddingLeft};
  margin-top: ${(props) => props.containerStyle?.marginTop};
  margin-right: ${(props) => props.containerStyle?.marginRight};
  margin-bottom: ${(props) => props.containerStyle?.marginBottom};
  margin-left: ${(props) => props.containerStyle?.marginLeft};
  background-color: ${(props) => props.containerStyle?.backgroundColor};
  border-width: ${(props) => props.containerStyle?.borderThickness !== '0px' ? props.containerStyle?.borderThickness : `${props.containerStyle?.borderTop} ${props.containerStyle?.borderRight} ${props.containerStyle?.borderBottom} ${props.containerStyle?.borderLeft}`};
  border-color: ${(props) => props.containerStyle?.borderColor};
  border-radius: ${(props) => props.containerStyle?.borderRadius};

  .slick-slider {
    min-width: 20vw;
    min-height: 10vh;
  }

  .slick-slide > div  {
    padding: ${(props) => props.primarySetting?.slideSpacing}
  }

  .slick-prev,
  .slick-next {
    ${(props) =>
      props?.primarySetting?.arrowImage
        ? `
      background-image: url('${props.primarySetting?.arrowImage}');
      background-size: contain;
      background-repeat: no-repeat;
      background-color: ${props.primarySetting?.arrowBgColor};
    `
        : ''}
    width: ${(props) =>
      props?.primarySetting?.arrowImage ? props.primarySetting?.arrowSize : '1rem'};
    height: ${(props) =>
      props?.primarySetting?.arrowImage ? props.primarySetting?.arrowSize : '1rem'};
    z-index: 1;

    &::before {
      content: '';
    }
  }

  /* Default Arrow Styles */
  .slick-prev {
    left: ${(props) => props.primarySetting?.arrowHorizontal || 0};
  }

  .slick-next {
    right: ${(props) => props.primarySetting?.arrowHorizontal || 0};
  }

  /* Arrow Styles without Arrow Image */
  ${(props) =>
    props.primarySetting?.arrowImage?.length === 0 &&
    `
    .slick-next::before, .slick-prev::before {
      font-size: ${props.primarySetting?.arrowSize};
      font-family: Courier;
      color: #000;
      background-color: ${props.primarySetting?.arrowBgColor || 'rgba(225,225,225,1)'};
      line-height: 0px;
    }

    .slick-prev::before { 
      content: '\u2039'; 
    }
    .slick-next::before { 
      content: '\u203A'; 
      right: 0px;
      position: absolute;
    }
  `}

  /* Slick Dots Styles Below */
  .slick-dots {
    left: ${(props) => props.primarySetting?.dotHorizontalPosition || 0};
    ${(props) =>
      props.primarySetting?.dotPosition === 'bottom'
        ? `bottom: ${props.primarySetting?.dotVerticalPosition || 0};`
        : `top: ${props.primarySetting?.dotVerticalPosition || 0};`}

    // Cancel default stylesheet styles for li
    // These will be set in the ListElement
    li {
      width: initial;
      height: initial;
      margin: initial;
      padding: initial;
    }

    li.slick-active div {
      background-color: ${(props) =>
        props.primarySetting?.activeDotColor || 'rgba(255,0,0,1)'};
    }
  }

  @media screen and (max-width: 768px) {
    & .desktopEnabled {
      .slick-prev,
      .slick-next {
        ${(props) =>
          props?.secondarySetting?.arrowImage
            ? `
        background-image: url('${props.secondarySetting?.arrowImage}');
        background-size: contain;
        background-repeat: no-repeat;
        background-color: ${props.secondarySetting?.arrowBgColor};
      `
            : ''}
        width: ${(props) =>
          props?.secondarySetting?.arrowImage ? props.secondarySetting?.arrowSize : '1rem'};
        height: ${(props) =>
          props?.secondarySetting?.arrowImage ? props.secondarySetting?.arrowSize : '1rem'};
        z-index: 1;

        &::before {
          content: '';
        }
      }

      /* Default Arrow Styles */
      .slick-prev {
        left: ${(props) => props.secondarySetting?.arrowHorizontal || 0};
      }
      .slick-next {
        right: ${(props) => props.secondarySetting?.arrowHorizontal || 0};
      }

      .slick-slide > div  {
        margin: ${(props) => props.secondarySetting?.slideSpacing}
      }

      /* Arrow Styles without Arrow Image */
      ${(props) =>
        props.secondarySetting?.arrowImage?.length === 0 &&
        `

      .slick-next::before, .slick-prev::before {
        font-size: ${props.secondarySetting?.arrowSize};
        font-family: Courier;
        color: #000;
        background-color: ${props.secondarySetting?.arrowBgColor || 'rgba(225,225,225,1)'};
        line-height: 0px;
      }

      .slick-prev::before {
        content: '\u2039'; 
      }
      .slick-next::before { 
        content: '\u203A'; 
        right: 0px;
        position: absolute;
    }`}

      /* Slick Dots Styles Below */
    .slick-dots {
        left: ${(props) => props.secondarySetting?.dotHorizontalPosition || 0};
        ${(props) =>
          props.secondarySetting?.dotPosition === 'bottom'
            ? `bottom: ${props.secondarySetting?.dotVerticalPosition || 0};`
            : `top: ${props.secondarySetting?.dotVerticalPosition || 0};`}

        // Cancel default stylesheet styles for li
      // These will be set in the ListElement
      li {
          width: initial;
          height: initial;
          margin: initial;
          padding: initial;
        }

        li.slick-active div {
          background-color: ${(props) =>
            props.secondarySetting?.activeDotColor || 'rgba(255,0,0,1)'};
        }
      }
    }
  }
`
export default CarouselWrapper
