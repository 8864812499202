import React from 'react'
import styled from '@emotion/styled'
import Button from '../Button'

interface CustomerConnectFormProps {
  message: string
  returnUrl: string
  onCancel: () => void
}

const CustomerConnectFormWrapper = styled.div`
  .message {
    margin-bottom: 5px;
  }

  Button {
    margin-right: 5px;
  }
`

export default function CustomerConnectForm({ message, returnUrl, onCancel }: CustomerConnectFormProps) {
  return (
    <CustomerConnectFormWrapper className='customerConnectForm'>
      <div className='message'>{message}</div>
      <Button
        type='primary'
        onClick={() => {
          window.location.href = `/account/login?return_url=${encodeURI(returnUrl)}`
        }}>
        Login
      </Button>
      <Button type='link' onClick={onCancel}>
        Cancel
      </Button>
    </CustomerConnectFormWrapper>
  )
}
