import axios from 'axios'
import React, { useCallback, useState } from 'react'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../redux'
import { notification } from '../../redux/slices/app'
import Button from '../Button'
import Select from '../Select'

interface WishListFormProps {
  updateId?: string
  onCancel?: () => void
  onSuccess?: (id: string) => void
}

export default function WishListForm({ updateId, onCancel, onSuccess }: WishListFormProps) {
  const lists = useAppSelector(state => state.wishlist.customer.lists)
  const [name, setName] = useState<string | undefined>('')
  const [visibility, setVisibility] = useState<string | undefined>('private')
  const [saving, setSaving] = useState(false)
  const [errors, setErrors] = useState([])
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (updateId) {
      // updateId provided find object in lists and update data
      const list = lists.find((l: any) => l.id === updateId)
      setName(list?.name)
      setVisibility(list?.visibility)
    }  else {
      setName('')
      setVisibility('private')
    }
  }, [updateId])

  const handleCreateListFormSubmit = useCallback(
    async (e: React.SyntheticEvent) => {
      e.preventDefault()

      try {
        setSaving(true)

        let response
        if (updateId) {
          response = await axios.put(`/customers/wishlist/${updateId}`, {
            name,
            visibility
          })
        } else {
          response = await axios.post('/customers/wishlist', {
            name,
            visibility
          })  
        }
        
        const id = response?.data?.id
        if (id) {
          onSuccess?.(id)
        }
      } catch (e: any) {
        const data = e.response?.data as any
        if (data.code === 422) {
          setErrors(data.errors)
        } else {
          dispatch(notification({ type: 'error', title: 'Oops, something went wrong.' }))
        }
      } finally {
        setSaving(false)
      }
    },
    [name, visibility]
  )

  return (
    <form onSubmit={handleCreateListFormSubmit} style={{ display: 'flex', gap: 5 }}>
      <input
        type='text'
        placeholder='List name'
        style={{ padding: 10, width: 300 }}
        value={name}
        disabled={saving}
        onChange={(e) => setName(e.target.value)}
      />
      {/* <Select
        value={visibility}
        onChange={(e) => setVisibility(e.target.value === 'public' ? 'public' : 'private')}
        disabled={saving}>
        <option value='private'>Private</option>
        <option value='public'>Public</option>
      </Select> */}
      <Button type='primary' htmlType='submit' disabled={saving}>
        Save
      </Button>
      <Button onClick={() => onCancel?.()} type="link" disabled={saving}>
        Cancel
      </Button>
      <div style={{ color: 'red', fontSize: 12 }}>
        {errors.map((e: any) => (
          <div>{e.msg}</div>
        ))}
      </div>
    </form>
  )
}
