import React from 'react'
import { getRelativeShopifyCDNPath } from '../../lib/helpers'

interface ShopifyImageProps {
  src: string
  height: number
  width: number
  style: any
  alt: string
  loading?: 'eager' | 'lazy' | undefined
}

export default function ShopifyImage({ src, height, width, style, alt, loading }: ShopifyImageProps) {
  const lastPeriod = src.lastIndexOf('.')
  const fileName = src.substr(0, lastPeriod)
  const fileExt = src.substr(lastPeriod, src.length - lastPeriod)

  const resolution = width < 2000 ? '_' + width * 2 + 'x' : ''
  const sizedSrc = getRelativeShopifyCDNPath(`${fileName}${resolution}${fileExt}`)
  return <img src={sizedSrc} height={height} width={width} alt={alt} style={style} loading={loading} />
}
